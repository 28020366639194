import React from 'react';
import * as L from './LayoutStyle'
import Menu from "../Menu/Menu";
import { ILayout } from "../../types/type";

const Layout = ({ 
  children 
}: ILayout): JSX.Element => {
  return (
    <L.Main>
      <Menu />
      <L.Content>
        {children}
      </L.Content>
    </L.Main>
  );
};

export default Layout;