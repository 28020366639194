import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as T from '../TablesStyle'
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import { observer } from "mobx-react-lite";
import AdminListStore from "../../../store/AdminListStore";
import AdminAction from "./AdminAction";
import { IAdminsTableProps } from "../../../types/type";
import { adminType } from "../../../api/network";
import { AdminTypes } from "../../../utils/adminType";
import setBlur from '../../../utils/setBlur';
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import isStorageLoading from '../../../utils/isStorageLoading';

const AdminTable = observer(({
  onReset,
  onDelete
}: IAdminsTableProps): JSX.Element => {
  const rowsEntries = Array(AdminListStore.rowsPerPage).fill(0);
  const currentRoles = adminType();
  const shouldRenderActions = () => {
    return currentRoles.includes(AdminTypes.superAdmin) ||
      currentRoles.includes(AdminTypes.canAddEditAdmins);
  };
  const colSpan = Array(shouldRenderActions() ? 3 : 2).fill(0);

  const renderRows = () => {
    if (isStorageLoading(AdminListStore) ||
      (AdminListStore.list.length === 0 && AdminListStore.isFilled)) {
      return (<LoadingTable loading={AdminListStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (AdminListStore.isStorageEmpty) {
      return (<EmptyRow columns={3} />);
    }

    return AdminListStore.list.map((row, index) => {
      if (row === null) {
        return (<LoadingRow key={index} colSpan={colSpan} />);
      }

      return (
        <T.TRow key={row.id}>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">{row.adminType}</TableCell>
          {shouldRenderActions() && (
            <TableCell align="center">
              <AdminAction
                id={row.id}
                email={row.email}
                onReset={onReset}
                onDelete={onDelete}
              />
            </TableCell>
          )}
        </T.TRow>
      );
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Role</TableCell>
            {shouldRenderActions() && (
              <TableCell align="center">Action</TableCell>
            )}
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(AdminListStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
})

export default AdminTable