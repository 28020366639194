import React from 'react';
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import { useNavigate } from "react-router-dom";
import NotificationStore from "../store/NotificationStore";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import Paginator from "../components/Paginator/Paginator";
import NotificationTable from "../components/Tables/NotificationTable/NotificationTable";
import { observer } from "mobx-react-lite";
import useFetchNotification from "../api/useFetch/useFetchNotification";
import NotificationTabs from "../components/Tables/NotificationTable/NotificationTabs";
import getNotificationsList from '../api/actions/getNotificationsList';
import AlignedButtonText from '../common/Text/AlignedButtonText';
import ShowSubLoading from '../utils/ShowSubLoading';
import { RouteList } from '../utils/adminType';

import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const NotificationsPage = observer(() => {
  const navigate = useNavigate();
  const { getNotification } = useFetchNotification();

  useClearStorage(NotificationStore);
  useGetTableData(NotificationStore, () => getNotificationsList(getNotification));

  return (
    <>
      <ShowSubLoading storage={NotificationStore} />
      <h1>Notifications</h1>

      <NotificationTabs />

      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={NotificationStore.rowsPerPage}
            amount={NotificationStore.amount}
            setRowsPerPage={NotificationStore.setRowsPerPage}
            disabled={NotificationStore.disabled}
          />

          {(NotificationStore.isCurrentModeNotification) && <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate(RouteList.addNotification)}
          >
            <AlignedButtonText>
              Add notification
            </AlignedButtonText>
          </Button>}
        </ToolbarTable>

        <NotificationTable />

        <Paginator
          amount={NotificationStore.amount}
          rowsPerPage={NotificationStore.rowsPerPage}
          page={NotificationStore.page}
          setPage={NotificationStore.setPage}
          disabled={NotificationStore.disabled}
        />
      </CardBox>
    </>
  );
});

export default NotificationsPage;