import React from "react";
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import SurveysStore from "../store/SurveysStore";
import SurveysTable from "../components/Tables/SurveysTable/SurveysTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useFetchSurveys from "../api/useFetch/useFetchSurveys";
import { RouteList } from "../utils/adminType";
import getSurveysList from "../api/actions/getSurveysList";
import AlignedButtonText from "../common/Text/AlignedButtonText";
import ShowSubLoading from "../utils/ShowSubLoading";

import useAdjustPage from '../hooks/useAdjustPage';
import useCompleteTableData from '../hooks/useCompleteTableData';
import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const SurveysPage = observer((): JSX.Element => {
  const { getSurveys, deleteSurvey } = useFetchSurveys();
  const navigate = useNavigate();

  useClearStorage(SurveysStore);
  useGetTableData(SurveysStore, () => getSurveysList(getSurveys));
  useCompleteTableData(SurveysStore, getSurveys);
  useAdjustPage(SurveysStore);

  const onOpenSurvey = (id: string) => {
    navigate(`${RouteList.editSurvey}/${id}`);
  };

  const onOpenStatistic = (id: string) => {
    navigate(`${RouteList.surveyStatistic}/${id}`);
  };

  return (
    <>
      <ShowSubLoading storage={SurveysStore} />
      <h1>Surveys list</h1>

      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={SurveysStore.rowsPerPage}
            amount={SurveysStore.amount}
            setRowsPerPage={SurveysStore.setRowsPerPage}
            disabled={SurveysStore.disabled}
          />

          <Button
            variant={"contained"}
            startIcon={<AddCircleIcon />}
            onClick={() => navigate(RouteList.addSurvey)}
          >
            <AlignedButtonText>
              Add survey
            </AlignedButtonText>
          </Button>
        </ToolbarTable>

        <SurveysTable
          onDelete={deleteSurvey}
          onOpen={onOpenSurvey}
          openStatistic={onOpenStatistic}
        />

        <Paginator
          amount={SurveysStore.amount}
          rowsPerPage={SurveysStore.rowsPerPage}
          page={SurveysStore.page}
          setPage={SurveysStore.setPage}
          disabled={SurveysStore.disabled}
        />
      </CardBox>
    </>
  );
});

export default SurveysPage;
