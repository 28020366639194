import React, { useEffect, useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { observer } from "mobx-react-lite";
import LawArticleFormStore from "../../../store/LawArticleFormStore";
import WarningStore from "../../../store/WarningStore";
import { TextEditorContainer } from "./LawArticleFormStyle";

const TextEditor = observer(() => {
  const [editorContent, setEditorContent] = useState(() =>
    EditorState.createEmpty()
  );
  const [textEditorRef, setTextEditorRef] = useState<any>(null);
  const [linkUpdated, setLinkUpdated] = useState(false);

  const editorContainerRef = useRef(null);

  useEffect(() => {
    const editor: any = document.querySelector(".rdw-editor-toolbar");

    if (editor) {
      editor.style.position = "sticky";
      editor.style.top = "0px";
      editor.style.left = "0px";
      editor.style["z-index"] = "999";
    };
  }, []);

  useEffect(() => {
    const getElementBySelector = (selector: string) => {
      return document.querySelector(selector);
    };

    const parentContainer = document.createElement("div");
    parentContainer.style.display = "flex";
    parentContainer.style.marginRight = "40px";
    const colorPicker = getElementBySelector(".rdw-colorpicker-wrapper");
    const toolbar = getElementBySelector(".rdw-editor-toolbar");

    const childrenElements = [
      colorPicker,
      getElementBySelector(".rdw-link-wrapper"),
      getElementBySelector(".rdw-emoji-wrapper"),
      getElementBySelector(".rdw-remove-wrapper"),
      getElementBySelector(".rdw-history-wrapper"),
    ];

    toolbar?.insertBefore(parentContainer, colorPicker);
    childrenElements.forEach((elem: any) => parentContainer.append(elem));
  }, []);

  const updateState = () => {
    const convertedState = convertFromRaw(LawArticleFormStore.editorRawData);
    setEditorContent(EditorState.moveFocusToEnd(
      EditorState.createWithContent(convertedState),
    ));
  };

  /*useEffect(() => {
    const editCache = localStorage.getItem("editCache");
    const { id, lawId } = LawArticleFormStore.getId();
    if (editCache && lawId) {
      const parsedCache = JSON.parse(editCache);

      if (parsedCache.editCache &&
        parsedCache.editCache.blocks.length === 1 &&
        !parsedCache.editCache.blocks[0].text) {
        return;
      }

      if (!parsedCache.id && lawId === parsedCache.lawId && parsedCache.editCache) {
        LawArticleFormStore.editorRawData = parsedCache.editCache;
        updateState();
      }

      if (id && id === parsedCache.id && lawId === parsedCache.lawId && parsedCache.editCache) {
        LawArticleFormStore.editorRawData = parsedCache.editCache;
        updateState();
      }
    }

    if (id || lawId) {
      localStorage.removeItem('editCache');
    }
  }, [LawArticleFormStore.getId()]);*/

  useEffect(() => {
    if (LawArticleFormStore.editorRawData) {
      updateState();
    }

    if (!LawArticleFormStore.editorRawData && LawArticleFormStore.text) {
      const currentState = convertToRaw(editorContent.getCurrentContent());
      currentState.blocks[0].text = LawArticleFormStore.text;
      const convertedState = convertFromRaw(currentState);
      setEditorContent(EditorState.moveFocusToEnd(
        EditorState.createWithContent(convertedState),
      ));
    }
  }, []);

  /*useEffect(() => {
    LawArticleFormStore.turnOnSaver();
    return () => LawArticleFormStore.turnOffSaver();
  }, []);*/

  const onChange = (editorState: any) => {
    setEditorContent(editorState);

    LawArticleFormStore.editorRawData = convertToRaw(editorContent.getCurrentContent());
  };

  const optionsList = [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'emoji',
    'remove',
    'history'
  ];

  const updateStateWithFocus = (removeFocus?: boolean) => {
    setEditorContent(EditorState.moveFocusToEnd(
      EditorState.createWithContent(convertFromRaw(LawArticleFormStore.editorRawData)),
    ));

    if (removeFocus) {
      setLinkUpdated(true);
    }
  };

  const onContainerClick = (e: any) => {
    if (e.target === editorContainerRef.current) {
      updateStateWithFocus();
    }
  };

  useEffect(() => {
    const onModalLinkClick = (e) => {
      const linkModalButton = document.querySelector(".rdw-link-modal-btn");
      if (e.target === linkModalButton) {
        updateStateWithFocus(true);
      }
    };

    const toolbar = document.querySelector(".rdw-editor-toolbar");
    toolbar?.addEventListener("click", onModalLinkClick);
    return () => toolbar?.removeEventListener("click", onModalLinkClick);
  }, []);

  const setEditorReference = (ref: any) => {
    setTextEditorRef(ref);
  };

  useEffect(() => {
    if (textEditorRef && LawArticleFormStore.isCreateModeOn) {
      textEditorRef.focus();
    };
  }, [textEditorRef]);

  useEffect(() => {
    if (textEditorRef && linkUpdated) {
      setTimeout(() => {
        textEditorRef.blur();
        setLinkUpdated(false);
      });
    };
  }, [textEditorRef, linkUpdated]);


  const onInput = () => {
    WarningStore.setIsEdited(true);
  };

  return (
    <TextEditorContainer
      onInput={onInput}
      ref={editorContainerRef}
      onClick={onContainerClick}
    >
      <Editor
        editorState={editorContent}
        onEditorStateChange={onChange}
        toolbar={{
          options: optionsList,
          inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'], },
        }}
        editorRef={setEditorReference}
      />
    </TextEditorContainer>
  );
});

export default TextEditor;