import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const MenuList = ({ onChange, value, list, title }: any): JSX.Element => {
  const renderList = () => {
    return (
      list.map((item:any, index: number) => (
        <MenuItem
          key={index}
          value={item}>
          {item}
        </MenuItem>
      ))
    );
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>{title}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        MenuProps={{ style: { maxHeight: 350 } }}
        label={title}>
        {renderList()}
      </Select>
    </FormControl>
  );
};

export default MenuList;