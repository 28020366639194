export const _transformCandidate = (item: any) => {
  return {
    id: item.id,
    avatar: item.avatar,
    electionCode: item.electionCode,
    mfa: item.mfa ? item.mfa : "-",
    district: item.district,
    party: item.party ? item.party : "-",
    firstName: item.firstName,
    lastName: item.lastName,
    nextInfo: item.nextInfo,
  };
};

export const _transformPartiesList = (item: any) => {
  return {
    id: item.id,
    code: item.code,
    name: item.name,
  };
};

export const _transformNews: any = (item: any) => {
  return {
    id: item.id,
    date: !!item.createdAt
      ? new Date(item.createdAt).toLocaleDateString()
      : "-",
    title: !!item.title
      ? item.title.length > 50
        ? item.title.slice(0, 50)
        : item.title
      : "-",
    text: !!item.text
      ? item.text.length > 100
        ? item.text.slice(0, 100) + "..."
        : item.text
      : "-",
    views: item.views ?? 0,
  };
};

export const _transformNew = (item: any) => {
  return {
    title: item.title,
    text: item.text,
    image: item.image,
  };
};

export const _transformSurveys = (item: any) => {
  return {
    id: item.id,
    title: item.title,
    date: !!item.createdAt
      ? new Date(item.createdAt).toLocaleDateString()
      : "-",
    expirationDate: !!item.expirationDate
      ? new Date(item.expirationDate).toLocaleDateString()
      : "-",
    text: item.text,
  };
};

export const _transformNotification = (item: any) => {
  return {
    id: item.key,
    email: item.email ? item.email : "-",
    notification: {
      title: item.title ? item.title : "-",
      body: item.text ? item.text : "-",
    },
  };
};
