import styled from "styled-components";

export const VideoItem = styled.div`
  width: 100%;
  height: 300px;
  background: #000;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  border: 1px solid #000;
  position: relative;
`;

