import React, { useEffect } from "react";
import { ChatWindow } from "./ChatFormStyles";
import SupportStore from "../../../store/SupportStore";
import { observer } from "mobx-react-lite";

const Chat = observer(({ children }: any): JSX.Element => {
  useEffect(() => {
    const chat: any = document.querySelector("#chat-window");
    chat.scrollTop = chat?.scrollHeight;
  }, [
    Object.values(SupportStore.currentChat.messages).length
  ]);

  return (<ChatWindow id="chat-window" children={children} />);
});

export default Chat;