import { makeAutoObservable } from "mobx";

class MapStore {
  coords: any = "";
  id: string = "";
  title: string = "";
  text: string = "";
  district: any = "";
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  };

  resetComplaintData() {
    this.coords = "";
    this.id = "";
    this.title = "";
    this.text = "";
    this.district = "";
  };

  setData(field: string, data: any) {
    this[field] = data;
  };
};

export default new MapStore();