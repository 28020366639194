import React, { useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { isAuth } from "../api/network";
import Layout from "../common/Layout/Layout";
import ModalConfirm from "../common/Modal/ModalConfirm";
import WarningStore from "../store/WarningStore";
import { observer } from "mobx-react-lite";
import { forceLogout } from "../api/network";
import { RouteList, AdminTypes } from "./adminType";
import { adminType } from "../api/network";
import useFetchComplaints from "../api/useFetch/useFetchComplaints";
import ComplaintsStore from "../store/ComplaintsStore";

type PrivateRouteProps = {
  outlet: JSX.Element;
};

const PrivateRoute = observer(({ outlet }: PrivateRouteProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getNewComplaintsAmount } = useFetchComplaints();
  const currentAdminType = adminType();

  const shouldGetComplaints = () => {
    return (currentAdminType.includes(AdminTypes.superAdmin) ||
      currentAdminType.includes(AdminTypes.complaintsManager)) &&
      location.pathname !== RouteList.complaintsList &&
      ComplaintsStore.counterIsNotInitiated
  };

  useEffect(() => {
    if (shouldGetComplaints()) {
      getNewComplaintsAmount()
        .then(({ complaintsAmount }) => ComplaintsStore.setCounter(complaintsAmount))
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (!isAuth) {
      localStorage.setItem("showNotification", "true");
      forceLogout();
    }
  });

  const confirmedRedirect = async () => {
    navigate(WarningStore.navPoint);
    WarningStore.resetStorage();
  };

  return isAuth ? (
    <Layout>
      <>
        {outlet}
        {WarningStore.isModalOpen && (
          <ModalConfirm
            setActive={WarningStore.toggleModal}
            windowTitle={WarningStore.modalTitle}
            disableStates={[WarningStore.toggleModal]}
            callback={confirmedRedirect}
          />
        )}
      </>
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
});

export default PrivateRoute;
