//react
import * as React from "react";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

//storage
import { observer } from "mobx-react-lite";
import ThemeModeStore from "./store/ThemeModeStore";
import ElectionStore from "./store/ElectionModeStore";
import LoginStore from "./store/LoginStore";
import CandidateStore from "./store/CandidateStore";
import DashboardStore from "./store/DashboardStore";

//styles && subcomponents
import { createTheme, ThemeProvider } from "@mui/material";
import { getDesignTokens } from "./styles/theme";
import GlobalStyles from "./styles/global";
import ModalConfirm from "./common/Modal/ModalConfirm";
import ModalTFACode from "./common/Modal/ModalTFACode";

//network && hooks && utils
import { adminType, isAuth } from "./api/network";
import useFetchElectionMode from "./api/useFetch/useFetchElectionMode";
import { AdminTypes, RouteList } from "./utils/adminType";
import PrivateRoute from "./utils/PrivateRoute";
import useTFA from "./api/useFetch/useEnableTFA";

//components
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import AdminListPages from "./pages/AdminListPage";
import PartiesPage from "./pages/PartiesPage";
import NewsPage from "./pages/NewsPage";
import SurveysPage from "./pages/SurveysPage";
import AddEditPartyPage from "./pages/AddEditPartyPage";
import AddAdminPage from "./pages/AddAdminPage";
import AddEditNewsPage from "./pages/AddEditNewsPage";
import NotificationsPage from "./pages/NotificationsPage";
import AddNotification from "./pages/AddNotification";
import ReportsPage from "./pages/ReportsPage";
import AddSurveyPage from "./pages/AddSurveyPage/";
import ChangeElectionModePage from "./pages/ChangeElectionModePage";
import ComplaintsPage from "./pages/ComplaintsPage";
import ComplaintPage from "./pages/ComplaintPage";
import AddCandidatePage from "./pages/AddCandidatePage";
import SurveysStatsPage from "./pages/SurveysStatPage";
import MapPlaceholder from "./pages/MapPlaceholder";
import Districts from "./pages/Districts";
import OpenNewsArticle from "./pages/OpenNewsArticle";
import PasswordSuccessPage from "./pages/PasswordSuccessPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import SupportPage from "./pages/SupportPage";
import ChatPage from "./pages/ChatPage";
import LogsPage from "./pages/LogsPage";
import LawsPage from "./pages/LawsPage";
import LawPage from "./pages/LawPage";
import AddEditLawPage from "./pages/AddEditLawPage";
import AddEditLawArticlePage from "./pages/AddEditLawArticlePage";
import LawArticlePage from "./pages/LawArticlePage";
import PodcastsPage from "./pages/PodcastsPage";
import AddEditPodcastPage from "./pages/AddEditPodcastPage";
import PodcastPage from "./pages/PodcastPage";
import AddEditEpisode from "./pages/AddEditEpisode";
import EpisodePage from "./pages/EpisodePage";
import ComplaintsReportsPage from "./pages/ComplaintsReportsPage";
import ChangePCMElectionModePage from "./pages/ChangePCMElectionModePage";
import useFetchPCMElectionMode from "./api/useFetch/useFetchPCMElectionMode";
import PCMElectionModeStore from "./store/PCMElectionModeStore";

const App = observer(() => {
  const [isConfirmOpened, setIsConfirmOpened] = useState(false);
  const [confirmAction, setConfirmAction] = useState<any>({ action: null });
  const [confirmTitle, setConfirmTitle] = useState<any>("");
  const [showConfirmTFA, setShowConfirmTFA] = useState(false);

  const theme = createTheme(getDesignTokens(ThemeModeStore.mode));
  const { getCurrentMode } = useFetchElectionMode();
  const { getCurrentPCMMode } = useFetchPCMElectionMode()
  const { generateQR } = useTFA();
  const shouldPrecache = () => {
    return (
      adminType().includes("admin") ||
      adminType().includes("superadmin") ||
      adminType().includes("spectator")
    );
  };

  const showQR = (url: any) => {
    setConfirmTitle(
      <div>
        <p>Scan this QRCode via your Google Authenticator application.</p>
        <img src={url} />
        <p>When you end up with application, please, confirm operation.</p>
      </div>
    );
    setConfirmAction({
      action: async () => {
        setTimeout(() => setShowConfirmTFA(true));
      },
    });
    setIsConfirmOpened(true);
  };

  useEffect(() => {
    return () => LoginStore.resetStorage();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("suggest")) {
      setConfirmTitle(
        "Would you like to enable two factor authentication for your profile?"
      );
      setConfirmAction({
        action: async () => {
          const email = localStorage.getItem("email");
          const { QRCode, secret } = await generateQR(email).catch((err) =>
            console.log(err)
          );
          LoginStore.setData("secret", secret);
          LoginStore.setData("email", email);
          setTimeout(() => showQR(QRCode));
        },
      });
      setIsConfirmOpened(true);
    }
  }, [localStorage.getItem("suggest")]);

  useEffect(() => {
    if (isAuth) {
      getCurrentMode().then((res: any) => {
        const currentMode = !!res ? res : "election";
        ElectionStore.setCurrentMode(currentMode);
        const allias: string =
          currentMode.toLowerCase() !== "final"
            ? "Candidate"
            : "Member of Parliament";
        CandidateStore.setCurrentAllias(allias);
      });
      getCurrentPCMMode().then((res: any) => {
        const currentMode = !!res ? res : "election";
        PCMElectionModeStore.setCurrentMode(currentMode);
        const allias: string =
            currentMode.toLowerCase() !== "final"
                ? "Candidate"
                : "Provincial Council Member";
        CandidateStore.setCurrentPCMAllias(allias);
      });
    }
  }, [isAuth]);

  useEffect(() => {
    if (LoginStore.isNotChached) {
      const shouldCachePage = () => {
        return (
          LoginStore.isNotChached && shouldPrecache() && DashboardStore.isFilled
        );
      };

      if (shouldCachePage()) {
        LoginStore.launchCacher(adminType());
      }
    }
  }, [LoginStore.isNotChached, DashboardStore.isFilled]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          {!isAuth && <Route path={RouteList.home} element={<LoginPage />} />}

          {adminType().map((type, index) => (
            <React.Fragment key={index}>
              <Route
                path="/"
                element={
                  <>
                    {type === AdminTypes.complaintsManager &&
                      ElectionStore.isCurrentModeFinal && (
                        <Navigate replace to={RouteList.complaintsList} />
                      )}
                    {type === AdminTypes.spectator && (
                      <PrivateRoute outlet={<DashboardPage candidateType="mp-candidate" />} />
                    )}
                    {type === AdminTypes.newsManager && (
                      <Navigate replace to={RouteList.news} />
                    )}
                    {type === AdminTypes.surveysManager && (
                      <Navigate replace to={RouteList.surveys} />
                    )}
                    {type === AdminTypes.admin && (
                      <PrivateRoute outlet={<DashboardPage candidateType="mp-candidate" />} />
                    )}
                    {type === AdminTypes.superAdmin && (
                      <PrivateRoute outlet={<DashboardPage candidateType="mp-candidate" key={22}/>} />
                    )}
                    {type === AdminTypes.partyManager && (
                      <PrivateRoute outlet={<PartiesPage />} />
                    )}
                    {type === AdminTypes.supportManager && (
                      <PrivateRoute outlet={<SupportPage />} />
                    )}
                    {type === AdminTypes.lawsManager && (
                      <PrivateRoute outlet={<LawsPage />} />
                    )}
                    {type === AdminTypes.podcastManager && (
                      <PrivateRoute outlet={<PodcastsPage />} />
                    )}
                  </>
                }
              />

              {(type === AdminTypes.superAdmin ||
                (type === AdminTypes.admin &&
                  adminType().includes(AdminTypes.canEditOtherCandidates))) && (
                <Route
                  path={`${RouteList.editCandidate}/:id`}
                  element={<PrivateRoute outlet={<AddCandidatePage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin || type === AdminTypes.admin || type === AdminTypes.spectator) && (
                  <Route
                      path={`${RouteList.pcmHome}`}
                      element={
                          <>
                              <PrivateRoute outlet={<DashboardPage candidateType="pcm-candidate" key={23}/>}/>
                          </>}
                  />
              )}

              {(type === AdminTypes.superAdmin ||
                (type === AdminTypes.admin &&
                  adminType().includes(AdminTypes.canEditOtherCandidates))) && (
                <Route
                  path={RouteList.addCandidate}
                  element={<PrivateRoute outlet={<AddCandidatePage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin ||
                  (type === AdminTypes.admin &&
                      adminType().includes(AdminTypes.canEditOtherCandidates))) && (
                  <Route
                      path={RouteList.addPCMCandidate}
                      element={<PrivateRoute outlet={<AddCandidatePage />} />}
                  />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.admin) && (
                <Route
                  path={RouteList.admin}
                  element={<PrivateRoute outlet={<AdminListPages />} />}
                />
              )}
              {(type === AdminTypes.superAdmin ||
                (type === AdminTypes.admin &&
                  adminType().includes(AdminTypes.canAddEditAdmins))) && (
                <Route
                  path={RouteList.addAdmin}
                  element={<PrivateRoute outlet={<AddAdminPage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.spectator ||
                type === AdminTypes.partyManager ||
                type === AdminTypes.admin) && (
                <Route
                  path={RouteList.parties}
                  element={<PrivateRoute outlet={<PartiesPage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.spectator ||
                (type === AdminTypes.admin &&
                  adminType().includes(AdminTypes.canCreateParties))) && (
                <Route
                  path={RouteList.addParty}
                  element={<PrivateRoute outlet={<AddEditPartyPage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.spectator ||
                (type === AdminTypes.admin &&
                  adminType().includes(AdminTypes.canCreateParties)) ||
                type === AdminTypes.partyManager) && (
                <Route
                  path={`${RouteList.editParty}/:id`}
                  element={<PrivateRoute outlet={<AddEditPartyPage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.newsManager) && (
                <>
                  <Route
                    path={RouteList.news}
                    element={<PrivateRoute outlet={<NewsPage />} />}
                  />
                  <Route
                    path={RouteList.addNews}
                    element={<PrivateRoute outlet={<AddEditNewsPage />} />}
                  />
                  <Route
                    path={`${RouteList.editNews}/:id`}
                    element={<PrivateRoute outlet={<AddEditNewsPage />} />}
                  />
                </>
              )}

              {type === AdminTypes.superAdmin && (
                <>
                  <Route
                    path={RouteList.notificationsList}
                    element={<PrivateRoute outlet={<NotificationsPage />} />}
                  />
                  <Route
                    path={RouteList.addNotification}
                    element={<PrivateRoute outlet={<AddNotification />} />}
                  />
                </>
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.complaintsManager) &&
                ElectionStore.isCurrentModeFinal && (
                  <>
                    <Route
                      path={RouteList.complaintsList}
                      element={<PrivateRoute outlet={<ComplaintsPage />} />}
                    />
                    <Route
                      path={RouteList.complaintsReports}
                      element={
                        <PrivateRoute outlet={<ComplaintsReportsPage />} />
                      }
                    />
                    <Route
                      path={`${RouteList.complaint}/:id`}
                      element={<PrivateRoute outlet={<ComplaintPage />} />}
                    />
                  </>
                )}

              {type === AdminTypes.superAdmin && (
                <Route
                  path={RouteList.logs}
                  element={<PrivateRoute outlet={<LogsPage />} />}
                />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.surveysManager) && (
                <>
                  <Route
                    path={RouteList.surveys}
                    element={<PrivateRoute outlet={<SurveysPage />} />}
                  />
                  <Route
                    path={RouteList.addSurvey}
                    element={<PrivateRoute outlet={<AddSurveyPage />} />}
                  />
                  <Route
                    path={`${RouteList.editSurvey}/:id`}
                    element={<PrivateRoute outlet={<AddSurveyPage />} />}
                  />
                  <Route
                    path={`${RouteList.surveyStatistic}/:id`}
                    element={<PrivateRoute outlet={<SurveysStatsPage />} />}
                  />
                </>
              )}

              {type === AdminTypes.superAdmin && (
                <Route
                  path={RouteList.changeElectionMode}
                  element={<PrivateRoute outlet={<ChangeElectionModePage />} />}
                />
              )}

              {type === AdminTypes.superAdmin && (
                  <Route
                      path={RouteList.changePCMElectionMode}
                      element={<PrivateRoute outlet={<ChangePCMElectionModePage />} />}
                  />
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.supportManager) && (
                <>
                  <Route
                    path={RouteList.support}
                    element={<PrivateRoute outlet={<SupportPage />} />}
                  />
                  <Route
                    path={`${RouteList.supportChat}/:id`}
                    element={<PrivateRoute outlet={<ChatPage />} />}
                  />
                </>
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.podcastManager) && (
                <>
                  <Route
                    path={RouteList.podcasts}
                    element={<PrivateRoute outlet={<PodcastsPage />} />}
                  />
                  <Route
                    path={RouteList.addPodcast}
                    element={<PrivateRoute outlet={<AddEditPodcastPage />} />}
                  />
                  <Route
                    path={`${RouteList.podcast}/:id`}
                    element={<PrivateRoute outlet={<PodcastPage />} />}
                  />
                  <Route
                    path={`${RouteList.addEpisode}/:podcastId/episodes/add`}
                    element={<PrivateRoute outlet={<AddEditEpisode />} />}
                  />
                  <Route
                    path={`${RouteList.editPodcast}/:id`}
                    element={<PrivateRoute outlet={<AddEditPodcastPage />} />}
                  />
                  <Route
                    path={`${RouteList.editEpisode}/:podcastId/episodes/edit/:id`}
                    element={<PrivateRoute outlet={<AddEditEpisode />} />}
                  />
                  <Route
                    path={`${RouteList.episode}/:podcastId/episodes/:id`}
                    element={<PrivateRoute outlet={<EpisodePage />} />}
                  />
                </>
              )}

              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.lawsManager) && (
                <>
                  <Route
                    path={RouteList.laws}
                    element={<PrivateRoute outlet={<LawsPage />} />}
                  />
                  <Route
                    path={RouteList.addLaw}
                    element={<PrivateRoute outlet={<AddEditLawPage />} />}
                  />
                  <Route
                    path={`${RouteList.editLaws}/:id`}
                    element={<PrivateRoute outlet={<AddEditLawPage />} />}
                  />
                  <Route
                    path={`${RouteList.law}/:id`}
                    element={<PrivateRoute outlet={<LawPage />} />}
                  />
                  <Route
                    path={`${RouteList.lawArticleEdit}/:lawId/articles/edit/:id`}
                    element={
                      <PrivateRoute outlet={<AddEditLawArticlePage />} />
                    }
                  />
                  <Route
                    path={`${RouteList.lawArticleAdd}/:lawId/articles/add`}
                    element={
                      <PrivateRoute outlet={<AddEditLawArticlePage />} />
                    }
                  />
                  <Route
                    path={`${RouteList.lawArticle}/:lawId/articles/:id`}
                    element={<PrivateRoute outlet={<LawArticlePage />} />}
                  />
                </>
              )}

              {type === AdminTypes.superAdmin && (
                <Route
                  path={RouteList.reports}
                  element={<PrivateRoute outlet={<ReportsPage />} />}
                />
              )}
              {(type === AdminTypes.superAdmin ||
                type === AdminTypes.admin) && (
                <>
                  <Route
                    path={RouteList.map}
                    element={<PrivateRoute outlet={<MapPlaceholder />} />}
                  />
                  <Route
                    path={RouteList.districts}
                    element={<PrivateRoute outlet={<Districts />} />}
                  />
                </>
              )}
            </React.Fragment>
          ))}

          <Route path={RouteList.article} element={<OpenNewsArticle />} />

          <Route
            path={RouteList.resetPassword}
            element={<PasswordResetPage />}
          />

          <Route
            path={RouteList.passwordSuccess}
            element={<PasswordSuccessPage />}
          />

          <Route
            path={RouteList.all}
            element={<PrivateRoute outlet={<h1>404 Page not found</h1>} />}
          />
        </Routes>
      </Router>

      {isConfirmOpened && (
        <ModalConfirm
          setActive={setIsConfirmOpened}
          windowTitle={confirmTitle}
          disableStates={[
            (state: boolean) => {
              localStorage.removeItem("suggest");
              localStorage.removeItem("email");
              setIsConfirmOpened(state);
            },
          ]}
          callback={confirmAction.action}
        />
      )}

      {showConfirmTFA && <ModalTFACode shouldRender={showConfirmTFA} />}
    </ThemeProvider>
  );
});

export default App;
