import network from "../../api/network";

const fetcher = (url: string) => {
  return async () => {
    const response = await network.get(url);

    return response.data;
  };
};

export default fetcher;