import React from 'react';
import useGenerateMFA from "../../api/useFetch/useGenerateMFA";
import { InputMFAProps } from "../../types/type";
import { Wrap, BtnMFA, Loader, Input, TextLabelName } from "./InputStyles";
import { observer } from "mobx-react-lite";
import CandidateStore from '../../store/CandidateStore';

const InputMFA = observer(({
  label,
  value,
  onChange,
  dataTestId,
  sendMessage,
  ...restProps
}: InputMFAProps): JSX.Element => {
  const { loading: MFALoading, generateMFA } = useGenerateMFA();

  const onGenerateMFAClick = () => {
    if (!MFALoading) {
      onChange("");
      generateMFA(sendMessage ? CandidateStore.getMFAData() : {})
        .then((res: any) => onChange(res.mfa))
        .catch((err: any) => console.log(err.response.data));
    }
  };

  return (
    <label data-testid="input-MFA-component">
      <TextLabelName>{label}</TextLabelName>
      <Wrap>
        {MFALoading && <Loader size={15} />}
        <Input
          value={value}
          data-testid={dataTestId}
          readOnly
          {...restProps}
        />
        <BtnMFA data-testid="generate-MFA" onClick={onGenerateMFAClick}>
          Generate another code
        </BtnMFA>
      </Wrap>
    </label>
  );
});

export default InputMFA;
