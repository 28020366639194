import React from "react";
// @ts-expect-error
import logo from "../../assets/images/logo.svg";
import * as M from "./MenuStyle";
import SwitchMode from "../../UI/Buttons/SwitchMode";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import { adminType, forceLogout } from "../../api/network";
import { observer } from "mobx-react-lite";
import ElectionStore from "../../store/ElectionModeStore";
import WarningStore from "../../store/WarningStore";
import ComplaintsStore from "../../store/ComplaintsStore";
import { AdminTypes, RouteList } from "../../utils/adminType";
import { useNavigate } from "react-router-dom";
import LawStore from "../../store/LawStore";
import PCMElectionModeStore from "../../store/PCMElectionModeStore";

const Menu = observer(() => {
  const navigate = useNavigate();

  const handleRedirect = (e: any) => {
    e.preventDefault();
    const path = e.target.name;

    if (path === "/laws") LawStore.resetPagination();
    if (WarningStore.isEdited) {
      WarningStore.toggleModal(true);
      WarningStore.setNavPoint(path);
      return;
    }

    navigate(path);
  };

  return (
    <M.Aside data-testid="main-menu">
      <M.Logo to="/">
        <img style={{ width: "100%", height: "100%" }} src={logo} alt="logo" />
      </M.Logo>
      <M.ToolBar>
        <SwitchMode />
        <Button
          data-testid="logout-btn"
          onClick={() => forceLogout()}
          startIcon={<LogoutIcon />}
        >
          Log out
        </Button>
      </M.ToolBar>

      <M.Mode>
        MP Election mode -{" "}
        <span className={"mode"}>
          {ElectionStore.isCurrentModeEmpty ? "..." : ElectionStore.currentMode}
        </span>
      </M.Mode>
        <M.Mode>
            PCM Election mode -{" "}
            <span className={"mode"}>
          {PCMElectionModeStore.isCurrentModeEmpty ? "..." : PCMElectionModeStore.currentMode}
        </span>
        </M.Mode>
      <M.Menu>
        {adminType().map((type, index) => (
            <React.Fragment key={index}>
                <M.SubTitle>MP Section</M.SubTitle>
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.spectator ||
                    type === AdminTypes.admin) && (
                    <M.MenuItem
                        to={RouteList.home}
                        dataTestId="mp-candidates-list-button"
                        label={
                            ElectionStore.isCurrentModeFinal
                                ? "Members of Parliament"
                                : "MP Candidates List"
                        }
                        handler={handleRedirect}
                        end={true}
                    />

                )}
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.complaintsManager) &&
                ElectionStore.isCurrentModeFinal && (
                    <M.MenuItem
                        to={RouteList.complaintsList}
                        label="Complaints"
                        handler={handleRedirect}
                        dataTestId="complaints-list-button"
                        showCounter={true}
                        count={ComplaintsStore.counter}
                    />
                )}
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.complaintsManager) &&
                ElectionStore.isCurrentModeFinal && (
                    <M.MenuItem
                        to={RouteList.complaintsReports}
                        label="Complaints Reports"
                        handler={handleRedirect}
                        dataTestId="complaints-reports-button"
                    />
                )}
                {type === AdminTypes.superAdmin && (
                    <M.MenuItem
                        to={RouteList.changeElectionMode}
                        label="Change mp election mode"
                        handler={handleRedirect}
                        dataTestId="changeElectionMode-button"
                    />
                )}
                <M.SubTitle>PCM Section</M.SubTitle>
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.spectator ||
                    type === AdminTypes.admin) && (
                    <M.MenuItem
                        to={RouteList.pcmHome}
                        dataTestId="pcm-candidates-list-button"
                        label={
                            PCMElectionModeStore.isCurrentModeFinal
                                ? "Province Council Member"
                                : "PCM Candidates List"
                        }
                        handler={handleRedirect}
                        end={true}
                    />
                )}
                {type === AdminTypes.superAdmin && (
                    <M.MenuItem
                        to={RouteList.changePCMElectionMode}
                        label="Change PCM election mode"
                        handler={handleRedirect}
                        dataTestId="changePCMElectionMode-button"
                    />
                )}
                <M.SubTitle>Data list</M.SubTitle>

                {(type === AdminTypes.superAdmin || type === AdminTypes.admin) && (
                    <M.MenuItem
                        to={RouteList.admin}
                        label="Admins"
                        handler={handleRedirect}
                        dataTestId="admins-list-button"
                    />
                )}

                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.spectator ||
                    type === AdminTypes.partyManager ||
                    type === AdminTypes.admin) && (
                    <M.MenuItem
                        to={RouteList.parties}
                        label="Parties"
                        handler={handleRedirect}
                        dataTestId="parties-list-button"
                    />
                )}

                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.newsManager) && (
                    <M.MenuItem
                        to={RouteList.news}
                        label="News"
                        handler={handleRedirect}
                        dataTestId="news-list-button"
                    />
                )}

                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.surveysManager) && (
                    <M.MenuItem
                        to={RouteList.surveys}
                        label="Surveys"
                        handler={handleRedirect}
                        dataTestId="surveys-list-button"
                    />
                )}

                {type === AdminTypes.superAdmin && (
                    <M.MenuItem
                        to={RouteList.notificationsList}
                        label="Notifications"
                        handler={handleRedirect}
                        dataTestId="notifications-list-button"
                    />
                )}

                {type === AdminTypes.superAdmin && (
                    <M.MenuItem
                        to={RouteList.logs}
                        label="Logs"
                        handler={handleRedirect}
                        dataTestId="logs-list-button"
                    />
                )}
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.lawsManager) && (
                    <M.MenuItem
                        to={RouteList.laws}
                        label="Laws"
                        handler={handleRedirect}
                        dataTestId="laws-list-button"
                    />
                )}
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.podcastManager) && (
                    <M.MenuItem
                        to={RouteList.podcasts}
                        label="Podcasts"
                        handler={handleRedirect}
                        dataTestId="podcasts-list-button"
                    />
                )}

                {(type === AdminTypes.superAdmin || type === AdminTypes.admin) && (
                    <M.SubTitle>APPS & PAGES</M.SubTitle>
                )}
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.complaintsManager ||
                    type === AdminTypes.admin) && (
                    <M.MenuItem
                        to={RouteList.map}
                        label="Map"
                        handler={handleRedirect}
                        dataTestId="map-button"
                    />
                )}
                {type === AdminTypes.superAdmin && (
                    <M.MenuItem
                        to={RouteList.reports}
                        label="Reports"
                        handler={handleRedirect}
                        dataTestId="reports-button"
                    />
                )}
                {(type === AdminTypes.superAdmin ||
                    type === AdminTypes.supportManager) && (
                    <M.MenuItem
                        to={RouteList.support}
                        label="Support"
                        handler={handleRedirect}
                    />
                )}
            </React.Fragment>
        ))}
      </M.Menu>
    </M.Aside>
  );
});

export default Menu;
