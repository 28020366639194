import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NewsForm from "../components/Form/NewsForm/NewsForm";
import { useNavigate } from "react-router-dom";
import NewsFormStore from "../store/NewsFormStore";
import WarningStore from "../store/WarningStore";
import useFetchNews from "../api/useFetch/useFetchNews";
import { observer } from "mobx-react-lite";
import navigator from "../utils/navigator";
import { RouteList } from "../utils/adminType";

const AddEditNewsPage = observer(() => {
  const { loading: loadingNewItem, getNew } = useFetchNews();
  const { loading: addingNews, addNews } = useFetchNews();
  const { loading: editingNews, editNews } = useFetchNews();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && NewsFormStore.isCreateModeOn) {
      NewsFormStore.setMode("edit");
      getNew(id).then((res: any) => {
        if (res) {
          NewsFormStore.initiate(res);
        } else {
          NewsFormStore.setError();
        }
      });
    }
    return () => NewsFormStore.resetStorage();
  }, []);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const redirectOnCreation = () => navigate(RouteList.news);

  const onAddEditNews = async () => {
    const data = NewsFormStore.getData();

    if (id) {
      NewsFormStore.setMode("changing");
      editNews(id, data).finally(redirectOnCreation);
    } else {
      addNews(data).finally(redirectOnCreation);
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      <h1>{NewsFormStore.pageTitle}</h1>

      <NewsForm
        onAddEdit={onAddEditNews}
        onBack={onBackClick}
        isLoading={loadingNewItem || addingNews || editingNews}
      />

    </Stack>
  );
});

export default AddEditNewsPage;
