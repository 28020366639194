import { useEffect } from "react";

//for those cases, where on last page of table there only one item, and we remove it
//so it cause situation, where user will on page number that doesnt exist anymore, so we need to decrement page

const useAdjustPage = (storage: any) => {
  useEffect(() => {
    const amountOfPages = Math.ceil(
      +storage.amount / +storage.rowsPerPage
    );
    if (amountOfPages < storage.page && storage.amount > 0) {
      storage.setPage(amountOfPages);
    }
  }, [storage.amount]);
};

export default useAdjustPage;