import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";
import { _transformSurveys } from "../tranformResponse";

const useFetchSurveys = () => {
  const { loading, request, error, clearError } = useHttp();

  const getSurveys = async ({ page, rowsPerPage }: IGetList) => {
    const res = await request('get', 'api/check/surveys', {
      params: {
        page,
        rowsPerPage,
      }
    });

    return {
      amount: res.amount,
      list: res.list.map(_transformSurveys)
    };
  };

  const getSurvey = async (id: string | undefined) => {
    return await request('get', `api/check/surveys/${id}`);
  };

  const createSurvey = async (data: any) => {
    return await request('put', "api/create/surveys", data);
  };

  const deleteSurvey = async (id: string) => {
    return await request('delete', `api/create/surveys/${id}`);
  };

  const updateSurvey = async (id: string, data: any) => {
    return await request('post', `api/create/surveys/${id}`, data);
  };

  return {
    loading, 
    error, 
    clearError,
    getSurveys,
    createSurvey,
    deleteSurvey,
    getSurvey,
    updateSurvey
  };
}

export default useFetchSurveys;