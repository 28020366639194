import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import ModalConfirm from "./ModalConfirm";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoginStore from "../../store/LoginStore";
import useTFA from "../../api/useFetch/useEnableTFA";

const ModalTFACode = observer(({
  shouldRender,
}: any): JSX.Element => {
  const [showWindow, setShowWindow] = useState(shouldRender);
  const [invalidCode, setInvalidCode] = useState(false);
  const {
    loading,
    enableTFA,
    verifyCode
  } = useTFA();

  const handleTFA = (e: any) => {
    e.preventDefault();
    setInvalidCode(false);
    verifyCode(LoginStore.email, LoginStore.code, LoginStore.secret)
      .then(() => {
        enableTFA(LoginStore.secret, LoginStore.email);
        setShowWindow(false);
        LoginStore.resetStorage();
      })
      .catch((e) => {
        console.log(e);
        setInvalidCode(true);
      })
  };

  const windowTitle = (
    <form data-testid="TFA-submit-form" onSubmit={(e) => handleTFA(e)} style={{ width: "100%", marginBottom: 15 }}>
      {invalidCode && (
        <div style={{ display: "flex", justifyContent: "center", }}>
          <p style={{ margin: 0, marginBottom: 15, color: "#FF0000" }}>Code is invalid. Please, try again</p>
        </div>
      )}
      <TextField
        sx={{ marginBottom: '15px' }}
        fullWidth
        label="Two factory authentication code"
        variant="outlined"
        type="text"
        autoFocus
        value={LoginStore.code}
        onChange={(e: any) => {
          LoginStore.setCode(e.target.value);
        }}
      />
      <LoadingButton
        loading={loading}
        disabled={loading || LoginStore.code.length === 0}
        type="submit"
        size="large"
        fullWidth
        variant="contained"
      >
        Confirm
      </LoadingButton>
    </form>
  );
  return (showWindow) ? (
    <ModalConfirm
      setActive={setShowWindow}
      windowTitle="Enter six digits code from you app to confirm TFA enabling"
      windowComponent={windowTitle}
      disableStates={[(state: boolean) => {
        localStorage.removeItem("suggest");
        localStorage.removeItem("email");
        setShowWindow(state);
      }]}
      callback={() => { }}
      shouldntRenderButtons={true}
    />
  ) : (<></>);
});
export default ModalTFACode;