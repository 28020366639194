import React, { useState } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import ModalAlert from "../../../common/Modal/ModalAlert";
import { observer } from "mobx-react-lite";
import { IAdminsActionItem } from "../../../types/type";
import AdminListStore from "../../../store/AdminListStore";

const AdminAction = observer(({
  id,
  email,
  onReset,
  onDelete
}: IAdminsActionItem): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState<any>({ action: null });

  const openModal = (title: string, action: Function) => {
    setIsModalOpen(true);
    setModalTitle(title);
    setModalAction({
      action: async () => {
        action();
        setAnchorEl(null);
      }
    });
    setAnchorEl(null);
  };

  const resetPassword = () => {
    if (email) {
      onReset(email).then(() => {
        setModalTitle("Password successfully resetted!");
        setAlert(true);
      });
    };
  };

  const resetPasswordConfirm = () => {
    openModal('Are you sure to resend?', resetPassword);
  };

  const closeAlert = async () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    openModal('Are you sure to delete this admin?', handleDelete);
  };

  const handleDelete = async () => {
    AdminListStore.removeItemLocally(id);
    AdminListStore.setInQueue(id);

    onDelete(id).finally(() => AdminListStore.removeFromQueue(id));
  };

  return (
    <>
      <ActionMenuButton anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem onClick={resetPasswordConfirm}><LockResetIcon /> Reset password</MenuItem>
        <MenuItem onClick={handleDeleteConfirm}><DeleteIcon color={'error'} /> Delete</MenuItem>
      </ActionMenuButton>

      {isModalOpen && (
        <ModalConfirm
          setActive={setIsModalOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalOpen]}
          callback={modalAction.action}
        />
      )}

      {alert && (
        <ModalAlert
          setActive={setAlert}
          windowTitle={modalTitle}
          disableStates={[setAlert]}
          callback={closeAlert}
        />
      )}
    </>
  );
});

export default AdminAction;