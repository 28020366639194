import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import * as S from './ShowEntriesStyle';
import { IShowEntries } from "../../types/type";

const ShowEntries = ({ rowsPerPage, amount, setRowsPerPage, disabled }: IShowEntries) => {
  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage(+event.target.value)
  };

  return (
    <S.Wrap>
      <S.Text>Show</S.Text>
      <FormControl>
        <Select
          id="show-entries-select"
          value={rowsPerPage.toString()}
          onChange={handleChange}
          disabled={disabled}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          {/*<MenuItem value={amount}>All</MenuItem>*/}
        </Select>
      </FormControl>
      <S.Text>entries</S.Text>
    </S.Wrap>
  );
};

export default ShowEntries;