import React from "react";
import { Pagination } from "@mui/material";
import * as P from "./PaginatorStyle";
import { IPaginator } from "../../types/type";

const Paginator = ({
  amount,
  setPage,
  page,
  rowsPerPage,
  disabled,
}: IPaginator): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      {amount > 5 && rowsPerPage !== amount && (
        <P.Wrap>
          <Pagination
            disabled={disabled}
            shape="rounded"
            count={
              Math.ceil(amount / rowsPerPage)
                ? Math.ceil(amount / rowsPerPage)
                : 1
            }
            page={page}
            onChange={handleChange}
            showFirstButton
            showLastButton
            color="primary"
          />
        </P.Wrap>
      )}
    </>
  );
};

export default Paginator;
