// @ts-nocheck
import React, { useEffect } from "react";
import { Container, CircularProgress, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Back, Header } from "./AddSurveyPageStyles";
import { useNavigate, useParams } from "react-router-dom";
import SurveyForm from "../../components/Form/SurveyForm";
import useFetchSurveys from "../../api/useFetch/useFetchSurveys";
import SurveysStore from "../../store/SurveyFormStore";
import { observer } from "mobx-react-lite";
import WarningStore from "../../store/WarningStore";
import navigator from "../../utils/navigator";

const AddSurveyPage = observer(() => {
  const { loading: inProcess, createSurvey, updateSurvey } = useFetchSurveys();
  const { loading, getSurvey } = useFetchSurveys();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id && !SurveysStore.currentModeIsEdit) {
      SurveysStore.setCurrentMode("edit");
      
      getSurvey(id)
        .then((res) => {
          const data: any[] = Object.entries(res);
          data.forEach(([key, value]) => {
            SurveysStore.setData(key, value);
          });
          SurveysStore.setStorageFilled();
        });
    }
    return () => SurveysStore.resetData();
  }, [id]);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const onSubmit = async (data: any) => {
    const resetData = () => {
      SurveysStore.resetData();
      navigate("/surveys");
    };
    if (id) {
      updateSurvey(id, data).then(() => resetData());
    } else {
      createSurvey(data).then(() => resetData());
    }
  };

  useEffect(() => {
    if (!id && SurveysStore.currentModeIsEdit) {
      SurveysStore.setCurrentMode("create");
      SurveysStore.resetData();
    }
  });

  return (
    <div data-testid="add-survey-page">
      <Container maxWidth="xl">
        <Back onClick={onBackClick}>
          <ArrowBackIosIcon style={{ height: 20 }} />
          <p>Back</p>
        </Back>
        {(id && !SurveysStore.isStorageFilled) ? (
          <Stack style={{ marginTop: "150px", marginLeft: "auto", marginRight: "auto" }} alignItems="center">
            <CircularProgress color="primary" size={150} />
            <p>
              {"Please wait, loading survey data..."}
            </p>
          </Stack>
        ) : (
          <>
            <Header>
              <h1>{SurveysStore.pageHeader}</h1>
              <p>All information required</p>
            </Header>

            <SurveyForm
              onSubmit={onSubmit}
              sendPushShow={!SurveysStore.currentModeIsEdit}
              isLoading={loading || inProcess}
            />
          </>
        )}
      </Container>
    </div>
  );
});

export default AddSurveyPage;
