const navigator = (storage: any, navigationFunc: any) => {
  if (storage.isEdited) {
    storage.toggleModal(true);
    storage.setNavPoint(-1);
    return;
  };

  navigationFunc(-1);
};

export default navigator;