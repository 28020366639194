import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import * as F from "./PartyFormStyle";
import { LoadingButton } from "@mui/lab";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import Uploader from "../../../UI/Uploader/Uploader";
import ErrorIcon from "@mui/icons-material/Error";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PartyFormStore from "../../../store/PartyFormStore";
import { observer } from "mobx-react-lite";
import { IForm, IDataParty } from "../../../types/type";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import WarningStore from "../../../store/WarningStore";

const schema = yup
  .object({
    name: yup.string().required().max(100),
    code: yup.number().required().max(999).min(100),
    description: yup.string().required().max(1000),
  })
  .required();

const PartyForm = observer(
  ({ isLoading, onAddEdit, onBack }: IForm): JSX.Element => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const {
      handleSubmit,
      reset,
      setValue,
      getValues,
      formState: { errors },
    } = useForm<IDataParty>({
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      const initiateData = PartyFormStore.getData();
      setValue("name", initiateData.name);
      setValue("code", +initiateData.code);
      setValue("description", initiateData.description);
    }, [isLoading]);

    const onSubmit: SubmitHandler<IDataParty> = (dataForm) =>
      setIsConfirmModalOpen(true);

    const onTextChange = (e: any) => {
      WarningStore.setIsEdited(true);
      const name = e.target.name;
      const value = e.target.value;

      if (name === "code" && value.length > 3) {
        return;
      }

      PartyFormStore.setField(name, value);
      setValue(name, value);
    };

    const changeLogo = (logoFile: string, logoName: string) => {
      PartyFormStore.setField("logoFile", logoFile);
      PartyFormStore.setField("logoName", logoName);
      PartyFormStore.setField("isLogo", true);
      WarningStore.setIsEdited(true);
    };

    const deleteLogo = () => {
      PartyFormStore.clearLogo();
    };

    return (
      <F.Wrap>
        {isLoading && !PartyFormStore.isCreateModeOn ? (
          <F.SkeletonParty variant="rectangular" animation="wave" />
        ) : PartyFormStore.noErrors ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBox>
              <Uploader
                image={PartyFormStore.logo}
                changeLogo={changeLogo}
                deleteLogo={deleteLogo}
              />

              <TextField
                fullWidth
                label="Party name"
                variant="outlined"
                sx={{ marginBottom: 3 }}
                helperText={errors.name?.message}
                error={!!errors.name?.message}
                name="name"
                data-testid="party-name-field"
                onChange={onTextChange}
                value={PartyFormStore.name}
              />

              <TextField
                fullWidth
                label="Code"
                variant="outlined"
                sx={{ marginBottom: 3 }}
                helperText={errors.code?.message}
                error={!!errors.code?.message}
                name="code"
                type="numeric"
                data-testid="party-code-field"
                onChange={onTextChange}
                value={PartyFormStore.code}
              />

              <TextField
                fullWidth
                label="About"
                multiline
                sx={{ marginBottom: 3 }}
                rows={4}
                helperText={errors.description?.message}
                error={!!errors.description?.message}
                name="description"
                data-testid="party-description-field"
                onChange={onTextChange}
                value={PartyFormStore.description}
              />

              <LoadingButton
                loading={isLoading}
                type="submit"
                size="large"
                variant="contained"
                sx={{ marginRight: 2 }}
              >
                {PartyFormStore.componentTitle}
              </LoadingButton>

              <Button onClick={() => onBack()} size="large" color="error">
                Cancel
              </Button>
            </CardBox>
          </form>
        ) : (
          <CardBox style={{ textAlign: "center" }}>
            <ErrorIcon color={"error"} />
            <p>There is no party with such an ID.</p>
            <p>Check the URL in the browser bar.</p>
          </CardBox>
        )}
        {isConfirmModalOpen && (
          <ModalConfirm
            setActive={setIsConfirmModalOpen}
            windowTitle={PartyFormStore.modalTitle}
            disabled={isLoading}
            disableStates={[setIsConfirmModalOpen]}
            callback={async () => {
              WarningStore.resetStorage();
              onAddEdit();
            }}
          />
        )}
      </F.Wrap>
    );
  }
);

export default PartyForm;
