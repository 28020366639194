import React from "react";
import ComplaintsTable from "../components/Tables/ComplaintsTable/ComplaintsTable";
import { CardBox } from "../common/Layout/LayoutStyle";
import ComplaintsTabs from "../components/Tables/ComplaintsTable/ComplaintsTabs";
import Paginator from "../components/Paginator/Paginator";
import { observer } from "mobx-react-lite";
import useFetchComplaints from "../api/useFetch/useFetchComplaints";
import ComplaintsStore from "../store/ComplaintsStore";
import getComplaintsList from "../api/actions/getComplaintsList";
import ShowSubLoading from "../utils/ShowSubLoading";
import { useNavigate } from "react-router-dom";
import { RouteList } from "../utils/adminType";

import useAdjustPage from "../hooks/useAdjustPage";
import useCompleteTableData from "../hooks/useCompleteTableData";
import useClearStorage from "../hooks/useClearStorage";
import useGetTableData from "../hooks/useGetTableData";
import ComplaintsStats from "../components/Tables/ComplaintsTable/ComplaintsStats";
import getComplaintsStats from "../api/actions/getComplaintsStats";

const ComplaintsPage = observer(() => {
  const {
    getComplaints,
    approveComplaints,
    rejectComplaints,
    deleteComplaints,
  } = useFetchComplaints();
  const navigate = useNavigate();

  useClearStorage(ComplaintsStore);
  useGetTableData(ComplaintsStore, () => getComplaintsList(getComplaints));
  useCompleteTableData(ComplaintsStore, getComplaints);
  useAdjustPage(ComplaintsStore);

  const handleMore = (id: string) => {
    navigate(`${RouteList.complaint}/${id}`);
  };

  return (
    <>
      <ShowSubLoading storage={ComplaintsStore} />
      <h1>Complaints</h1>

      <ComplaintsTabs />

      <CardBox>
        <ComplaintsTable
          onOpen={handleMore}
          onDelete={deleteComplaints}
          onReject={rejectComplaints}
          onApprove={approveComplaints}
        />

        <Paginator
          amount={ComplaintsStore.amount}
          rowsPerPage={5}
          page={ComplaintsStore.page}
          setPage={ComplaintsStore.setPage}
          disabled={ComplaintsStore.disabled}
        />
      </CardBox>
    </>
  );
});

export default ComplaintsPage;
