import styled, { ThemeProps } from "styled-components";
import { Theme, Paper, Typography, Button } from "@mui/material";

export const Form = styled.form`
  padding-top: 0;
  margin-bottom: 100px;

  & #cand-district {
    -webkit-text-fill-color: rgba(0, 0, 0);
  }
`;

export const AddFile = styled.label`
  input {
    display: none;
  }

  .label-name {
    margin: 0 0 6px 0;
    font-size: 12px;
    display: block;
  }

  .label-choose {
    background: #f8f8f8;
    border-radius: 6px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light' ? "#afafaf" : "#afafaf"};
  }
`;

export const AddAnswer = styled(Button)`
  position: absolute;
  right: 10px;
  bottom: 30px;
  min-width: 82px;
`;

export const NewAnswer = styled.div`
  position: relative;
  margin-bottom: 42px;
`;

export const AnswerItem = styled.div`
  display: flex;
  padding: 20px 10px 20px 0;
  border-bottom: 1px solid #f8f8f8;
`;

export const TextAnswer = styled.div`
  padding-right: 10px;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  div {
    width: 100%;

    textarea {
      height: 40px;
    }
  }
`;

export const Img = styled.img`
  max-width: 100%;
  width: 100%;
  border-radius: 0 0 6px 6px;
`;

export const HeaderFile = styled.div`
  border-radius: 6px 6px 0 0;
  background: #fff;
  margin-top: 20px;
  display: flex;
  padding: 20px 15px;
  align-items: center;
  font-size: 13px;
  box-shadow: 0 3px 8px rgba(18, 19, 18, 0.1);

  .delete {
    margin-left: auto;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.secondary.main};
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
  }
`;

export const DistrictsSelectorWrapper = styled(Paper)`
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light' ? "#fff" : "#333336"};
  & div[data-testid='selected-district'] {
    background: #fff;

    & span {
      color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light' ? "#000" : "#000"};
    }
  }
`;

export const DistrictsList = styled.div`
  height: 170px;
  width: 250px;
`;

export const Province = styled(Typography)`
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  &:hover, &:active {
    opacity: .6;
  }
`;

export const District = styled(Typography)`
  cursor: pointer;

  &:hover, &:active {
    opacity: .6;
  }
`;