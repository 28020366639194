import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import * as T from '../TablesStyle'
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import { observer } from "mobx-react-lite";
import NewsStore from "../../../store/NewsStore";
import NewsAction from "./NewsAction";
import { INewsTable } from "../../../types/type";
import setBlur from '../../../utils/setBlur';
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import ButtonLoader from '../../../common/Buttons/ButtonLoader';
import isStorageLoading from '../../../utils/isStorageLoading';

const NewsTable = observer(({
  isLoading,
  getSharesAmount,
  onDelete,
  onOpen
}: INewsTable): JSX.Element => {
  const rowsEntries = Array(NewsStore.rowsPerPage).fill(0);
  const colSpan = Array(6).fill(0);

  const renderShares = (id: string) => {
    if (NewsStore.sharesAmount[id] !== undefined) {
      return NewsStore.sharesAmount[id];
    }

    return (
      <ButtonLoader
        pointerHandler={() => {
          getSharesAmount(id);
        }}
        loading={isLoading}
        title="Get shares amount"
      />
    );
  };

  const renderRows = () => {
    if (isStorageLoading(NewsStore)) {
      return (<LoadingTable loading={NewsStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (NewsStore.isStorageEmpty) {
      return (<EmptyRow columns={6} />);
    }

    return NewsStore.list.map((row, index) => {
      if (row === null) {
        return (<LoadingRow key={index} colSpan={colSpan} />);
      }

      return (
        <T.TRow key={row.id}>
          <TableCell align="left">{row.date}</TableCell>
          <TableCell align="left">{row.title}</TableCell>
          <TableCell align="left">{row.text}</TableCell>
          <TableCell align="left">{row.views}</TableCell>
          <TableCell align="center">{renderShares(row.id)}</TableCell>
          <TableCell align="center">
            <NewsAction
              onDelete={onDelete}
              onOpen={onOpen}
              id={row.id}
            />
          </TableCell>
        </T.TRow>
      );
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Text</TableCell>
            <TableCell align="left">Views</TableCell>
            <TableCell align="center">Shares amount</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(NewsStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
})

export default NewsTable;