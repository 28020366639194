import styled from "styled-components";

export const StatisticItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 24px;

  .text {
    padding-left: 27px;
    padding-right: 20px;
  }
`;

export const ItemColor = styled.span`
  background: #e46756;
  border-radius: 7px;
  width: 22px;
  min-width: 22px;
  height: 22px;
  display: inline-flex;
`;

export const ItemCount = styled.div`
  margin-left: auto;
  white-space: nowrap;

  .count {
    font-weight: bold;
    font-size: 14px;
  }

  .percent {
    color: #b5b5c3;
  }
`;