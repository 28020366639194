import styled from "styled-components";
import { Skeleton } from "@mui/material";

export const Wrap = styled.div`
  width: 530px;
  max-width: 100%;
  display: block;
`;

export const PlaceholderContainer = styled.div`
  position: relative;

  & p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    text-align: center;
  }
`;

export const SkeletonParty = styled(Skeleton)`
  border-radius: 5px;
  width: 530px;
  height: 601px;
  max-width: 100%;
`;