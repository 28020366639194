import React, { useState } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { IActionItemProps } from "../../../types/type";
import { observer } from "mobx-react-lite";
import NewsStore from "../../../store/NewsStore";
import closeTableWindow from '../../../utils/closeTableWindow';

const NewsAction = observer(({
  id,
  onOpen,
  onDelete
}: IActionItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleDeleteConfirm = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleDelete = () => {
    NewsStore.removeItemLocally(id);
    NewsStore.setInQueue(id);

    onDelete(id).finally(() => NewsStore.removeFromQueue(id));
  };

  return (
    <>
      <ActionMenuButton anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem onClick={closeTableWindow(id, onOpen, setAnchorEl)}><EditIcon /> Edit</MenuItem>
        <MenuItem onClick={handleDeleteConfirm}><DeleteIcon color={'error'} /> Delete</MenuItem>
      </ActionMenuButton>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle='Are you sure to delete this article?'
          disableStates={[setOpen]}
          callback={async () => handleDelete()}
        />
      )}
    </>
  );
});

export default NewsAction;