import { makeAutoObservable } from "mobx";
import { defaultValueParty } from "../utils/constants";

class PartyFormStore {
  name: string = '';
  code: string | number = '';
  description: string = '';
  isLogo: boolean = false;
  logo: string = '';
  logoFile: string = '';
  logoName: string = '';

  _currentMode: string = 'create';
  _error: boolean = false;
  _errorState: any = null;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
    this.resetData = this.resetData.bind(this);
  };

  setErrorDetail(data: any) {
    this._errorState = data;
  };

  get modalTitle() {
    return (this.isCreateModeOn) ? (
      'Are you sure to create a new party?'
    ) : (
      'Are you sure to save current party edits?'
    );
  };

  clearLogo() {
    this.logoFile = '';
    this.logoName = '';
    this.isLogo = false;
  };

  get componentTitle() {
    return `${this.isCreateModeOn ? "Add" : "Edit"} party`;
  };

  getData() {
    return (this.logoFile && this.logoName && this.isLogo) ? ({
      name: this.name,
      code: this.code,
      description: this.description,
      isLogo: this.isLogo,
      file: this.logoFile,
      fileName: this.logoName,
    }) : ({
      name: this.name,
      code: +this.code,
      description: this.description,
      logo: this.logo,
    });
  };

  get isCreateModeOn() {
    return this._currentMode === 'create';
  };

  get noErrors() {
    return !this._error;
  };

  setError() {
    this._error = true;
  };

  setField(field: string, data: any) {
    this[field] = data;
  };

  onFirstRender() {
    this._currentMode = 'edit';
  };

  resetData() {
    this.setData(defaultValueParty);
    this._currentMode = 'create';
    this._error = false;
    this._errorState = null;
  };

  setData(data: any) {
    Object
      .entries(data)
      .forEach(([key, value]) => {
        this[key] = value;
      });
  };
};

export default new PartyFormStore();