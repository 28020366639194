import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "normalize.css";
import "./assets/fonts/opensans/opensans.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Fallback from "./utils/Fallback";

Sentry.init({
  dsn: "https://10d8136ff5be469eb9e06e71a5e7f287@o4504287851905024.ingest.sentry.io/4504287858262016",
  integrations: [new BrowserTracing()],

  tracesSampleRate: 0.5,
});

const customFallback = <Fallback />;

const container = document.getElementById("root")!;
const root = ReactDOM.createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback={customFallback}>
    <App />
  </Sentry.ErrorBoundary>
);
