import React from 'react';
import { Tab, Theme } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { observer } from "mobx-react-lite";
import NotificationStore from "../../../store/NotificationStore";
import styled, { ThemeProps } from "styled-components";

const Wrap = styled.div`
  margin-left: 30px;
`;

const TabItem = styled(Tab)`
  background: ${(props: ThemeProps<Theme>) => props.theme.palette.background.default};
  margin-right: 10px;
  border-radius: 5px 5px 0 0;
  
  &:hover {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
  }
  
  &.Mui-selected {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette.action.hover};
  }
`;

const DashboardTabs = observer(() => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    NotificationStore.setType(newValue);
    NotificationStore.setRowsPerPage(5);
    NotificationStore.setPage(1);
  };

  return (
    <Wrap>
      <TabContext value={NotificationStore.type}>
        <TabList onChange={handleChange}>
          <TabItem disabled={NotificationStore.disabled} label="Notification" value="notification" />
          <TabItem disabled={NotificationStore.disabled} label="News" value="news" />
          <TabItem disabled={NotificationStore.disabled} label="Surveys" value="surveys" />
        </TabList>
      </TabContext>
    </Wrap>
  );
});

export default DashboardTabs;