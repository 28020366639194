import ReportsStore from "../../store/ReportsStore";

const getReportsAmount = async (fetcher: Function) => {
  ReportsStore.disable();

  const result = await fetcher();

  ReportsStore.setAmount(result);

  ReportsStore.enable();

  return result;
};

export default getReportsAmount;