import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";
import { Container } from "@mui/material";

export const DistrictTitleContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 20px;
  width: 100%;
`;

export const Map = styled.div`
  width: 100%;

  @media (max-width:1555px) {
    width: 90%;
  }

  ${(props: ThemeProps<Theme>) => props.theme.breakpoints.up("sm")} {
    position: absolute;
    top: 80px;
    height: 700px;
    margin-top: 0;
  }

  @media (max-width:600px) {
    position: absolute;
    top: 0px;
    height: 75vh;
    margin-top: 25px;
  }
`;
