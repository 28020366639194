import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as T from '../TablesStyle'
import { NameAvatar } from '../TablesStyle'
import LoadingTable from "../LoadingTable";
import { observer } from "mobx-react-lite";
import SupportStore from "../../../store/SupportStore";
import { ButtonTable } from '../TablesStyle';
import { useNavigate } from "react-router-dom";
import { RouteList } from '../../../utils/adminType';
import setBlur from '../../../utils/setBlur';
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import isStorageLoading from '../../../utils/isStorageLoading';

const SupportTable = observer((): JSX.Element => {
  const colNumber = 3;
  const rowsEntries = Array(SupportStore.rowsPerPage).fill(0);
  const colSpan = Array(colNumber).fill(0);
  const navigate = useNavigate();

  const openDialogue = (id: string) => {
    return () => navigate(`${RouteList.supportChat}/${id}`);
  };

  const renderRows = () => {
    if (isStorageLoading(SupportStore)) {
      return (<LoadingTable loading={SupportStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (SupportStore.isStorageEmpty) {
      return (<EmptyRow columns={3} />);
    }

    return SupportStore.list.map((row) => (
      <T.TRow data-testid={`support-${row.id}`} key={row.id}>

        <TableCell align="left">
          <NameAvatar src={row.avatar} name={`${row.firstName} ${row.lastName}`} />
        </TableCell>
        <TableCell align="center">
          {row.messages ? Object.keys(row.messages).length : ""}
        </TableCell>
        <TableCell align="center">
          <ButtonTable onClick={openDialogue(row.id)}>Open dialogue</ButtonTable>
        </TableCell>
      </T.TRow>
    ));
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Messages</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(SupportStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
});

export default SupportTable;