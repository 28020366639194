import React from 'react';
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import FilterTable from "../components/Tables/FilterTable/FilterTable";
import AdminListStore from '../store/AdminListStore';
import AdminTable from "../components/Tables/AdminTable/AdminTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { adminType } from '../api/network';
import { AdminTypes, RouteList } from '../utils/adminType';
import useFetchAdmin from '../api/useFetch/useFetchAdmin';
import getAdminList from '../api/actions/getAdminsList';
import ShowSubLoading from '../utils/ShowSubLoading';
import AlignedButtonText from '../common/Text/AlignedButtonText';

import useAdjustPage from '../hooks/useAdjustPage';
import useCompleteTableData from '../hooks/useCompleteTableData';
import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const AdminListPages = observer(() => {
  const navigate = useNavigate();
  const currentRoles = adminType();
  const { getAdmin, deleteAdmin, resetPasswordAdmin } = useFetchAdmin();

  useClearStorage(AdminListStore);
  useGetTableData(AdminListStore, () => getAdminList(getAdmin));
  useCompleteTableData(AdminListStore, getAdmin);
  useAdjustPage(AdminListStore);

  return (
    <>
      <ShowSubLoading storage={AdminListStore} />
      <h1>Admin list</h1>

      <FilterTable
        searchValue={AdminListStore.searchValue}
        setSearchValue={AdminListStore.setSearchValue}
        setPage={AdminListStore.setPage}
        disabled={AdminListStore.disabled}
      />

      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={AdminListStore.rowsPerPage}
            amount={AdminListStore.amount}
            setRowsPerPage={AdminListStore.setRowsPerPage}
            disabled={AdminListStore.disabled}
          />
          {(currentRoles.includes(AdminTypes.superAdmin) ||
            currentRoles.includes(AdminTypes.canAddEditAdmins)) && (
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => navigate(RouteList.addAdmin)}
              >
                <AlignedButtonText>
                  Add admin
                </AlignedButtonText>
              </Button>
            )}
        </ToolbarTable>

        <AdminTable
          onReset={resetPasswordAdmin}
          onDelete={deleteAdmin}
        />

        <Paginator
          amount={AdminListStore.amount}
          rowsPerPage={AdminListStore.rowsPerPage}
          page={AdminListStore.page}
          setPage={AdminListStore.setPage}
          disabled={AdminListStore.disabled}
        />
      </CardBox>
    </>
  );
});

export default AdminListPages;