import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFetchComplaints from "../api/useFetch/useFetchComplaints";
import { CardBox } from "../common/Layout/LayoutStyle";
import ComplaintsStore from "../store/ComplaintsStore";
import { RouteList } from "../utils/adminType";
import ShowSubLoading from "../utils/ShowSubLoading";

import getComplaintsStats from "../api/actions/getComplaintsStats";
import ComplaintsStats from "../components/Tables/ComplaintsTable/ComplaintsStats";
import useAdjustPage from "../hooks/useAdjustPage";
import useClearStorage from "../hooks/useClearStorage";
import useCompleteTableData from "../hooks/useCompleteTableData";
import useGetTableData from "../hooks/useGetTableData";

const ComplaintsReportsPage = observer(() => {
  const { getComplaintsAll } = useFetchComplaints();
  const navigate = useNavigate();

  useClearStorage(ComplaintsStore);

  useGetTableData(ComplaintsStore, () => getComplaintsStats(getComplaintsAll));
  useCompleteTableData(ComplaintsStore, getComplaintsAll);
  useAdjustPage(ComplaintsStore);

  const handleMore = (id: string) => {
    navigate(`${RouteList.complaint}/${id}`);
  };

  return (
    <>
      <ShowSubLoading storage={ComplaintsStore} />
      <h1>Complaints Reports</h1>
      <CardBox>
        <ComplaintsStats />
      </CardBox>
    </>
  );
});

export default ComplaintsReportsPage;
