import { makeAutoObservable } from "mobx";
import draftToHtml from 'draftjs-to-html';

class LawArticleFormStore {
  title: string = "";
  image: string = "";
  fileName: string = "";
  text: any = "";
  editorRawData: any = "";
  lawArticleData: any = {};
  _currentMode: string = "create";
  _error: boolean = false;
  //_id: any = {};
  _imageEdited: boolean = false;
  //_TIMEOUT: number = 10000;
  //_showNotification: boolean = false;
  //_timerId: any = null;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  };

  /*getId() {
    return this._id;
  };*/

  get imageEdited() {
    return this._imageEdited;
  }

  imageEditMode(mode: boolean) {
    this._imageEdited = mode;
  }

  /*get shouldNotificate() {
    return this._showNotification;
  }*/

  /*disableNotification() {
    this._showNotification = false;
  }*/

  /*setId(id: string | undefined, lawId: string | undefined) {
    this._id = { id, lawId };
  };*/

  resetLawArticleData() {
    this.lawArticleData = {};
  };

  setLawArticleData(data: any) {
    this.lawArticleData = data;
  };

  get modalTitle() {
    return this.isCreateModeOn
      ? "Are you sure you want to create a new law article?"
      : "Are you sure you want to edit law article?";
  }

  get pageTitle() {
    return this.isCreateModeOn
      ? "Add law article"
      : "Edit law article";
  }

  get saveButtonTitle() {
    return this.isCreateModeOn
      ? "Add law article"
      : "Edit law article";
  }

  getData() {
    const data = Object.fromEntries(Object
      .entries(this)
      .filter((item) => !item[0].includes("_"))
    );

    data.text = draftToHtml(this.editorRawData);
    return data;
  }

  setMode(mode: string) {
    this._currentMode = mode;
  }

  get isCreateModeOn() {
    return this._currentMode === "create";
  }

  get noErrors() {
    return !this._error;
  }

  /*turnOnSaver() {
    this._timerId = setInterval(() => {
      const oldValue: any = localStorage.getItem('editCache');
      if (oldValue) {
        const parsedValue = JSON.parse(oldValue);

        if (parsedValue.editCache && parsedValue.editCache.blocks) {
          if ((parsedValue.editCache.blocks && this.editorRawData.blocks) &&
            (parsedValue.editCache.blocks.length !== this.editorRawData.blocks.length)
          ) {
            this._showNotification = true;
          } else {
            parsedValue.editCache.blocks.forEach((item: any, index: number) => {
              if (this.editorRawData.blocks && (item.text !== this.editorRawData.blocks[index].text)) {
                this._showNotification = true;
              }
            });
          }
        }
      }

      localStorage.setItem('editCache', JSON.stringify({
        editCache: this.editorRawData,
        id: this._id.id,
        lawId: this._id.lawId,
      }));
    }, this._TIMEOUT);
  };

  turnOffSaver() {
    clearInterval(this._timerId);
  };*/

  setError() {
    this._error = true;
  }

  get articleTitle() {
    if (this.lawArticleData.title) {
      const title = this.lawArticleData.title;
      return title[0].toUpperCase() + title.slice(1);
    };
    return "";
  };

  setData(field: string, data: any) {
    this[field] = data;
  }

  resetStorage() {
    const excludeList = [
      "title",
      "image",
      "fileName",
      "_currentMode",
      "_error",
      "lawArticleData",
      "editorRawData",
      "editCache",
      "_imageEdited",
      //"_timerId",
      //"_TIMEOUT",
      //"_showNotification",
      //"_id",
      "text",
    ];
    this.title = "";
    this.image = "";
    this._imageEdited = false;
    this.fileName = "";
    this._currentMode = "create";
    this._error = false;
    this.text = "";
    this.editorRawData = "";
    //this._showNotification = false;
    //this._id = {};
    Object
      .entries(this)
      .forEach((item) => {
        const key = item[0];
        if (!excludeList.includes(key)) {
          delete this[key];
        }
      });
  };

  get isNotReady() {
    if (this.image && !this._imageEdited) {
      return !this._imageEdited;
    }

    if (!this.editorRawData.blocks) {
      return true;
    }

    const filteredBlocks = this.editorRawData?.blocks?.filter((block: any) => !!block.text);

    return !(filteredBlocks.length !== 0 && this.title);
  };

  initiate(data: any) {
    Object.entries(data).forEach((item) => {
      const key = item[0];
      const value: any = item[1];
      if (key === "editorRawData") {
        value.entityMap = value.entityMap ? value.entityMap : {};
        value.blocks[0].data = value.blocks[0].data ? value.blocks[0].data : {};
        value.blocks[0].entityRanges = value.blocks[0].entityRanges ? value.blocks[0].entityRanges : [];
      };
      this[key] = value;
    })
  };
};

export default new LawArticleFormStore();
