import React from "react";
import { Pagination } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Wrap, TextWrapper } from "./ComplaintFormStyles";

const AttachmentsPaginate = ({
  attachmentsLength,
  onChange,
  currentAttachment,
}: any): JSX.Element => {
  return (
    <Wrap data-testid="paginate-attachments">
      <TextWrapper>
        Select attachment index from list:
      </TextWrapper>

      <Select
        value={currentAttachment + 1}
        onChange={(e) => onChange(e.target.value - 1)}
        MenuProps={{ MenuListProps: { style: { padding: 0, maxHeight: 108 } } }}
        style={{ marginRight: 15, padding: 5 }}>
        {new Array(attachmentsLength).fill(0).map((attachment, index) => {
          return (<MenuItem key={index} data-testid={`select-${index + 1}`} value={index + 1}>{index + 1}</MenuItem>);
        })}
      </Select>

      <Pagination
        count={attachmentsLength}
        page={currentAttachment + 1}
        onChange={(e, newIndex) => onChange(newIndex - 1)}
        showFirstButton
        showLastButton
        color="primary"
        shape="rounded"
      />
    </Wrap>
  );
};

export default AttachmentsPaginate;
