import React, { useEffect, useState } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import { Button, TextField } from "@mui/material";
import * as F from "./LawArticleFormStyle";
import Dropzone from "../../../UI/Dropzone/Dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IForm, IDataNews } from "../../../types/type";
import { observer } from "mobx-react-lite";
import LawArticleFormStore from "../../../store/LawArticleFormStore";
import WarningStore from "../../../store/WarningStore";
import TextEditor from "./TextEditor";
import ImageCropper from "../../../common/ImageCropper/ImageCropper";
//import CloseIcon from '@mui/icons-material/Close';
//import IconButton from '@mui/material/IconButton';
//import Snackbar from '@mui/material/Snackbar';

const schema = yup
  .object({
    title: yup.string().required().max(300),
  })
  .required();

const LawArticleForm = observer(({ onAddEdit, onBack, isLoading }: IForm) => {
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useForm<IDataNews>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ title: LawArticleFormStore.title });
  }, [LawArticleFormStore.title]);

  const changeFile = (value: string, name: string) => {
    WarningStore.setIsEdited(true);
    LawArticleFormStore.setData("image", value);
    LawArticleFormStore.setData("fileName", name);
    LawArticleFormStore.imageEditMode(false);
  };

  const onTextChange = (e: any) => {
    WarningStore.setIsEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    LawArticleFormStore.setData(name, value);
    setValue(name, value);
  };

  const onSubmit: SubmitHandler<IDataNews> = (dataForm) => setOpen(true);

  const renderComponent = () => {
    if (isLoading && !LawArticleFormStore.isCreateModeOn) {
      return (<F.SkeletonParty variant="rectangular" animation="wave" />);
    };

    if (!LawArticleFormStore.noErrors) {
      return (
        <CardBox style={{ textAlign: "center" }}>
          <ErrorIcon color={"error"} />
          <p>There is no laws with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </CardBox>
      );
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBox>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            sx={{ marginBottom: 3 }}
            inputProps={{ maxLength: 300 }}
            helperText={`${LawArticleFormStore.title.length} / 300`}
            name="title"
            onChange={onTextChange}
            value={LawArticleFormStore.title}
            data-testid="law-article-title"
          />

          <Dropzone
            accept={"image/jpg,image/jpeg,image/png"}
            margin={3}
            file={LawArticleFormStore.image}
            changeFile={changeFile}
            dataTestId="law-article-form-image"
            dontShowPreview={true}
          />

          <ImageCropper
            src={LawArticleFormStore.image}
            storage={LawArticleFormStore}
            dataTestId="law-article-form-image"
          />

          <TextEditor />

          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Button
              type={"submit"}
              variant={"contained"}
              size={"large"}
              data-testid="save-law-article-button"
              disabled={!isValid || isLoading || LawArticleFormStore.isNotReady || !WarningStore.isEdited}
            >
              {LawArticleFormStore.saveButtonTitle}
            </Button>

            <Button
              type={"button"}
              onClick={() => onBack()}
              size={"large"}
              color="error"
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </div>
        </CardBox>
      </form>
    );
  };

  /*const handleClose = () => {
    LawArticleFormStore.disableNotification();
  };

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
      data-testid="snackbar-notification"
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );*/

  return (
    <F.Wrap>
      {renderComponent()}
      {/*<Snackbar
        open={LawArticleFormStore.shouldNotificate}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Last edits were saved locally"
        action={action}
      />*/}

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={LawArticleFormStore.modalTitle}
          disabled={isLoading}
          disableStates={[setOpen]}
          callback={async () => {
            WarningStore.resetStorage();
            onAddEdit();
          }}
        />
      )}
    </F.Wrap>
  );
});

export default LawArticleForm;
