import React from 'react';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { ILogAction } from '../../../types/type';
import { ButtonTable } from '../TablesStyle';

const LogsAction = ({ open }: ILogAction) => {
  return (
    <ButtonTable onClick={open}>
      <ReadMoreIcon style={{ marginRight: 15 }} />
      View details
    </ButtonTable>
  );
};

export default LogsAction;