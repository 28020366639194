import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

const MapPlaceholder = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/districts");
  });

  return (
    <Stack style={{ marginTop: "20%", marginBottom: "20%" }} alignItems="center">
      <CircularProgress color="primary" size={200} />
      <Typography style={{ marginTop: 25 }}>{"Please wait, loading map, it may take a while..."}</Typography>
    </Stack>
  );
};

export default MapPlaceholder;
