export const provinces = [
  {
    provinceName: "Kirkuk",
  },
  {
    provinceName: "Baghdad",
  },
  {
    provinceName: "Diyala",
  },
  {
    provinceName: "Anbar",
  },
  {
    provinceName: "Salahaddin",
  },
  {
    provinceName: "Ninawa",
  },
  {
    provinceName: "Anbar",
  },
  {
    provinceName: "Albasra",
  },
  {
    provinceName: "Diwaniyah",
  },
  {
    provinceName: "Almuthanaa",
  },
  {
    provinceName: "Najaf",
  },
  {
    provinceName: "Babil",
  },
  {
    provinceName: "Dhiqar",
  },
  {
    provinceName: "Karbala",
  },
  {
    provinceName: "Maysan",
  },
  {
    provinceName: "Wasit",
  },
  {
    provinceName: "Erbil",
  },
  {
    provinceName: "Sulaymaniyah",
  },
  {
    provinceName: "Duhok",
  },
];
