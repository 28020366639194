import { makeAutoObservable } from "mobx";
import getAdminList from "../api/actions/getAdminsList";
import getComplaintsList from "../api/actions/getComplaintsList";
import getElectionModes from "../api/actions/getElectionModes";
import getNewsList from "../api/actions/getNewsList";
import getNotificationsList from "../api/actions/getNotificationsList";
import getPartiesList from "../api/actions/getPartiesList";
import getReportsAmount from "../api/actions/getReportsAmount";
import getReportsVotes from "../api/actions/getReportsVotes";
import getSupportChats from "../api/actions/getSupportChats";
import getSurveysList from "../api/actions/getSurveysList";
import fetchList from "../utils/fetchers/fetchList";
import getLogsList from "../api/actions/getLogsList";
import fetchReports from "../utils/fetchers/fetchReports";
import fetcher from "../utils/fetchers/fetcher";

class LoginStore {
  _TFACode: string = "";
  email: string = "";
  secret: string = "";
  TIMEOUT: number = 200;
  _dataChached: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  };

  get isNotChached() {
    return !this._dataChached;
  };

  setData(field: string, data: any) {
    this[field] = data;
  };

  async getDataDelayed(func: Function) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        await func();
        resolve('page pre-cached');
      }, this.TIMEOUT);
    });
  };

  async launchCacher(role: any) {
    if (role.includes("spectator")) {
      await this.getDataDelayed(async () => getPartiesList(fetchList('api/getparties')));
    }

    if (role.includes("admin")) {
      await this.getDataDelayed(async () => getAdminList(fetchList('api/getadmins')));
      await this.getDataDelayed(async () => getPartiesList(fetchList('api/getparties')));
    }

    if (role.includes("superadmin")) {
      await this.getDataDelayed(async () => getAdminList(fetchList('api/getadmins')));
      await this.getDataDelayed(async () => getPartiesList(fetchList('api/getparties')));
      await this.getDataDelayed(async () => getNewsList(fetchList('api/check/news')));
      await this.getDataDelayed(async () => getSurveysList(fetchList('api/check/surveys')));
      await this.getDataDelayed(async () => getNotificationsList(fetchList('api/getAllNotifications')));
      await this.getDataDelayed(async () => getComplaintsList(fetchList('api/check/complaints')));
      await this.getDataDelayed(async () => getLogsList(fetchList('api/check/logs')));
      await this.getDataDelayed(async () => getReportsAmount(fetchReports('api/getReports', true)));
      await this.getDataDelayed(async () => getReportsVotes(fetchReports('api/getReports')));
      await this.getDataDelayed(async () => getElectionModes(fetcher('api/check/electionModes')));
      await this.getDataDelayed(async () => getSupportChats(fetchList('api/loadSupport')));
    }

    this._dataChached = true;
  };

  setCode(code: string) {
    this._TFACode = code;
  };

  resetStorage() {
    this._TFACode = "";
    this.email = "";
    this.secret = "";
  };

  get code() {
    return this._TFACode;
  };
};

export default new LoginStore();