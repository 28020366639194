import { useHttp } from "../../hooks/useHttp";

const UseFetchReports = () => {
  const { loading, request, error, clearError } = useHttp();

  const getReports = async () => {
    return await request('get', 'api/getReports', {
      params: { type: "votes" }
    });
  };

  const getReportsOnly = async () => {
    return await request('get', 'api/getReports', {
      params: { amountOnly: true },
    });
  };

  const getCustomReports = async (data: any) => {
    return await request('get', 'api/getReports', data);
  };

  return {
    loading, 
    error, 
    clearError,
    getReports,
    getReportsOnly,
    getCustomReports
  }
};

export default UseFetchReports;