import { PaletteMode } from "@mui/material";

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    primary: {
      main: "#55AE7E",
      light: "#77be97",
      dark: "#3b7958",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FB3640",
      light: "#fb5e66",
      dark: "#af252c",
      contrastText: "#fff",
    },
    typography: {
      fontFamily: ["Open Sans, sans-serif"],
    },
    ...(mode === 'light'
      ? {
        background: { paper: '#edeef0', default: '#fff' }
      }
      : {
        background: { paper: '#222226', default: '#333336' }
      }),
  }
});