import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const DocumentLink = styled.a`
  display: inline-block;
  height: 39px;
  width: 39px;
  margin-right: 10px;
  padding: 8px 8px 9px 9px;
  color: #333;
  border: none;
  background-color: #D2D2D2;
  border-radius: 5px;
  box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #55AE7E;
    color: #fff;
  }
`;

export const ComplaintImage = styled.img`
  height: 450px;
  width: 450px;
`;

export const ComplaintVideo = styled.div`
  height: 450px;
  width: 450px;
  background: #000;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  border: 1px solid #000;

`;

export const AttachmentsContainer = styled.div`
  margin-top: 50px; 
  display: flex;
  justify-content: center;
`;

export const Controls = styled.div`
  height: 24px;
  margin: 0;
  padding: 0;
`;

export const PaginateContainer = styled.div`
  margin-top: 20px;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  & button {
    background: #EEF0F8;
    font-size: 14px;
    font-weight: 600;

    &.MuiPaginationItem-previousNext, 
    &.MuiPaginationItem-firstLast {
      background: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
      color: #fff;

      &:hover {
        color: #fff;
        background: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.dark};
      }
    }
  }
`;

export const TextWrapper = styled.p`
  margin: 0px;
  margin-right: 15px;
  color: #8A8A8E;
  font-size: 15px;

  @media (max-width: 700px) {
    font-size: 13px;
  }
`;

export const MapButton = styled.button`
  height: 39px;
  width: 39px;
  padding: 8px 8px 9px 9px;
  color: #333;
  border: none;
  background-color: #D2D2D2;
  border-radius: 5px;
  box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #55AE7E;
    color: #fff;
  }
`;

export const DefaultParagraph = styled.p`
  margin: 0px;
  padding: 0px;
`;

export const AuthorInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;