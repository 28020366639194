export default (search: any) => {
  if (search)
    try {
      let res: any = {};
      let params = search?.split("?")[1];
      params = params.split("&");

      params.map((item: any) => {
        res[`${item.split("=")[0]}`] = item.split("=")[1];
      });
      return res;
    } catch (error) {
      console.error(error);
    }
};
