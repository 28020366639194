import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { InputContainer } from "./LawFormStyle";
import {
  TextField,
  InputAdornment,
  Popover
} from "@mui/material";
import styled from "styled-components";
import toggleScrollbar from "../../../utils/toggleScrollbar";

const StyledInputContainer = styled(InputContainer)`
  display: flex; 
  align-items: center;
  width: 100%;

  & input {
    -webkit-text-fill-color: #000 !important;
  }
`;

const Selector = observer(({ children, value, customWidth }: any): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    toggleScrollbar(anchorEl);
  }, [anchorEl]);

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const handlePopoverClick = (event: any) => {
    setAnchorEl(event.currentTarget.closest("#input-container"));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledInputContainer id="input-container">
      <TextField
        disabled={true}
        variant="outlined"
        name="receiver"
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <span
                aria-describedby={popoverId}
                style={{ cursor: "pointer" }}
                onClick={handlePopoverClick}
              >
                Select
              </span>
              <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                container={document.querySelector("form")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div style={{ height: 100, width: customWidth ? customWidth : 470 }} onClick={() => setAnchorEl(null)}>
                  {children}
                </div>
              </Popover>
            </InputAdornment>
          ),
        }}
      />
    </StyledInputContainer>
  );

});

export default Selector;