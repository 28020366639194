import React from "react";
import ReactPlayer from "react-player";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import * as F from "./EpisodeContentStyle";
import ErrorIcon from "@mui/icons-material/Error";
import { IWithIsLoading } from "../../../types/type";
import { observer } from "mobx-react-lite";
import EpisodeFormStore from "../../../store/EpisodeFormStore";
import Stack from '@mui/material/Stack';

const EpisodeContent = observer(({ isLoading }: IWithIsLoading) => {
  const renderContent = () => {
    if (isLoading) {
      return (<F.SkeletonParty variant="rectangular" animation="wave" />);
    };

    const mediaContainerStyles = !EpisodeFormStore.episodeData.isVideo ? ({
      width: 400,
      height: 400,
      margin: "0 auto",
      backgroundImage: `url(${EpisodeFormStore.episodeData.podcastIcon})`,
      backgroundSize: "cover",
    }) : ({});

    return (EpisodeFormStore.noErrors) ? (
      <F.Container data-testid="law-article-content-container">
        <F.SubContainer>
          <p>Created at:</p>
          <p>{new Date(EpisodeFormStore.episodeData.createdAt).toLocaleDateString()}</p>
        </F.SubContainer>

        {EpisodeFormStore.episodeData.image && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="Law article image"
              width={400}
              height={400}
              src={EpisodeFormStore.episodeData.image} data-testid="law-article-image"
            />
          </div>
        )}

        {isFinite(EpisodeFormStore.episodeData.views) && (
          <F.SubContainer>
            <p>Total amount of views: {EpisodeFormStore.episodeData.views}</p>
          </F.SubContainer>
        )}

        {EpisodeFormStore.episodeData.mediaUrl && (
          <Stack
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: 10, height: 525, ...mediaContainerStyles }}
          >
            <ReactPlayer
              url={EpisodeFormStore.episodeData.mediaUrl}
              controls={true}
              width="100%"
              height="100%"
            />
          </Stack>
        )}

        {EpisodeFormStore.episodeData.text && (
          <Stack justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
            <p>{EpisodeFormStore.episodeData.text}</p>
          </Stack>
        )}
      </F.Container>
    ) : (
      <CardBox style={{ textAlign: "center" }}>
        <ErrorIcon color={"error"} />
        <p>There is no episodes with such an ID.</p>
        <p>Check the URL in the browser bar.</p>
      </CardBox>
    );
  };

  return (
    <F.Wrap>
      {renderContent()}
    </F.Wrap>
  );
});

export default EpisodeContent;
