import React, { useEffect } from "react";
import { ModalProps } from "../../types/type";
import { observer } from "mobx-react-lite";
import {
  ModalBody,
  ModalWrap,
  Title,
  ModalContent
} from "./ModalStyles";

const Modal = observer(({
  active,
  setActive,
  activeAlways,
  title,
  component,
  children
}: ModalProps): JSX.Element => {

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => { document.body.style.overflow = "unset" };
  }, []);

  return (
    <ModalWrap
      data-testid="modal-window"
      className={active ? "active" : ""}
      onClick={activeAlways ? () => { } : setActive}>
      <ModalBody onClick={e => e.stopPropagation()}>
        {title ? (
          <Title>
            {title}
          </Title>
        ) : null}
        {component ? (
          component
        ) : null}
        <ModalContent style={{ width: "100%" }}>
          {children}
        </ModalContent>
      </ModalBody>
    </ModalWrap>
  );
});

export default Modal;
