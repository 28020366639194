import styled, { ThemeProps } from "styled-components";
import { Theme, Grid } from "@mui/material";

export const Form = styled.form`
  display: block;
`;

export const GridHr = styled(Grid)`
  hr {
    border-style: solid;
    border-bottom: 0;
    border-color: #DEDEDE;
  }
`;

export const VideoHead = styled.div`
  background: #ffffff;
  color: #000;
  display: flex;
  padding: 20px 15px;
  border-radius: 12px 12px 0 0;
  align-items: center;
  font-size: 13px;
  box-shadow: 0 3px 8px rgba(18, 19, 18, 0.1);

  @media (max-width:700px) {
    font-size: 11px;
  }

  .delete {
    margin-left: auto;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.secondary.main};
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
  }
`;