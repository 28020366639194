import React, { useEffect } from "react";
import DistrictsMap from "../../components/MapContainer";
import { DistrictTitleContainer, Map } from "./DistrictsStyles";
import useFetchParties from "../../api/useFetch/useFetchParties";
import useFetchCandidate from "../../api/useFetch/useFetchCandidate";
import PartiesStore from "../../store/PartiesStore";

const Districts = () => {
  const { getPartiesList } = useFetchParties();
  const { loading, customGetAllCandidates } = useFetchCandidate();

  useEffect(() => {
    getPartiesList().then((res: any) => PartiesStore.setList(res));
  }, []);

  const getCands = async (data: any) => {
    if (!loading) {
      return await customGetAllCandidates(data);
    }
  };

  return (
    <DistrictTitleContainer>
      <Map>
        <DistrictsMap
          getCands={getCands}
        />
      </Map>
    </DistrictTitleContainer>
  );
};

export default Districts;
