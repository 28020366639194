import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as T from '../TablesStyle'
import { NameAvatar } from '../TablesStyle'
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import isStorageLoading from '../../../utils/isStorageLoading';
import { observer } from "mobx-react-lite";
import PartiesStore from "../../../store/PartiesStore";
import PartiesAction from "./PartiesAction";
import { adminType } from "../../../api/network";
import { AdminTypes } from "../../../utils/adminType";
import setBlur from '../../../utils/setBlur';
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import { IActionItem } from '../../../types/type';

const PartiesTable = observer(({
  onDelete,
  onOpen
}: IActionItem): JSX.Element => {
  const [colNumber, setColNumber] = useState(4);
  const rowsEntries = Array(PartiesStore.rowsPerPage).fill(0);
  const colSpan = Array(colNumber).fill(0);
  const currentRoles = adminType();

  useEffect(() => {
    adminType().map((type: string) => {
      if (type === AdminTypes.spectator) {
        setColNumber(3);
      }
    })
  }, []);

  const partyManagerCode: any = localStorage.getItem("partyManagerCode");
  const isPartyManager = currentRoles.includes(AdminTypes.partyManager);

  const shouldRenderActions = (partyCode?: number) => {
    return (currentRoles.includes(AdminTypes.superAdmin)) ||
      (currentRoles.includes(AdminTypes.admin) && currentRoles.includes(AdminTypes.canCreateParties) ||
        +partyManagerCode === partyCode);
  };

  const renderRows = () => {
    if (isStorageLoading(PartiesStore) ||
      (PartiesStore.list.length === 0 && PartiesStore.isFilled)) {
      return <LoadingTable loading={PartiesStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />
    };

    if (PartiesStore.isStorageEmpty) {
      return (<EmptyRow columns={4} />);
    };

    return PartiesStore.list.map((row, index) => {
      if (row === null) {
        return (<LoadingRow key={index} colSpan={colSpan} />);
      }

      const shouldRender = shouldRenderActions(row.code);

      return (
        <T.TRow data-testid={`party-${row.id}`} key={row.id}>
          <TableCell align="left">
            <NameAvatar src={row.logo} name={row.name} />
          </TableCell>
          <TableCell align="center">{row.code}</TableCell>
          <TableCell align="center">{row.description?.trim() !== '' ? row.description : '-'}</TableCell>
          {shouldRender && (
            <TableCell align="center">
              <PartiesAction
                id={row.id}
                onOpen={onOpen}
                onDelete={onDelete}
              />
            </TableCell>
          )}
          {(isPartyManager && !shouldRender) && (
            <TableCell align="center">
            </TableCell>
          )}
        </T.TRow>
      );
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Code</TableCell>
            <TableCell align="center">Description</TableCell>
            {(shouldRenderActions() || isPartyManager) && (
              <TableCell align="center">Action</TableCell>
            )}
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(PartiesStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
});

export default PartiesTable;