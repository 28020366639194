import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  
  & > div {
    margin: 0 10px;
  }
  
  .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 20px;
  }
`;

export const Text = styled.span`
  font-size: 13px;
`;