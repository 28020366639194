import {
  IDataForm,
  IDataNews,
  IDataParty,
  ICandidateTemplateData,
} from "../types/type";

export const CHARACTER_LIMIT = 1000;
export const electionCodeLengthMax = 3;
export const electionCodeLengthMin = 2;
export const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
export const allowedAttachmentsTypes = ["image", "audio", "video"];
export const defaultValues: IDataForm = {
  receiver: "everyone",
  title: "",
  body: "",
  district: "",
  currentReceiver: [],
};
export const defaultValueNews: IDataNews = {
  title: "",
  text: "",
  image: "",
  fileName: "",
  sendPush: false,
  thumbnail: "",
};
export const defaultValueParty: IDataParty = {
  name: "",
  code: "",
  description: "",
  isLogo: false,
  logo: "",
  logoFile: "",
  logoName: "",
};
export const initialSearch: ICandidateTemplateData = {
  firstName: "",
  lastName: "",
  electionCode: "",
  mfa: "",
  district: "",
  party: "",
};
export const mandatoryFields: string[] = [
  "Full name",
  "Surname",
  "District",
  "Election code",
  "Seat type",
];

export const lawMandatoryFields: string[] = [
  "Title",
  "Law article title",
  "Law article text",
  "Issuer",
  "Legislation type",
  "Legislation Number",
  "Issue Number",
  "Law Enforcement",
  // "Issue date",
  "Legislation date",
  "Source",
  // "Note"
];
