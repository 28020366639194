import { useEffect } from "react";

const useGetTableData = (storage: any, fetcher: Function, condition?: boolean) => {
  useEffect(() => {
    if (!storage.isFilled && !condition) {
      fetcher()
        .catch(storage.emergencyRender)
        .finally(storage.setRendered);
    }
  }, [...storage.getSettings(true)]);
};

export default useGetTableData;