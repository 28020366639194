import InputText from '../../../common/Input/InputText';
import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";
import { TabBtn } from '../../../pages/AddCandidatePage/AddCandidateStyle';

export const LogField = styled(InputText)`
  margin-bottom: 10px;
  line-height: 0px;
  padding-top: 10px;
  padding-bottom: 11px;
`;

export const Title = styled.p`
  margin: 0 0 6px 0;
  font-size: 12px;
`;

export const LogButton = styled(TabBtn)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: ${(props: ThemeProps<Theme>) => {
    return props.theme.palette.primary.main;
  }};
  color: #fff;
  width: 100%;
  margin-top: 10px;
`;

export const ActionLog = styled(LogField)`
  color: ${(props: ThemeProps<Theme>) => {
    return props.theme.palette.mode === 'dark' ? '#fff' : '#000';
  }};
`;