import { makeAutoObservable } from "mobx";

class SupportStore {
  list: any[] = [];
  rowsPerPage: number = 5;
  amount: number = 0;
  page: number = 1;
  disabled: boolean = false;
  _filled: boolean = false;
  _onceRendered: boolean = false;
  answer: string = "";
  currentChat: any = { messages: {} };
  timerId: any = 0;
  TIMEOUT: number = 10000;
  updater: any = null;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  };

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [this.page, this.rowsPerPage];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
    };
  };

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  };

  get isStorageEmpty() {
    return this.list.length === 0;
  };

  resetStorage() {
    this.page = 1;
    this.rowsPerPage = 5;
    this.amount = 0;
    this.disabled = false;
    this.currentChat = { messages: {} };
    this.answer = "";
    this.updater = null;
    this._filled = false;
    clearTimeout(this.timerId);
  };

  get isFilled() {
    return this._filled;
  };

  get isRendered() {
    return this._onceRendered;
  };

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  };

  resetTextField() {
    this.answer = "";
  };

  setData(field: string, data: any) {
    this[field] = data;
  };

  launchUpdater() {
    const functionRunner = () => {
      this.updater();
      this.timerId = setTimeout(functionRunner, this.TIMEOUT);
    };
    this.timerId = setTimeout(functionRunner, this.TIMEOUT);
  };

  restartUpdater() {
    clearTimeout(this.timerId);
    this.launchUpdater();
  };

  setChat(data: any) {
    this.currentChat = { ...this.currentChat, ...data };
  };

  setPage = (page: number) => {
    if (!this.disabled) {
      this.page = page;
      this._filled = false;
    }
  };

  setRowsPerPage = (rows: number) => {
    if (!this.disabled) {
      this.rowsPerPage = rows;
      this.page = 1;
      this._filled = false;
    }
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  setList = (list: any) => {
    this.list = list;
    this._filled = true;
  };

  enable() {
    this.disabled = false;
  };

  disable() {
    this.disabled = true;
  };

  getData() {
    const [firstMessage]: any = Object.values(this.currentChat?.messages);
    return {
      id: this.currentChat?.id,
      answer: this.answer,
      mpUid: firstMessage.fromUserUid,
    };
  };
};

export default new SupportStore();