import ReportsStore from "../../store/ReportsStore";

const getReportsVotes = async (fetcher: Function) => {
  ReportsStore.disable();

  const result = await fetcher();

  ReportsStore.setList('candidates', result);

  ReportsStore.enable();

  return result;
};

export default getReportsVotes;