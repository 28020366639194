import styled, { ThemeProps } from "styled-components";
import { Theme, CircularProgress } from "@mui/material";

export const TextLabel = styled.label`
  position: relative;
  display: block;

  .length {
    margin-top: 6px;
    font-size: 12px;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
    ? '#000'
    : '#fff'
  };
    display: block;

    @media (max-width:700px) {
      font-size: 10px;
    }
  }
`

export const TextLabelName = styled.p`
  margin: 0 0 6px 0;
  font-size: 12px;

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const Input = styled.input`
  background: #F8F8F8;
  border-radius: 6px;
  border: 0;
  padding: 12px 9px;
  display: block;
  width: 100%;
  font-family: 'Open Sans',sans-serif;
  font-size: 14px;

  @media (max-width:700px) {
    font-size: 12px;
  }

  &:focus, &:active {
    outline: none;
  }
`;

export const TextHelper = styled.span`
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 12px;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.secondary.main};

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const DateInput = styled(Input)`
  width: 180px;
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  
  input {
    display: none;
  }
`;

export const Btn = styled.span`
  display: block;
  width: 100%;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 9px;
  border-radius: 6px;
  cursor: pointer;
  transition: all .3s;

  @media (max-width:700px) {
    font-size: 12px;
  }
  
  &:hover {
    transition: all .3s;
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.dark};
  }
`;

export const Wrap = styled.div`
  position: relative;
`;

export const BtnMFA = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 12px;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.secondary.main};

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const Loader = styled(CircularProgress)`
  position: absolute;
  left: 10px;
  top: 30%;
  transform: translateY(-50%);
  display: inline-block;
`;

export const PhoneLabel = styled.label`
  position: relative;
  display: block;

  input {
    background: #F8F8F8;
    border-radius: 6px;
    border: 0;
    padding: 12px 9px;
    display: block;
    width: 100%;

    &:focus, &:active {
      outline: none;
    }
  }
`;

export const LabelName = styled.p`
  margin: 0 0 6px 0;
  font-size: 12px;

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const Helper = styled.span`
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 12px;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.secondary.main};

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const PlaceList = styled.ul`
  position: absolute;
  left: 0;
  top: 59%;
  width: 100%;
  background: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  z-index: 10;
`;

export const Li = styled.li`
  display: block;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
  color: #000;
  
  &:hover {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
  }
`;

export const PlaceLoader = styled(CircularProgress)`
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: 5px;
`;

export const TextWrap = styled.div`
  width: 100%;

  textarea:disabled {
    opacity: 0.8;
  }

  .label {
    margin: 0 0 6px 0;
    font-size: 12px;
    display: block;
    width: 100%;

    @media (max-width:700px) {
      font-size: 10px;
    }
}

.length {
  margin-top: 6px;
  font-size: 12px;
  display: block;

  @media (max-width:700px) {
    font-size: 10px;
  }
}

textarea {
  background: #F8F8F8;
  border: 0;
  display: block;
  width: 100%;
  border-radius: 6px;
  padding: 12px 9px;
  height: 100px;
  resize: none;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 14px;

  @media (max-width:700px) {
    font-size: 12px;
  }
  
  &:focus {
    outline: none;
  }
}
`;