import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { observer } from "mobx-react-lite";
import { RouteList } from "../../../utils/adminType";
import { EpisodeItem, EpisodeItemContainer } from "./PodcastContentStyle";
import EditIcon from "@mui/icons-material/Edit";
import useFetchPodcasts from "../../../api/useFetch/useFetchPodcasts";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import PodcastsStore from "../../../store/PodcastsStore";

const Episode = observer(({ id, data, loading, podcastId }: any) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { deleteEpisode } = useFetchPodcasts();

  const editEpisode = () => {
    navigate(`${RouteList.editEpisode}/${podcastId}/episodes/edit/${id}`);
  };

  const openEpisode = () => {
    navigate(`${RouteList.episode}/${podcastId}/episodes/${id}`);
  };

  const deleteEpisodeHandler = async () => {
    PodcastsStore.removeSubItemLocally(id);
    await deleteEpisode(id, podcastId);
  };

  return (
    <EpisodeItemContainer>
      <EpisodeItem title={data.title} arrow>
        <Button
          data-testid={`episode-content-open-article-${id}`}
          onClick={openEpisode}
          style={{ textTransform: "revert" }}
        >
          <PodcastsIcon style={{ marginRight: 10 }} />
          {(data.title.length > 45) ? `${data.title.slice(0, 42)}...` : data.title}
        </Button>
      </EpisodeItem>
      <Tooltip title="Edit" arrow>
        <Button data-testid={`episode-content-edit-${id}`} onClick={editEpisode}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Delete" arrow>
        <Button data-testid={`episode-content-delete-article-${id}`} onClick={() => setOpen(true)}>
          <DeleteIcon />
        </Button>
      </Tooltip>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle="Are you sure to delete podcast episode?"
          disabled={loading}
          disableStates={[setOpen]}
          callback={deleteEpisodeHandler}
        />
      )}

    </EpisodeItemContainer>
  );
});

export default Episode;
