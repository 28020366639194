//@ts-nocheck
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { ExportXLSXProps } from '../types/type';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

export const ButtonExport = styled(Button)`
  font-size: 14px;

  @media (max-width:700px): {
   font-size: 12px;
  }
`;

export const ExportXLSX = ({
  data,
  fileName,
  preClick,
  dataLoaded,
  dataTestId
}: ExportXLSXProps): JSX.Element => {
  const [loading, setloading] = useState(false);

  const exportToXLSX = async (data: any, fileName: string) => {

    if (data)
      try {
        const MAX_XL_LENGTH = 32767;
        setloading(true);
        if (preClick) {
          data = await preClick();
          console.log('length xlsx ', data);
        }

        data.forEach((element: any) => {
          delete element.mfa;
          delete element.rating;
          element.electionCode = String(element.electionCode) ?? element.electionCode;
          element.party = String(element.party) ?? element.party;
        });

        const ws = XLSX.utils.json_to_sheet(data);
        const filteredWS = Object.fromEntries(
          Object
            .entries(ws)
            .filter((item) => {
              if (item[0] === "!ref") {
                return true;
              }
              return item[1].v?.length < MAX_XL_LENGTH;
            }));
        const wb = { Sheets: { data: filteredWS }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const element = document.createElement('a');
        const file = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setloading(false);
      } catch (error) {
        console.error(error);
        setloading(false);
        alert(error);
      }
  };

  return loading ? (
    <p>Loading...</p>
  ) : (
    <ButtonExport
      disabled={dataLoaded}
      variant='outlined'
      data-testid={dataTestId}
      onClick={(e) => exportToXLSX(data, fileName)}>
      Export
    </ButtonExport>
  );
};
