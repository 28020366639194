//@ts-nocheck
import { Container, CssBaseline } from "@mui/material";

import React, { useEffect } from "react";
import MobileStoreButton from "react-mobile-store-button";
import parseSearchBar from "../utils/parseSearchBar";
import { encodeUppercase } from "../utils/validators";

const OpenNewsArticle = () => {
  document.title = "News";

  function getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else return null;
  }

  useEffect(async () => {
    let link = encodeUppercase(parseSearchBar(location?.search)?.itemId);

    if (getMobileOperatingSystem === "Android") {
      window.open(
        `intent://${link}/#Intent;scheme=opencertainarticle;package=com.creativeadvancedtechnologies.EParliament.production;end`,
        "_self"
      );
    } else {
      window.open(`opencertainarticle://${link}`, "_self");
    }

    setTimeout(function () {
      if (getMobileOperatingSystem === "iOS") {
        window.open("https://apps.apple.com/us/app/أنا-البرلمان/id1556378010");
      } else if (getMobileOperatingSystem === "Android") {
        window.open(
          "https://play.google.com/store/apps/details?id=com.creativeadvancedtechnologies.EParliament.production"
        );
      }
    }, 250);
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",

          marginTop: "10%",
        }}
      >
        <br />
        <CssBaseline />
        <MobileStoreButton
          width={200}
          store="ios"
          url={"https://apps.apple.com/us/app/أنا-البرلمان/id1556378010"}
          linkProps={{ title: "iOS Store Button" }}
        />
        <MobileStoreButton
          width={200}
          store="android"
          url={
            "https://play.google.com/store/apps/details?id=com.creativeadvancedtechnologies.EParliament.production"
          }
          linkProps={{ title: "Play Store Button" }}
        />
      </div>
    </Container>
  );
};

export default OpenNewsArticle;
