//@ts-nocheck
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import network, { isAuth } from "../api/network";
import logo from "../assets/images/logo.svg";
import parseSearchBar from "../utils/parseSearchBar";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  Paper,
  TitleWrapper,
  Title,
  Logo,
  LogoIcon,
  LogoTitle,
} from "./PasswordResetPageStyles";
import checkPassword from "../utils/checkPassword";

const PasswordResetPage = () => {
  if (isAuth) {
    navigate("/");
  }

  let code = parseSearchBar(location?.search)?.oobCode;
  const navigate = useNavigate();

  useEffect(() => {
    if (parseSearchBar(location?.search)?.mode === "verifyEmail") {
      network.post(`api/auth/verifyEmail`, { code }).then(async (res) => {
        location.href = "/passwordSuccess?type=email";
      });
    }
  }, []);

  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleLogin = async (event) => {
    setLoading(true);
    event.preventDefault();
    const password = event.target.elements.password.value;
    const confirmPassword = event.target.elements.confirmPassword.value;

    let complexity = checkPassword(password);
    if (code) {
      if (password !== confirmPassword)
        setPasswordError("Passwords are not the same!");
      else if (!complexity?.result) setPasswordError(complexity?.error);
      else {
        setPasswordError(null);

        await network
          .post(`api/auth/resetPassword`, { code, password })
          .then(async (res) => {
            navigate("/passwordSuccess");
          });
      }
    } else {
      alert("Error! Please ask for password again.");
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <Logo>
          <LogoIcon src={logo} alt="logo" />
          <LogoTitle variant="h6">E-Parliament</LogoTitle>
        </Logo>
        <br />
        <CssBaseline />
        {parseSearchBar(location?.search)?.mode === "verifyEmail" ? (
          <div>
            <TitleWrapper>
              <Title component="h1" variant="h5">
                Email verification in progress!
              </Title>
            </TitleWrapper>
          </div>
        ) : (
          <div>
            <TitleWrapper>
              <Title component="h1" variant="h5">
                Enter new password
              </Title>
            </TitleWrapper>

            <form onSubmit={handleLogin} style={{ width: "100%" }} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                autoFocus
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                // error={!!passwordError}
                // helperText={passwordError}
                style={{ minHeight: 45, color: "#AFAFAF" }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ zIndex: 100 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large"
                      >
                        {showPassword ? (
                          <Visibility style={{ fill: "#AFAFAF" }} />
                        ) : (
                          <VisibilityOff style={{ fill: "#AFAFAF" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm password"
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                error={!!passwordError}
                helperText={passwordError}
                style={{ minHeight: 45, color: "#AFAFAF" }}
                size="small"
              />
              <LoadingButton
                disabled={loading}
                type="submit"
                size="large"
                variant="contained"
                fullWidth
              >
                {"Set new password"}
              </LoadingButton>
            </form>
          </div>
        )}
      </Paper>
    </Container>
  );
};

export default PasswordResetPage;
