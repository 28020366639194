import React from 'react';
import * as F from '../PartyForm/PartyFormStyle'
import { CardBox } from "../../../common/Layout/LayoutStyle";
import ErrorIcon from '@mui/icons-material/Error';
import LogsStore from '../../../store/LogsStore';
import { observer } from 'mobx-react-lite';
import { IWithIsLoading } from "../../../types/type";
import getResultDescription from '../../../utils/resultDescriptor';
import { LogField, ActionLog, Title, LogButton } from './LogFormStyles';
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from "@mui/material/TableHead";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

const LogForm = observer(({ isLoading }: IWithIsLoading): JSX.Element => {
  const navigate = useNavigate();

  const getMethodColor = (method: string) => {
    switch (method) {
      case "PATCH":
        return "80, 227, 194";
      case "DELETE":
        return "249, 62, 62";
      case "PUT":
        return "252, 161, 48";
      case "POST":
        return "73, 204, 144";
    }
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <F.SkeletonParty
          variant="rectangular"
          animation="wave"
        />
      );
    };

    const currentModes = {
      editLawArticle: (LogsStore.logData.method === "POST" && LogsStore.logData.url.includes("/create/lawArticle/")),
      editLaw: (LogsStore.logData.method === "POST" && LogsStore.logData.url.includes("/create/law/")),
      editPodcast: (LogsStore.logData.method === "POST" && LogsStore.logData.url.includes("/create/podcast/")),
      editEpisode: (LogsStore.logData.method === "POST" && LogsStore.logData.url.includes("/create/episode/")),
    };

    const onOpen = (path: string, id: string) => {
      if (currentModes.editLawArticle) {
        const [articleId, lawId] = id.split('/');
        navigate(`${path}/${lawId}/articles/edit/${articleId}`);
        return;
      }

      if (currentModes.editEpisode) {
        const [episode, podcast] = id.split('/');
        navigate(`${path}/${podcast}/episodes/edit/${episode}`);
        return;
      }
      
      navigate(`${path}/${id}`);
    };

    const methodColor = getMethodColor(LogsStore.logData.method);
    const { description, path, shouldRender }: any = getResultDescription(LogsStore.logData.url, LogsStore.logData.method, true);

    const getId = () => {
      if (currentModes.editLawArticle) {
        return LogsStore.logData.url.split('/lawArticle/')[1];
      }
      if (currentModes.editLaw) {
        return LogsStore.logData.url.split('/law/')[1];
      }
      if (currentModes.editPodcast) {
        return LogsStore.logData.url.split('/podcast/')[1];
      }
      if (currentModes.editEpisode) {
        return LogsStore.logData.url.split('/episode/')[1];
      }

      return LogsStore.logData?.body?.id;
    };


    const openItemButton = (
      <LogButton
        variant="contained"
        type="button"
        onClick={() => {
          onOpen(path, getId());
        }}>
        <ReadMoreIcon />
        Open
      </LogButton>
    );

    const renderRow = (data: any, index: number) => {
      const formatRow = () => {
        if (typeof data[1] === 'object') {
          return `Amount ${Object.entries(data[1]).length}`;
        } 
        return (typeof data[1] !== 'string') ? String(data[1]) : data[1].slice(0, 150);
      };
      return (
        <TableRow key={index}>
          <TableCell align="center">
            {data[0] && data[0]}
          </TableCell>
          <TableCell align="center" style={{ wordBreak: "break-all" }}>
            {formatRow()}
          </TableCell>
        </TableRow>
      );
    };

    const getRows = () => {
      const emptyData = (
        <TableRow>
          <TableCell align="center">
            Nothing to show, request was empty.
          </TableCell>
          <TableCell align="center">
          </TableCell>
        </TableRow>
      );

      if (!LogsStore.logData.body) {
        return (emptyData);
      }

      const entries = Object.entries(LogsStore.logData.body);

      if (entries.length === 0) {
        return (emptyData);
      };

      if (entries.some((item) => item[0] === 'id' && shouldRender)) {
        return entries
          .filter((item) => item[0] !== "id")
          .map((data: any, index: number) => (renderRow(data, index)));
      }

      return entries.map((data: any, index: number) => renderRow(data, index));
    };

    const getOperationDetails = () => {
      if ((LogsStore.logData.body &&
        Object.entries(LogsStore.logData.body).length >= 1) ||
        shouldRender) {
        return (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Key</TableCell>
                  <TableCell align="center">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getRows()}
              </TableBody >
            </Table>
            {(LogsStore.logData?.body?.id || shouldRender) &&  
              openItemButton}
          </>
        );
      };

      if (LogsStore.logData?.url === "/create/lawBulkImport" || LogsStore.logData?.url === "/create/bulkEditLaws") {
        return null;
      }
      
      return (openItemButton);
    };

    return (LogsStore.noErrors) ? (
      <CardBox>
        <LogField
          value={LogsStore.logData.email}
          label="Author"
          disabled={true}
        />

        <LogField
          value={new Date(LogsStore.logData.created_at).toLocaleDateString()}
          label="Date"
          disabled={true}
        />

        <ActionLog
          value={description}
          label="Action"
          disabled={true}
          style={
            { border: `2px solid rgb(${methodColor})`, background: `rgba(${methodColor}, 0.1)` }
          }
        />

        <div>
          <Title>
            Operation details:
          </Title>
          {getOperationDetails()}
        </div>
      </CardBox>
    ) : (
      <CardBox style={{ textAlign: 'center' }}>
        <ErrorIcon color={'error'} />
        <p>There is no log with such an ID.</p>
        <p>Check the URL in the browser bar.</p>
      </CardBox>
    );
  };

  return (
    <F.Wrap>
      {renderData()}
    </F.Wrap>
  );
});

export default LogForm;