import React, { useEffect, useState } from "react";
import { Paper, Stack, CircularProgress, Button } from "@mui/material";
import * as S from "./BulkStyle";
import readXlsxFile from "read-excel-file";
import GetAppIcon from "@mui/icons-material/GetApp";
import ModalAlert from "../../common/Modal/ModalAlert";
import { lawMandatoryFields } from "../../utils/constants";
import LawStore from "../../store/LawStore";
import { observer } from "mobx-react-lite";
import WarningStore from "../../store/WarningStore";
import { BulkProps } from "../../types/type";
import lawsCSVChecker from "../../utils/lawsCSVChecker";
import MandatoryFields from "./MandatoryFields";

const BulkUploadLaws = observer(({
  onUpload
}: BulkProps): JSX.Element => {
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isUploadingData, setIsUploadingData] = useState(false);
  const [modalTitle, setModalTitle] = useState<any>("");

  const onDelete = () => {
    LawStore.setBulkData("upFileCSV", null);
    LawStore.setBulkData("isFileSelected", false);
    LawStore.setBulkData("uploadedFileName", {});
    LawStore.setBulkData("isBulkButtonActive", false);
    LawStore.setBulkData("errorValidationFile", null);
    WarningStore.setIsEdited(false);
  };

  console.log(LawStore.bulk.errorValidationFile)

  const AddCSVButton = () => (
    <S.CSVContainer>
      <S.InputLabelBulk htmlFor="file-csv-upload">
        <S.LabelBulkContent>
          {!LawStore.bulk.isFileSelected ? (
            <span style={{ width: "40%", textAlign: "center" }}>
              Select XLSX (bulk insert) less than 10 MB
            </span>
          ) : (
            <S.FileContainer>
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>
                {LawStore.bulk.uploadedFileName.fileName}
              </span>
              <span style={{ fontSize: 12, marginBottom: 10 }}>
                {`${(LawStore.bulk.uploadedFileName.fileSize / 1000000).toFixed(3)} Mb`}
              </span>
              <S.DeleteFile onClick={onDelete}>
                Delete
              </S.DeleteFile>
            </S.FileContainer>
          )}
        </S.LabelBulkContent>

        {(LawStore.bulk.errorValidationFile) && (
          <S.ProgressWrapper>
            <S.ProgressContainer>
              {(LawStore.bulk.errorValidationFile) && (
                <>
                  <S.UploadSuccess>
                    {LawStore.bulk.errorValidationFile.successful} laws uploaded
                    successfully
                  </S.UploadSuccess>
                  <S.ErrorsContainer>
                    <S.UploadFailed>
                      {LawStore.bulk.errorValidationFile.failed} laws were not uploaded due to errors
                    </S.UploadFailed>
                    <S.DownloadErrors onClick={() => downloadXLSX(LawStore.bulk.resultXLS)}>
                      <GetAppIcon
                        style={{ width: 11, height: 11, marginRight: 8 }}
                      />
                      Download error list
                    </S.DownloadErrors>
                  </S.ErrorsContainer>
                </>
              )}
            </S.ProgressContainer>
          </S.ProgressWrapper>
        )}
      </S.InputLabelBulk>

      <input
        style={{ display: "none" }}
        type="file"
        id="file-csv-upload"
        accept=".xlsx"
        onChange={onCSVFileUpload}
      />

      <S.UploadWrapperBulk>

        <Button
          variant='contained'
          disabled={!LawStore.bulk.isBulkButtonActive}
          onClick={onUploadCSVClick}>
          Import
        </Button>
      </S.UploadWrapperBulk>
    </S.CSVContainer>
  );

  const downloadXLSX = (data: any) => {
    const element = document.createElement("a");
    const file = new Blob([new Uint8Array(data.data).buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    element.href = URL.createObjectURL(file);
    element.download = "failed_laws.xlsx";
    document.body.appendChild(element);
    element.click();
  };

  const onCSVFileUpload = async (e: any) => {
    const file = e.target.files[0];

    LawStore.setBulkData("uploadedFileName", {
      fileName: e.target.files[0].name,
      fileSize: e.target.files[0].size,
    });

    LawStore.setBulkData("resultXLS", null);

    if (file.size > 1524000) {
      setModalTitle("File is too large!");
      setIsModalErrorOpen(true);
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async (e) => {
      try {
        let bulk = await readXlsxFile(file);
        let check = lawsCSVChecker(bulk, lawMandatoryFields, setModalTitle, setIsModalErrorOpen);
        if (check) {
          LawStore.setBulkData("isBulkButtonActive", true);
          LawStore.setBulkData("isFileSelected", true);
          LawStore.setBulkData("upFileCSV", bulk);
          setModalTitle("File seems to be ok. You can proceed for further import.");
          setIsModalErrorOpen(true);
          WarningStore.setIsEdited(true);
        }
      } catch (error) {
        console.log(error)
        setModalTitle("File is corrupted. Check all the fields.");
        setIsModalErrorOpen(true);
      }
    };
  };

  useEffect(() => {
    if (!LawStore.bulk.upFileCSV) {
      LawStore.setBulkData("isBulkButtonActive", false);
    }
  }, [LawStore.bulk.upFileCSV]);

  const onUploadCSVClick = async (e: any) => {
    setIsUploadingData(true);
    e.preventDefault();
    LawStore.setBulkData("isBulkButtonActive", false);
    LawStore.setBulkData("errorValidationFile", null);
    let bulk = LawStore.bulk.upFileCSV;

    onUpload(bulk.slice(0, 1), bulk.slice(1))
      .then((response: any) => {
        if (response.message === "Laws successfully created!") {
          LawStore.setBulkData("uploadedFileName", {});
          LawStore.setBulkData("errorValidationFile", null);
          setModalTitle(`All good! ${response.message ?? ""}`);
          setIsModalErrorOpen(true);
          LawStore.setBulkData("upFileCSV", null);
          LawStore.setBulkData("isFileSelected", false);
          WarningStore.setIsEdited(false);
        } else {
          setModalTitle(`Something wrong! ${response.message}`);
          setIsModalErrorOpen(true);
        }
      })
      .catch((error: any) => {
        let res = error?.response?.data;

        LawStore.setBulkData("isBulkButtonActive", false);
        LawStore.setBulkData("upFileCSV", null);


        if (error?.response?.status == 400 && (res?.failedAmount || res?.successfulAmount)) {
          LawStore.setBulkData("errorValidationFile", {
            failed: res.failedAmount,
            successful: res.successfulAmount,
          });
          LawStore.setBulkData("resultXLS", res.xls);
        } else {
          setModalTitle(`Error! ${res.message}`);
          setIsModalErrorOpen(true);
          LawStore.setBulkData("isFileSelected", false);
        }
      })
      .finally(() => {
        LawStore.setBulkData("isBulkButtonActive", false);
        setIsUploadingData(false);
      });
  };

  return (
    <S.UploadContainer>
      {!isUploadingData ? <AddCSVButton /> : (
        <Stack style={{ marginTop: "150px", marginLeft: "auto", marginRight: "auto" }} alignItems="center">
          <CircularProgress color="primary" size={150} />
          <p>
            {"Please wait, it may took some time to import..."}
          </p>
        </Stack>
      )}

      <div style={{ display: "flex", flexDirection: "column", minWidth: "41%" }}>
        <a
          style={{ textDecoration: "none" }}
          href="https://firebasestorage.googleapis.com/v0/b/eparliament-staging.appspot.com/o/utils%2FLawsImportStructure.xlsx?alt=media&token=ab4634a1-8f16-41cf-a82d-1a68e80313a6"
          download
        >
          <Paper style={{ display: "flex", alignItems: "center", padding: "30px 20px", backgroundColor: "#fff" }}>
            <GetAppIcon className="download-icon" style={{ width: 26, height: 26 }} />
            <S.TemplateLabel>Download template</S.TemplateLabel>
          </Paper>
        </a>
        <S.TemplateAnnotation>
          This file contains mandatory fields which shouldn’t be skipped
        </S.TemplateAnnotation>
        <S.AnnotationHeaderUpload>Mandatory fields in file: </S.AnnotationHeaderUpload>
        <MandatoryFields fields={lawMandatoryFields} />
      </div>

      {isModalErrorOpen && (
        <ModalAlert
          setActive={setIsModalErrorOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalErrorOpen]}
          callback={() => setModalTitle("")}
        />
      )}

    </S.UploadContainer>
  );
});

export default BulkUploadLaws;