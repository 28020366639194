import React from 'react';
import styled from "styled-components";
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import FilterTable from "../components/Tables/FilterTable/FilterTable";
import PartiesStore from '../store/PartiesStore';
import PartiesTable from "../components/Tables/PartiesTable/PartiesTable";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { adminType } from "../api/network";
import { AdminTypes, RouteList } from "../utils/adminType";
import getPartiesList from '../api/actions/getPartiesList';
import ShowSubLoading from '../utils/ShowSubLoading';
import AlignedButtonText from '../common/Text/AlignedButtonText';

//hooks
import useFetchParties from '../api/useFetch/useFetchParties';
import useAdjustPage from '../hooks/useAdjustPage';
import useCompleteTableData from '../hooks/useCompleteTableData';
import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const Hidden = styled.button`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
`;

const PartiesPage = observer(() => {
  const navigate = useNavigate();
  const { loading, getParties, deleteParty } = useFetchParties();
  const currentRoles = adminType();

  useClearStorage(PartiesStore);
  useGetTableData(PartiesStore, () => getPartiesList(getParties));
  useCompleteTableData(PartiesStore, getParties);
  useAdjustPage(PartiesStore);

  const onOpen = (id: string) => {
    navigate(`${RouteList.editParty}/${id}`);
  };

  const testErrorCreation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    throw new Error("Staging error only for Sentry eyes");
  };

  return (
    <div data-testid="parties-page">
      <ShowSubLoading storage={PartiesStore} />
      <h1>Parties list</h1>

      <FilterTable
        searchValue={PartiesStore.searchValue}
        setSearchValue={PartiesStore.setSearchValue}
        setPage={PartiesStore.setPage}
        disabled={PartiesStore.disabled || loading}
      />

      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={PartiesStore.rowsPerPage}
            amount={PartiesStore.amount}
            setRowsPerPage={PartiesStore.setRowsPerPage}
            disabled={PartiesStore.disabled}
          />

          {(currentRoles.includes(AdminTypes.superAdmin) ||
            currentRoles.includes(AdminTypes.canCreateParties)) && (
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => navigate(RouteList.addParty)}
                data-testid="add-party-button"
              >
                <AlignedButtonText>
                  Add party
                </AlignedButtonText>
              </Button>
            )}

        </ToolbarTable>

        <Hidden id="Sentry" onClick={testErrorCreation}/>

        <PartiesTable
          onDelete={deleteParty}
          onOpen={onOpen}
        />

        <Paginator
          amount={PartiesStore.amount}
          rowsPerPage={PartiesStore.rowsPerPage}
          page={PartiesStore.page}
          setPage={PartiesStore.setPage}
          disabled={PartiesStore.disabled}
        />
      </CardBox>
    </div>
  );
});

export default PartiesPage;