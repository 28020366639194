import React from "react";
import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

const Wrap = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  padding: 20px 0;
  margin-top: auto;
  margin-left: 9px;
  
  button {
    margin-right: 12px;
    width: 50%;
    
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const ModalFooterBtnGroup = ({ children }: any): JSX.Element => {
  return (
    <Wrap>
      {children}
    </Wrap>
  );
};

export default ModalFooterBtnGroup
