import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import useFetchPCMElectionMode from "../api/useFetch/useFetchPCMElectionMode";
import { observer } from "mobx-react-lite";
import CandidateStore from '../store/CandidateStore';
import PCMElectionModeStore from "../store/PCMElectionModeStore";
import PCMElectionModeForm from "../components/Form/ElectionModeForm/PCMElectionModeForm";
import getPCMElectionModes from "../api/actions/getPCMElectionModes";

const ChangePCMElectionModePage = observer(() => {
  const {
    loading,
    handleElectionMode,
    getMode,
    getCurrentPCMMode
  } = useFetchPCMElectionMode();

  const onCurrentMode = () => {
      getCurrentPCMMode()
      .then((res: any) => {
        const currentMode = (!!res) ? res : 'election';
        PCMElectionModeStore.setCurrentMode(currentMode);
        const allias: string =
          currentMode.toLowerCase() !== "final"
            ? "Candidate"
            : "Provincial Council Member";
        CandidateStore.setCurrentAllias(allias);
      });
  };

  useEffect(() => {
    if (PCMElectionModeStore.isModesEmpty) {
      getPCMElectionModes(getMode);
    }
    if (PCMElectionModeStore.isCurrentModeEmpty) {
      onCurrentMode();
    }
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" data-testid="change-mode-page">
      <h1>Change PCM election mode</h1>

      <PCMElectionModeForm
        handleElectionMode={handleElectionMode}
        updateCurrentMode={onCurrentMode}
        isLoading={loading}
      />
    </Stack>
  );
});

export default ChangePCMElectionModePage;