import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const Main = styled.main`
  display: flex;
  min-height: 100vh;
  padding-left: 312px;
`

export const Content = styled.div`
  padding: 0 30px;
  width: 100%;

  h1 {
    font-weight: 600;
    font-size: 20px;
    margin: 30px 0;
  }
`

export const CardBox = styled.div`
  min-height: 160px;
  padding: 30px;
  border-radius: 5px;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  box-shadow: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
    ? '0 1px 0 0 #dce1e6, 0 0 0 1px #e7e8ec'
    : '0 1px 0 0 #222226, 0 0 0 1px #222226'
  };
  margin-bottom: 30px;
`

export const ToolbarTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`