import styled from "styled-components";
import { Skeleton } from "@mui/material";
import { CardBox } from "../../../common/Layout/LayoutStyle";

export const Wrap = styled.div`
  width: 1160px;
  max-width: 100%;
  display: block;
`;

export const SkeletonParty = styled(Skeleton)`
  border-radius: 5px;
  width: 1160px;
  height: 601px;
  max-width: 100%;
`;

export const Container = styled(CardBox)`
  display: flex;
  flex-direction: column;
`;

export const SubContainer = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start; 

  p {
    margin: 0px;
  }
`;

export const ArticleContainer = styled.div`
  width: 100%;

  img {
    width: 100% !important;
    height: 100% !important;
  }
`;