import React from 'react';
import { Grid, CircularProgress } from "@mui/material";
import { CardBox } from "../../common/Layout/LayoutStyle";
import ExportXLSX from './ExportXLSX';
import { observer } from 'mobx-react-lite';
import { IReports } from "../../types/type";
import Stack from '@mui/material/Stack';
import ReportsStore from '../../store/ReportsStore';

const Reports = observer(({
  progress
}: IReports): JSX.Element => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <CardBox>
          <h3>Download all voters?</h3>
          {progress && <p>{progress}%</p>}
          <ExportXLSX
            data={ReportsStore.votes}
            dataTestId="download-voters"
            fileName="sortedVoters"
          />
        </CardBox>
      </Grid>
      <Grid item xs={6}>
        <CardBox>
          {(ReportsStore.isCandsLoading) ? (
            <Stack data-testid="NL-progress-anim" alignItems="center" justifyContent="center">
              <CircularProgress color="primary" size={70} />
              <p style={{ margin: 0, padding: 0 }}>Please wait, loading profiles...</p>
            </Stack>
          ) : (
            <>
              <h3>Download all members of parliament?</h3>
              <ExportXLSX
                data={ReportsStore.candidates}
                dataTestId="download-candidates"
                fileName="sortedCandidates"
              />
            </>
          )}
        </CardBox>
      </Grid >
    </Grid >
  );
});

export default Reports;