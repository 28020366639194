import React, { useEffect, useState } from 'react';
import * as N from './NotificationFormStyle'
import { CardBox } from "../../../common/Layout/LayoutStyle";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import {
  Controller,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { neighbourhoods } from "../../../assets/districts/data";
import { translateDistrict } from "../../../utils/i18n";
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues } from "../../../utils/constants";
import { IDataForm } from "../../../types/type";
import WarningStore from '../../../store/WarningStore';
import * as yup from "yup";
import { INotificationForm } from '../../../types/type';
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import ModalAlert from '../../../common/Modal/ModalAlert';

const schema: any = yup.object({
  title: yup.string().required(),
  body: yup.string().required(),
  receiver: yup.string().required(),
  district: yup.string().when('receiver', {
    is: 'dedicated',
    then: (schema) => schema.required(),
  }),
  currentReceiver: yup.array().when('receiver', {
    is: 'dedicated',
    then: (schema) => schema.min(1),
  }),
}).required();

const NotificationForm = ({ onNotificationSent, isLoading, redirect }: INotificationForm): JSX.Element => {
  const { register, handleSubmit, control, reset, watch, formState: { isValid } } = useForm<IDataForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [district, setDistricts] = useState<Array<string>>([]);
  const [currentReceiver, setCurrentReceiver] = useState({ voters: false, candidates: false });
  const [open, setOpen] = useState({ confirm: false, alert: false });
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState<any>({ action: null });

  useEffect(() => {
    const districtNames: Array<string> = [];

    neighbourhoods.map(item => {
      let name = item.neighbourhoodId.split("; #")[0];
      districtNames.push(name);
    });

    setDistricts([...new Set([...districtNames])]);
  }, []);

  useEffect(() => {
    if (!isLoading && modalTitle) {
      setModalTitle("Notification successfully created!");
      setModalAction({ action: async () => redirect() });
      setOpen({ ...open, alert: true });
    }
  }, [isLoading]);

  const sendNotification = async (formState: any) => {
    const { receiver, title, body, currentReceiver, district } = formState;

    const getReceiver = () => {
      if (receiver === 'dedicated') {
        return `/topics/${district}${currentReceiver.length === 2 ? 'all' : currentReceiver[0]}`
      };
      return '/topics/application';
    };
    await onNotificationSent({ body, title, receiver: getReceiver() });


    WarningStore.resetStorage();
    reset(defaultValues);
  };

  const onSubmit: SubmitHandler<IDataForm> = dataForm => {
    setModalTitle("Are you sure to create notification?");
    setModalAction({ action: async () => sendNotification(dataForm) });
    setOpen({ ...open, confirm: true });
  };

  const onModalStateChange = (state: any) => {
    setOpen({ confirm: state, alert: state });
  };

  return (
    <N.Wrap>
      <CardBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <FormLabel>Choose notification receiver:</FormLabel>
                <Controller
                  name={'receiver'}
                  control={control}
                  render={field => (
                    <RadioGroup row {...field.field}>
                      <FormControlLabel
                        value="everyone"
                        control={<Radio />}
                        label="Everyone"
                      />
                      <FormControlLabel
                        value="dedicated"
                        control={<Radio />}
                        label="Dedicated receiver"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={watch('title')}
                {...register('title', {
                  onChange: () => WarningStore.setIsEdited(true),
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Body"
                value={watch('body')}
                {...register('body', {
                  onChange: () => WarningStore.setIsEdited(true),
                })}
              />
            </Grid>

            {watch('receiver') === 'dedicated' && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel>Send to</InputLabel>
                    <Controller
                      name='district'
                      control={control}
                      render={(field) => {
                        return (
                          <Select
                            label="Send to"
                            MenuProps={{ style: { height: 300 } }}
                            {...field.field}
                          >
                            {district.map((item, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => WarningStore.setIsEdited(true)}
                                value={item.split("_N_")[0] + 'n' + item.split("_N_")[1]}>
                                {`${translateDistrict(item)} (${item.replace("_N_", " ")})`}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={'For voters'}
                    value={'voters'}
                    checked={currentReceiver.voters}
                    {...register('currentReceiver', {
                      onChange: event => {
                        setCurrentReceiver(currentReceiver => ({
                          ...currentReceiver,
                          [event.target.value]: event.target.checked
                        }));
                      }
                    })}
                    control={<Checkbox />}
                  />
                  <FormControlLabel
                    label={'For members of parliament'}
                    value={'candidates'}
                    checked={currentReceiver.candidates}
                    {...register('currentReceiver', {
                      onChange: event => {
                        setCurrentReceiver(currentReceiver => ({
                          ...currentReceiver,
                          [event.target.value]: event.target.checked
                        }))
                      }
                    })}
                    control={<Checkbox />}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Button
                disabled={!isValid}
                type="submit"
                variant="contained"
                size="large"
              >
                Send push notification
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardBox>

      {open.confirm && (
        <ModalConfirm
          setActive={onModalStateChange}
          windowTitle={modalTitle}
          disabled={isLoading}
          disableStates={[onModalStateChange]}
          callback={modalAction.action}
        />
      )}

      {open.alert && (
        <ModalAlert
          setActive={onModalStateChange}
          windowTitle={modalTitle}
          disableStates={[onModalStateChange]}
          callback={modalAction.action}
          informative={true}
        />
      )}

    </N.Wrap>
  );
};

export default NotificationForm;