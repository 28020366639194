import React from "react";
import { List } from "@mui/material";
import { AnnotationItems } from "./BulkStyle";

const MandatoryFields = ({ fields, attention }: any): JSX.Element => {
  return (
    <List>
      {fields.map((field: string, index: number) => (
        <AnnotationItems key={index}>
          {field}
        </AnnotationItems>
      ))}
      {attention && (
        <AnnotationItems>
          Please, do not change any ID in file.
        </AnnotationItems>
      )}
    </List>
  );
};

export default MandatoryFields;