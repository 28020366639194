import network from "../../api/network";
import { removeEmpty } from "../removeEmpty";
import { IGetList } from "../../types/type";
import { _transformNews } from "../../api/tranformResponse";
import { _transformSurveys } from "../../api/tranformResponse";
import { _transformNotification } from "../../api/tranformResponse";

const fetchList = (url: string) => {
  return async ({ page, rowsPerPage, searchValue, type }: IGetList) => {
    const response = await network.get(url, {
      params: removeEmpty({
        page,
        rowsPerPage,
        type,
        searchValue: removeEmpty(searchValue),
      })
    });

    switch(url) {
    }

    if (url === 'api/check/news') {
      return {
        amount: response.data.amount,
        list: response.data.list.map(_transformNews),
      };
    };

    if (url === 'api/check/surveys') {
      return {
        amount: response.data.amount,
        list: response.data.list.map(_transformSurveys),
      };
    };

    if (url === 'api/getAllNotifications') {
      return {
        amount: response.data.amount,
        list: response.data.list.map(_transformNotification),
      };
    };

    return response.data;
  };
};

export default fetchList;