import React from 'react';
import * as T from "../../components/Tables/TablesStyle";
import TableCell from "@mui/material/TableCell";
import { IEmptyRow } from '../../types/type';

const EmptyRow = ({ columns }: IEmptyRow): JSX.Element => {
  return (
    <T.TRow>
      <TableCell align="center" colSpan={columns}>
        Nothing to show, current list is empty
      </TableCell>
    </T.TRow>
  );
};

export default EmptyRow;