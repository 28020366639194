import React from "react";
import ComplaintForm from "../components/Form/ComplaintForm/ComplaintForm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useFetchComplaints from "../api/useFetch/useFetchComplaints";
import Stack from "@mui/material/Stack";

const ComplaintPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    loading,
    getComplaint,
    approveComplaints,
    rejectComplaints,
    onSaveEdits
  } = useFetchComplaints();

  const onCancelClick = () => {
    navigate(-1);
  };

  const onSave = (dataForm: any) => {
    return onSaveEdits(id, dataForm);
  };

  const navigateToComplaint = () => {
    navigate("/complaints-list");
  };

  const redirectToMap = () => {
    navigate("/map");
  };

  const onApprove = async () => {
    return await approveComplaints(id);
  };

  const onReject = async (comment?: string) => {
    return await rejectComplaints(id, comment);
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      <h1>Approving complaint</h1>
      {id ? (
        <ComplaintForm
          id={id}
          onCancel={onCancelClick}
          getComplaint={getComplaint}
          onSaveEdits={onSave}
          approveComplaints={onApprove}
          onReject={onReject}
          isLoading={loading}
          onAction={navigateToComplaint}
          onMapClick={redirectToMap}
        />
      ) : (
        <p>Some error occurred, please try again later.</p>
      )}
    </Stack>
  );
};

export default ComplaintPage;
