import { makeAutoObservable } from "mobx";
import { PartyType, ICandidate } from "../types/type";
import { initialSearch } from "../utils/constants";
import { CandidateInfoError, AvatarInfo } from "../utils/candidateInfo";

class CandidateStore {
  selects: { [key: string]: { [key: string]: any } } = {
    district: { active: false, list: [] },
    party: { active: false, list: [] },
    education: { active: false, list: [] },
    gender: { active: false, list: ["Female", "Male"] },
    seatType: {
      active: false,
      list: ["Public", "Christian", "Subba", "Fili", "Shabak", "Azidi"],
    },
    place: { active: false, list: [] },
  };
  state: any = {
    partiesFilled: false,
    educationFilled: false,
    candidatesFilled: false,
    _currentMode: "create",
    _currentAllias: "Candidate",
    _currentPCMAllias: "Candidate",
  };
  candidatesAmount: number = 0;
  fetchedCandidatesList: ICandidate[] = [];
  candidatesList: ICandidate[] = [];
  avatarData: any = { ...AvatarInfo };
  partiesList: any = [];
  seatType: any = [];
  candidateType: string = "";
  gender: string | number = "";
  party: number = 0;
  partyName: string = "";
  firstName: string = "";
  lastName: string = "";
  electionCode: string = "";
  bornPlace: string = "";
  district: string = "";
  email: string = "";
  education: string = "";
  occupation: string = "";
  phoneNumber: string = "";
  birthDate: string = "";
  mfa: string = "";
  avatar: string = "";
  statement: string = "";
  awards: string = "";
  achievements: string = "";
  position: string = "";
  goals: string = "";
  budget: string = "";
  uid: string = "";
  deviceId: string = "";
  mp: boolean = false;
  electionVotes: string = "";
  error: any = { ...CandidateInfoError };
  mediaData: any = {
    media: {
      file: "",
      url: null,
      name: null,
      size: null,
    },
    mediaSecond: {
      file: "",
      url: null,
      name: null,
      size: null,
    },
  };
  bulk: any = {
    searchValue: initialSearch,
    uploadedFile: {},
    upFileSCV: [],
    isFileSelected: false,
    uploadedFileName: {},
    errorValidationFile: null,
    resultXLS: null,
    isProgressBar: null,
    upFileCSV: null,
    isBulkButtonActive: false,
    autoInvite: false,
    importResult: [],
  };
  newInfo: any = "";
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  setCandidateType(candidateType: string) {
    this.candidateType = candidateType;
  }

  setBulkData(key: string, data: any) {
    this.bulk[key] = data;
  }

  setImportResult(data: any) {
    this.bulk.importResult = data;
  }

  setCurrentAllias(allias: string) {
    this.state._currentAllias = allias;
  }

  setCurrentPCMAllias(allias: string) {
    this.state._currentPCMAllias = allias;
  }

  setCurrentMode(mode: string) {
    this.state._currentMode = mode;
  }

  get isCurrentModeCreate() {
    return this.state._currentMode === "create";
  }

  get currentHeader() {
    return this.state._currentMode === "create"
      ? `Add ${this.state._currentAllias}`
      : `${this.state._currentAllias} information`;
  }

  get currentPCMHeader() {
    return this.state._currentMode === "create"
        ? `Add ${this.state._currentPCMAllias}`
        : `${this.state._currentPCMAllias} information`;
  }

  setCSVFile(data: any) {
    this.bulk.upFileSCV = data;
  }

  setSearchValue(data: any) {
    this.bulk.searchValue = {
      ...this.bulk.searchValue,
      ...data,
    };
  }

  setFetchedCandidateData(data: any) {
    const dataEntries = Object.entries(data);

    dataEntries.forEach((data) => {
      const key = data[0];
      const value = data[1];
      switch (key) {
        case "media":
          this.mediaData.media.url = value;
          return;
        case "mediaSecond":
          this.mediaData.mediaSecond.url = value;
          return;
        case "error":
          this.candidateError = value;
          return;
        case "mediaData":
          return;
      }
      this[key] = value;
    });
  }

  setState(data: any) {
    this.state = {
      ...this.state,
      ...data,
    };
  }

  resetFetchedCandidateData() {
    const exceptionList = [
      "selects",
      "state",
      "candidatesAmount",
      "fetchedCandidatesList",
      "candidatesList",
      "avatarData",
      "partiesList",
      "seatType",
      "gender",
      "party",
      "partyName",
      "firstName",
      "lastName",
      "electionCode",
      "bornPlace",
      "district",
      "email",
      "education",
      "occupation",
      "phoneNumber",
      "birthDate",
      "mfa",
      "avatar",
      "statement",
      "awards",
      "achievements",
      "position",
      "goals",
      "budget",
      "uid",
      "deviceId",
      "error",
      "mediaData",
      "candidatesAmount",
      "fetchedCandidatesList",
      "candidatesList",
      "bulk",
    ];
    const data = Object.entries(this).filter(
      (item) => !exceptionList.includes(item[0])
    );
    data.forEach((item) => (this[item[0]] = null));
  }

  getCandidate() {
    const excludeList = [
      "error",
      "mediaData",
      "bulk",
      "selects",
      "state",
      "candidatesAmount",
      "fetchedCandidatesList",
      "avatarData",
      "candidatesList",
      "partiesList",
      "newInfo",
    ];
    return {
      ...Object.fromEntries(
        Object.entries(this).filter(
          (item) => item[1] !== null && !excludeList.includes(item[0])
        )
      ),
      media: this.mediaData.media.file
        ? this.mediaData.media.file
        : this.mediaData.media.url,
      mediaName: this.mediaData.media.name,
      mediaSecond: this.mediaData.mediaSecond.file
        ? this.mediaData.mediaSecond.file
        : this.mediaData.mediaSecond.url,
      mediaSecondName: this.mediaData.mediaSecond.name,
    };
  }

  setPartyName() {
    this.partyName = this.selects.party.list.filter(
      (item: any) => item.code === this.party
    )[0]?.name;
  }

  setBulkFile(data: any) {
    this.bulk.uploadedFile = data;
  }

  setSelectionList(list: string[], field: any) {
    this.selects = {
      ...this.selects,
      [field]: {
        ...this.selects[field],
        list,
      },
    };
  }

  setPartiesList(list: PartyType[]) {
    this.partiesList = list;
  }

  resetData() {
    const stringKeys = [
      "gender",
      "partyName",
      "firstName",
      "lastName",
      "electionCode",
      "bornPlace",
      "district",
      "email",
      "education",
      "occupation",
      "phoneNumber",
      "birthDate",
      "mp",
      "electionVotes",
      "mfa",
      "avatar",
      "statement",
      "awards",
      "achievements",
      "position",
      "goals",
      "budget",
      "uid",
    ];
    stringKeys.forEach((key) => {
      this[key] = "";
    });
    this.seatType = [];
    this.party = 0;
    this.deviceId = "";
    this.mediaData = {
      media: {
        file: "",
        url: null,
        name: null,
        size: null,
      },
      mediaSecond: {
        file: "",
        url: null,
        name: null,
        size: null,
      },
    };
    this.bulk = {
      searchValue: initialSearch,
      uploadedFile: {},
      upFileSCV: [],
      isFileSelected: false,
      uploadedFileName: {},
      errorValidationFile: null,
      resultXLS: null,
      isProgressBar: false,
      upFileCSV: null,
      isBulkButtonActive: false,
      autoInvite: false,
      importResult: [],
    };
  }

  getData() {
    return {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      electionCode: this.electionCode,
      bornPlace: this.bornPlace,
      district: this.district,
      education: this.education,
      seatType: this.seatType,
      gender: this.gender,
      email: this.email,
      occupation: this.occupation,
      phoneNumber: this.phoneNumber,
      birthDate: this.birthDate,
      mfa: this.mfa,
      party: this.party,
      avatar: this.avatar,
      statement: this.statement,
      awards: this.awards,
      achievements: this.achievements,
      position: this.position,
      goals: this.goals,
      budget: this.budget,
      mp: this.mp,
      electionVotes: this.electionVotes,
      media: this.mediaData.media.file || this.mediaData.media.url || "",
      mediaName: this.mediaData.media.name,
      mediaSecond:
        this.mediaData.mediaSecond.file || this.mediaData.mediaSecond.url || "",
      mediaSecondName: this.mediaData.mediaSecond.name,
    };
  }

  updateErrorData(name: string, data: any) {
    this.error = {
      ...this.error,
      [name]: {
        ...this.error[name],
        ...data,
      },
    };
  }

  updateMedia(media: any) {
    this.mediaData = {
      ...this.mediaData,
      ...media,
    };
  }

  setCandidatesList(list: ICandidate[]) {
    this.candidatesList = list;
  }

  setFetchedList(list: ICandidate[]) {
    this.fetchedCandidatesList = list;
  }

  getMFAData() {
    return {
      sendAnEmail: true,
      email: this.email,
      uid: this.uid,
    };
  }

  setCandidatesAmount(amount: number) {
    this.candidatesAmount = amount;
  }

  setData(key: any, value: any) {
    this[key] = value;
  }

  updateSelects(newData: any) {
    this.selects = {
      ...this.selects,
      ...newData,
    };
  }
}

export default new CandidateStore();
