import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";
import { removeEmpty } from "../../utils/removeEmpty";

const useFetchComplaints = () => {
  const { loading, request, error, clearError } = useHttp();

  const getComplaints = async ({
    page,
    rowsPerPage,
    type,
    searchValue,
  }: IGetList) => {
    const res = await request("get", "api/check/complaints", {
      params: removeEmpty({
        page,
        rowsPerPage,
        type,
        searchValue: removeEmpty(searchValue),
      }),
    });

    return {
      amount: res.amount,
      list: res.list,
    };
  };

  const getComplaintsAll = async ({
    page,
    rowsPerPage,

    searchValue,
  }: IGetList) => {
    const res = await request("get", "api/check/complaintsAll", {
      params: removeEmpty({
        page,
        rowsPerPage,
        searchValue: removeEmpty(searchValue),
      }),
    });

    return {
      amount: res.amount,
      amountFiltered: res.amountFiltered,
      stats: res.stats,
      list: res.list,
    };
  };

  const downloadComplaintsCSV = async ({ searchValue }) => {
    const res = await request("get", "api/check/complaintsAll", {
      params: removeEmpty({
        type: "download",
        searchValue: removeEmpty(searchValue),
      }),
    });

    return {
      amount: res.amount,
      amountFiltered: res.amountFiltered,
      stats: res.stats,
      list: res.list,
    };
  };

  const getComplaint = async (id: string) => {
    return await request("get", `api/check/complaints/${id}`);
  };

  const approveComplaints = async (id: string | undefined) => {
    return await request("post", `api/approve/complaint/${id}`);
  };

  const rejectComplaints = async (id: string | undefined, comment?: string) => {
    const path = comment
      ? `api/decline/complaint/${id}/?comment=${comment}`
      : `api/decline/complaint/${id}`;

    return await request("post", path);
  };

  const deleteComplaints = async (id: string) => {
    return await request("delete", `api/create/complaints/${id}`);
  };

  const onSaveEdits = async (id: string | undefined, data: object) => {
    return await request("post", `api/create/complaints/${id}`, data);
  };

  const getComplaintGategories = async () => {
    return await request("get", "api/getComplaintCategories");
  };

  const getNewComplaintsAmount = async () => {
    return await request("get", "api/getNewComplaintsAmount");
  };

  return {
    loading,
    error,
    clearError,
    getComplaints,
    getComplaintsAll,
    approveComplaints,
    deleteComplaints,
    rejectComplaints,
    getComplaint,
    onSaveEdits,
    downloadComplaintsCSV,
    getComplaintGategories,
    getNewComplaintsAmount,
  };
};

export default useFetchComplaints;
