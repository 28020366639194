import React from 'react';
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import PodcastsStore from '../store/PodcastsStore';
import PodcastsTable from '../components/Tables/PodcastsTable/PodcastsTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from "@mui/material";
import useFetchPodcasts from '../api/useFetch/useFetchPodcasts';
import { useNavigate } from "react-router-dom";
import getPodcastsList from '../api/actions/getPodcastsList';
import ShowSubLoading from '../utils/ShowSubLoading';
import { RouteList } from '../utils/adminType';

import useAdjustPage from '../hooks/useAdjustPage';
import useCompleteTableData from '../hooks/useCompleteTableData';
import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const PodcastsPage = observer(() => {
  const navigate = useNavigate();
  const { getPocasts, deletePodcast } = useFetchPodcasts();

  useClearStorage(PodcastsStore);
  useGetTableData(PodcastsStore, () => getPodcastsList(getPocasts));
  useCompleteTableData(PodcastsStore, getPocasts);
  useAdjustPage(PodcastsStore);

  const onOpen = (id: string) => {
    navigate(`${RouteList.podcast}/${id}`);
  };

  const onEdit = (id: string) => {
    navigate(`${RouteList.editPodcast}/${id}`);
  };

  return (
    <div data-testid="podcasts-page">
      <ShowSubLoading storage={PodcastsStore} />

      <h1>Podcasts list</h1>

      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={PodcastsStore.rowsPerPage}
            amount={PodcastsStore.amount}
            setRowsPerPage={PodcastsStore.setRowsPerPage}
            disabled={PodcastsStore.disabled}
          />

          <Button
            variant={"contained"}
            startIcon={<AddCircleIcon />}
            data-testid="add-podcast-button"
            onClick={() => navigate(RouteList.addPodcast)}
          >
            <span>Add podcast</span>
          </Button>
        </ToolbarTable>

        <PodcastsTable
          onDelete={deletePodcast}
          onEdit={onEdit}
          onOpen={onOpen}
        />

        <Paginator
          amount={PodcastsStore.amount}
          rowsPerPage={PodcastsStore.rowsPerPage}
          page={PodcastsStore.page}
          setPage={PodcastsStore.setPage}
          disabled={PodcastsStore.disabled}
        />
      </CardBox>
    </div>
  );
});

export default PodcastsPage;