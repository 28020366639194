import React, { useEffect, useState } from "react";
import { DropDownMenuProps } from "../../../types/type";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

const DropDownMenuButton = ({
  title,
  list,
  value,
  onChange,
}: DropDownMenuProps): JSX.Element => {
  const [initial, setinitial] = useState(list[0]);
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>{title}</InputLabel>
      <Select
        value={value || initial}
        onChange={(e) => {
          setinitial(e);
          onChange(e.target.value);
        }}
        MenuProps={{ style: { maxHeight: 350 } }}
        label={title}
      >
        {list.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownMenuButton;
