import styled from "styled-components";
import { Skeleton } from "@mui/material";

export const Wrap = styled.div`
  width: 100%;
  display: block;
`;

export const SkeletonParty = styled(Skeleton)`
  border-radius: 5px;
  width: 100%;
  height: 601px;
`;

export const TextEditorContainer = styled.div`
  position: relative;
  border: 1px solid black; 
  padding: 2px;
  min-height: 400px;
  margin-bottom: 10px;

  & .rdw-link-modal {
    height: 230px;
  }

`;