import React, {useEffect} from "react";
import DashboardTable from "../components/Tables/DashboardTable/DashboardTable";
import {CardBox, ToolbarTable} from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import {observer} from "mobx-react-lite";
import DashboardStore from "../store/DashboardStore";
import DashboardTabs from "../components/Tables/DashboardTable/DashboardTabs";
import Paginator from "../components/Paginator/Paginator";
import FilterTable from "../components/Tables/FilterTable/FilterTable";
import useFetchCandidate from "../api/useFetch/useFetchCandidate";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {adminType} from "../api/network";
import {AdminTypes, RouteList} from "../utils/adminType";
import ElectionModeStore from "../store/ElectionModeStore";
import getCandidatesList from "../api/actions/getCandidatesList";
import ShowSubLoading from "../utils/ShowSubLoading";
import PartiesStore from "../store/PartiesStore";
import CandidateStore from "../store/CandidateStore";
import AlignedButtonText from "../common/Text/AlignedButtonText";

import useAdjustPage from '../hooks/useAdjustPage';
import useCompleteTableData from '../hooks/useCompleteTableData';
import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';
import PCMElectionModeStore from "../store/PCMElectionModeStore";

const DashboardPage = observer(({candidateType}: { candidateType: string }): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentRoles = adminType();
    const {
        getCandidates,
        deleteCandidate,
        suspendCandidate,
        activateCandidate,
    } = useFetchCandidate();

    useEffect(() => {
        DashboardStore.setCandidateType(candidateType)
        CandidateStore.setCandidateType(candidateType)
    }, [])

    useClearStorage(DashboardStore);

    useGetTableData(DashboardStore, () => getCandidatesList(getCandidates));
    useCompleteTableData(DashboardStore, getCandidates);
    useAdjustPage(DashboardStore);

    useEffect(() => {
        if (location?.state) {
            const state: any = location.state;
            DashboardStore.setType("all");
            DashboardStore.setSearchValue({
                ...DashboardStore.searchValue,
                [state.searchType]: state.searchRequest,
            });
        }
        ;

        return () => {
            location.state = {};

            DashboardStore.setType("active");
        };
    }, []);

    const shouldRenderActions = () => {
        return currentRoles.includes(AdminTypes.superAdmin) ||
            (currentRoles.includes(AdminTypes.admin) && currentRoles.includes(AdminTypes.canEditOtherCandidates));
    };

    const onOpen = (id: string) => {
        navigate(`${RouteList.editCandidate}/${id}`);
    };

    return (
        <div data-testid="dashboard-page">
            <ShowSubLoading storage={DashboardStore}/>

            <h1>{candidateType == "mp-candidate" ? ElectionModeStore.dashboardTitle : PCMElectionModeStore.dashboardTitle}</h1>

            <FilterTable
                searchValue={DashboardStore.searchValue}
                setSearchValue={DashboardStore.setSearchValue}
                setPage={DashboardStore.setPage}
                disabled={DashboardStore.disabled}
            />

            <DashboardTabs/>

            <CardBox>
                <ToolbarTable>
                    <ShowEntries
                        rowsPerPage={DashboardStore.rowsPerPage}
                        amount={DashboardStore.amount}
                        setRowsPerPage={DashboardStore.setRowsPerPage}
                        disabled={DashboardStore.disabled}
                    />
                    {shouldRenderActions() && (
                        <Button
                            variant="contained"
                            startIcon={<AddCircleIcon/>}
                            onClick={() => navigate(candidateType === 'mp-candidate' ? RouteList.addCandidate : RouteList.addPCMCandidate)}
                            data-testid="add-candidate-button"
                        >
                            <AlignedButtonText>{candidateType === 'mp-candidate' ? ElectionModeStore.dashboardButtonTitle : PCMElectionModeStore.dashboardButtonTitle}</AlignedButtonText>
                        </Button>
                    )}
                </ToolbarTable>

                <DashboardTable
                    partyList={PartiesStore.allParties}
                    onDelete={deleteCandidate}
                    onSuspend={suspendCandidate}
                    onActivate={activateCandidate}
                    onOpen={onOpen}
                />

                <Paginator
                    amount={DashboardStore.amount}
                    rowsPerPage={DashboardStore.rowsPerPage}
                    page={DashboardStore.page}
                    setPage={DashboardStore.setPage}
                    disabled={DashboardStore.disabled}
                />
            </CardBox>
        </div>
    );
});

export default DashboardPage;
