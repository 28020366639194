import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";
import {
  Typography,
  TextField,
  Button,
  Paper
} from "@mui/material";

export const Amount = styled(Typography)`
  font-size: 18px;

  @media (max-width:800px): {
    width: 100%;
  }

  @media (max-width:700px): {
    font-size: 16px;
  }
`;

export const CSVButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-right: 18px;
`;

export const AnnotationItems = styled(Typography)`
  font-size: 12px;
  color: #818181;

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const ButtonsWrapperBulk = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  width: 100%;
  padding-top: 17px;
  margin: ${(props: ThemeProps<Theme>) => props.theme.spacing(1)};
  ${(props: ThemeProps<Theme>) => props.theme.breakpoints.down("xl")} {
    width: 100%;
  }
`;

export const UploadWrapperBulk = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;

  ${(props: ThemeProps<Theme>) => props.theme.breakpoints.down("xl")} {
    width: 100%;
  }

  & .checkBox {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
    font-weight: 400;

    & .MuiFormControlLabel-label {
      font-size: 16px;
  
      @media (max-width:700px) {
        font-size: 14px;
      }
    }
  
    & .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
    }
  }

  & .checkBoxDisabled {
    color: #E9E9E9;
    font-weight: 400;

    & .MuiFormControlLabel-label {
      font-size: 16px;

      @media (max-width:700px) {
        font-size: 14px;
      }
    }
    & .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      fill: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? 'rgba(0,0,0,0.38)'
    : '#E9E9E9'};
    }
  }
`;

export const InputLabelBulk = styled.label`
  border: 1px solid #F8F8F8;
  color: #AFAFAF;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
  background-color: #F8F8F8;
  height: 388px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @media (max-width:700px): {
    font-size: 14px;
  }
`;

export const SearchFieldsWrapper = styled.div`
  width: 300px;

  @media (max-width:800px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @media (min-width:701px) and (max-width:732px) {
    width: 97%;
  }

  @media (max-width:663px) {
    width: 97%;
  }

  @media (max-width:457px): {
    width: 100%;
  }

  & legend span {
    @media (max-width:700px) {
      font-size: 14px;
    }
  }
`;

export const BulkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  @media (max-width:800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin-top: 25px;

  & .MuiSvgIcon-root {
    fill: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? '#000'
    : '#fff'};
  }

  & .download-icon {
    fill: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? '#000'
    : '#000'};
  }

  @media (max-width:850px) {
    width: 100%;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 17%;

  @media (max-width:800px) {
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
  }
`;

export const InputWrapper = styled(TextField)`
  margin-bottom: 10px;

  @media (max-width:800px) {
    margin-right: 10px;

    &:nth-of-type(3n) {
      margin-right: 0px;
    }
  }

  @media (min-width:701px) and (max-width:732px) {
    &:nth-of-type(2n) {
      margin-right: 0px;
    }

    &:nth-of-type(3n) {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  @media (max-width:663px) {
    &:nth-of-type(2n) {
      margin-right: 0;
    }

    &:nth-of-type(3n) {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width:444px) {
    width: 100%;
    margin-right: 0;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 10px;
    }
  }
`;

export const ButtonBulk = styled(Button)`
  min-width: 60% !important;
  width: 100%;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
  text-transform: capitalize;
  border-radius: 60px;
  height: 65px;
  color: white;
  font-size: 18px;
  font-weight: 400;

  @media (max-width:700px) {
    font-size: 16px;
  }

  &:hover {
    background: #3a9161;
  }
`;

export const ButtonUploadBulk = styled(Button)`
  min-width: 60% !important;
  width: 60% !important;
  align-self: center;
  background-color:  ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
  text-transform: capitalize;
  border-radius: 60px;
  height: 65px;
  color:  #fff;
  margin:  ${(props: ThemeProps<Theme>) => props.theme.spacing(1)};
  font-size: 18px;
  font-weight: 400;

  @media (max-width:700px) {
    font-size: 16px;
  }

  &:hover {
    background: #3a9161;
  }
`;

export const ButtonUploadBulkDisabled = styled(Button)`
  min-width: 60% !important;
  width: 60% !important;
  align-self: center;
  background-color:  #E9E9E9;
  text-transform: capitalize;
  border-radius: 60px;
  height: 65px;
  &.Mui-disabled {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? 'rgba(0,0,0,0.26)'
    : '#000'};
  }
  margin:  ${(props: ThemeProps<Theme>) => props.theme.spacing(1)};
  font-size: 18px;
  font-weight: 400;

  @media (max-width:700px) {
    font-size: 16px;
  }

  &:hover {
    background-color: #E9E9E9;
    color: #FFF;
  }
`;

export const ButtonBulkDisabled = styled(Button)`
  min-width: 60% !important;
  width: 100%;
  background-color: #E9E9E9;
  text-transform: capitalize;
  border-radius: 60px;
  height: 65px;
  font-size: 18px;
  font-weight: 400;

  &.Mui-disabled {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? 'rgba(0,0,0,0.26)'
    : '#000'};
  }

  @media (max-width:700px) {
    font-size: 16px;
  }

  &:hover {
    background-сolor: #E9E9E9;
    color: #FFFFFF;
  }
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 40%;
  border-top: 2px solid #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const LabelBulkContent = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

export const TemplateLabel = styled.span`
  color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? '#000'
    : '#000'};
`;

export const RootProgress = styled.div`
  margin-bottom: 10px;
  width: 100%;
  
  & > * + * {
    margin-top: ${(props: ThemeProps<Theme>) => props.theme.spacing(2)};
    height: 3px;
  }
`;

export const ProgressTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const TemplateAnnotation = styled.span`
  font-size: 12px;
  margin-top: 20px;
  align-text: justify;
  color: #A3A3A3;

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const UploadSuccess = styled.span`
  font-size: 12px;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
`;

export const UploadFailed = styled.span`
  font-size: 12px;
  color: #FB3640;
`;

export const AnnotationHeader = styled.h2`
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 0px;

  &:nth-of-type(2) {
    margin-top: 30px;
  }

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const AnnotationHeaderUpload = styled.h2`
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 0px;

  @media (max-width:700px) {
    font-size: 10px;
  }
`;

export const DeleteFile = styled.span`
  color: #55AE7E;
  font-weight: 12px;
  text-transform: capitalize;
  text-decoration: underline;
`;

export const CSVContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-right: 30px;
`;

export const FileContainer = styled(Paper)`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

export const ErrorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const DownloadErrors = styled(Button)`
  font-size: 12px;
  text-transform: capitalize;
  background-color: #FFF;
  border-radius: 24px;
  padding-left: 16px;
  padding-right: 20px;
`;

export const AddCSVButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-right: 18px;
`;

export const FileInfoContainer = styled(Paper)`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-arround;
  flex-direction: column;
`;

export const DeleteFileButton = styled.span`
  color: #55AE7E;
  font-weight: 12px;
  text-transform: capitalize;
  text-decoration: underline;
`;

export const ResultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  margin-bottom: 10px;

  & span {
    min-width: fit-content;
    margin-right: 5px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const UploadResultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const DownloadButton = styled(Button)`
  font-size: 12px;
  text-transform: capitalize;
  background-color: #FFF;
  border-radius: 24px;
  padding-left: 16px;
  padding-right: 20px;
`;

export const DeleteUploadedFileButton = styled.span`
  color: #55AE7E;
  font-weight: 12px;
  text-transform: capitalize;
  text-decoration: underline;
`;