import React from "react";

const AlignedButtonText = ({ children }: any): JSX.Element => {
  return (
    <p style={{ margin: 0, padding: 0, marginTop: 3 }}>
      {children}
    </p>
  );
};

export default AlignedButtonText