import React, { useEffect } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import { Button, CircularProgress, TextField, Stack } from "@mui/material";
import ComplaintsStore from "../../../store/ComplaintsStore";
import MapStore from "../../../store/MapStore";
import { SubmitHandler, useForm } from "react-hook-form";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import * as S from "./ComplaintFormStyles";
import ReactPlayer from "react-player";
import AttachmentsPaginate from "./AttachmentsPaginate";
import { allowedAttachmentsTypes } from "../../../utils/constants";
import { observer } from "mobx-react-lite";
import { IComplaintForm, IDataComplaintForm } from "../../../types/type";
import WorldIcon from "../../../assets/images/WorldIcon";
import formatName from "../../../utils/formatName";

const ComplaintForm = observer(({
  id,
  onCancel,
  getComplaint,
  onSaveEdits,
  approveComplaints,
  onReject,
  isLoading,
  onAction,
  onMapClick
}: IComplaintForm): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<IDataComplaintForm>({
    mode: "onChange",
  });

  useEffect(() => {
    getData();
    return () => ComplaintsStore.resetComplaintData();
  }, []);

  const getData = () => {
    ComplaintsStore.resetComplaintData();
    getComplaint(id)
      .then((res: any) => {
        if (res.attachmentsList) {
          const [filteredByDocumentType] = res
            .attachmentsList
            .filter((attachment: any) => attachment?.type === "document");
          const attachments = res.attachmentsList.filter((complaint: any) => {
            return allowedAttachmentsTypes.includes(complaint?.type);
          });

          if (filteredByDocumentType) {
            ComplaintsStore.setData('document', filteredByDocumentType);
          };

          ComplaintsStore.setData('attachmentsList', attachments);
        };

        if (res?.location) {
          MapStore.setData('coords', res.location);
          MapStore.setData('id', res?.uid);
          MapStore.setData('title', res?.category);
          MapStore.setData('text', res?.initialMessage);
          MapStore.setData('district', res?.district);
        };

        const title = res.category || res.newCategory;
        const body = res.initialMessage;

        reset({
          category: title,
          initialMessage: body,
        });

        ComplaintsStore.setTextData({ title, body });
        ComplaintsStore.setData('date', res.createdAt);
        ComplaintsStore.setData('approve', res.approved);
        ComplaintsStore.setData('newCategory', res.newCategory);
        ComplaintsStore.setData('firstName', res?.firstName);
        ComplaintsStore.setData('lastName', res?.lastName);
      });
  };

  const renderAttachment = (attachment: any) => {
    const url = attachment.url;
    if (attachment.type === "image") {
      return (
        <S.ComplaintImage data-testid="complaint-image" src={url} />
      );
    };

    return (
      <S.ComplaintVideo>
        <ReactPlayer
          url={url}
          controls={true}
          width={"100%"}
          height={"100%"}
        />
      </S.ComplaintVideo>
    );
  };

  const onSubmit: SubmitHandler<IDataComplaintForm> = (dataForm) => {
    //old logic, after applying edits on complaint user will stay on the same page
    //onSaveEdits(dataForm).then((res: any) => getData());

    //new logic, user will be redirect into complaints table after applying edits on complaint
    onSaveEdits(dataForm).then((res: any) => onAction());
  };

  const toggleModal = (state: boolean) => ComplaintsStore.setData('isModalOpen', state);

  const approveConfirm = () => {
    ComplaintsStore.setData('modalTitle', "Are you sure to approve this complaint?");
    ComplaintsStore.setData('modalAction', { action: handleApprove });
    ComplaintsStore.setComplaintMode('approve');
    toggleModal(true);
  };

  const handleApprove = async () => {
    approveComplaints().then(() => onAction());
  };

  const rejectConfirm = () => {
    ComplaintsStore.setData('modalTitle', "Are you sure to reject this complaint?");
    ComplaintsStore.setData('modalAction', { action: handleReject });
    ComplaintsStore.setComplaintMode('reject');
    toggleModal(true);
  };

  const handleReject = async (comment: string) => {
    onReject(comment).then(() => onAction());
  };

  const mapClickHandler = () => {
    onMapClick();
  };

  return (
    <CardBox style={{ position: "relative", width: "650px", maxWidth: "100%" }}>
      {isLoading ? (
        <Stack
          style={{ marginBottom: 122, marginTop: 122 }}
          alignItems="center"
        >
          <CircularProgress color="primary" size={100} />
          <p>{"Please wait, loading data..."}</p>
        </Stack>
      ) : (
        <form style={{ position: "relative" }} onSubmit={handleSubmit(onSubmit)}>
          <p style={{ marginTop: 0, marginBottom: 20 }}>
            Date: {new Date(ComplaintsStore.date).toLocaleDateString()}
          </p>
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            {(MapStore?.coords) && (
              <S.MapButton
                data-testid="show-complaint-on-map"
                type="button"
                title="Show this complaint on map"
                onClick={mapClickHandler}
                aria-label="Show this complaint on map">
                <WorldIcon />
              </S.MapButton>
            )}
            {(ComplaintsStore.document?.url) && (
              <S.DocumentLink
                data-testid="download-complaint-docs"
                download
                href={ComplaintsStore.document.url}
                title="Download attached documents"
                aria-label="Download attached documents"
              >
                <FilePresentIcon style={{ height: 22, width: 22 }} />
              </S.DocumentLink>
            )}
          </div>

          <S.AuthorInfo>
            <S.DefaultParagraph>
              MP: {formatName(ComplaintsStore?.firstName)} {formatName(ComplaintsStore?.lastName)}
            </S.DefaultParagraph>
          </S.AuthorInfo>

          <TextField
            fullWidth
            label="Complaint name"
            variant="outlined"
            sx={{ marginBottom: 3, marginTop: 2 }}
            inputProps={{ maxLength: 50 }}
            helperText={`${ComplaintsStore.textData.title?.length} / 50`}
            {...register("category", {
              required: true,
              onChange: (e) => {
                ComplaintsStore.setData('saveButtonDisabled', false);
                ComplaintsStore.textData.title = e.target.value;
              },
            })}
          />

          <TextField
            fullWidth
            label="Complaint"
            variant="outlined"
            sx={{ marginBottom: 3 }}
            inputProps={{ maxLength: 1000 }}
            helperText={`${ComplaintsStore.textData.body.length} / 1000`}
            multiline
            rows={4}
            {...register("initialMessage", {
              required: true,
              onChange: (e) => {
                ComplaintsStore.setData('saveButtonDisabled', false);
                ComplaintsStore.textData.body = e.target.value;
              },
            })}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {(ComplaintsStore.newCategory || !ComplaintsStore.approve) && (
                <Button
                  type="button"
                  onClick={approveConfirm}
                  variant="contained"
                  size="large"
                  sx={{ marginRight: 2 }}
                >
                  Approve
                </Button>
              )}
              {(ComplaintsStore.newCategory || ComplaintsStore.approve || ComplaintsStore.approve === undefined) && (
                <Button
                  type="button"
                  onClick={rejectConfirm}
                  variant="contained"
                  size="large"
                  color="warning"
                  sx={{ marginRight: 2 }}
                >
                  Reject
                </Button>
              )}
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={!isValid || ComplaintsStore.saveButtonDisabled}
              >
                Save edit
              </Button>

              <Button
                type="button"
                onClick={() => onCancel()}
                size="large"
                color="error"
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </div>
          </div>

          {ComplaintsStore.attachmentsList?.length > 1 && (
            <S.PaginateContainer data-testid="complaint-media-paginate">
              <S.Controls>
                <AttachmentsPaginate
                  attachmentsLength={ComplaintsStore.attachmentsList.length}
                  onChange={(index: number) => ComplaintsStore.setIndex(index)}
                  currentAttachment={ComplaintsStore.currentIndex}
                />
              </S.Controls>
            </S.PaginateContainer>
          )}

          {ComplaintsStore.attachmentsList && ComplaintsStore.attachmentsList?.length !== 0 && (
            <S.AttachmentsContainer data-testid="complaint-media-container">
              {renderAttachment(ComplaintsStore.attachmentsList[ComplaintsStore.currentIndex])}
            </S.AttachmentsContainer>
          )}

          {ComplaintsStore.isModalOpen && (
            <ModalConfirm
              setActive={toggleModal}
              additionalTextField={ComplaintsStore.isCurrentModeReject}
              windowTitle={ComplaintsStore.modalTitle}
              disableStates={[toggleModal]}
              callback={ComplaintsStore.getModalAction}
            />
          )}
        </form>
      )}
    </CardBox>
  );
});

export default ComplaintForm;
