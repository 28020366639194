import React, { useEffect, useState } from "react";
import {
  Paper,
  List,
  FormControlLabel,
  Button,
  Stack,
  CircularProgress
} from "@mui/material";
import * as S from "./BulkStyle";
import readXlsxFile from "read-excel-file";
import GetAppIcon from "@mui/icons-material/GetApp";
import CustomCheckbox from "../../common/CustomCheckbox";
import ModalAlert from "../../common/Modal/ModalAlert";
import { mandatoryFields } from "../../utils/constants";
import CandidateStore from "../../store/CandidateStore";
import { BulkProps } from "../../types/type";
import { observer } from "mobx-react-lite";
import WarningStore from "../../store/WarningStore";
import MandatoryFields from "./MandatoryFields";

const BulkUpload = observer(({
  onUpload
}: BulkProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(CandidateStore.bulk.autoInvite);

  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const onDelete = () => {
    CandidateStore.setBulkData("upFileCSV", null);
    CandidateStore.setBulkData("isFileSelected", false);
    CandidateStore.setBulkData("uploadedFileName", {});
    CandidateStore.setBulkData("isBulkButtonActive", false);
    CandidateStore.setBulkData("errorValidationFile", null);
    WarningStore.setIsEdited(false);
  };

  const AddCSVButton = () => (
    <S.CSVContainer>
      <S.InputLabelBulk htmlFor="file-csv-upload">
        <S.LabelBulkContent>
          {!CandidateStore.bulk.isFileSelected ? (
            <span style={{ width: "40%", textAlign: "center" }}>
              Select XLSX (bulk insert) less than 10 MB
            </span>
          ) : (
            <S.FileContainer>
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>
                {CandidateStore.bulk.uploadedFileName.fileName}
              </span>
              <span style={{ fontSize: 12, marginBottom: 10 }}>
                {`${(CandidateStore.bulk.uploadedFileName.fileSize / 1000000).toFixed(3)} Mb`}
              </span>
              <S.DeleteFile onClick={onDelete}>
                Delete
              </S.DeleteFile>
            </S.FileContainer>
          )}
        </S.LabelBulkContent>

        {(CandidateStore.bulk.errorValidationFile) ? (
          <S.ProgressWrapper>
            <S.ProgressContainer>

              {(CandidateStore.bulk.errorValidationFile) && (
                <>
                  <S.ProgressTitle>
                    Uploading status
                  </S.ProgressTitle>
                  <S.UploadSuccess>
                    {CandidateStore.bulk.errorValidationFile.successful} candidates uploaded
                    successfully
                  </S.UploadSuccess>
                  <S.ErrorsContainer>
                    <S.UploadFailed>
                      {CandidateStore.bulk.errorValidationFile.failed} candidates uploaded with
                      errors
                    </S.UploadFailed>
                    <S.DownloadErrors onClick={() => downloadXLSX(CandidateStore.bulk.resultXLS)}>
                      <GetAppIcon
                        style={{ width: 11, height: 11, marginRight: 8 }}
                      />
                      Download error list
                    </S.DownloadErrors>
                  </S.ErrorsContainer>
                </>
              )}
            </S.ProgressContainer>
          </S.ProgressWrapper>
        ) : null}
      </S.InputLabelBulk>

      <input
        style={{ display: "none" }}
        type="file"
        id="file-csv-upload"
        accept=".xlsx"
        onChange={onCSVFileUpload}
      />

      <S.UploadWrapperBulk>
        <FormControlLabel
          className={
            CandidateStore.bulk.isBulkButtonActive ? "checkbox" : "checkBoxDisabled"
          }
          label="Auto invite them"
          control={
            <CustomCheckbox
              disabled={CandidateStore.bulk.isBulkButtonActive ? false : true}
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                CandidateStore.setBulkData("autoInvite", !isChecked);
              }}
              value="remember"
              color="primary"
            />
          }
        />
        <Button
          variant='contained'
          disabled={!CandidateStore.bulk.isBulkButtonActive}
          onClick={onUploadCSVClick}>
          Import
        </Button>
      </S.UploadWrapperBulk>
    </S.CSVContainer>
  );

  const downloadXLSX = (data: any) => {
    const element = document.createElement("a");
    const file = new Blob([new Uint8Array(data.data).buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    element.href = URL.createObjectURL(file);
    element.download = "failed_candidates.xlsx";
    document.body.appendChild(element);
    element.click();
  };

  const csvChecker = async (bulk: any) => {
    try {
      let mandatoryFieldsCheck = true;
      let unique = [...new Set(bulk)];
      let dupFields: any = [];

      if (bulk.length !== unique.length) {
        return false;
      }

      mandatoryFields.forEach((field) => {
        if (bulk[0]?.join().indexOf(field) === -1) {
          mandatoryFieldsCheck = false;
        }
      });

      if (mandatoryFieldsCheck) {
        for (let row = 1; row < bulk.length; row++) {
          const candidate = bulk[row];

          if (candidate.length === bulk[0].length) {
            for (let i = 0; i < bulk[0].length; i++) {
              const fieldName_CSV = bulk[0][i];
              const fieldValue_CSV = candidate[i];

              if (
                fieldName_CSV === "email" ||
                fieldName_CSV === "Email" ||
                fieldName_CSV === "Phone number" ||
                fieldName_CSV === "phoneNumber"
              ) {
                for (let cell = 0; cell < bulk.length; cell++) {
                  const currentCell = bulk[cell][i];

                  if (
                    fieldValue_CSV !== "" &&
                    fieldValue_CSV !== null &&
                    currentCell === fieldValue_CSV &&
                    cell !== row
                  ) {
                    console.log("dupl", fieldName_CSV, fieldValue_CSV);
                    dupFields.push(cell);
                  }
                }
              }
            }
          }
        }

        if (dupFields.length > 0) {
          setModalTitle(`Conflict! Check file for duplicates in rows ${dupFields} !`);
          setIsModalErrorOpen(true);
          return false;
        } else {
          return true;
        }
      } else {
        setModalTitle("Conflict! Check file for missing mandatory fields!");
        setIsModalErrorOpen(true);
        return false;
      }
    } catch (error) {
      console.error(error);
      setModalTitle(`Error! ${error}!`);
      setIsModalErrorOpen(true);
      return false;
    }
  };

  const onCSVFileUpload = async (e: any) => {
    const file = e.target.files[0];

    CandidateStore.setBulkData("uploadedFileName", {
      fileName: e.target.files[0].name,
      fileSize: e.target.files[0].size,
    });

    CandidateStore.setBulkData("resultXLS", null);

    if (file.size > 1524000) {
      setModalTitle("File is too large!");
      setIsModalErrorOpen(true);
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async (e) => {
      try {
        let bulk = await readXlsxFile(file);
        let check = await csvChecker(bulk);
        if (check) {
          CandidateStore.setBulkData("isBulkButtonActive", true);
          CandidateStore.setBulkData("isFileSelected", true);
          CandidateStore.setBulkData("upFileCSV", bulk);
          setModalTitle("File seems to be ok. You can proceed for further import.");
          setIsModalErrorOpen(true);
          WarningStore.setIsEdited(true);
        }
      } catch (error) {
        console.log(error)
        setModalTitle("File is corrupted. Check all the fields.");
        setIsModalErrorOpen(true);
      }
    };
  };

  useEffect(() => {
    if (!CandidateStore.bulk.upFileCSV) {
      CandidateStore.setBulkData("isBulkButtonActive", false);
      CandidateStore.setBulkData("autoInvite", false);
      setIsChecked(false);
    }
  }, [CandidateStore.bulk.upFileCSV]);

  const onUploadCSVClick = async (e: any) => {
    e.preventDefault();

    CandidateStore.setBulkData("isBulkButtonActive", false);
    CandidateStore.setBulkData("errorValidationFile", null);
    CandidateStore.setBulkData("isProgressBar", true);

    let bulk = CandidateStore.bulk.upFileCSV;

    onUpload({
      bulk: bulk,
      candidateType: CandidateStore.candidateType,
      isAuto: isChecked,
    })
      .then((response: any) => {
        if (response === "Bulk import succeeded!") {
          CandidateStore.setBulkData("uploadedFileName", {});
          CandidateStore.setBulkData("errorValidationFile", null);
          setModalTitle(`All good! ${response ?? ""}`);
          setIsModalErrorOpen(true);
          CandidateStore.setBulkData("upFileCSV", null);
          CandidateStore.setBulkData("isFileSelected", false);
          WarningStore.setIsEdited(false);
        } else {
          setModalTitle(`Something wrong! ${response}`);
          setIsModalErrorOpen(true);
        }
      })
      .catch((error: any) => {
        let res = error?.response?.data;

        CandidateStore.setBulkData("isBulkButtonActive", false);
        CandidateStore.setBulkData("autoInvite", false);
        CandidateStore.setBulkData("upFileCSV", null);


        if (error?.response?.status == 400 && res?.data?.failed > 0) {
          CandidateStore.setBulkData("errorValidationFile", res.data);
          CandidateStore.setBulkData("resultXLS", res.xls);
        } else {
          setModalTitle(`Error! ${error}`);
          setIsModalErrorOpen(true);
          CandidateStore.setBulkData("isFileSelected", false);
        }
      })
      .finally(() => {
        CandidateStore.setBulkData("isBulkButtonActive", true);
        CandidateStore.setBulkData("isProgressBar", false);
      });
  };

  return (
    <S.UploadContainer>
      {CandidateStore.bulk.isProgressBar ? (
        <Stack style={{ marginTop: "150px", marginLeft: "auto", marginRight: "auto" }} alignItems="center">
          <CircularProgress color="primary" size={150} />
          <p>
            {"Please wait, it may took some time to import..."}
          </p>
        </Stack>
      ) : (
        <AddCSVButton />
      )}

      <div style={{ display: "flex", flexDirection: "column", minWidth: "41%" }}>
        <a
          style={{ textDecoration: "none" }}
          href="https://firebasestorage.googleapis.com/v0/b/eparliament-staging.appspot.com/o/utils%2Fcandidates.xlsx?alt=media&token=d23e5157-b093-4bf4-94d6-82be11346edc"
          download
        >
          <Paper style={{ display: "flex", alignItems: "center", padding: "30px 20px", backgroundColor: "#fff" }}>
            <GetAppIcon className="download-icon" style={{ width: 26, height: 26 }} />
            <S.TemplateLabel>Download template</S.TemplateLabel>
          </Paper>
        </a>
        <S.TemplateAnnotation>
          This file contains mandatory fields which shouldn’t be skipped
        </S.TemplateAnnotation>
        <S.AnnotationHeaderUpload>Mandatory fields in file: </S.AnnotationHeaderUpload>
        <MandatoryFields fields={mandatoryFields} />
        <S.AnnotationHeaderUpload>Education List </S.AnnotationHeaderUpload>
        <List>
          {CandidateStore.selects.education.list?.map((mf: any, i: any) => (
            <S.AnnotationItems key={mf}>
              {`${i} - `}
              {mf}
            </S.AnnotationItems>
          ))}
        </List>
      </div>

      {isModalErrorOpen && (
        <ModalAlert
          setActive={setIsModalErrorOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalErrorOpen]}
          callback={() => setModalTitle("")}
        />
      )}
    </S.UploadContainer>
  );
});

export default BulkUpload;