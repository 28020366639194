import React, { useState } from "react";
import ComplaintsStore from "../../../store/ComplaintsStore";
import { MenuItem } from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { IComplaintActionProps } from "../../../types/type";
import closeTableWindow from '../../../utils/closeTableWindow';

const ComplaintsAction = ({
  id,
  onOpen,
  onDelete,
  onApprove,
  onReject
}: IComplaintActionProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState<any>({ action: null });
  const [showComment, setShowComment] = useState(true);

  const openModal = (title: string, action: Function) => {
    setIsModalOpen(true);
    setModalTitle(title);
    setModalAction({
      action: async (comment: string) => {
        action(comment);
        setAnchorEl(null);
      },
    });
    setAnchorEl(null);
  };

  const handleOperation = (operation: Function) => {
    return () => {
      setAnchorEl(null);

      ComplaintsStore.removeItemLocally(id);
      ComplaintsStore.setInQueue(id);

      operation(id).finally(() => ComplaintsStore.removeFromQueue(id));
    };
  };

  const rejectConfirm = () => {
    setShowComment(true);
    openModal("Are you sure to reject this complaint?", handleOperation(onReject));
  };

  const approveConfirm = () => {
    setShowComment(false);
    openModal("Are you sure to approve this complaint?", handleOperation(onApprove));
  };

  const deleteConfirm = () => {
    setShowComment(false);
    openModal("Are you sure to delete this complaint?", handleOperation(onDelete));
  };

  const renderMenuItem = () => {
    return (
      <div>
        <MenuItem onClick={closeTableWindow(id, onOpen, setAnchorEl)}>
          <ReadMoreIcon /> More
        </MenuItem>
        {(ComplaintsStore.type === "new" || ComplaintsStore.type === "rejected") && (
          <MenuItem onClick={approveConfirm}>
            <AddTaskIcon color="primary" /> Approve
          </MenuItem>
        )}
        {(ComplaintsStore.type === "new" || ComplaintsStore.type === "approved") && (
          <MenuItem onClick={rejectConfirm}>
            <ThumbDownIcon color="warning" /> Reject
          </MenuItem>
        )}
        {ComplaintsStore.type === "rejected" && (
          <MenuItem onClick={deleteConfirm}>
            <DeleteIcon color="error" /> Delete
          </MenuItem>
        )}
      </div>
    );
  };

  return (
    <>
      <ActionMenuButton anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {renderMenuItem()}
      </ActionMenuButton>

      {isModalOpen && (
        <ModalConfirm
          setActive={setIsModalOpen}
          additionalTextField={showComment}
          windowTitle={modalTitle}
          disableStates={[setIsModalOpen]}
          callback={modalAction.action}
        />
      )}
    </>
  );
};

export default ComplaintsAction;
