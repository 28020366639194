import React, { useEffect, useState } from "react";
import { translateDistrict } from "../../../utils/i18n";
import { SurveyFormProps } from "../../../types/type";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Theme,
  CircularProgress,
  Button,
  Stack,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import VideoCamIcon from "@mui/icons-material/Videocam";
import InputText from "../../../common/Input/InputText";
import Textarea from "../../../common/Input/Textarea";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import { VideoItem } from "../../../common/PublicInformation/";
import { neighbourhoods } from "../../../assets/districts/data";
import { provinces } from "../../../assets/provinces/data";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import ModalAlert from "../../../common/Modal/ModalAlert";
import {
  Form,
  AddFile,
  AddAnswer,
  NewAnswer,
  AnswerItem,
  TextAnswer,
  Img,
  HeaderFile,
  DistrictsSelectorWrapper,
  DistrictsList,
  Province,
  District,
} from "./SurveyFormStyles";
import { ThemeProps } from "styled-components";
import SurveysStore from "../../../store/SurveyFormStore";
import ElectionStore from "../../../store/ElectionModeStore";
import WarningStore from "../../../store/WarningStore";
import toggleScrollbar from "../../../utils/toggleScrollbar";
import InputDate from "../../../common/Input/InputDate";

const SurveyForm = observer(
  ({ onSubmit, sendPushShow, isLoading }: SurveyFormProps): JSX.Element => {
    const [answerText, setAnswerText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState<any>("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
    const [areas, setAreas] = useState<any>([]);
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [modalAction, setModalAction] = useState<any>({ action: null });

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    useEffect(() => {
      if (!SurveysStore.district) {
        SurveysStore.setData("district", "All districts");
        SurveysStore.setData("forCandidateOrVoter", null);
      }
    }, []);

    useEffect(() => {
      toggleScrollbar(anchorEl);
    }, [anchorEl]);

    const handlePopoverClick = (event: any) => {
      setAnchorEl(event.currentTarget.closest("#input-container"));
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const validate = () => {
      let isValid = true;
      const error: string[] = [];
      const errorData: any = {};

      if (SurveysStore.title.length > 100 || SurveysStore.title.length === 0) {
        error.push("Survey title");
        errorData.title = "Title is required";
        isValid = false;
      }
      if (SurveysStore.text.length > 250 || SurveysStore.text.length === 0) {
        error.push("Survey text");
        errorData.text = "Text is required";
        isValid = false;
      }
      if (SurveysStore.variants.length < 2) {
        error.push("Make at least 2 answers for survey");
        errorData.variants = "Make at least 2 answers";
        isValid = false;
      }

      if (!isValid) {
        setIsModalErrorOpen(true);
        SurveysStore.setErrorData(errorData);
        setModalTitle(
          <>
            <p>Following steps are required to create survey:</p>
            <ul>
              {error.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        );
      }

      return isValid;
    };

    const onChangeInput = (e: any) => {
      WarningStore.setIsEdited(true);
      const name = e.target.name ? e.target.name : "";
      const value = e.target.value ? e.target.value : "";
      const formattedValue = name === "answerType" ? Number(value) : value;

      SurveysStore.setData(name, formattedValue);
    };

    const onFileUpload = (e: any) => {
      setIsVideoLoading(true);
      const file = e.target.files[0];
      const isVideo = file.type.includes("video");

      if (
        (file.type.includes("image") && file.size > 1048576) ||
        (isVideo && file.size > 10485760)
      ) {
        setIsModalErrorOpen(true);
        setModalTitle("File too big!");
        e.target.value = null;
        setIsVideoLoading(false);
        return;
      }

      SurveysStore.setData("isVideo", isVideo ? true : false);

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = async (e) => {
        SurveysStore.setData("image", fileReader.result);
        SurveysStore.setData("fileName", file.name);
        setIsVideoLoading(false);
      };

      e.target.value = null;
    };

    const onDeleteFile = async () => {
      SurveysStore.setData("image", null);
      SurveysStore.setData("fileName", null);
      SurveysStore.setData("isVideo", false);
      SurveysStore.setData("thumbnail", null);
    };

    const initiateFileDeletion = () => {
      setIsModalOpen(true);
      setModalTitle("Are you sure to delete this file?");
      setModalAction({ action: async () => onDeleteFile() });
    };

    const renderFile = () => {
      if (!SurveysStore.isVideo && SurveysStore.image) {
        return (
          <>
            <HeaderFile>
              <ImageIcon
                style={{
                  marginRight: 10,
                  fill: `${(props: ThemeProps<Theme>) =>
                    props.theme.palette.primary.main}`,
                }}
              />
              <span>Image file</span>
              <span
                className="delete"
                data-testid="delete-uploaded-image"
                onClick={initiateFileDeletion}
              >
                Delete
              </span>
            </HeaderFile>
            <Img src={SurveysStore.image} data-testid="uploaded-image" alt="" />
          </>
        );
      }

      if (SurveysStore.isVideo && SurveysStore.image) {
        return (
          <>
            <HeaderFile>
              <VideoCamIcon
                style={{
                  marginRight: 10,
                  fill: `${(props: ThemeProps<Theme>) =>
                    props.theme.palette.primary.main}`,
                }}
              />
              <span>Video file</span>
              <span className="delete" onClick={initiateFileDeletion}>
                Delete
              </span>
            </HeaderFile>
            <VideoItem>
              <ReactPlayer
                url={SurveysStore.image}
                controls={true}
                width={"100%"}
                height={"100%"}
              />
            </VideoItem>
          </>
        );
      }
    };

    const addAnswer = () => {
      if (SurveysStore.variants.includes(answerText.trim())) {
        setIsModalErrorOpen(true);
        setModalTitle("Error! Such an answer already exists.");
      } else {
        WarningStore.setIsEdited(true);
        SurveysStore.setData("variants", [
          ...new Set([...SurveysStore.variants, answerText.trim()]),
        ]);
        setAnswerText("");
      }
    };

    const handleSendPush = () => {
      SurveysStore.setData("sendPush", !SurveysStore.sendPush);
    };

    const onDeleteAnswer = (text: string) => {
      WarningStore.setIsEdited(true);
      const newAnswerList = SurveysStore.variants.filter(
        (item: any) => item !== text
      );

      SurveysStore.setData("variants", newAnswerList);
    };

    const renderAnswerList = () => {
      return SurveysStore.variants.map((item: any, index: number) => {
        return (
          <AnswerItem key={index}>
            <TextAnswer>
              {SurveysStore.answerType === 0 ? (
                <Radio data-testid={`answer-radio${index}`} disabled checked />
              ) : (
                <Checkbox
                  data-testid={`answer-checkbox${index}`}
                  disabled
                  checked
                />
              )}
              <Textarea
                maxLength={250}
                name={String(index)}
                value={item}
                data-testid={`answer-item${index}`}
                disabled={true}
                style={{ width: "100%" }}
              />
            </TextAnswer>
            <div style={{ marginLeft: "auto" }}>
              <Button
                onClick={() => onDeleteAnswer(item)}
                variant="contained"
                data-testid={`delete-answer${index}`}
                color="error"
              >
                Delete
              </Button>
            </div>
          </AnswerItem>
        );
      });
    };

    const onSelectReceiver = (district: any) => {
      return () => {
        SurveysStore.setData("district", district);
        WarningStore.setIsEdited(true);
        setAnchorEl(null);
      };
    };

    useEffect(() => {
      const areas: any[] = [];

      provinces.slice().map((province) => {
        const districtsList = neighbourhoods.filter(
          (district) => district.province === province.provinceName
        );
        let districtNames: any[] = [];

        districtsList.map((district) => {
          const districtName = district.neighbourhoodId.split("; #")[0];
          districtNames.push(districtName);
        });

        districtNames = [...new Set(districtNames)];

        areas.push(
          <Province
            onClick={onSelectReceiver(districtNames)}
            data-testid={`survey-${province.provinceName}`}
            key={`i${province.provinceName} + ${Math.random()}`}
          >
            {province.provinceName}
          </Province>
        );

        districtNames.map((district) => {
          areas.push(
            <District
              onClick={onSelectReceiver(district)}
              data-testid={`survey-${district}`}
              key={`i${district}${Math.random()}`}
            >
              {`${translateDistrict(district)} (${district.replace(
                "_N_",
                " "
              )})`}
            </District>
          );
        });
      });

      setAreas(areas);
    }, []);

    const getReceiverName = () => {
      if (Array.isArray(SurveysStore.district)) {
        const areaName = SurveysStore.district[0]?.split("_N_")[0];
        return `all ${areaName[0].toUpperCase()}${areaName.slice(
          1
        )} districts.`;
      } else {
        return SurveysStore.district;
      }
    };

    const submitHandler = (e: any) => {
      e.preventDefault();
      if (validate()) {
        setIsModalOpen(true);
        setModalTitle(SurveysStore.getModalTitle);
        setModalAction({
          action: async () => {
            WarningStore.resetStorage();
            onSubmit(SurveysStore.getData());
          },
        });
      }
    };

    return (
      <Form
        data-testid="surveys-form"
        noValidate
        autoComplete="off"
        onSubmit={submitHandler}
      >
        <DistrictsSelectorWrapper elevation={0}>
          <div>
            <Typography>Send to {getReceiverName()}</Typography>
          </div>

          <div
            id="input-container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              disabled={true}
              id="cand-district"
              variant="outlined"
              name="receiver"
              data-testid="selected-district"
              value={SurveysStore.district}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span
                      aria-describedby={popoverId}
                      style={{ cursor: "pointer" }}
                      onClick={handlePopoverClick}
                      data-testid="select-district"
                    >
                      Select
                    </span>
                    <Popover
                      id={popoverId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      container={document.querySelector("form")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <DistrictsList>{areas}</DistrictsList>
                    </Popover>
                  </InputAdornment>
                ),
              }}
            />

            <IconButton
              color="primary"
              data-testid="reset-selected-district"
              onClick={() => {
                if (SurveysStore.district) {
                  SurveysStore.setData("district", "All districts");
                  WarningStore.setIsEdited(true);
                }
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div>
            <RadioGroup
              sx={{ mt: 3 }}
              row
              aria-label="choice"
              name="answerType"
              value={SurveysStore.forCandidateOrVoter ?? 0}
              style={{ alignItems: "center" }}
              onChange={(e) => {
                if (SurveysStore.district) {
                  WarningStore.setIsEdited(true);
                  SurveysStore.setData(
                    "forCandidateOrVoter",
                    parseInt(e.target.value)
                  );
                }
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                disabled={!SurveysStore.district}
                data-testid="survey-everyone-radio"
                label="For everyone"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                disabled={!SurveysStore.district}
                data-testid="survey-voters-radio"
                label="For voters"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                disabled={!SurveysStore.district}
                label={`For ${
                  ElectionStore.isCurrentModeFinal
                    ? "members of parliament"
                    : "candidates"
                }`}
                data-testid="survey-candidates-radio"
              />
            </RadioGroup>
          </div>
        </DistrictsSelectorWrapper>

        <Grid
          style={{ marginTop: 0 }}
          sx={{ mt: 3, mb: 5 }}
          container
          columnSpacing={8}
          rowSpacing={4}
        >
          <Grid
            style={{ paddingTop: 0 }}
            item
            md={5}
            xs={12}
            container
            columnSpacing={8}
            rowSpacing={4}
          >
            <Grid item xs={12}>
              <InputText
                name="title"
                label="Title*"
                dataTestId="survey-title"
                value={SurveysStore.title}
                helperText={SurveysStore.error.title}
                maxLength={100}
                placeholder="New survey"
                onChange={onChangeInput}
              />
            </Grid>
            <Grid item xs={12}>
              <InputDate
                name="expirationDate"
                label="Expiration date"
                value={SurveysStore.expirationDate || ""}
                dataTestId="survey-expiration-date"
                onChange={(field: string, value: any) => {
                  WarningStore.setIsEdited(true);
                  SurveysStore.setData(field, Date.parse(value));
                }}
                minYears={-9999}
                maxYears={9999}
              />
            </Grid>
            <Grid item xs={12} style={{ position: "relative" }}>
              <Textarea
                name="text"
                label="Text*"
                dataTestId="survey-text"
                value={SurveysStore.text}
                helperText={SurveysStore.error.text}
                helperTextProps={{ left: 65 }}
                maxLength={250}
                placeholder="Text your survey"
                onChange={onChangeInput}
              />
            </Grid>
            <Grid item xs={12}>
              <AddFile>
                <span className="label-name">Add file</span>
                <input
                  type="file"
                  data-testid="survey-image-uploader"
                  onChange={(e) => {
                    onFileUpload(e);
                    WarningStore.setIsEdited(true);
                  }}
                  accept="image/png,image/jpeg,video/mp4"
                  disabled={isVideoLoading}
                />
                <span
                  className="label-choose"
                  style={{ cursor: isVideoLoading ? "auto" : "pointer" }}
                >
                  {isVideoLoading ? "Loading..." : "Choose file or drop here"}
                </span>
              </AddFile>

              {isVideoLoading && (
                <Stack
                  style={{ marginTop: "75px", marginBottom: "40px" }}
                  alignItems="center"
                >
                  <CircularProgress color="primary" size={100} />
                  <p>Please wait, loading video...</p>
                </Stack>
              )}

              {renderFile()}

              <RadioGroup
                sx={{ mt: 3 }}
                row
                aria-label="choice"
                name="answerType"
                value={SurveysStore.answerType}
                onChange={onChangeInput}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  data-testid="single-choice"
                  label="Single choice"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  data-testid="multi-choice"
                  label="Multi choice"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <NewAnswer>
                <Textarea
                  value={answerText}
                  helperText={SurveysStore.error.variants}
                  maxLength={250}
                  dataTestId="survey-answer"
                  onChange={(e: any) => setAnswerText(e.target.value)}
                  name="answer"
                  label="Add text new answer*"
                  //helperText="Require at least two answers"
                />
                <AddAnswer
                  disabled={!answerText.length}
                  data-testid="add-answer"
                  onClick={addAnswer}
                  variant="contained"
                >
                  Add
                </AddAnswer>
              </NewAnswer>

              {renderAnswerList()}
            </Grid>

            {sendPushShow && (
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    value={SurveysStore.sendPush}
                    data-testid="survey-send-notif"
                    onChange={handleSendPush}
                    label="Send push notification"
                  />
                </FormGroup>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                disabled={!WarningStore.isEdited || isLoading || isVideoLoading}
                fullWidth={true}
                variant="contained"
                type="submit"
                style={{ minHeight: 45 }}
                data-testid="create-survey"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {isModalOpen && (
          <ModalConfirm
            setActive={setIsModalOpen}
            windowTitle={modalTitle}
            disabled={isDeleting}
            enableStates={[setIsDeleting]}
            disableStates={[setIsDeleting, setIsModalOpen, setModalTitle]}
            callback={modalAction.action}
          />
        )}
        {isModalErrorOpen && (
          <ModalAlert
            setActive={setIsModalErrorOpen}
            windowTitle={modalTitle}
            disableStates={[setIsModalErrorOpen]}
            callback={() => setModalTitle("")}
          />
        )}
      </Form>
    );
  }
);

export default SurveyForm;
