import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";

const useFetchSupport = () => {
  const { loading, request, error, clearError } = useHttp();

  const getSupportList = async ({ page, rowsPerPage }: IGetList) => {
    return await request('get', 'api/loadSupport', {
      params: {
        page,
        rowsPerPage,
      },
    });
  };

  const getChat = async (id: string | undefined) => {
    return await request('get', `api/loadSupport/${id}`);
  };

  const sendMessage = async (data: any) => {
    return await request('post', 'api/answerSupport', data);
  };

  return {
    loading,
    error,
    clearError,
    getSupportList,
    getChat,
    sendMessage
  };
};

export default useFetchSupport;