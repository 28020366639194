import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as T from '../TablesStyle'
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import { observer } from "mobx-react-lite";
import PodcastsStore from '../../../store/PodcastsStore';
import setBlur from '../../../utils/setBlur';
import PodcastAction from './PodcastsAction';
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import isStorageLoading from '../../../utils/isStorageLoading';
import { IExtendedTableProps } from '../../../types/type';
import getSlicedString from '../../../utils/getSlicedString';

const PodcastsTable = observer(({
  onEdit,
  onOpen,
  onDelete
}: IExtendedTableProps): JSX.Element => {
  const rowsEntries = Array(PodcastsStore.rowsPerPage).fill(0);
  const colSpan = Array(5).fill(0);

  const renderRows = () => {
    if (isStorageLoading(PodcastsStore)) {
      return (<LoadingTable loading={PodcastsStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (PodcastsStore.isStorageEmpty) {
      return (<EmptyRow columns={5} />);
    }

    return (PodcastsStore.list.map((item, index) => {
      if (item === null) {
        return (<LoadingRow key={index} colSpan={colSpan} />);
      }

      return (
        <T.TRow key={item.id}>
          <TableCell align="left">{new Date(item.createdAt).toLocaleDateString()}</TableCell>
          <TableCell align="left">{item.title}</TableCell>
          <TableCell align="left">{item.episodesAmount}</TableCell>
          <TableCell align="left">{getSlicedString(item.description, 100)}</TableCell>
          <TableCell align="center">
            <PodcastAction
              id={item.id}
              onEdit={onEdit}
              onOpen={onOpen}
              onDelete={onDelete}
            />
          </TableCell>
        </T.TRow>
      );
    }));
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">
              <p style={{ margin: 0, padding: 0, width: 102 }}>Episodes amount</p>
            </TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(PodcastsStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
})

export default PodcastsTable;