import { makeAutoObservable } from "mobx";

export const getMode = localStorage.getItem('darkMode');

class ThemeModeStore {
  mode: 'dark' | 'light' = !!getMode ? 'dark' : 'light'

  constructor() {
    makeAutoObservable(this);
  };

  setMode = (value: boolean) => {
    if (value) {
      this.mode = 'dark'
      localStorage.setItem('darkMode', 'true')
    } else {
      this.mode = 'light'
      localStorage.removeItem('darkMode')
    };
  };
};

export default new ThemeModeStore();