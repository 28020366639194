import React, { useEffect, useState } from "react";
import * as L from "./LoginFormStyle";
import logo from "../../../assets/images/logo.svg";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import useFetchLogin from "../../../api/useFetch/useFetchLogin";
import useTFA from "../../../api/useFetch/useEnableTFA";
import { ILoginForm } from "../../../types/type";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalAlert from "../../../common/Modal/ModalAlert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const schema = yup
  .object({
    login: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const LoginForm = () => {
  const [remember, setRemember] = useState<boolean>(
    !!localStorage.getItem("rememberMe")
  );
  const [showPassword, setShowPassword] = useState(false);
  const [is2uthEnabled, setIs2uthEnabled] = useState(false);
  const [firstPhaseData, setFirstPhaseData] = useState<any>({});
  const [codeLength, setCodeLength] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(
    !!localStorage.getItem("showNotification")
  );
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    if (localStorage.getItem("showNotification")) {
      localStorage.removeItem("showNotification");
    }
  }, []);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const authorize = (data: any) => {
    if (remember) {
      localStorage.setItem("token", data.customToken);

      sessionStorage.removeItem("token");
    } else {
      sessionStorage.setItem("token", data.customToken);

      localStorage.removeItem("token");
    }

    if (data.email) {
      localStorage.setItem("email", data.email);
      localStorage.setItem("suggest", data.suggest);
    }

    if (data.partyManagerCode) {
      localStorage.setItem("partyManagerCode", data.partyManagerCode);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ILoginForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { loading, onLogin } = useFetchLogin();
  const { verifyCode } = useTFA();

  const verifyTFACode = async (
    email: string | null,
    code: undefined | string
  ) => {
    verifyCode(email, code)
      .then(() => {
        authorize(firstPhaseData);
        location.reload();
      })
      .catch((err) => {
        setIsModalErrorOpen(true);
        setModalTitle(
          "Two factor authentication code is wrong, please, try again."
        );
      });
  };

  const onSubmit: SubmitHandler<ILoginForm> = async (data) => {
    if (is2uthEnabled) {
      await verifyTFACode(data.login, data.TFACode);
      return;
    }
    onLogin(data.login, data.password)
      .then((res) => {
        const { TFAEnabled } = res;

        if (TFAEnabled) {
          setIs2uthEnabled(true);
          setFirstPhaseData({ ...res });
          return;
          //authorize({ customToken, roles });
        } else {
          authorize({ ...res, email: data.login, suggest: true });
        }

        location.reload();
      })
      .catch((err) => {
        setIsModalErrorOpen(true);
        setModalTitle("Wrong password or login. Please, try again.");
      });
  };

  register("rememberMe", {
    onChange: (e) => {
      const value = e.target.checked;

      if (value) {
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("rememberMe");
      }

      setRemember(value);
    },
  });

  useEffect(() => {
    if (is2uthEnabled) {
      setCodeLength(true);
    }
  }, [is2uthEnabled]);

  const checkCodeLength = () => {
    return is2uthEnabled ? codeLength : false;
  };

  register("TFACode", {
    onChange: (e) => {
      const length = e.target.value.length;
      if (length > 0) {
        setCodeLength(false);
      } else {
        setCodeLength(true);
      }
      if (length > 6) {
        e.target.value = String(e.target.value).slice(0, 6);
      }
    },
  });

  const handleClose = () => {
    setNotificationEnabled(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      data-testid="snackbar-notification"
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <L.Wrap data-testid="login-form">
      <L.Logo>
        <img src={logo} alt="" />
        <p>E-Parliament</p>
      </L.Logo>

      <L.Form onSubmit={handleSubmit(onSubmit)}>
        <L.Title>
          {is2uthEnabled
            ? "Enter your six digits code from authentication app"
            : "Login"}
        </L.Title>
        {is2uthEnabled ? (
          <TextField
            sx={{ marginBottom: "15px" }}
            fullWidth
            label="Two factory authentication code"
            variant="outlined"
            data-testid="TFA-code"
            type="text"
            autoFocus
            {...register("TFACode")}
          />
        ) : (
          <>
            <L.FieldWithoutStyles
              sx={{ marginBottom: "15px" }}
              fullWidth
              label="Email"
              variant="outlined"
              data-testid="login-email"
              type="text"
              {...register("login")}
            />
            <L.FieldWithoutStyles
              sx={{ marginBottom: "15px" }}
              fullWidth
              label="Password"
              variant="outlined"
              data-testid="login-password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ zIndex: 100 }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      size="large"
                    >
                      {showPassword ? (
                        <Visibility style={{ fill: "#AFAFAF" }} />
                      ) : (
                        <VisibilityOff style={{ fill: "#AFAFAF" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("password")}
            />
          </>
        )}

        {!is2uthEnabled && (
          <FormControlLabel
            sx={{ marginBottom: "15px" }}
            checked={remember}
            control={<Checkbox />}
            label="Remember me"
            {...register("rememberMe")}
          />
        )}

        <LoadingButton
          loading={loading}
          disabled={loading || !isValid || checkCodeLength()}
          type="submit"
          size="large"
          variant="contained"
          data-testid="login-button"
          fullWidth
        >
          {is2uthEnabled ? "Enter" : "Log in"}
        </LoadingButton>
      </L.Form>

      {isModalErrorOpen && (
        <ModalAlert
          setActive={setIsModalErrorOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalErrorOpen]}
          callback={() => {}}
        />
      )}

      <Snackbar
        open={notificationEnabled}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Your session is expired. Please, login again"
        action={action}
      />
    </L.Wrap>
  );
};

export default LoginForm;
