import { useHttp } from "../../hooks/useHttp";
import { removeEmpty } from "../../utils/removeEmpty";
import { IGetList } from "../../types/type";
import { _transformPartiesList } from "../tranformResponse";

const useFetchParties = () => {
  const { loading, request, error, clearError } = useHttp();

  const getParties = async ({ page, rowsPerPage, searchValue }: IGetList) => {
    const res = await request("get", "api/getparties", {
      params: removeEmpty({
        page,
        rowsPerPage,
        searchValue: removeEmpty(searchValue),
      }),
    });
    return {
      amount: res.amount,
      list: res.list,
    };
  };

  const getPartiesList = async () => {
    const res = await request("get", "api/getparties");
    return res.list.map(_transformPartiesList);
  };

  const getParty = async (id: string | undefined) => {
    return await request("get", "api/getparty", {
      params: { id },
    });
  };

  const editParty = async (data: object, id: string) => {
    return await request("post", "api/editparty", { ...data, id });
  };

  const addParty = async (data: object) => {
    return await request("post", "api/createparty", { ...data });
  };

  const deleteParty = async (id: string) => {
    return await request("post", "api/deleteparty", { id });
  };

  return {
    loading,
    error,
    clearError,
    getParties,
    getPartiesList,
    getParty,
    addParty,
    editParty,
    deleteParty,
  };
};

export default useFetchParties;
