import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ComplaintsStore from "../../../store/ComplaintsStore";
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import * as T from "../TablesStyle";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { translateDistrict } from "../../../utils/i18n";
import ComplaintsAction from "./ComplaintsAction";
import formatName from "../../../utils/formatName";
import setBlur from "../../../utils/setBlur";
import EmptyRow from "../../../common/EmptyTableRow/EmptyTableRow";
import { TableCellTextContainer } from "../TablesStyle";
import { IDistrict, IComplainstTableProps } from "../../../types/type";
import isStorageLoading from "../../../utils/isStorageLoading";
import DistrictsMenu from "../FilterTable/DistrictsMenu";
import { DistrictsList } from "../../Form/SurveyForm/SurveyFormStyles";
import allDistricts from "../FilterTable/districtsList";
import useFetchComplaints from "../../../api/useFetch/useFetchComplaints";
import FilterTable from "../FilterTable/FilterTable";
import Paginator from "../../Paginator/Paginator";
import ShowEntries from "../../../UI/ShowEntries/ShowEntries";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";

const ComplaintsStats = (): JSX.Element => {
  const { getComplaintsAll, getComplaintGategories, downloadComplaintsCSV } =
    useFetchComplaints();

  const [amount, setamount] = useState(0);
  const [res, setres] = useState(Object);
  const [mps, setmps] = useState(Object);
  const [loading, setloading] = useState(false);

  const [categories, setcategories] = useState(Object);

  const [expandedRows, setexpandedRows] = useState<string[]>([]);

  useEffect(() => {
    // first
    getComplaintGategories().then((r) => {
      setcategories(r);
    });
    return () => {
      // second
    };
  }, []);
  useEffect(() => {
    // first
    getComplaintsAll({
      page: ComplaintsStore.page,
      rowsPerPage: ComplaintsStore.rowsPerPage,
      searchValue: ComplaintsStore.searchValue,
    }).then((r) => {
      setres(r.stats);
      setamount(r.amount);
      let mpsList = {};
      Object.entries(r.stats).map((distobj: any) => {
        let distlist = distobj[1];
        let districtName = distobj[0];

        Object.entries(distlist.mps).map((mpinfo: any) => {
          try {
            mpinfo[1][
              "displayName"
            ] = `${mpinfo[1]["firstName"]} ${mpinfo[1]["lastName"]}: ${mpinfo[1]["electionCode"]}`;
          } catch (error) {
            ///
          }
          try {
            mpinfo[1]["district"] = districtName;
          } catch (error) {
            ///
          }
          try {
            mpinfo[1]["district"] = districtName;
          } catch (error) {
            ///
          }
          mpsList[`${mpinfo[0]}`] = mpinfo[1];
        });
      });

      setmps(mpsList);
    });

    return () => {
      // second
    };
  }, [
    ComplaintsStore.page,

    ComplaintsStore.rowsPerPage,

    ComplaintsStore.searchValue,
  ]);

  const formatRecord = (record: string) => {
    if (!record) {
      return "-";
    }

    return record.length <= 75 ? record : record.slice(0, 75) + "...";
  };

  const isExpanded = (rowid: string) => {
    const idx = expandedRows.find((id) => {
      return id === rowid;
    });

    return idx;
  };

  const handleExpand = (rowid: string) => {
    let newExpandedRows = [...expandedRows];

    let idxFound = newExpandedRows.findIndex((id) => {
      return id === rowid;
    });

    if (idxFound > -1) {
      newExpandedRows.splice(idxFound, 1);
    } else {
      newExpandedRows.push(rowid);
    }
    setexpandedRows([...newExpandedRows]);
  };

  const exportToXLSX = async (data: any, fileName: string) => {
    if (data)
      try {
        const MAX_XL_LENGTH = 32767;
        setloading(true);
        const ws = XLSX.utils.json_to_sheet(data);
        const filteredWS = Object.fromEntries(
          Object.entries(ws).filter((item) => {
            if (item[0] === "!ref") {
              return true;
            }
            return item[1].v?.length < MAX_XL_LENGTH;
          })
        );
        const wb = { Sheets: { data: filteredWS }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const element = document.createElement("a");
        const file = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setloading(false);
      } catch (error) {
        console.error(error);
        setloading(false);
        alert(error);
      }
  };
  const SaveCSV = () => {
    const downloadXLSX = () => {
      downloadComplaintsCSV({ searchValue: ComplaintsStore.searchValue })
        .then((result) => {
          let data: any[] = [];

          result.list.map((row, index) => {
            data.push({
              DATE: `${new Date(row.createdAt).toLocaleDateString()}`,
              Approved: `${!!row.approved}`,
              District: translateDistrict(row.district),
              Category:
                formatRecord(row.newCategory) === "-"
                  ? formatRecord(row.category)
                  : `NEW: ${formatRecord(row.newCategory)}`,
              "Complaint text": JSON.stringify(row.initialMessage),
              // "Attachments":// JSON.stringify(row.attachmentsList),
              Likes: `${row?.likes ? Object.keys(row?.likes)?.length : 0}`,
              MP: `${formatName(row?.firstName)} ${formatName(row?.lastName)}`,
            });
          });
          // console.log(data);
          exportToXLSX(
            data,
            Object.entries(ComplaintsStore.searchValue).flat(3).join("_")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <Button
        disabled={loading}
        variant="contained"
        onClick={() => downloadXLSX()}
      >
        Download XLSX
      </Button>
    );
  };
  const listMPs = (district: Array<object>) => {
    let mpslist = Object.entries(district["mps"]).sort();

    return (
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">MP</TableCell>
            <TableCell align="left">First name</TableCell>
            <TableCell align="left">Last name</TableCell>
            <TableCell align="left">Election code</TableCell>
            <TableCell align="left">Complaint amount</TableCell>
            <TableCell align="left">Likes amount</TableCell>
          </TableRow>
        </T.THead>
        <TableBody>
          <TableCell align="left"></TableCell>
          {mpslist.map((mpinfo: any) => {
            return (
              <T.TRow style={{ backgroundColor: "#bbb" }} key={mpinfo[0]}>
                <TableCell align="left">
                  {isNaN(Number(mpinfo[1]["electionCode"])) ? (
                    <></>
                  ) : (
                    <a
                      style={{ color: "blue" }}
                      href={`/edit-candidate/${mpinfo[0]}`}
                    >
                      Edit MP
                    </a>
                  )}
                </TableCell>
                {Object.entries(mpinfo[1]).map((mp) => {
                  return <TableCell align="left">{mp[1]}</TableCell>;
                })}
              </T.TRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const renderStats = () => {
    if (Object.entries(res).length === 0) {
      return <LoadingRow key={0} colSpan={[0]} />;
      // return <EmptyRow columns={3} />;
    } else {
      const rows = Object.entries(res).map((row: object, index) => {
        if (row === null) {
          return <LoadingRow key={index} colSpan={[0]} />;
        }

        return (
          <>
            <T.TRow key={row[0]}>
              <TableCell align="left" onClick={() => handleExpand(row[0])}>
                {row[0]}
              </TableCell>
              <TableCell align="left">
                <TableCellTextContainer>
                  {row[1].complaintsAmount}
                </TableCellTextContainer>
              </TableCell>
              <TableCell align="left">
                <TableCellTextContainer>
                  {row[1].likesAmount}
                </TableCellTextContainer>
              </TableCell>
            </T.TRow>
            {isExpanded(row[0]) ? listMPs(row[1]) : <></>}
          </>
        );
      });

      return rows;
    }
  };

  const rowsEntries = Array(5).fill(0);
  const colSpan = Array(6).fill(0);

  const renderRows = () => {
    if (isStorageLoading(ComplaintsStore)) {
      return (
        <LoadingTable
          loading={ComplaintsStore.disabled}
          rowsEntries={rowsEntries}
          colSpan={colSpan}
        />
      );
    }

    if (ComplaintsStore.isStorageEmpty) {
      return <EmptyRow columns={6} />;
    }

    const rows = ComplaintsStore.list.map((row, index) => {
      if (row === null) {
        return <LoadingRow key={index} colSpan={colSpan} />;
      }

      return (
        <T.TRow key={row?.uid}>
          <TableCell align="left">
            {new Date(row.createdAt).toLocaleDateString()}
          </TableCell>
          <TableCell align="left">{translateDistrict(row.district)}</TableCell>
          <TableCell align="left">
            <TableCellTextContainer>
              {row.newCategory
                ? `NEW: ${formatRecord(row.newCategory)}`
                : formatRecord(row.category)}
            </TableCellTextContainer>
          </TableCell>
          <TableCell align="left">
            <TableCellTextContainer>
              {formatRecord(row.initialMessage)}
            </TableCellTextContainer>
          </TableCell>
          <TableCell align="left">
            {formatName(row?.firstName)} {formatName(row?.lastName)}
          </TableCell>
          <TableCell align="left">
            {row?.likes ? Object.keys(row?.likes)?.length : 0}{" "}
          </TableCell>
        </T.TRow>
      );
    });

    return rows;
  };

  return (
    <T.TContainer>
      Total complaints: {amount}
      <div />
      Filtered complaints: {ComplaintsStore.amountFiltered}
      <FilterTable
        additionalLists={{ mp: mps, category: categories }}
        searchValue={ComplaintsStore.searchValue}
        setSearchValue={ComplaintsStore.setSearchValue}
        setPage={ComplaintsStore.setPage}
        disabled={ComplaintsStore.disabled}
      />
      <ShowEntries
        rowsPerPage={ComplaintsStore.rowsPerPage}
        amount={ComplaintsStore.amount}
        setRowsPerPage={ComplaintsStore.setRowsPerPage}
        disabled={ComplaintsStore.disabled}
      />
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">District</TableCell>
            <TableCell align="left">Complaints amount</TableCell>
            <TableCell align="left">Likes amount</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(ComplaintsStore)}>{renderStats()}</TableBody>
      </Table>
      <Table>
        <SaveCSV />
        <T.THead>
          <TableRow>
            <TableCell align="left">DATE</TableCell>
            <TableCell align="left">District</TableCell>
            <TableCell align="left">Category name</TableCell>
            <TableCell align="left">Complaint</TableCell>
            <TableCell align="left">MP</TableCell>
            <TableCell align="left">Likes</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(ComplaintsStore)}>{renderRows()}</TableBody>
      </Table>
      <Paginator
        amount={ComplaintsStore.amountFiltered ?? amount}
        rowsPerPage={ComplaintsStore.rowsPerPage}
        page={ComplaintsStore.page}
        setPage={ComplaintsStore.setPage}
        disabled={ComplaintsStore.disabled}
      />
    </T.TContainer>
  );
};

export default ComplaintsStats;
