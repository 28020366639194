export const AvatarInfo = {
  file: null,
  fileName: null,
  isLogo: false
};

export const nextInfo = {
  statement: '',
  awards: '',
  achievements: '',
  budget: '',
  goals: '',
  position: '',
  media: '',
  mediaSecond: ''
};

export const CandidateInfoError = {
  formErr: false,
  validateFields: {
    email: false,
    phoneNumber: false,
    firstName: false,
    lastName: false,
    electionCode: false,
    media: false,
    mediaSecond: false
  },
  required: {
    firstName: false,
    lastName: false,
    electionCode: false,
    district: false,
    seatType: false,
    gender: false
  }
};
