import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import * as E from './ElectionModeFormStyle'
import { useNavigate } from "react-router-dom";
import ElectionStore from "../../../store/ElectionModeStore";
import { observer } from "mobx-react-lite";
import { ElectionModeFormProps, IElectionModeForm } from "../../../types/type";

const ElectionModeForm = observer(({
  handleElectionMode,
  updateCurrentMode,
  isLoading
}: ElectionModeFormProps): JSX.Element => {
  const navigate = useNavigate();

  const { handleSubmit, control, reset, watch, formState: { isValid } } = useForm<IElectionModeForm>({
    defaultValues: {
      mode: '',
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IElectionModeForm> = (data) => {
    const { mode } = data;
    reset({ mode: '' });
    ElectionStore.setCurrentMode('');
    handleElectionMode(mode).then((res: any) => updateCurrentMode());
  };

  return (
    <CardBox style={{ width: '650px', maxWidth: '100%' }}>
      <E.Title>
        Election mode is — <span>{!!ElectionStore.currentMode ? ElectionStore.currentMode.toUpperCase() : <Skeleton />}</span>
      </E.Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth={true}>
          <InputLabel>Send to</InputLabel>
          <Controller
            name={'mode'}
            control={control}
            rules={{ required: true }}
            render={field => (
              <Select
                label="Send to"
                {...field.field}
                data-testid="select-election-mode"
                disabled={ElectionStore.modes.length === 0}
              >
                {ElectionStore.modes?.map((item, index) => (
                  <MenuItem
                    key={index}
                    data-testid={item}
                    value={item.toLowerCase()}>
                    {item.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <E.Buttons>
          <Button
            disabled={!isValid || isLoading}
            type={"submit"}
            variant={"contained"}
            size={"large"}
            data-testid="change-mode-btn"
          >
            Change
          </Button>

          <Button
            color={"error"}
            size={"large"}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </E.Buttons>
      </form>
    </CardBox>
  );
});

export default ElectionModeForm;