import React, { ChangeEvent, useEffect, useState } from "react";
import Uploader from "../../../common/ImageUploader";
import {
  Grid,
  CircularProgress,
  Stack,
  Button,
  //Checkbox,
  //FormControlLabel
} from "@mui/material";
import InputText from "../../../common/Input/InputText";
import InputDate from "../../../common/Input/InputDate";
import InputPlace from "../../../common/Input/InputPlace";
import { translateDistrict } from "../../../utils/i18n";
import SelectModal from "../../../common/SelectModal/SelectModal";
import { neighbourhoods } from "../../../assets/districts/data";
import SelectParty from "../../../common/SelectModal/SelectParty";
import InputMFA from "../../../common/Input/InputMFA";
import {
  electionCodeLengthMax,
  electionCodeLengthMin,
} from "../../../utils/constants";
import SelectSeatType from "../../../common/SelectModal/SelectSeatType";
import InputPhone from "../../../common/Input/InputPhone";
import {
  localVideo,
  mailValidate,
  mustBeEqual,
  nameValidate,
  required,
  validateElectionCode,
  youTubeUrl,
} from "../../../utils/validators";
import SelectDistrict from "../../../common/SelectModal/SelectDistrict";
import Textarea from "../../../common/Input/Textarea";
import InputFile from "../../../common/Input/inputFile";
import { VideoItem } from "../../../common/PublicInformation/";
import ReactPlayer from "react-player";
import parsePhoneNumber, { Metadata } from "libphonenumber-js";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import ModalAlert from "../../../common/Modal/ModalAlert";
import CandidateStore from "../../../store/CandidateStore";
import { CandidateFormProps } from "../../../types/type";
import { observer } from "mobx-react-lite";
import { IModalAction } from "../../../types/type";
import WarningStore from "../../../store/WarningStore";
import { Form, GridHr, ResetButton, DuplicateItem } from "./CandidateStyles";
import { getDuration } from "../../../utils/video";
import ElectionModeStore from "../../../store/ElectionModeStore";
import QRCode from "react-qr-code";
import { EmailIcon } from "react-share";

const Hr = () => {
  return (
    <GridHr item xs={12}>
      <hr />
    </GridHr>
  );
};

const CandidateForm = observer(
  ({
    submitBtn,
    onSubmit,
    getPlaces,
    isFetching,
    resetDeviceID,
    add,
    clearPhoneNumbers,
  }: CandidateFormProps): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingCandidate, setIsLoadingCandidate] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState<any>("");
    const [modalActions, setModalActions] = useState<IModalAction>({
      action: null,
    });
    const [isCreating, setIsCreating] = useState(false);
    const [fMedia, setFMedia] = useState<any>();
    const [sMedia, setSMedia] = useState<any>();
    const [firstRender, setFirstRender] = useState(true);
    const [isLoadingVideo, setIsLoadingVideo] = useState<any>({
      media: false,
      mediaSecond: false,
    });
    const [duplicates, setDuplicates] = useState<any>(null);
    const [sendMFACode, setSendMFACode] = useState(false);

    const showErrorMessage = (title: any) => {
      setModalTitle(title);
      setIsModalErrorOpen(true);
    };

    useEffect(() => {
      if (!CandidateStore.email) {
        setSendMFACode(false);
      }
    }, [CandidateStore.email]);

    useEffect(() => {
      setFMedia(CandidateStore.mediaData.media.url);
      setSMedia(CandidateStore.mediaData.mediaSecond.url);
    }, [
      CandidateStore.mediaData.media.url,
      CandidateStore.mediaData.mediaSecond.url,
    ]);

    useEffect(() => {
      if (!isFetching) {
        let districtNames = [
          "Federal",
          "Provincial_Ninawa",
          "Provincial_Wasit",
        ];

        let provinces = [
          "Federal",
          "Provincial_Ninawa",
          "Provincial_Wasit",
        ];

        neighbourhoods.map((item) => {
          let name = item.neighbourhoodId.split("; #")[0];
          districtNames.push(name);
          provinces.push(item.province)
        });

        if (CandidateStore.candidateType === 'mp-candidate') {
          CandidateStore.setSelectionList(
              [...new Set([...districtNames])],
              "district"
          );
        } else {
          CandidateStore.setSelectionList(
              [...new Set([...provinces])],
              "district"
          )
        }

        if (!WarningStore.isEdited) {
          WarningStore.setIsEdited(false);
        }

        if (CandidateStore.gender === 0 || !CandidateStore.gender) {
          CandidateStore.setData("gender", "Male");
        }

        if (CandidateStore.party) {
          CandidateStore.setPartyName();
        }
      }
    }, [isFetching]);

    const onAvatarUpload = async (e: any) => {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        CandidateStore.setData("avatarData", {
          file: fileReader.result,
          fileName: file.name,
          isLogo: true,
        });
        CandidateStore.setData("avatar", fileReader.result);
        WarningStore.setIsEdited(true);
      };
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name ? e.target.name : "";
      const value = e.target.value ? e.target.value : "";

      CandidateStore.setData(name, value);
      WarningStore.setIsEdited(true);
    };

    const onChangeElectionVotes = (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name ? e.target.name : "";
      const value = e.target.value ? e.target.value : "";

      if (isNaN(Number(value)) || value.length > 14) {
        return;
      }

      CandidateStore.setData(
        name,
        parseInt(value.replaceAll(",", ""))
          .toString()
          .replace(/\B(?=(\d{14})+(?!\d))/g, ",") ?? 0
      );
      WarningStore.setIsEdited(true);
    };

    const onChangeInputElection = (e: any) => {
      const name = e.target.name ? e.target.name : "";
      const value = e.target.value ? e.target.value : "";

      CandidateStore.setData(name, Number(value));
      WarningStore.setIsEdited(true);
    };

    const handleSelect = (e: any) => {
      isModalOpen ? setIsModalOpen(false) : setIsModalOpen(true);
      CandidateStore.updateSelects({
        [e]: {
          ...CandidateStore.selects[e],
          active: !CandidateStore.selects[e].active,
        },
      });
    };

    const onChangeSelect = (name: any, value: any) => {
      CandidateStore.setData(name, value);
      WarningStore.setIsEdited(true);
    };

    const onSelectSeatType = (name: any, value: any) => {
      CandidateStore.setData(name, value);
      WarningStore.setIsEdited(true);
    };

    const onChangeSelectParty = (value: any, code: any) => {
      CandidateStore.setData("party", code);
      CandidateStore.setData("partyName", value);
      WarningStore.setIsEdited(true);
    };

    const onPlaceSelect = (item: string) => {
      CandidateStore.setData("bornPlace", item);
      CandidateStore.updateSelects({
        place: { ...CandidateStore.selects.place, list: [] },
      });
    };

    const onPlaceChange = (e: ChangeEvent<HTMLInputElement>) => {
      CandidateStore.setData("bornPlace", e.target.value);
      CandidateStore.updateSelects({
        place: { ...CandidateStore.selects.place, active: true },
      });

      let prevPlace = e.target.value;
      setTimeout(() => {
        if (e.target.value === prevPlace) {
          getPlaces(e).then((list: string[]) => {
            CandidateStore.updateSelects({
              place: { active: false, list },
            });
          });
        }
      }, 1000);

      WarningStore.setIsEdited(true);
    };

    const onGenderSelect = (name: string, value: number) => {
      CandidateStore.setData(name, value);
      WarningStore.setIsEdited(true);
    };

    const onChangePhone = (name: string, value: any) => {
      if (value) {
        CandidateStore.setData("phoneNumber", value);

        if (firstRender && !CandidateStore.isCurrentModeCreate) {
          setFirstRender(false);
          return;
        }

        WarningStore.setIsEdited(true);
      }
    };

    const onChangeMfa = (value: string | number) => {
      CandidateStore.setData("mfa", value);
      WarningStore.setIsEdited(true);
    };

    const renderDuplicateList = (duplicates: any[]): JSX.Element => {
      return (
        <div>
          <p>{"Phone number is already taken by:"}</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DuplicateItem>First name</DuplicateItem>
            <DuplicateItem>Last name</DuplicateItem>
            <DuplicateItem>District</DuplicateItem>
            <DuplicateItem>Profile type</DuplicateItem>
          </div>
          {duplicates.map((duplicate, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <DuplicateItem>{duplicate.firstName}</DuplicateItem>
                <DuplicateItem>{duplicate.lastName}</DuplicateItem>
                <DuplicateItem>{duplicate.district}</DuplicateItem>
                <DuplicateItem>{duplicate.type}</DuplicateItem>
              </div>
            );
          })}
          <p>
            {
              "Would you like to delete this phone number from voters profiles? It will only affect voters."
            }
          </p>
        </div>
      );
    };

    const suggestToDelete = (numberHolders?: any[]) => {
      setIsConfirmModalOpen(false);
      setIsLoadingCandidate(false);
      setIsCreating(false);
      setDuplicates(numberHolders);
    };

    const getFieldsWithErrors = () => {
      const result: string[] = [];
      const extractFieldsWithErrors = (fieldsContainer: any) => {
        Object.entries(fieldsContainer).forEach((field) => {
          const key = field[0];
          const value = field[1];
          if (value) {
            result.push(key);
          }
        });
      };
      extractFieldsWithErrors(CandidateStore.error.required);
      extractFieldsWithErrors(CandidateStore.error.validateFields);
      const describeFieldNames = (fields: string[]) => {
        const describedFields: string[] = Array.from(new Set(result));
        return describedFields.map((name: string) => {
          return name
            .split(/(?=[A-Z])/)
            .map((word) => word.toLowerCase())
            .join(" ");
        });
      };
      return describeFieldNames(result);
    };

    const handleSubmit = async () => {
      setIsLoadingCandidate(true);
      setIsCreating(true);

      const userCredentials = CandidateStore.getData();

      const invalidDataMessage = () => {
        setIsConfirmModalOpen(false);
        setIsLoadingCandidate(false);
        setIsCreating(false);
        showErrorMessage(
          <div>
            <p>Error! Check the validated fields:</p>
            {getFieldsWithErrors().map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        );
      };

      validate()
        ? await onSubmit(userCredentials)
            .then((res: any) => {
              if (res.status === 409) {
                throw res;
              }
              setIsLoadingCandidate(false);
              setIsCreating(false);
            })
            .catch((e: any) => {
              suggestToDelete(e.data);
            })
        : invalidDataMessage();
    };

    useEffect(() => {
      if (duplicates) {
        setModalTitle(
          renderDuplicateList([...duplicates.voters, ...duplicates.candidates])
        );
        setModalActions({
          action: async () => {
            setIsLoadingCandidate(true);
            setIsCreating(true);
            await clearPhoneNumbers(
              duplicates.voters.map((item: any) => item.id)
            )
              .then(async () => {
                if (duplicates.candidates.length === 0) {
                  await onSubmit(CandidateStore.getData()).then(() => {
                    setIsLoadingCandidate(false);
                    setIsCreating(false);
                  });
                } else {
                  setIsLoadingCandidate(false);
                  setIsCreating(false);
                }
              })
              .catch((e: any) => {
                setIsLoadingCandidate(false);
                setIsCreating(false);
                setModalTitle(
                  "Error occured while deleting phone numbers from voters."
                );
                setIsModalErrorOpen(true);
              });
          },
        });
        setIsConfirmModalOpen(true);
      }
    }, [duplicates]);

    const confirmChanges = (e: any) => {
      e.preventDefault();

      const closeModalOnKeyPressed = (e: any) => {
        if (e.key === "Escape") {
          setIsConfirmModalOpen(false);
          setIsLoadingCandidate(false);
        }
      };

      document.addEventListener("keydown", closeModalOnKeyPressed, {
        once: true,
      });

      setModalTitle("Are you sure to save changes?");
      setModalActions({ action: handleSubmit });
      setIsConfirmModalOpen(true);
    };

    const validate = () => {
      let isValid = true;
      const requiredName = Object.keys(CandidateStore.error.required);

      requiredName.map((name) => {
        if (name !== "seatType") {
          const value = required(CandidateStore[name]);
          CandidateStore.updateErrorData("required", {
            [name]: value,
          });
          value ? (isValid = false) : null;
        }

        if (name === "seatType") {
          const value = required(
            Array.isArray(CandidateStore[name])
              ? CandidateStore[name].join()
              : CandidateStore[name]
          );
          CandidateStore.updateErrorData("required", {
            [name]: value,
          });
          value ? (isValid = false) : null;
        }
      });

      const validateName = Object.keys(CandidateStore.error.validateFields);

      const concatFields = (fn: Function, val: any, number?: number) => {
        let value;
        if (val === "media" || val === "mediaSecond") {
          value = fn(CandidateStore.mediaData[val].url, number);
        } else {
          value = fn(CandidateStore[val], number);
        }
        CandidateStore.updateErrorData("validateFields", {
          [val]: value,
        });
        value ? (isValid = false) : null;
      };

      const validatePhoneNumber = (name: string) => {
        //get current country
        const currentCountry = parsePhoneNumber(CandidateStore[name])?.country;

        //phone library may not support this country phone codes
        if (!currentCountry) {
          concatFields(mustBeEqual, name, CandidateStore[name].length);
          return;
        }

        //class inluding countries number formats
        const metadata: any = new Metadata();
        //setting metadata country
        metadata.selectNumberingPlan(currentCountry);
        //get all possible country number lengths, method return an array of lengths
        const countryPhoneLengths = metadata.numberingPlan.possibleLengths();
        //get phone code of current country
        const [countryPhoneCode] = Object.entries(
          metadata.metadata.country_calling_codes
        ).filter((item: any) => item[1].includes(currentCountry))[0];
        //cutting phone code from the original number
        const separatedNumberLength = CandidateStore[name].split(
          `+${countryPhoneCode}`
        )[1].length;
        //if new number length without phone code don`t match possible length, lets mark them as invalid data
        if (!countryPhoneLengths.includes(separatedNumberLength)) {
          //in case of possible lengths including only one number
          const lengthsRange: any =
            countryPhoneLengths.length === 1
              ? countryPhoneLengths
              : `${countryPhoneLengths[0]} - ${
                  countryPhoneLengths[countryPhoneLengths.length - 1]
                } `;
          concatFields(mustBeEqual, name, lengthsRange);
        }
      };

      validateName.map((name) => {
        if (
          name === "firstName" ||
          (name === "lastName" && CandidateStore[name].length !== 0)
        ) {
          concatFields(nameValidate, name);
        }

        if (name === "email" && CandidateStore[name].length !== 0) {
          concatFields(mailValidate, name);
        }

        if (
          name === "phoneNumber" &&
          CandidateStore[name]?.length !== 0 &&
          CandidateStore[name]
        ) {
          validatePhoneNumber(name);
        }
        if (name === "electionCode" && CandidateStore[name]?.length !== 0) {
          concatFields(validateElectionCode, name);
        }

        if (
          name === "media" ||
          (name === "mediaSecond" &&
            CandidateStore.mediaData[name].url?.length !== 0)
        ) {
          if (
            CandidateStore.mediaData[name].url?.length &&
            youTubeUrl(CandidateStore.mediaData[name]?.url) &&
            !CandidateStore.mediaData[name]?.file
          ) {
            concatFields(localVideo, name);
          }
        }
      });

      return isValid;
    };

    const onChangeInputMedia = (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name ? e.target.name : "";
      const value = e.target.value ? e.target.value : "";

      if (!value.length) {
        CandidateStore.updateMedia({
          [name]: {
            ...CandidateStore.mediaData[name],
            file: "",
            url: "",
            name: "",
          },
        });
      }

      if (value.length && (!youTubeUrl(value) || !localVideo(value))) {
        CandidateStore.updateMedia({
          [name]: {
            ...CandidateStore.mediaData[name],
            file: "",
            url: value,
            name: "",
          },
        });
      } else {
        return;
      }

      WarningStore.setIsEdited(true);
    };

    const onChangeVideo = (e: any) => {
      const name = e.target.name ? e.target.name : "";
      setIsLoadingVideo({ ...isLoadingVideo, [name]: true });

      const file = e.target.files[0];
      e.target.value = null;

      if (file.size / 1048576 > 10) {
        showErrorMessage("Video file cant be more than 10MB.");
        setIsLoadingVideo({ ...isLoadingVideo, [name]: false });
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async (e) => {
        const duration: any = await getDuration(fileReader);

        if (Math.floor(duration) > 60) {
          showErrorMessage(
            "The duration of the video should be no more than 1 minute."
          );
          setIsLoadingVideo({ ...isLoadingVideo, [name]: false });
          return;
        }

        CandidateStore.setData(name, "");

        CandidateStore.updateMedia({
          [name]: {
            ...CandidateStore.mediaData[name],
            file: fileReader.result,
            url: URL.createObjectURL(file),
            name: file.name,
            size: file.size / 1048576 <= 10,
          },
        });

        WarningStore.setIsEdited(true);
        setIsLoadingVideo({ ...isLoadingVideo, [name]: false });
      };
    };

    const checkUrl = (url: string) => {
      return youTubeUrl(url) === "Url are incorrect" ? "" : url;
    };

    const getGreetings = () => {
      if (CandidateStore.firstName && CandidateStore.lastName) {
        return CandidateStore.firstName + " " + CandidateStore.lastName + ", ";
      }
      return "";
    };

    return (
      <div data-testid="candidate-form">
        {isCreating || isFetching ? (
          <Stack
            style={{ marginTop: "300px", marginBottom: "50px" }}
            alignItems="center"
          >
            <CircularProgress color="primary" size={100} />
            {isFetching ? (
              <p>{"Please wait, loading data..."}</p>
            ) : (
              <p>
                {CandidateStore.uid
                  ? "Applying new changes, please wait..."
                  : "Creating candidate profile, please wait..."}
              </p>
            )}
          </Stack>
        ) : (
          <Form
            style={{ position: "relative" }}
            noValidate
            autoComplete="off"
            onSubmit={confirmChanges}
          >
            {CandidateStore.deviceId && (
              <ResetButton
                onClick={() => {
                  setIsConfirmModalOpen(true);
                  setModalTitle("Are you sure to delete device ID?");
                  setModalActions({ action: resetDeviceID });
                }}
              >
                Reset device ID
              </ResetButton>
            )}
            <Uploader
              pic={CandidateStore.avatar}
              onChange={onAvatarUpload}
              name="avatar"
              fileType={`accept = "image/gif, image/png, image/jpeg"`}
              alt="avatar"
            />
            <Grid
              sx={{ mt: 3, mb: 5 }}
              container
              columnSpacing={8}
              rowSpacing={4}
            >
              <Grid item md={6} xs={12}>
                <InputText
                  name="firstName"
                  helperText={
                    CandidateStore.error.required.firstName ||
                    CandidateStore.error.validateFields.firstName
                  }
                  maxLength="250"
                  value={CandidateStore.firstName}
                  label="Name"
                  placeholder="John"
                  dataTestId="candidate-first-name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(e)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputText
                  name="lastName"
                  helperText={
                    CandidateStore.error.required.lastName ||
                    CandidateStore.error.validateFields.lastName
                  }
                  maxLength="250"
                  value={CandidateStore.lastName}
                  label="Last name"
                  placeholder="Connor"
                  dataTestId="candidate-last-name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(e)
                  }
                />
              </Grid>

              <Hr />

              <Grid item md={6} xs={12}>
                <InputText
                  name="email"
                  helperText={CandidateStore.error.validateFields.email}
                  value={CandidateStore.email}
                  label="E-mail"
                  placeholder="23232323@gmail.com"
                  dataTestId="candidate-email"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(e)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputPhone
                  name="phoneNumber"
                  value={CandidateStore.phoneNumber}
                  helperText={CandidateStore.error.validateFields.phoneNumber}
                  label="Phone"
                  placeholder="Phone number"
                  dataTestId="candidate-phone-number"
                  onChange={onChangePhone}
                />
              </Grid>
              {ElectionModeStore.isCurrentModeFinal && (
                <>
                  <Hr />
                  <Grid item md={6} xs={12}>
                    <InputText
                      name="electionVotes"
                      helperText={
                        CandidateStore.error.validateFields.electionVotes
                      }
                      type="numeric"
                      value={
                        // CandidateStore.electionVotes ??
                        Number(
                          `${CandidateStore.electionVotes}`.replace(",", "")
                        )
                      }
                      label="Election votes"
                      placeholder="1,234"
                      dataTestId="candidate-election-votes"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChangeElectionVotes(e)
                      }
                    />
                  </Grid>
                </>
              )}
              <Hr />

              <Grid item md={6} xs={12}>
                <InputPlace
                  name="bornPlace"
                  value={CandidateStore.bornPlace}
                  label="Born Place"
                  placeholder="US, New York"
                  loader={CandidateStore.selects.place.active}
                  placeList={CandidateStore.selects.place.list}
                  data-testid="candidate-born-place"
                  onPlaceSelect={onPlaceSelect}
                  onChange={(e: any) => onPlaceChange(e)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputDate
                  name="birthDate"
                  label="Date of Birth"
                  value={
                    CandidateStore.birthDate
                      ? CandidateStore.birthDate
                      : new Date(
                          new Date().setFullYear(new Date().getFullYear() - 18)
                        )
                  }
                  dataTestId="candidate-birth-date"
                  onChange={onChangeSelect}
                  minYears={18}
                  maxYears={75}
                />
              </Grid>

              <Hr />

              <Grid item md={6} xs={12}>
                <SelectDistrict
                  name="district"
                  label={CandidateStore.candidateType === 'mp-candidate' ? "Select district" : "Select province"}
                  labelBtn="Select"
                  helperText={CandidateStore.error.required.district}
                  value={
                    CandidateStore.district
                      ? `${translateDistrict(
                          CandidateStore.district
                        )} (${CandidateStore.district.replace("_N_", " ")})`
                      : ""
                  }
                  active={CandidateStore.selects.district.active}
                  onChange={onChangeSelect}
                  dataTestId="candidate-select-district"
                  setActive={handleSelect}
                  selectList={CandidateStore.selects.district.list}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectParty
                  name="party"
                  label="Select party"
                  value={CandidateStore.partyName}
                  active={CandidateStore.selects.party.active}
                  onChange={onChangeSelectParty}
                  dataTestId="candidate-select-party"
                  setActive={handleSelect}
                  selectList={CandidateStore.selects.party.list}
                />
              </Grid>

              <Hr />

              <Grid item md={6} xs={12}>
                <InputText
                  helperText={
                    CandidateStore.error.required.electionCode ||
                    CandidateStore.error.validateFields.electionCode
                  }
                  name="electionCode"
                  type="numeric"
                  max="999"
                  min="0"
                  value={CandidateStore.electionCode}
                  label={`Election code / Number (${electionCodeLengthMax} or ${electionCodeLengthMin} digits)`}
                  placeholder="000"
                  dataTestId="candidate-election-code"
                  onChange={(e: any) => onChangeInputElection(e)}
                  onInput={(e: any) => {
                    e.target.value = e.target.value
                      ? Math.max(0, parseInt(e.target.value))

                          .toString()
                          .slice(0, electionCodeLengthMax)
                      : "";
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputMFA
                  name="mfa"
                  dataTestId="candidate-MFA"
                  value={CandidateStore.mfa}
                  label="MFA code"
                  onChange={onChangeMfa}
                  sendMessage={sendMFACode}
                />
                {/*!CandidateStore.isCurrentModeCreate && (
                  <FormControlLabel
                    data-testid="send-candidate-email-message"
                    control={
                      <Checkbox
                        aria-label="Click on generate MFA with this checkbox enabled will send an email messsage to candidate with MFA message"
                        disabled={!CandidateStore.email}
                        checked={sendMFACode}
                        value={sendMFACode}
                        onChange={(e) => {
                          setSendMFACode(e.target.checked)
                        }}
                      />
                    }
                    label="Send message to email with QR and MFA code"
                  />
                )*/}
                {CandidateStore.mfa && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    data-testid="QR-and-share-container"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p>Share MFA code:</p>
                      <a
                        style={{
                          width: 40,
                          height: 40,
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                        href={`mailto:${CandidateStore.email}
                      ?body=${getGreetings()}MFA code is: ${CandidateStore.mfa}
                      &subject=MFA Code`}
                        data-testid="share-button"
                      >
                        <EmailIcon size={40} />
                      </a>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div data-testid="QR-code">
                        <p style={{ margin: 0 }}>MFA QR Code:</p>
                        <QRCode value={CandidateStore.mfa} />
                      </div>
                    </div>
                  </div>
                )}
              </Grid>

              <Hr />

              <Grid item md={6} xs={12}>
                <InputText
                  name="occupation"
                  value={CandidateStore.occupation}
                  label="Occupation"
                  placeholder="Occupation"
                  dataTestId="candidate-occupation"
                  onChange={(e: any) => onChangeInput(e)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectModal
                  name="education"
                  label="Highest Education level"
                  labelBtn="Select"
                  value={CandidateStore.education}
                  active={CandidateStore.selects.education.active}
                  onChange={onChangeSelect}
                  dataTestId="candidate-education"
                  setActive={handleSelect}
                  selectList={CandidateStore.selects.education.list}
                />
              </Grid>

              <Hr />

              <Grid item md={6} xs={12}>
                <SelectModal
                  name="gender"
                  label="Gender"
                  labelBtn="Select gender"
                  helperText={CandidateStore.error.required.gender}
                  value={CandidateStore.gender}
                  active={CandidateStore.selects.gender.active}
                  onChange={onGenderSelect}
                  dataTestId="candidate-gender"
                  setActive={handleSelect}
                  selectList={CandidateStore.selects.gender.list}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectSeatType
                  name="seatType"
                  helperText={CandidateStore.error.required.seatType}
                  label="Seat type"
                  labelBtn="Select"
                  dataTestId="candidate-select-seat-type"
                  value={CandidateStore.seatType}
                  active={CandidateStore.selects.seatType.active}
                  onChange={onSelectSeatType}
                  setActive={handleSelect}
                  selectList={CandidateStore.selects.seatType.list}
                />
              </Grid>

              {add && (
                <>
                  <Hr />

                  <Grid item md={6} xs={12}>
                    <Textarea
                      name="statement"
                      maxLength={1000}
                      value={CandidateStore.statement}
                      dataTestId="candidate-statement"
                      label="Statement"
                      onChange={(e: any) => onChangeInput(e)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Textarea
                      name="awards"
                      maxLength={1000}
                      value={CandidateStore.awards}
                      dataTestId="candidate-awards"
                      label="Previous awards"
                      onChange={(e: any) => onChangeInput(e)}
                    />
                  </Grid>

                  <Hr />

                  <Grid item md={6} xs={12}>
                    <Textarea
                      name="achievements"
                      maxLength={1000}
                      value={CandidateStore.achievements}
                      dataTestId="candidate-achievements"
                      label="Achievements"
                      onChange={(e: any) => onChangeInput(e)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Textarea
                      name="goals"
                      maxLength={1000}
                      value={CandidateStore.goals}
                      dataTestId="candidate-goals"
                      label="Main Goals to achieve when Elected"
                      onChange={(e: any) => onChangeInput(e)}
                    />
                  </Grid>

                  <Hr />

                  <Grid item md={6} xs={12}>
                    <Textarea
                      name="position"
                      maxLength={1000}
                      value={CandidateStore.position}
                      dataTestId="candidate-position"
                      label="Position against main issue"
                      onChange={(e: any) => onChangeInput(e)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Textarea
                      name="budget"
                      maxLength={1000}
                      value={CandidateStore.budget}
                      dataTestId="candidate-budget"
                      label="Company budget and source"
                      onChange={(e: any) => onChangeInput(e)}
                    />
                  </Grid>
                  <Hr />
                  <Grid item md={6} xs={12}>
                    <Grid container columnSpacing={1} rowSpacing={0}>
                      <Grid item lg={8} xs={12}>
                        <InputText
                          type="url"
                          helperText={CandidateStore.error.validateFields.media}
                          name="media"
                          dataTestId="fMedia-URL"
                          placeholder="YouTube url"
                          maxLength={1000}
                          value={checkUrl(CandidateStore.mediaData.media.url)}
                          label="First video"
                          onChange={(e: any) => onChangeInputMedia(e)}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <InputFile
                          name="media"
                          label="Upload video"
                          style={{ width: "100%", marginTop: 24 }}
                          dataTestId="fMedia-upload"
                          type="file"
                          accept="video/mp4, video/x-m4v, video/*"
                          onChange={(e: any) => onChangeVideo(e)}
                          isDisabled={
                            isLoadingVideo.media || isLoadingVideo.mediaSecond
                          }
                        >
                          Upload video
                        </InputFile>
                      </Grid>
                    </Grid>

                    {isLoadingVideo.media && (
                      <Stack
                        style={{ marginTop: "125px", marginBottom: "40px" }}
                        alignItems="center"
                      >
                        <CircularProgress color="primary" size={100} />
                        {<p>{"Please wait, loading video..."}</p>}
                      </Stack>
                    )}

                    {CandidateStore.mediaData.media.url &&
                    !isLoadingVideo.media ? (
                      <VideoItem
                        data-testid="first-media-player"
                        style={{ marginTop: 20 }}
                      >
                        <ReactPlayer
                          url={fMedia}
                          controls={true}
                          width={"100%"}
                          height={"100%"}
                        />
                      </VideoItem>
                    ) : null}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Grid container columnSpacing={1} rowSpacing={0}>
                      <Grid item lg={8} xs={12}>
                        <InputText
                          type="url"
                          helperText={
                            CandidateStore.error.validateFields.mediaSecond
                          }
                          name="mediaSecond"
                          dataTestId="sMedia-URL"
                          placeholder="YouTube url"
                          maxLength={1000}
                          value={checkUrl(
                            CandidateStore.mediaData.mediaSecond.url
                          )}
                          label="Second video"
                          onChange={(e: any) => onChangeInputMedia(e)}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <InputFile
                          name="mediaSecond"
                          label="Upload video"
                          style={{ width: "100%", marginTop: 24 }}
                          dataTestId="sMedia-upload"
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={(e: any) => onChangeVideo(e)}
                          isDisabled={
                            isLoadingVideo.media || isLoadingVideo.mediaSecond
                          }
                        >
                          Upload video
                        </InputFile>
                      </Grid>
                    </Grid>

                    {isLoadingVideo.mediaSecond && (
                      <Stack
                        style={{ marginTop: "125px", marginBottom: "40px" }}
                        alignItems="center"
                      >
                        <CircularProgress color="primary" size={100} />
                        {<p>{"Please wait, loading video..."}</p>}
                      </Stack>
                    )}

                    {CandidateStore.mediaData.mediaSecond.url &&
                    !isLoadingVideo.mediaSecond ? (
                      <VideoItem
                        data-testid="second-media-player"
                        style={{ marginTop: 20 }}
                      >
                        <ReactPlayer
                          url={sMedia}
                          controls={true}
                          width={"100%"}
                          height={"100%"}
                        />
                      </VideoItem>
                    ) : null}
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  sx={{ width: 345, minHeight: 50 }}
                  variant="contained"
                  disabled={
                    !WarningStore.isEdited ||
                    isCreating ||
                    isLoadingVideo.mediaSecond ||
                    isLoadingVideo.media
                  }
                  color="primary"
                  type="submit"
                  data-testid="save-candidate-btn"
                >
                  {submitBtn}
                </Button>
              </Grid>
            </Grid>

            {isConfirmModalOpen && (
              <ModalConfirm
                setActive={setIsConfirmModalOpen}
                windowTitle={modalTitle}
                disabled={isLoadingCandidate}
                enableStates={[setIsLoadingCandidate]}
                disableStates={[
                  setIsLoadingCandidate,
                  setIsConfirmModalOpen,
                  setModalActions,
                ]}
                callback={modalActions.action}
              />
            )}

            {isModalErrorOpen && (
              <ModalAlert
                setActive={setIsModalErrorOpen}
                windowTitle={modalTitle}
                disableStates={[setIsModalErrorOpen]}
                callback={() => setModalTitle("")}
              />
            )}
          </Form>
        )}
      </div>
    );
  }
);

export default CandidateForm;
