import React, { useState } from "react";
import * as XLSX from "xlsx";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IExportXLSX } from "../../types/type";

const ExportXLSX = ({
  data,
  fileName,
  dataTestId
}: IExportXLSX): JSX.Element => {
  const [loading, setloading] = useState(false);

  const exportToXLSX = async (data: any, fileName: string) => {
    if (data)
      try {
        const MAX_XL_LENGTH = 32767;
        setloading(true);
        data.forEach((element: any) => {
          delete element.mfa;
          delete element.rating;
        });
        const ws = XLSX.utils.json_to_sheet(data);
        const filteredWS = Object.fromEntries(
          Object
            .entries(ws)
            .filter((item) => {
              if (item[0].includes("!ref")) {
                return true;
              }
              return item[1].v?.length < MAX_XL_LENGTH;
            })
        );
        const wb = { Sheets: { data: filteredWS }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setloading(false);
      } catch (error) {
        console.error(error);
        setloading(false);
        alert(error);
      }
  };

  return (
    <LoadingButton
      loading={loading}
      disabled={loading}
      variant="contained"
      data-testid={dataTestId}
      startIcon={<FileDownloadIcon />}
      onClick={(e) => exportToXLSX(data, fileName)}>
      Export
    </LoadingButton>
  );
};

export default ExportXLSX;
