import React, { useEffect, useState } from 'react';
import Reports from "../components/Reports/Reports";
import ReportsStore from '../store/ReportsStore';
import { observer } from 'mobx-react-lite';
import useFetchReports from '../api/useFetch/useFetchReports';
import ModalAlert from "../common/Modal/ModalAlert";
import getReportsVotes from '../api/actions/getReportsVotes';
import getReportsAmount from '../api/actions/getReportsAmount';

const ReportsPage = observer(() => {
  const [progress, setProgress] = useState<any>();
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const {
    getReports,
    getReportsOnly,
    getCustomReports
  } = useFetchReports();

  const downloadVotersFragmented = async () => {
    let upload = true;
    let amount = ReportsStore.amount;

    let list: any = [];
    let size = 1000;
    let p: any = 0;

    for (let i = 0; i < amount / size; i++) {
      if (upload) {
        await getCustomReports({ params: { count: i * size, size: size } })
          .then(async (response: any) => {
            list.push(response);

            if (response.length === 0) {
              i = amount / size;
              upload = false;
              await setProgress(null);
              ReportsStore.setList('votes', list.flat());
              return list.flat();
            };
          })
          .catch((error: any) => {
            upload = false;
            setModalTitle(`Error! ${JSON.stringify(error)}`);
            setIsModalErrorOpen(true);
          })
          .finally(() => {
            p = parseInt(String(((i * size) / amount) * 100)).toFixed(0);
            setProgress(p ?? 0);
            if (p >= 100 || +p === 0) {
              upload = false;
              setProgress(null);
              ReportsStore.setList('votes', list.flat());
              return list.flat();
            };
          });
      }
      // We can disable this condition as number of users who voted can be less than total number of users
      // else {
      //   setModalTitle("Something wrong!");
      //   setIsModalErrorOpen(true);
      //   break;
      // }
    }

    return list.flat();
  };

  useEffect(() => {
    if (!ReportsStore.isAmountFilled) {
      getReportsAmount(getReportsOnly).then(() => {
        downloadVotersFragmented();
      });
      return;
    }
    downloadVotersFragmented();
  }, []);

  useEffect(() => {
    if (!ReportsStore.isFilled) {
      getReportsVotes(getReports);
    }
    return () => ReportsStore.resetStorage();
  }, []);

  return (
    <div data-testid="reports-page">
      <h1>Reports</h1>
      <Reports progress={progress} />

      {isModalErrorOpen && (
        <ModalAlert
          setActive={setIsModalErrorOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalErrorOpen]}
          callback={() => setModalTitle("")}
        />
      )}

    </div>
  );
});

export default ReportsPage;
