import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";
import { _transformNews } from "../tranformResponse";

const useFetchNews = () => {
  const { loading, request, error, clearError } = useHttp();

  const getNews = async ({ page, rowsPerPage }: IGetList) => {
    const res = await request('get', 'api/check/news', {
      params: {
        page,
        rowsPerPage,
      }
    });
    return {
      amount: res.amount,
      list: res.list.map(_transformNews)
    };
  };

  const getNew = async (id: string | undefined) => {
    const res = await request('get', `api/check/news/${id}`)
    return  res ? ({
      title: res.title,
      text: res.text,
      image: res.image,
      fileName: !!res.fileName ? res.fileName : ''
    }) : "";
  };

  const addNews = async (data: object) => {
    return await request('put', 'api/create/news', data);
  };

  const editNews = async (id: string | undefined, data: object) => {
    return await request('post', `api/create/news/${id}`, data);
  };

  const deleteNews = async (id: string) => {
    return await request('delete', `api/create/news/${id}`);
  };

  const getNewsShareAmount = async (id: string) => {
    return await request('get', `api/check/newsShareAmount/${id}`);
  };

  return {
    loading, 
    error, 
    clearError,
    getNews,
    getNew,
    addNews,
    editNews,
    deleteNews,
    getNewsShareAmount
  }
}

export default useFetchNews;