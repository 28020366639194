import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as T from "../TablesStyle";
import LoadingTable from "../LoadingTable";
import { observer } from "mobx-react-lite";
import LogsStore from "../../../store/LogsStore";
import LogsAction from "./LogsAction";
import setBlur from "../../../utils/setBlur";
import getResultDescription from "../../../utils/resultDescriptor";
import LogContainer from "../../Form/LogForm/LogContainer";
import EmptyRow from "../../../common/EmptyTableRow/EmptyTableRow";
import isStorageLoading from '../../../utils/isStorageLoading';

const LogsTable = observer((): JSX.Element => {
  const rowsEntries = Array(LogsStore.rowsPerPage).fill(0);
  const colSpan = Array(4).fill(0);
  const [logId, setLogId] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const closeLog = () => {
    setLogId(null);
    setOpen(false);
  };

  const openLog = (id: string) => {
    setLogId(id);
    setOpen(true);
  };

  const renderRows = () => {
    if (isStorageLoading(LogsStore) ||
      (Object.keys(LogsStore.list).length === 0 && LogsStore.isFilled)) {
      return (
        <LoadingTable loading={LogsStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />
      );
    };

    if (LogsStore.isStorageEmpty) {
      return <EmptyRow columns={4} />;
    }

    return LogsStore.list.map((row: any) => {
      return (
        <T.TRow key={row.id}>
          <TableCell align="left">
            {new Date(row.created_at).toLocaleDateString()}
          </TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">
            {getResultDescription(row.url, row.method)}
          </TableCell>
          <TableCell align="center">
            <LogsAction open={() => openLog(row.id)} />
          </TableCell>
        </T.TRow>
      );
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Author</TableCell>
            <TableCell align="left">Result</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(LogsStore)}>{renderRows()}</TableBody>
      </Table>
      {logId && <LogContainer isOpen={open} id={logId} closeLog={closeLog} />}
    </T.TContainer>
  );
});

export default LogsTable;
