import React from 'react';
import { InputTextProps } from "../../types/type";
import {
  TextLabel,
  TextLabelName,
  Input,
  TextHelper
} from "./InputStyles";

const InputText = ({
  label,
  helperText,
  value,
  dataTestId,
  ...restProps
}: InputTextProps): JSX.Element => {
  return (
    <TextLabel data-testid="input-text-component">
      <TextLabelName>{label}</TextLabelName>
      <Input data-testid={dataTestId} value={value} {...restProps} />
      {(helperText) && (
        <TextHelper data-testid={`error-${dataTestId}`}>
          {helperText}
        </TextHelper>
      )}
      {restProps.maxLength && <span className="length">{value?.length}/{restProps.maxLength}</span>}
    </TextLabel>
  );
};

export default InputText;