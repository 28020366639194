import React from "react";
import { Tab, Theme } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { observer } from "mobx-react-lite";
import ComplaintsStore from "../../../store/ComplaintsStore";
import styled, { ThemeProps } from "styled-components";

const Wrap = styled.div`
  margin-left: 30px;
`;

const TabItem = styled(Tab)`
  background: ${(props: ThemeProps<Theme>) =>
    props.theme.palette.background.default};
  margin-right: 10px;
  border-radius: 5px 5px 0 0;

  &:hover {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
  }

  &.Mui-selected {
    background: ${(props: ThemeProps<Theme>) =>
      props.theme.palette.action.hover};
  }
`;

const ComplaintsTabs = observer(() => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    ComplaintsStore.setType(newValue);
    ComplaintsStore.setPage(1);
  };

  return (
    <Wrap>
      <TabContext value={ComplaintsStore.type}>
        <TabList onChange={handleChange}>
          <TabItem
            disabled={ComplaintsStore.disabled}
            label="New"
            value="new"
          />
          <TabItem
            disabled={ComplaintsStore.disabled}
            label="Approved"
            value="approved"
          />
          <TabItem
            disabled={ComplaintsStore.disabled}
            label="Rejected"
            value="rejected"
          />
        </TabList>
      </TabContext>
    </Wrap>
  );
});

export default ComplaintsTabs;
