import { useHttp } from "../../hooks/useHttp";

const useTFA = () => {
  const { loading, request, error, clearError } = useHttp();

  const generateQR = async (email: string | null) => {
    return await request("post", "api/generateQR", { email });
  };

  const enableTFA = async (secret: string | null, email: string | null) => {
    return await request("post", "api/enableTFA", { secret, email });
  };

  const verifyCode = async (email: string | null, code: undefined | string, secret?: string | undefined) => {
    return await request("post", "api/verifyCode", secret ? ({ email, code, dummySecret: secret }) : ({ email, code }));
  };

  return {
    loading,
    error,
    clearError,
    generateQR,
    enableTFA,
    verifyCode
  };
}

export default useTFA;