import React from 'react';
import NotificationStore from "../../../store/NotificationStore";
import LoadingTable from "../LoadingTable";
import * as T from "../TablesStyle";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { observer } from "mobx-react-lite";
import setBlur from '../../../utils/setBlur';
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import isStorageLoading from '../../../utils/isStorageLoading';

const NotificationTable = observer((): JSX.Element => {
  const rowsEntries = Array(NotificationStore.rowsPerPage).fill(0);
  const colSpan = Array(3).fill(0);

  const renderRows = () => {
    if (isStorageLoading(NotificationStore)) {
      return (<LoadingTable loading={NotificationStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (NotificationStore.isStorageEmpty) {
      return (<EmptyRow columns={2} />);
    }

    return NotificationStore.list.map((row) => {
      return (
        <T.TRow key={row.id}>
          {/*<TableCell align="left">{row.email}</TableCell>*/}
          <TableCell align="left">{row.notification?.title ?? "--"}</TableCell>
          <TableCell align="left">{row.notification?.body ?? "--"}</TableCell>
        </T.TRow>
      );
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            {/*<TableCell align="left">Email</TableCell>*/}
            {/*additional header cell to fix visual bug that cause white empty space in table header
              when email cell will be rendered if user have specific role, need to include additional role check for cell below,
              to render only if user don`t have specific role.
            */}
            {NotificationStore.disabled && <TableCell align="left"></TableCell>}
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Body</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(NotificationStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
});

export default NotificationTable;