import { useHttp } from "../../hooks/useHttp";
import { removeEmpty } from "../../utils/removeEmpty";
import { IGetList } from "../../types/type";
import {
  _transformCandidate,
  _transformPartiesList,
} from "../tranformResponse";

const useFetchCandidate = () => {
  const { loading, request, error, clearError } = useHttp();

  const getCandidates = async ({
    type,
    candidateType,
    page,
    rowsPerPage,
    searchValue,
    nextKey,
    prevKey,
  }: IGetList) => {
    const res: any = await request("get", "dashboard/getcands", {
      params: removeEmpty({
        page,
        candidateType,
        rowsPerPage,
        searchValue: removeEmpty(searchValue),
        type,
        nextKey,
        prevKey,
      }),
    });
    return {
      amount: res.amount,
      list: res.list.map(_transformCandidate),
      partiesData: res.parties,
    };
  };

  const getAllCandidates = async ({
    searchKey,
    searchValue,
    candidateType,
  }: {
    [key: string]: string;
  }) => {
    const res = await request("get", "dashboard/getcands", {
      params: removeEmpty({
        searchKey: searchKey,
        searchValue: removeEmpty(searchValue),
        candidateType: candidateType,
      }),
    });
    return {
      amount: res.amount,
      list: res.list.map(_transformCandidate),
    };
  };

  const customGetAllCandidates = async (data: any) => {
    return await request("get", "dashboard/getcands", {
      params: { ...data },
    });
  };

  const createCandidate = async (userData: any) => {
    return await request("post", "api/create/candidate", {
      ...userData,
      gender: userData.gender === "Male" ? 1 : 0,
      seatType: [...userData.seatType],
      mediaName: userData.mediaName ? userData.mediaName : "Youtube video",
      mediaSecondName: userData.mediaSecondName
        ? userData.mediaSecondName
        : "Youtube video",
    });
  };

  const getCandidate = async (id: string | undefined) => {
    return await request("get", "api/getcandinfo", {
      params: { id },
    });
  };

  const clearPhoneNumbers = async (data: string[]) => {
    return await request("post", "api/clearPhoneNumber", { voters: data });
  };

  const getNewInfo = async (nextinfo: any) => {
    return await request("get", "api/getcandnextinfo", {
      params: { nextinfo },
    });
  };

  const deleteVideo = async (data: any) => {
    return await request("post", "api/deletevideo", data);
  };

  const updateCandidate = async (data: any) => {
    return await request("post", "api/editcandidate", data);
  };

  const approveCandidate = async (id: string) => {
    return await request("post", "api/approvenewinfo", { id });
  };

  const declineCandidate = async (id: string) => {
    return await request("post", "api/declinenewinfo", { id });
  };

  const deleteCandidate = async (id: string) => {
    return await request("post", "api/delete/candidate", { id });
  };

  const suspendCandidate = async (id: string) => {
    return await request("post", "api/candidate/suspend", { id });
  };

  const activateCandidate = async (id: string) => {
    return await request("post", "api/candidate/activate", { id });
  };

  const resetDeviceId = async (id: string | undefined) => {
    return await request("post", "api/resetdeviceid", { id });
  };

  return {
    loading,
    error,
    clearError,
    getCandidates,
    getAllCandidates,
    createCandidate,
    getCandidate,
    deleteVideo,
    updateCandidate,
    getNewInfo,
    approveCandidate,
    declineCandidate,
    deleteCandidate,
    suspendCandidate,
    activateCandidate,
    customGetAllCandidates,
    resetDeviceId,
    clearPhoneNumbers,
  };
};

export default useFetchCandidate;
