import { useHttp } from "../../hooks/useHttp";

const useGenerateMFA = () => {
  const { loading, request, error, clearError } = useHttp();

  const generateMFA = async (data: any) => {
    return await request("post", "api/generateMfaCode", data);
  };

  return {
    loading,
    error,
    clearError,
    generateMFA
  };
}

export default useGenerateMFA;