import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import { Button, TextField, Grid, CircularProgress, LinearProgress } from "@mui/material";
import * as F from "./EpisodeFormStyle";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IForm, IDataNews } from "../../../types/type";
import { observer } from "mobx-react-lite";
import EpisodeFormStore from "../../../store/EpisodeFormStore";
import WarningStore from "../../../store/WarningStore";
import { youTubeUrl, localVideo } from "../../../utils/validators";
import InputText from "../../../common/Input/InputText";
import InputFile from "../../../common/Input/inputFile";
import Stack from "@mui/material/Stack";
import { VideoItem } from "../PublicInformation/PublicInformationStyles";
import ReactPlayer from "react-player";
import ReactHlsPlayer from 'react-hls-player';
import { fStorage, db } from "../../../utils/firebase";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { ref as refDB, child, push } from "firebase/database";

const schema = yup
  .object({
    title: yup.string().required().max(50),
    text: yup.string().required().max(3000),
  })
  .required();

const EpisodeForm = observer(({ onAddEdit, onBack, isLoading }: IForm) => {
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState<any>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isM3u8, setIsM3u8] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState("");
  const [progress, setProgress] = useState<any>(0);

  const player = useRef(null);

  const podcastIcon = (!EpisodeFormStore.isVideo && EpisodeFormStore.podcastIcon) ? ({
    height: 400,
    width: 400,
    margin: "0 auto",
    backgroundImage: `url(${EpisodeFormStore.podcastIcon})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }) : ({});

  const handleUpload = async () => {
    if (!uploadedFile && !EpisodeFormStore.mediaUrl && !EpisodeFormStore.rawData) {
      return;
    }

    const deleteFile = async (ref: any) => {
      return await deleteObject(ref)
        .then(() => {
          console.log("Old file deleted successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const finalizeUploading = (downloadURL?: any) => {
      if (downloadURL) {
        EpisodeFormStore.setData("mediaUrl", downloadURL);
      }

      setUploading(false);
      setUploadingStatus("");
      setProgress(0);
      setUploadedFile(null);
      WarningStore.resetStorage();
      onAddEdit();
    };

    const startUpload = (storageRef: any, file: any) => {
      const uploadTask: any = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot: any) => setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        (err: any) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            finalizeUploading(downloadURL);
          });
        }
      );
    };

    if (!EpisodeFormStore.currentId) {
      const setEpisodeKey = () => {
        const episodeKey = push(child(refDB(db), `podcasts/${EpisodeFormStore.podcastId}/episodes`)).key;
        EpisodeFormStore.setData('currentId', episodeKey);
      }

      if (uploadedFile?.name) {
        const createStorageRef = () => {
          setEpisodeKey();
          return ref(fStorage, `podcasts/${EpisodeFormStore.podcastId}/episodes/${EpisodeFormStore.currentId}/${uploadedFile.name}`);
        };

        const storageRef = createStorageRef();
        setUploading(true);
        setUploadingStatus("Please wait, uploading media...");
        startUpload(storageRef, uploadedFile);
      } else {
        setEpisodeKey();
        finalizeUploading();
      }
    } else {
      if (EpisodeFormStore.oldFileName && EpisodeFormStore.currentId && EpisodeFormStore.rawData) {
        const oldFileRef = ref(
          fStorage,
          `podcasts/${EpisodeFormStore.podcastId}/episodes/${EpisodeFormStore.currentId}`
        );
        await listAll(oldFileRef)
          .then(async (res) => {
            await Promise.all(res.items.map(async (itemRef) => await deleteFile(itemRef)));
          })
          .catch((err) => {
            console.log("error while deleting files: ", err);
          })
      };

      if (uploadedFile?.name) {
        const storageRef = ref(
          fStorage,
          `podcasts/${EpisodeFormStore.podcastId}/episodes/${EpisodeFormStore.currentId}/${uploadedFile.name}`
        );
        setUploading(true);
        setUploadingStatus("Please wait, uploading media...");

        startUpload(storageRef, uploadedFile);
      } else {
        finalizeUploading();
      }
    }
  };

  useEffect(() => {
    const video: any = player.current;
    if (!video) {
      return;
    }
    video.onloadedmetadata = () => {
      EpisodeFormStore.setData("duration", Math.ceil(video.duration));
    }
    return () => { video.onloadedmetadata = null };
  }, [isM3u8, player.current]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useForm<IDataNews>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setMedia(EpisodeFormStore.rawData || EpisodeFormStore.mediaUrl);
  }, [EpisodeFormStore.rawData, EpisodeFormStore.mediaUrl, EpisodeFormStore.fileName]);

  useEffect(() => {
    reset({
      title: EpisodeFormStore.title,
      text: EpisodeFormStore.text,
    });
  }, [EpisodeFormStore.title]);

  const onTextChange = (e: any) => {
    WarningStore.setIsEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    EpisodeFormStore.setData(name, value);
    setValue(name, value);
  };

  const onSubmit: SubmitHandler<IDataNews> = (dataForm) => setOpen(true);

  const renderComponent = () => {
    if (isLoading && !EpisodeFormStore.isCreateModeOn) {
      return (<F.SkeletonParty variant="rectangular" animation="wave" />);
    };

    if (!EpisodeFormStore.noErrors) {
      return (
        <CardBox style={{ textAlign: "center" }}>
          <ErrorIcon color={"error"} />
          <p>There is no laws with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </CardBox>
      );
    }

    const checkUrl = (url: string) => {
      return youTubeUrl(url) === "Url are incorrect" ? "" : url;
    };

    const onChangeInputMedia = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? e.target.value : "";
      const resetVideo = () => {
        EpisodeFormStore.setData("fileName", "");
        EpisodeFormStore.setData("rawData", null);
        setUploadedFile(null);
        setMedia(null);
        WarningStore.setIsEdited(true);
      }


      if (!value.length) {
        EpisodeFormStore.setData("mediaUrl", value);
        resetVideo();
      }

      if (value.length && (!youTubeUrl(value) || !localVideo(value))) {
        EpisodeFormStore.setData("mediaUrl", value);
        resetVideo();
      } else {
        return;
      }
    };

    const onChangeVideo = (e: any) => {
      setIsLoadingVideo(true);
      EpisodeFormStore.setData("mediaUrl", "");

      const file = e.target.files[0];
      setUploadedFile(file);
      e.target.value = null;
      const isItMediaList = file.name.includes("m3u8");
      const isItAudio = file.type.includes("audio");
      setIsM3u8(isItMediaList);

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async (e) => {
        EpisodeFormStore.setData("fileName", file.name);
        EpisodeFormStore.setData("isVideo", !isItAudio);
        EpisodeFormStore.setData("rawData", URL.createObjectURL(file));

        WarningStore.setIsEdited(true);
        setIsLoadingVideo(false);
      };
    };

    const onDurationHandler = (duration: number) => {
      EpisodeFormStore.setData("duration", Math.ceil(duration));
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBox>
          {uploading ? (
            <div>
              <p>{uploadingStatus}</p>
              <LinearProgress
                value={progress}
                variant="determinate"
                color="primary"
              />
            </div>
          ) : (
            <>
              <TextField
                fullWidth
                label="Title"
                variant="outlined"
                sx={{ marginBottom: 3 }}
                inputProps={{ maxLength: 50 }}
                helperText={`${EpisodeFormStore.title.length} / 50`}
                name="title"
                onChange={onTextChange}
                value={EpisodeFormStore.title}
                data-testid="episode-title"
              />

              <Grid item md={6} xs={12}>
                <Grid container columnSpacing={1} rowSpacing={0}>
                  <Grid item lg={8} xs={12}>
                    <InputText
                      type="url"
                      name="media"
                      placeholder="YouTube url"
                      maxLength={1000}
                      value={checkUrl(EpisodeFormStore.mediaUrl)}
                      label="Episode media"
                      onChange={(e: any) => onChangeInputMedia(e)}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <InputFile
                      name="media"
                      label="Upload media"
                      style={{ width: "100%", marginTop: 24 }}
                      type="file"
                      accept="application/x-mpegURL, video/mp4, video/x-m4v, video/*, audio/*"
                      onChange={(e: any) => onChangeVideo(e)}
                      isDisabled={isLoadingVideo}
                    >
                      Upload video
                    </InputFile>
                  </Grid>
                </Grid>

                {isLoadingVideo && (
                  <Stack
                    style={{ marginTop: "125px", marginBottom: "40px" }}
                    alignItems="center"
                  >
                    <CircularProgress color="primary" size={100} />
                    {<p>{"Please wait, loading media..."}</p>}
                  </Stack>
                )}

                {((EpisodeFormStore.rawData || EpisodeFormStore.mediaUrl) && !isLoadingVideo) && (
                  <VideoItem
                    data-testid="podcast-media-player"
                    style={{ marginTop: 20, ...podcastIcon }}
                  >
                    {isM3u8 ? (
                      <ReactHlsPlayer
                        src={media}
                        autoPlay={false}
                        controls={true}
                        playerRef={player}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <ReactPlayer
                        url={media}
                        onDuration={onDurationHandler}
                        controls={true}
                        width="100%"
                        height="100%"
                      />
                    )}
                  </VideoItem>
                )}
              </Grid>

              <TextField
                fullWidth
                label="Text"
                variant="outlined"
                multiline
                sx={{ marginBottom: 3, marginTop: 4 }}
                inputProps={{ maxLength: 3000 }}
                helperText={`${EpisodeFormStore.text.length} / 3000`}
                rows={4}
                name="text"
                onChange={onTextChange}
                data-testid="episode-text"
                value={EpisodeFormStore.text}
              />

              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                  type={"submit"}
                  variant={"contained"}
                  size={"large"}
                  data-testid="save-law-article-button"
                  disabled={EpisodeFormStore.isNotReadyToSend || isLoading}
                >
                  {EpisodeFormStore.saveButtonTitle}
                </Button>

                <Button
                  type={"button"}
                  onClick={() => onBack()}
                  size={"large"}
                  color="error"
                  sx={{ marginLeft: 2 }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </CardBox>
      </form >
    );
  };

  return (
    <F.Wrap>
      {renderComponent()}

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={EpisodeFormStore.modalTitle}
          disabled={isLoading}
          disableStates={[setOpen]}
          callback={async () => handleUpload()}
        />
      )}
    </F.Wrap>
  );
});

export default EpisodeForm;
