import { useHttp } from "../../hooks/useHttp";
import { removeEmpty } from "../../utils/removeEmpty";
import { IGetList } from "../../types/type";

const useFetchAdmin = () => {
  const { loading, request, error, clearError } = useHttp();

  const getAdmin = async ({ page, rowsPerPage, searchValue }: IGetList) => {
    const res = await request('get', 'api/getadmins', {
      params: removeEmpty({
        page,
        rowsPerPage,
        searchValue: removeEmpty(searchValue),
      })
    });
    return {
      amount: res.amount,
      list: res.list
    };
  };

  const getAdminTypes = async () => {
    return await request('get', 'api/check/adminSubTypes');
  };

  const addAdmin = async (data: object) => {
    return await request('post', 'api/create/admin', {
      adminCredentials: data
    });
  };

  const deleteAdmin = async (id: string) => {
    return await request('post', 'api/deleteadmin', { id });
  };

  const resetPasswordAdmin = async (email: string) => {
    return await request('post', 'api/resendAdminPasswordReset', { email });
  };

  return {
    loading, 
    error, 
    clearError,
    getAdmin,
    getAdminTypes,
    addAdmin,
    deleteAdmin,
    resetPasswordAdmin
  };
};

export default useFetchAdmin;