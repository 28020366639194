import React, { useState } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { observer } from "mobx-react-lite";
import { IActionItemProps } from "../../../types/type";
import PartiesStore from "../../../store/PartiesStore";
import closeTableWindow from '../../../utils/closeTableWindow';

const PartiesAction = observer(({
  id,
  onDelete,
  onOpen
}: IActionItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    handleClose();
    setOpen(true);
  };

  const handleDelete = async () => {
    PartiesStore.removeItemLocally(id);
    PartiesStore.setInQueue(id);

    onDelete(id).finally(() => PartiesStore.removeFromQueue(id));
  };

  return (
    <>
      <ActionMenuButton dataTestId={`table-open-menu-${id}`} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem data-testid={`edit-party-button-${id}`} onClick={closeTableWindow(id, onOpen, setAnchorEl)}>
          <EditIcon /> Edit
        </MenuItem>
        <MenuItem data-testid={`delete-party-button-${id}`} onClick={handleDeleteConfirm}>
          <DeleteIcon color={'error'} /> Delete
        </MenuItem>
      </ActionMenuButton>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={"Are you sure to delete this party"}
          disableStates={[setOpen]}
          callback={async () => handleDelete()}
        />
      )}
    </>
  );
});

export default PartiesAction;