import styled, { ThemeProps } from "styled-components";
import { Skeleton, Theme, Typography } from "@mui/material";

export const Wrap = styled.div`
  width: 920px;
  max-width: 100%;
  display: block;
`;

export const SkeletonParty = styled(Skeleton)`
  border-radius: 5px;
  width: 920px;
  height: 601px;
  max-width: 100%;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;

  & > div {
    width: 100%;
  }

  & div[data-testid='selected-district'] {
    background: #fff;

    & span {
      color: "#000";
    }
  }
`;

export const Text = styled(Typography)`
  cursor: pointer;

  &:hover, &:active {
    opacity: .6;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;