import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const Form = styled.form`
  width: 100%;
  padding-top: 0px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 18%;
  max-width: 50%;
  word-break: break-all;
  margin: 30px;
  border-radius: 15px;
  padding: 10px;
  background-color: ${(props: ThemeProps<Theme>) => {
    return props.theme.palette.mode === "dark" ? "#fff" : props.theme.palette.background.paper;
  }};
  color: #000;
`;

export const MessageText = styled.p`
  margin: 0px;
  margin-top: 15px;
`;

export const MessageTitle = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin: 0px;
  }
`;

export const ChatWindow = styled.div`
  height: 65vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid #E7EBF0;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: scroll;
  overflow-x: hidden;
`;