import { makeAutoObservable } from "mobx";

class WarningStore {
  isEdited: boolean = false;
  isModalOpen: boolean = false;
  modalTitle: string = "Are you sure to leave this page? Your current data will not be saved";
  navPoint: any = "";

  constructor() {
    makeAutoObservable(this);
    this.toggleModal = this.toggleModal.bind(this);
  };

  resetStorage() {
    this.setIsEdited(false);
    this.toggleModal(false);
    this.navPoint = "";
  };

  setNavPoint(point: any) {
    this.navPoint = point;
  };

  toggleModal(state: boolean) {
    if (this.isModalOpen !== state) {
      this.isModalOpen = state;
    };
  };

  setIsEdited(state: boolean) {
    if (this.isEdited !== state) {
      this.isEdited = state;
    };
  };
};

export default new WarningStore();