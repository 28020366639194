import { makeAutoObservable } from "mobx";

class LogsStore {
  list: any[] = [];
  page: number = 1;
  rowsPerPage: number = 5;
  _filled: boolean = false;
  _onceRendered: boolean = false;
  nextKey?: string | null;
  prevKey?: string | null;
  amount: number = 0;
  disabled: boolean = false;
  logData: any = {};
  _error: boolean = false;
  searchValue: { [key: string]: string } = {
    author: "",
    date: "",
  };

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  }

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [
        this.page,
        this.rowsPerPage,
        this.searchValue,
        this.nextKey,
        this.prevKey,
      ];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
      searchValue: this.searchValue,
      nextKey: this.nextKey,
      prevKey: this.prevKey,
    };
  }

  resetLogData() {
    this.logData = {};
  }

  get isStorageEmpty() {
    return this.list.length === 0;
  }

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  }

  resetStorage() {
    this.disabled = false;
    this.page = 1;
    this.rowsPerPage = 5;
    this.amount = 0;
    this._filled = false;
    this._error = false;
  }

  setError() {
    this._error = true;
  }

  get noErrors() {
    return !this._error;
  }

  setLogData(data: any) {
    this.logData = data;
    // if (this.logData?.url === "/create/lawBulkImport" || this.logData?.url === "/create/bulkEditLaws") {
    //   delete this.logData.body;
    // }
  }

  get isFilled() {
    return this._filled;
  }

  get isRendered() {
    return this._onceRendered;
  }

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  }
  updatenextKey() {
    if (this.list.length > 0 && this.list[this.list.length - 1]["id"])
      this.nextKey = this.list[this.list.length - 1].id;
  }

  setList = (newList: any) => {
    this.list = newList;
    this._filled = true;
    console.log("prevKey", this.prevKey);
  };

  setPage = (page: number) => {
    console.log("n,p", this.nextKey, this.prevKey);
    if (this.page > page) this.nextKey = this.prevKey;
    else this.updatenextKey();
    this.prevKey = this.list[0].id;
    this.page = page;
    this._filled = false;
  };

  setRowsPerPage = (rows: number) => {
    this.rowsPerPage = rows;
    this.page = 1;
    this.nextKey = null;
    this.prevKey = null;
    this._filled = false;
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

  setSearchValue = (obj: { [key: string]: string }) => {
    if (!this.disabled) {
      this.searchValue = obj;
      this._filled = false;
    }
  };
}

export default new LogsStore();
