import React, { useEffect, useState } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import { Button, TextField } from "@mui/material";
import * as F from "./NewsFormStyle";
import Dropzone from "../../../UI/Dropzone/Dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IForm, IDataNews } from "../../../types/type";
import { observer } from "mobx-react-lite";
import NewsFormStore from "../../../store/NewsFormStore";
import CustomCheckbox from "../../../common/CustomCheckbox";
import WarningStore from "../../../store/WarningStore";

const schema = yup
  .object({
    title: yup.string().required().max(200),
    text: yup.string().required().max(6000),
  })
  .required();

const NewsForm = observer(({ onAddEdit, onBack, isLoading }: IForm) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useForm<IDataNews>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const initiateData = NewsFormStore.getData();
    reset({
      title: initiateData.title,
      text: initiateData.text,
    });
  }, [NewsFormStore.title, NewsFormStore.text]);

  const changeFile = ({ resizedImage, fileName, image }) => {
    WarningStore.setIsEdited(true);

    NewsFormStore.setData("image", image);
    NewsFormStore.setData("thumbnail", resizedImage);
    NewsFormStore.setData("fileName", fileName);
  };

  const onTextChange = (e: any) => {
    WarningStore.setIsEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    NewsFormStore.setData(name, value);
    setValue(name, value);
  };

  useEffect(() => {
    if (NewsFormStore.sendPush && NewsFormStore.title.length > 100) {
      NewsFormStore.setData("title", NewsFormStore.title.slice(0, 100));
    }
  }, [NewsFormStore.sendPush]);

  const onSubmit: SubmitHandler<IDataNews> = (dataForm) =>
    setIsConfirmModalOpen(true);

  return (
    <F.Wrap>
      {isLoading ? (
        <F.PlaceholderContainer>
          <p>{NewsFormStore.loadingDescription}</p>
          <F.SkeletonParty variant="rectangular" animation="wave" />
        </F.PlaceholderContainer>
      ) : NewsFormStore.noErrors ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardBox>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              sx={{ marginBottom: 3 }}
              name="title"
              onChange={onTextChange}
              value={NewsFormStore.title}
              inputProps={NewsFormStore.sendPush ? { maxLength: 100 } : {}}
              helperText={NewsFormStore.sendPush ? `${NewsFormStore.title.length} / 100` : ""}
            />

            <Dropzone
              accept={"image/jpg,image/jpeg,image/png"}
              margin={3}
              file={NewsFormStore.image}
              ratio={"4:3"}
              changeFile={changeFile}
              shouldResize={true}
            />

            <TextField
              fullWidth
              label="Text"
              multiline
              sx={{ marginBottom: 3 }}
              name="text"
              helperText={`${NewsFormStore.text.length}/6000`}
              inputProps={{ maxLength: 6000 }}
              rows={4}
              onChange={onTextChange}
              value={NewsFormStore.text}
            />

            {NewsFormStore.isCreateModeOn && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomCheckbox
                  value={NewsFormStore.sendPush}
                  name="sendPush"
                  style={{ textAlign: "center" }}
                  onChange={(e) =>
                    NewsFormStore.setData("sendPush", e.target.checked)
                  }
                />
                <p>Send push for everyone?</p>
              </div>
            )}

            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!isValid || isLoading || !NewsFormStore.image}
            >
              {NewsFormStore.saveButtonTitle}
            </Button>

            <Button
              type="button"
              onClick={() => onBack()}
              size="large"
              color="error"
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </CardBox>
        </form>
      ) : (
        <CardBox style={{ textAlign: "center" }}>
          <ErrorIcon color={"error"} />
          <p>There is no news with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </CardBox>
      )}

      {isConfirmModalOpen && (
        <ModalConfirm
          setActive={setIsConfirmModalOpen}
          windowTitle={NewsFormStore.modalTitle}
          disabled={isLoading}
          disableStates={[setIsConfirmModalOpen]}
          callback={async () => {
            WarningStore.resetStorage();
            onAddEdit();
          }}
        />
      )}
    </F.Wrap>
  );
});

export default NewsForm;
