import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import * as T from "../TablesStyle";
import { NameAvatar } from "../TablesStyle";
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import { observer } from "mobx-react-lite";
import DashboardStore from "../../../store/DashboardStore";
import { translateDistrict } from "../../../utils/i18n";
import { adminType } from "../../../api/network";
import { useEffect, useState } from "react";
import { AdminTypes } from "../../../utils/adminType";
import { IDashboardProps } from "../../../types/type";
import DashboardActions from "./DashboardActions";
import FeedIcon from '@mui/icons-material/Feed';
import setBlur from "../../../utils/setBlur";
import EmptyRow from '../../../common/EmptyTableRow/EmptyTableRow';
import isStorageLoading from '../../../utils/isStorageLoading';

const DashboardTable = observer(({
  partyList,
  onDelete,
  onSuspend,
  onActivate,
  onOpen
}: IDashboardProps): JSX.Element => {
  const currentRoles = adminType();
  const [colNumber, setColNumber] = useState(6);

  const rowsEntries = Array(DashboardStore.rowsPerPage).fill(0);
  const colSpan = Array(colNumber).fill(0);

  useEffect(() => {
    adminType().map((type: string) => {
      if (type === AdminTypes.spectator) {
        setColNumber(5);
      }
    });
  }, []);

  const renderParty = (code: number) => {
    if (partyList?.length && DashboardStore.list.length) {
      return partyList.map((item, index) => {
        if (item.code === code) {
          return (
            <p key={index} style={{ margin: "0 auto", width: "max-content" }}>
              {item.name}
            </p>
          );
        }
      })
    }
  };

  const shouldRenderActions = () => {
    return (
      currentRoles.includes(AdminTypes.superAdmin) ||
      (currentRoles.includes(AdminTypes.admin) &&
        (currentRoles.includes(AdminTypes.canEditOtherCandidates) ||
          currentRoles.includes(AdminTypes.canSuspendCandidates)))
    );
  };

  const renderRows = () => {
    if (isStorageLoading(DashboardStore) ||
      (DashboardStore.list.length === 0 && DashboardStore.isFilled)) {
      return (<LoadingTable loading={DashboardStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (DashboardStore.isStorageEmpty) {
      return (<EmptyRow columns={6} />);
    };

    return DashboardStore.list.map((row, index) => {
      if (row === null) {
        return (<LoadingRow key={index} colSpan={colSpan} />);
      }

      return (<T.TRow key={row.id}>
        <TableCell align="left">
          <NameAvatar
            src={row.avatar}
            name={`${row.firstName} ${row.lastName}`}
          />
        </TableCell>
        <TableCell align="center">{row.electionCode}</TableCell>
        <TableCell align="center">{row.mfa}</TableCell>
        <TableCell align="center">
          {translateDistrict(row.district)}
        </TableCell>
        <TableCell style={{ padding: 0 }} align="center">{renderParty(+row.party)}</TableCell>
        {shouldRenderActions() && (
          <TableCell style={{ display: "flex" }} align="right">
            {(currentRoles.includes(AdminTypes.superAdmin) ||
              (currentRoles.includes(AdminTypes.admin) &&
                currentRoles.includes(AdminTypes.canEditOtherCandidates))) && (
                <T.ButtonTable
                  style={row.nextInfo ? { backgroundColor: "#c62828" } : {}}
                  data-testid={`open-profile-button-${row.id}`}
                  onClick={() => onOpen(row.id)}
                >
                  <FeedIcon style={{ marginRight: 5 }} />
                  {DashboardStore.getOpenButtonTitle(row.nextInfo)}
                </T.ButtonTable>
              )}

            <DashboardActions
              id={row.id}
              onDelete={onDelete}
              onSuspend={onSuspend}
              onActivate={onActivate}
            />
          </TableCell>
        )}
      </T.TRow>);
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Election&nbsp;code</TableCell>
            <TableCell align="center">MFA</TableCell>
            <TableCell align="center">District</TableCell>
            <TableCell align="center">Party</TableCell>
            {shouldRenderActions() && (
              <TableCell align="center">Action</TableCell>
            )}
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(DashboardStore)}>
          {renderRows()}
        </TableBody>
      </Table>
    </T.TContainer>
  );
}
);

export default DashboardTable;
