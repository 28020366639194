import { makeAutoObservable } from "mobx";

class LawStore {
  list: any = [];
  page: number = 1;
  rowsPerPage: number = 5;
  amount: number = 0;
  disabled: boolean = false;
  visible: boolean = false;
  lawData: any = {};
  bulk: any = {
    uploadedFile: {},
    upFileSCV: [],
    importResult: [],
    isFileSelected: false,
    uploadedFileName: {},
    errorValidationFile: null,
    resultXLS: null,
    upFileCSV: null,
    isBulkButtonActive: false,
  };
  bulkExportData: any = {
    laws: [],
    fetchedLaws: [],
    uploadedFile: {},
    upFileSCV: {},
    errorValidationFile: null,
    searchValue: {
      lawTitle: "",
      lawArticleTitle: "",
      issuer: "",
      lawEnforcement: "",
      legislationNumber: "",
      legislationType: "",
      source: "",
      category: "",
    },
    amount: 0,
    xls: null,
  };
  searchValue: { [key: string]: string } = {
    lawTitle: "",
    issuer: "",
    lawEnforcement: "",
    legislationNumber: "",
    issueNumber: "",
    issueDate: "",
    legislationDate: "",
    note: "",
    legislationType: "",
    source: "",
  };

  _itemsInAction: Set<string> = new Set();
  _error: boolean = false;
  _filled: boolean = false;
  _onceRendered: boolean = false;
  _timeMark: number = 0;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  }

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  }

  setTimeMark(mark: number) {
    this._timeMark = mark;
  }

  get lastUserRequest() {
    return this._timeMark;
  }

  setInQueue(id: string) {
    this._itemsInAction.add(id);
  }

  removeFromQueue(id: string) {
    this._itemsInAction.delete(id);
  }

  get isRequireToUpdate() {
    return (
      this.list.includes(null) &&
      this._itemsInAction.size === 0 &&
      !this.disabled
    );
  }

  get isStorageEmpty() {
    return this.list.length === 0;
  }

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [this.page, this.rowsPerPage, this.searchValue];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
      searchValue: this.searchValue,
    };
  }

  removeItemLocally(uid: string) {
    this.list = this.list.filter((item) => item?.id !== uid);
    this.list.push(null);
  }

  setImportResult(data: any) {
    this.bulk.importResult = data;
  }

  //bulk edit functions
  get isExportDataFetched() {
    return this.bulkExportData.laws.length === 0;
  }

  setBulkExportData(field: string, data: any) {
    this.bulkExportData[field] = data;
  }

  setTableSearchValue = (obj: { [key: string]: string }) => {
    this.searchValue = obj;
    this._filled = false;
  };

  setSearchValue(data: any) {
    this.bulkExportData.searchValue = {
      ...this.bulkExportData.searchValue,
      ...data,
    };
  }

  setBulkExportFetchedList(data: any) {
    this.bulkExportData.fetchedLaws = data;
  }

  //bulk import functions
  setBulkData(field: string, data: any) {
    this.bulk[field] = data;
  }

  //laws table functions
  setLawData(law: any) {
    this.lawData = law;
  }

  setData(field: string, value: any) {
    this[field] = value;
  }

  setError() {
    this._error = true;
  }

  resetLawData() {
    this.lawData = {};
  }

  get isFilled() {
    return this._filled;
  }

  get isRendered() {
    return this._onceRendered;
  }

  get noErrors() {
    return !this._error;
  }

  get pageTitle() {
    return this.lawData.title ?? "Law";
  }

  resetPagination() {
    this.page = 1;
  }
  resetStorage() {
    this.rowsPerPage = 5;
    // this.page = 1;
    this.disabled = false;
    this._filled = false;
    this._timeMark = 0;
    this._itemsInAction = new Set();
    this.visible = false;
    this.bulkExportData = {
      laws: [],
      fetchedLaws: [],
      uploadedFile: {},
      searchValue: {
        lawTitle: "",
        lawArticleTitle: "",
        issuer: "",
        lawEnforcement: "",
        legislationNumber: "",
        legislationType: "",
        source: "",
      },
      errorValidationFile: null,
      upFileSCV: {},
      xls: null,
      amount: 0,
    };
    this.bulk = {
      uploadedFile: {},
      upFileSCV: [],
      importResult: [],
      isFileSelected: false,
      uploadedFileName: {},
      errorValidationFile: null,
      resultXLS: null,
      upFileCSV: null,
      isBulkButtonActive: false,
    };
    this.searchValue = {
      lawTitle: "",
      issuer: "",
      lawEnforcement: "",
      legislationNumber: "",
      issueNumber: "",
      issueDate: "",
      legislationDate: "",
      note: "",
      legislationType: "",
      source: "",
    };
  }

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  }

  setList = (newList: any) => {
    this.list = newList;
    this._filled = true;
  };

  setPage = (page: number) => {
    if (!this.disabled) {
      this.page = page;
      this._filled = false;
    }
  };

  setRowsPerPage = (rows: number) => {
    if (!this.disabled) {
      this.rowsPerPage = rows;
      this.page = 1;
      this._filled = false;
    }
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }
}

export default new LawStore();
