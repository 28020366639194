const getStats = async (fetcher: Function, storage: any, subStorage?: any) => {
  storage.disable();

  if (storage.setTimeMark) {
    storage.setTimeMark(Date.now());
  }

  const setData = (list: any, stats: any, amountFiltered: number) => {
    storage.setList(list);
    storage.setStats(stats);
    storage.setAmountFiltered(amountFiltered);
  };

  const { list, stats, amount, amountFiltered } = await fetcher(
    storage.getSettings()
  );

  setData(list, stats, amountFiltered);

  storage.enable();
};

export default getStats;
