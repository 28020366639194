import styled from "styled-components";

export const ComplaintWrapper = styled.div`
  display: flex;
  height: 260px;
  width: 250px;
  flex-direction: column;
  justify-content: center;
  z-dndex: 1919001;

  & p {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
  }
`;

export const ComplaintContent = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #F8F8F8;
  border-radius: 6px;
`;