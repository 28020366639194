import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";
import { removeEmpty } from "../../utils/removeEmpty";

const useFetchLogs = () => {
  const { loading, request, error, clearError } = useHttp();

  const getLogs = async ({
    page,
    rowsPerPage,
    searchValue,
    nextKey,
    prevKey,
  }: IGetList) => {
    const res = await request("get", "api/check/logs", {
      params: {
        page,
        rowsPerPage,
        searchValue: removeEmpty(searchValue),
        nextKey,
        prevKey,
      },
    });
    return {
      amount: res.amount,
      list: res.list,
    };
  };

  const getLog = async (id: string | undefined) => {
    return await request("get", `api/check/logs/${id}`);
  };

  return {
    loading,
    error,
    clearError,
    getLogs,
    getLog,
  };
};

export default useFetchLogs;
