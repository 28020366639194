import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as T from "../TablesStyle";
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import { observer } from "mobx-react-lite";
import SurveysStore from "../../../store/SurveysStore";
import { ISurveyTableProps } from "../../../types/type";
import SurveysActions from "./SurveysActions";
import setBlur from "../../../utils/setBlur";
import EmptyRow from "../../../common/EmptyTableRow/EmptyTableRow";
import isStorageLoading from "../../../utils/isStorageLoading";
import getSlicedString from "../../../utils/getSlicedString";

const SurveysTable = observer(
  ({ onDelete, onOpen, openStatistic }: ISurveyTableProps): JSX.Element => {
    const rowsEntries = Array(SurveysStore.rowsPerPage).fill(0);
    const colSpan = Array(4).fill(0);

    const renderRows = () => {
      if (isStorageLoading(SurveysStore)) {
        return (
          <LoadingTable
            loading={SurveysStore.disabled}
            rowsEntries={rowsEntries}
            colSpan={colSpan}
          />
        );
      }

      if (SurveysStore.isStorageEmpty) {
        return <EmptyRow columns={4} />;
      }

      return SurveysStore.list.map((row, index) => {
        if (row === null) {
          return <LoadingRow key={index} colSpan={colSpan} />;
        }

        return (
          <T.TRow key={row.id}>
            <TableCell align="left">{row.date}</TableCell>
            <TableCell align="left">{row.expirationDate}</TableCell>
            <TableCell align="left">{getSlicedString(row.title, 80)}</TableCell>
            <TableCell align="left">{getSlicedString(row.text, 80)}</TableCell>
            <TableCell align="center">
              <SurveysActions
                id={row.id}
                onOpen={onOpen}
                openStatistic={openStatistic}
                onDelete={onDelete}
              />
            </TableCell>
          </T.TRow>
        );
      });
    };

    return (
      <T.TContainer>
        <Table>
          <T.THead>
            <TableRow>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Expiration date</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Text</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </T.THead>
          <TableBody style={setBlur(SurveysStore)}>{renderRows()}</TableBody>
        </Table>
      </T.TContainer>
    );
  }
);

export default SurveysTable;
