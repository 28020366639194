import { makeAutoObservable } from "mobx";
import { ISurveysArray } from "../types/type";

class SurveysStore {
  list: Array<ISurveysArray | null> = [];
  page: number = 1;
  rowsPerPage: number = 5;
  amount: number = 0;
  disabled: boolean = false;

  _filled: boolean = false;
  _onceRendered: boolean = false;
  _timeMark: number = 0;
  _itemsInAction: Set<string> = new Set();

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  }

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  }

  setTimeMark(mark: number) {
    this._timeMark = mark;
  }

  get lastUserRequest() {
    return this._timeMark;
  }

  setInQueue(id: string) {
    this._itemsInAction.add(id);
  }

  removeFromQueue(id: string) {
    this._itemsInAction.delete(id);
  }

  get isRequireToUpdate() {
    return (
      this.list.includes(null) &&
      this._itemsInAction.size === 0 &&
      !this.disabled
    );
  }

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [this.page, this.rowsPerPage];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
    };
  }

  get isStorageEmpty() {
    return this.list.length === 0;
  }

  removeItemLocally(uid: string) {
    this.list = this.list.filter((item) => item?.id !== uid);
    this.list.push(null);
  }

  get isFilled() {
    return this._filled;
  }

  get isRendered() {
    return this._onceRendered;
  }

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  }

  resetStorage() {
    this.page = 1;
    this.rowsPerPage = 5;
    this.disabled = false;
    this._filled = false;
    this._timeMark = 0;
    this._itemsInAction = new Set();
  }

  setList = (newList: ISurveysArray[]) => {
    this.list = newList;
    this._filled = true;
  };

  setPage = (page: number) => {
    if (!this.disabled) {
      this.page = page;
      this._filled = false;
    }
  };

  setRowsPerPage = (rows: number) => {
    if (!this.disabled) {
      this.rowsPerPage = rows;
      this.page = 1;
      this._filled = false;
    }
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }
}

export default new SurveysStore();
