import React from "react";
import { observer } from "mobx-react-lite";
import ComplaintsStore from "../../../store/ComplaintsStore";
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import * as T from "../TablesStyle";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { translateDistrict } from "../../../utils/i18n";
import ComplaintsAction from "./ComplaintsAction";
import formatName from "../../../utils/formatName";
import setBlur from "../../../utils/setBlur";
import EmptyRow from "../../../common/EmptyTableRow/EmptyTableRow";
import { TableCellTextContainer } from "../TablesStyle";
import { IComplainstTableProps } from "../../../types/type";
import isStorageLoading from "../../../utils/isStorageLoading";

const ComplaintsTable = observer(
  ({
    onApprove,
    onReject,
    onOpen,
    onDelete,
  }: IComplainstTableProps): JSX.Element => {
    const rowsEntries = Array(5).fill(0);
    const colSpan = Array(6).fill(0);

    const formatRecord = (record: string) => {
      if (!record) {
        return "-";
      }

      return record.length <= 75 ? record : record.slice(0, 75) + "...";
    };

    const renderRows = () => {
      if (isStorageLoading(ComplaintsStore)) {
        return (
          <LoadingTable
            loading={ComplaintsStore.disabled}
            rowsEntries={rowsEntries}
            colSpan={colSpan}
          />
        );
      }

      if (ComplaintsStore.isStorageEmpty) {
        return <EmptyRow columns={6} />;
      }

      const rows = ComplaintsStore.list.map((row, index) => {
        if (row === null) {
          return <LoadingRow key={index} colSpan={colSpan} />;
        }

        return (
          <T.TRow key={row?.uid}>
            <TableCell align="left">
              {new Date(row.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell align="left">
              {translateDistrict(row.district)}
            </TableCell>
            <TableCell align="left">
              <TableCellTextContainer>
                {row.newCategory
                  ? `NEW: ${formatRecord(row.newCategory)}`
                  : formatRecord(row.category)}
              </TableCellTextContainer>
            </TableCell>
            <TableCell align="left">
              <TableCellTextContainer>
                {formatRecord(row.initialMessage)}
              </TableCellTextContainer>
            </TableCell>
            <TableCell align="left">
              {formatName(row?.firstName)} {formatName(row?.lastName)}
            </TableCell>
            <TableCell align="center">
              <ComplaintsAction
                id={row.uid}
                onApprove={onApprove}
                onReject={onReject}
                onOpen={onOpen}
                onDelete={onDelete}
              />
            </TableCell>
          </T.TRow>
        );
      });

      return rows;
    };

    return (
      <T.TContainer>
        <Table>
          <T.THead>
            <TableRow>
              <TableCell align="left">DATE</TableCell>
              <TableCell align="left">District</TableCell>
              <TableCell align="left">Category name</TableCell>
              <TableCell align="left">Complaint</TableCell>
              <TableCell align="left">MP</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </T.THead>
          <TableBody style={setBlur(ComplaintsStore)}>{renderRows()}</TableBody>
        </Table>
      </T.TContainer>
    );
  }
);

export default ComplaintsTable;
