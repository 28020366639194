import { makeAutoObservable } from "mobx";

class EpisodeFormStore {
  title: string = "";
  fileName: string = "";
  mediaUrl: string = "";
  text: any = "";
  duration: any = {};
  isVideo: boolean = false;
  currentId: string = "";
  podcastId: string = "";
  rawData: any = null;
  podcastIcon: string = "";

  episodeData: any = {};
  _currentMode: string = "create";
  _error: boolean = false;
  _oldFileName: string = "";
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  };

  resetEpisodeData() {
    this.episodeData = {};
  };

  setOldFileName(oldFileName: string) {
    this._oldFileName = oldFileName;
  };

  get oldFileName() {
    return this._oldFileName;
  };

  setEpisodeData(data: any) {
    this.episodeData = data;
  };

  get modalTitle() {
    return this.isCreateModeOn
      ? "Are you sure you want to create a new podcast episode?"
      : "Are you sure you want to edit podcast episode?";
  }

  get pageTitle() {
    return this.isCreateModeOn
      ? "Add podcast episode"
      : "Edit podcast episode";
  }

  get saveButtonTitle() {
    return this.isCreateModeOn
      ? "Add podcast episode"
      : "Edit podcast episode";
  }

  getData() {
    const excludeList = [
      "podcastId",
      "rawData",
      "episodeData",
      "podcastIcon",
      "views",
      "likes",
    ];

    if (!this.isCreateModeOn) {
      excludeList.push("currentId")
    }

    if (!this.fileName) {
      this.fileName = this._oldFileName;
    }

    const data = Object.fromEntries(Object
      .entries(this)
      .filter((item) => !(item[0].includes("_") || excludeList.includes(item[0])))
    );

    return data;
  }

  setMode(mode: string) {
    this._currentMode = mode;
  }

  get isCreateModeOn() {
    return this._currentMode === "create";
  }

  get noErrors() {
    return !this._error;
  }

  setError() {
    this._error = true;
  }

  get episodeTitle() {
    if (this.episodeData.title) {
      const title = this.episodeData.title;
      return title[0].toUpperCase() + title.slice(1);
    };
    return "";
  };

  setData(field: string, data: any) {
    this[field] = data;
  }

  get isNotReadyToSend() {
    return !(this.title && this.text && (this.mediaUrl || this.fileName));
  };

  resetStorage() {
    const excludeList = [
      "title",
      "fileName",
      "_currentMode",
      "_error",
      "episodeData",
      "mediaUrl",
      "text",
      "duration",
      "isVideo",
      "_oldFileName",
      "currentId",
      "podcastId",
      "rawData",
      "podcastIcon",
    ];

    this.rawData = null;
    this.currentId = "";
    this.podcastIcon = "";
    this.podcastId = "";
    this.title = "";
    this.mediaUrl = "";
    this.fileName = "";
    this.isVideo = false;
    this._currentMode = "create";
    this.episodeData = {};
    this.duration = 0;
    this._error = false;
    this.text = "";
    this._oldFileName = "";

    Object
      .entries(this)
      .forEach((item) => {
        const key = item[0];
        if (!excludeList.includes(key)) {
          delete this[key];
        }
      });
  };

  initiate(data: any) {
    Object.entries(data).forEach((item) => {
      const key = item[0];
      const value: any = item[1];
      this[key] = value;
    })
  };
};

export default new EpisodeFormStore();
