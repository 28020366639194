import styled from "styled-components";
import { TextField } from "@mui/material";

export const FieldWithoutStyles = styled(TextField)`
  & input {
    &::-ms-reveal {
      display: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px #edeef0 inset !important;
    }
`;

export const Wrap = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  flex-direction: column;
`

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    font-weight: 600;
  }
`

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
`

export const Form = styled.form`
  width: 345px;
  max-width: 100%;
`