import styled from "styled-components";
import { Grid } from "@mui/material";

export const Form = styled.form`
  padding-top: 45px;
`;

export const GridHr = styled(Grid)`
  hr {
    border-style: solid;
    border-bottom: 0;
    border-color: #dedede;
  }
`;

export const DuplicateItem = styled.p`
  min-width: 125px;
`;

export const ResetButton = styled.span`
  position: absolute;
  top: 45px;
  right: 0;
  cursor: pointer;
  color: #fb3640;
  userselect: none;

  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
`;