import { makeAutoObservable } from "mobx";

class SurveyStatsStore {
  title: string = "";
  text: string = "";
  answers: any = null;
  variants: any[] = [];
  answerType: number | null = null;
  stats: any[] | undefined = undefined;
  disabled: boolean = false;

  _calculating: boolean = false;
  _error: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
    this.resetData = this.resetData.bind(this);
  };

  setError() {
    this._error = true;
  };

  get isFailed() {
    return this._error;
  };
  
  get isCalculating() {
    return this._calculating;
  };

  setIsCalculating(state: boolean) {
    this._calculating = state;
  };

  setData(data: any) {
    Object
      .entries(data)
      .forEach(([key, value]) => {
        this[key] = value;
      });
  };

  disable() {
    this.disabled = true;
  };

  enable() {
    this.disabled = false;
  };
  
  setStats(stats: any[]) {
    this.stats = stats;
    this.setIsCalculating(false);
  };

  resetData() {
    this.title = "";
    this.text = "";
    this.answers = null;
    this.variants = [];
    this.answerType = null;
    this.stats = undefined;
    this.disabled = false;
    this._calculating = false;
  };
};

export default new SurveyStatsStore();