import styled from "styled-components";
import { Skeleton } from "@mui/material";

export const Wrap = styled.div`
  width: 530px;
  max-width: 100%;
  display: block;
`;

export const SkeletonParty = styled(Skeleton)`
  border-radius: 5px;
  width: 530px;
  height: 601px;
  max-width: 100%;
`;