import React, { useState } from 'react';
import { CardBox } from "../../../common/Layout/LayoutStyle";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { observer } from "mobx-react-lite";
import AdminFormStore from "../../../store/AdminFormStore";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { IAdminForm } from "../../../types/type";
import { IForm } from "../../../types/type";
import WarningStore from '../../../store/WarningStore';
import PartiesStore from '../../../store/PartiesStore';

const schema = yup.object({
  email: yup.string().email().required(),
  adminTypes: yup.string().required(),
  party: yup.number().when('adminTypes', {
    is: 'party manager',
    then: (schema) => schema.required(),
  }),
  adminSubType: yup.array().when('adminTypes', {
    is: 'admin',
    then: (schema) => schema.min(1),
  }),
}).required();

const AdminForm = observer(({
  onBack,
  onAddEdit,
  isLoading
}: IForm): JSX.Element => {
  const { register, handleSubmit, watch, reset, formState: { isValid } } = useForm<IAdminForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [adminCredentials, setAdminCredentials] = useState<object>();

  const onSubmit: SubmitHandler<IAdminForm> = data => {
    const roles = [data.adminTypes];

    const newAdminCredentials = {
      email: data.email,
      password: '',
      adminType: data.adminTypes,
      partyManagerCode: (data.party && data.adminTypes === "party manager") ? data.party : undefined,
      roles: (data.adminSubType && data.adminTypes === "admin") ? roles.concat(data.adminSubType) : roles
    };

    setAdminCredentials(adminCredentials => ({ ...adminCredentials, ...newAdminCredentials }));
    setIsConfirmModalOpen(true);
  };

  const createAdmin = () => {
    if (adminCredentials) {
      onAddEdit(adminCredentials);
    };
  };

  const renderAdminSubTypes = () => {
    if (watch("adminTypes") === "admin") {
      return (
        <Grid item xs={12}>
          <FormGroup>
            {AdminFormStore.adminSubTypes.map((item, index) => {
              const titleCut = item.replace(/([A-Z])/g, " $1");
              const prettyName = `${(item.charAt(0) + titleCut.slice(1)).toLowerCase()}?`;

              return (
                <FormControlLabel
                  key={index}
                  label={prettyName}
                  value={item}
                  {...register('adminSubType')}
                  control={<Checkbox />}
                />
              );
            })}
          </FormGroup>
        </Grid>
      );
    };
  };

  const renderPartySelect = () => {
    return (
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Select party</InputLabel>
          <Select
            defaultValue={111}
            label="Select party"
            {...register("party")}
          >
            {PartiesStore.allParties.map((item, index) => (
              <MenuItem key={index} value={item.code}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <CardBox style={{ width: '650px', maxWidth: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              {...register("email", {
                onChange: () => WarningStore.setIsEdited(true),
              })}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select type</InputLabel>
              <Select
                defaultValue={''}
                label="Select type"
                {...register("adminTypes", {
                  onChange: (e: any) => {
                    reset({ adminTypes: e.target.value });
                  },
                })}
              >
                {AdminFormStore.adminTypes.map((item, index) => (
                  <MenuItem key={index} value={item?.toLowerCase()}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {watch("adminTypes") === "admin" && renderAdminSubTypes()}
          {watch("adminTypes") === "party manager" && renderPartySelect()}

          <Grid item xs={6}>
            <Button
              type={"button"}
              fullWidth
              variant={"contained"}
              color={"error"}
              size={"large"}
              onClick={() => onBack()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              disabled={!isValid || isLoading}
              fullWidth
              variant="contained"
              size="large"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>

      {isConfirmModalOpen && (
        <ModalConfirm
          setActive={setIsConfirmModalOpen}
          windowTitle='Are you sure to add admin?'
          disabled={isLoading}
          disableStates={[setIsConfirmModalOpen]}
          callback={async () => {
            WarningStore.resetStorage();
            createAdmin();
          }}
        />
      )}
    </CardBox>
  );
})

export default AdminForm;