import { makeAutoObservable } from "mobx";

class AdminFormStore {
  adminTypes: Array<string> = [];
  adminSubTypes: Array<string> = [];
  _isFilled: boolean = false;

  constructor() {
    makeAutoObservable(this);
  };

  setIsFilled() {
    this._isFilled = true;
  };

  get isNotFilled() {
    return !this._isFilled;
  };

  setAdminTypes = (arr: Array<string>) => {
    this.adminTypes = arr;
  };

  setAdminSubTypes = (arr: Array<string>) => {
    this.adminSubTypes = arr;
  };
};

export default new AdminFormStore();