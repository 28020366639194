import React, { useEffect, useState } from "react";
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import LawStore from "../store/LawStore";
import LawsTable from "../components/Tables/LawsTable/LawsTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, FormGroup, FormControlLabel, Switch } from "@mui/material";
import useFetchLaws from "../api/useFetch/useFetchLaws";
import { useNavigate } from "react-router-dom";
import getLawsList from "../api/actions/getLawsList";
import ShowSubLoading from "../utils/ShowSubLoading";
import { RouteList } from "../utils/adminType";
import { TabWrap, TabBtn } from "./AddCandidatePage/AddCandidateStyle";
import BulkUploadLaws from "../components/Bulk/BulkUploadLaws";
import BulkEditLaws from "../components/Bulk/BulkEditLaws";
import FilterTable from "../components/Tables/FilterTable/FilterTable";
import i18n from "../utils/i18n";
import AlignedButtonText from "../common/Text/AlignedButtonText";

import useAdjustPage from "../hooks/useAdjustPage";
import useCompleteTableData from "../hooks/useCompleteTableData";
import useClearStorage from "../hooks/useClearStorage";
import useGetTableData from "../hooks/useGetTableData";
import useCallback from "../hooks/useCallback";

const LawsPage = observer(({ reset }: { reset?: boolean }): JSX.Element => {
  const [tab, setTab] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [locale, setLocale] = useState(i18n.language);

  const navigate = useNavigate();
  const { getLaws, getAllLaws, lawsBulkEdit, bulkAddLaws, deleteLaw } =
    useFetchLaws();
  const { loading, changeLawsVisibility } = useFetchLaws();

  useClearStorage(LawStore);

  useGetTableData(LawStore, () => getLawsList(getLaws), tab !== 1);
  useCompleteTableData(LawStore, getLaws);
  useAdjustPage(LawStore);

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
    setLocale(i18n.language === "ar" ? "en" : "ar");
  };

  useCallback(switchLanguage);

  useEffect(() => {
    getAllLaws()
      .then((res) => {
        LawStore.setBulkExportData("laws", res.list);
        LawStore.setBulkExportData("fetchedLaws", res.list);
        LawStore.setBulkExportData("amount", res.amount);
        LawStore.setData("visible", res.visible);
      })
      .finally(() => setLoadingData(false));
  }, []);

  const onTabClick = (e: any) => {
    const index = e.currentTarget.getAttribute("data-index");
    setTab(Number(index));
  };

  const switchVisibilityMode = async () => {
    const result = await changeLawsVisibility(!LawStore.visible);
    LawStore.setData("visible", result.mode);
  };

  const onEdit = (id: string) => {
    navigate(`${RouteList.editLaws}/${id}`);
  };

  const onOpen = (id: string) => {
    navigate(`${RouteList.law}/${id}`);
  };

  return (
    <div data-testid="laws-page">
      <ShowSubLoading storage={LawStore} />

      <h1>Laws list</h1>

      {!loadingData && (
        <FormGroup>
          <FormControlLabel
            disabled={loading}
            control={
              <Switch
                onChange={switchVisibilityMode}
                checked={LawStore.visible}
              />
            }
            label="Switch laws visibility"
          />
        </FormGroup>
      )}

      {tab === 1 && !loadingData && (
        <Button
          variant="contained"
          onClick={switchLanguage}
          sx={{ marginBottom: 2 }}
        >
          {`Switch language | ${locale}`}
        </Button>
      )}

      {tab === 1 && (
        <FilterTable
          searchValue={LawStore.searchValue}
          setSearchValue={LawStore.setTableSearchValue}
          setPage={LawStore.setPage}
          disabled={LawStore.disabled}
          supportMultiLanguage={true}
        />
      )}

      <TabWrap style={{ marginBottom: 15 }}>
        <TabBtn
          onClick={(e) => onTabClick(e)}
          variant="contained"
          className={tab === 1 ? "active" : ""}
          data-testid="laws-table-tab"
          data-index={1}
        >
          Laws list
        </TabBtn>
        <TabBtn
          onClick={(e) => onTabClick(e)}
          variant="contained"
          className={tab === 2 ? "active" : ""}
          data-testid="laws-import-tab"
          data-index={2}
        >
          Laws Bulk Import
        </TabBtn>
        <TabBtn
          onClick={(e) => onTabClick(e)}
          variant="contained"
          className={tab === 3 ? "active" : ""}
          data-testid="laws-edit-tab"
          data-index={3}
        >
          Laws Bulk Edit
        </TabBtn>
      </TabWrap>

      {tab === 1 && (
        <CardBox>
          <ToolbarTable>
            <ShowEntries
              rowsPerPage={LawStore.rowsPerPage}
              amount={LawStore.amount}
              setRowsPerPage={LawStore.setRowsPerPage}
              disabled={LawStore.disabled}
            />

            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              data-testid="add-law-button"
              onClick={() => navigate(RouteList.addLaw)}
            >
              <AlignedButtonText>Add law</AlignedButtonText>
            </Button>
          </ToolbarTable>

          <LawsTable onEdit={onEdit} onOpen={onOpen} onDelete={deleteLaw} />

          <Paginator
            amount={LawStore.amount}
            rowsPerPage={LawStore.rowsPerPage}
            page={LawStore.page}
            setPage={LawStore.setPage}
            disabled={LawStore.disabled}
          />
        </CardBox>
      )}

      {tab === 2 && <BulkUploadLaws onUpload={bulkAddLaws} />}
      {tab === 3 && <BulkEditLaws onUpload={lawsBulkEdit} />}
    </div>
  );
});

export default LawsPage;
