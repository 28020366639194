import React from 'react';
import { Tab, Theme } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { observer } from "mobx-react-lite";
import DashboardStore from "../../../store/DashboardStore";
import styled, { ThemeProps } from "styled-components";

const Wrap = styled.div`
  margin-left: 30px;
`;

const TabItem = styled(Tab)`
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  margin-right: 10px;
  border-radius: 5px 5px 0 0;
  
  &:hover {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
  }
  
  &.Mui-selected {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.action.hover};
  }
`;

const DashboardTabs = observer(() => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    DashboardStore.setType(newValue);
    DashboardStore.setRowsPerPage(5);
    DashboardStore.setPage(1);
  };

  return (
    <Wrap data-testid="dashboard-tabs">
      <TabContext value={DashboardStore.type}>
        <TabList onChange={handleChange}>
          <TabItem data-testid="active-tab" disabled={DashboardStore.disabled} label="Active" value="active" />
          <TabItem data-testid="suspended-tab" disabled={DashboardStore.disabled} label="Suspended" value="suspended" />
          <TabItem data-testid="invited-tab" disabled={DashboardStore.disabled} label="Invited" value="invited" />
          <TabItem data-testid="registered-tab"disabled={DashboardStore.disabled} label="Registered" value="registered" />
          <TabItem data-testid="all-tab" disabled={DashboardStore.disabled} label="All" value="all" />
        </TabList>
      </TabContext>
    </Wrap>
  );
});

export default DashboardTabs;