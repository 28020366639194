import styled, { ThemeProps } from "styled-components";
import { Theme, MenuList } from "@mui/material";

export const SelectList = styled(MenuList)`
  list-style: none;
  padding: 0;
  margin: 0 0 0 9px;

  li {
    cursor: pointer;
    padding: 0;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
    ? '#000'
    : '#fff'
  };

    &:hover {
      background: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
        ? '#F4F4F4'
        : props.theme.palette?.primary.main}; 
      }
    }
`;

export const H4 = styled.h4`
  margin: 0 0 0 9px;
  font-weight: 600;
  padding: 18px 20px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
    ? '#000'
    : '#fff'
  };
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 6px 9px;
`;

export const SelectPartyList = styled.ol`
list-style: none;
padding: 0;
margin: 0 0 0 9px;

li {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: 50%;
    margin-right: 15px;
  }

  svg {
    width: 40px;
    height: 40px;
    fill: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    margin-right: 15px;
  }

  &.active {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}
`;

export const InputSelect = styled.label`
cursor: pointer;
position: relative;
display: block;

input {
  cursor: pointer;
}

.select-label {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 12px;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.secondary.main};

  @media (max-width:700px) {
    font-size: 10px;
  }
}
`;

export const SelectModalList = styled.ol`
list-style: none;
padding: 0;
margin: 0 0 0 9px;

li {
  padding: 15px 20px;
  cursor: pointer;

  &:hover {
    background: #F4F4F4;
  }

  &.active {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    color: #fff;
  }
}
`;

export const SelectDistrictList = styled.ol`
list-style: none;
padding: 0;
margin: 0 0 0 9px;

li {
  padding: 15px 20px;
  cursor: pointer;

  &.active {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    color: #fff;
  }
}
`;