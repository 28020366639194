import { useEffect } from "react";

const useCompleteTableData = (storage, fetcher) => {
  useEffect(() => {
    if (storage.isRequireToUpdate) {
      const currentMark = Date.now();

      fetcher(storage.getSettings())
        .then(({ list, amount }) => {
          if (currentMark > storage.lastUserRequest) {
            storage.setList(list);
            storage.setAmount(amount);
          }
        });
    }
  }, [storage.isRequireToUpdate]);
};

export default useCompleteTableData;