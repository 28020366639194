import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";
import { removeEmpty } from "../../utils/removeEmpty";

const useFetchLaws = () => {
  const { loading, request, error, clearError } = useHttp();

  const getLaws = async ({ page, rowsPerPage, searchValue }: IGetList) => {
    const res = await request('get', 'api/check/laws', {
      params: removeEmpty({
        page,
        rowsPerPage,
        searchValue: removeEmpty(searchValue),
      }),
    });
    return {
      amount: res.amount,
      list: res.list,
    };
  };

  const getLaw = async (id: string | undefined) => {
    return await request('get', `api/check/laws/${id}`);
  };

  const getLawArticle = async (id: string | undefined, lawId: string | undefined) => {
    return await request('get', "api/check/articles", {
      params: {
        id,
        lawId,
      }
    })
  };

  const addLaw = async (data: object) => {
    return await request('put', 'api/create/law', { data });
  };

  const addLawArticle = async (data: object, lawId: string | undefined) => {
    return await request('put', `api/create/lawArticle/${lawId}`, { data });
  };

  const editLaw = async (id: string | undefined, data: object) => {
    return await request('post', `api/create/law/${id}`, { data });
  };

  const editLawArticle = async (id: string | undefined, data: object, lawId: string | undefined) => {
    return await request('post', `api/create/lawArticle/${id}/${lawId}`, { data });
  };

  const deleteLaw = async (id: string) => {
    return await request('delete', `api/create/law/${id}`);
  };

  const deleteLawArticle = async (id: string, lawId: string) => {
    return await request('delete', `api/delete/law/${lawId}/${id}`);
  };

  const bulkAddLaws = async (headers: any, lawsData: any) => {
    return await request('post', "api/create/lawBulkImport", { bulkHeaders: headers, bulk: lawsData });
  };

  const getAllLaws = async () => {
    return await request('get', 'api/getAllLaws');
  };

  const lawsBulkEdit = async (data: any) => {
    return await request("post", "api/create/bulkEditLaws", data);
  };

  const changeLawsVisibility = async (mode: boolean) => {
    return await request("post", "api/change/lawsVisibility", { mode });
  };

  return {
    loading,
    error,
    clearError,
    getLaws,
    getLaw,
    getLawArticle,
    addLawArticle,
    addLaw,
    editLawArticle,
    editLaw,
    deleteLaw,
    deleteLawArticle,
    bulkAddLaws,
    getAllLaws,
    lawsBulkEdit,
    changeLawsVisibility
  }
}

export default useFetchLaws;