import network from "../api/network";
import { useAsyncCallback } from 'react-use-async-callback';

export const useHttp = () => {
  const [request, { isExecuting: loading, errors: error, successfullyExecuted: clearError }] = useAsyncCallback(async (
    method: 'get' | 'post' | 'put' | 'delete' = 'get',
    url: string,
    data?: any
  ): Promise<any> => {
    const response = await network[method](url, data);
    return response.data;
  }, []);

  return { request, loading, error, clearError };
};