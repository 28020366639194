import React from "react";
import { TextareaProps } from "../../types/type";
import { TextWrap, TextHelper } from "./InputStyles";

const Textarea = ({
  label,
  maxLength,
  value,
  onChange,
  name,
  dataTestId,
  helperText,
  helperTextProps,
  ...restProps
}: TextareaProps): JSX.Element => {
  return (
    <TextWrap data-testid="text-area-component">
      {label && <span className="label">{label}</span>}
      <textarea
        maxLength={maxLength}
        name={name}
        value={value}
        onChange={onChange}
        data-testid={dataTestId}
        {...restProps}
      />
      {helperText && (
        <TextHelper style={helperTextProps ?? {}} data-testid={`error-${dataTestId}`}>
          {helperText}
        </TextHelper>
      )}
      {maxLength && <span className="length">{value.length}/{maxLength}</span>}
    </TextWrap>
  );
};

export default Textarea;
