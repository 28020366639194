import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import LawForm from "../components/Form/LawForm/LawForm";
import { useNavigate } from "react-router-dom";
import LawFormStore from "../store/LawFormStore";
import WarningStore from "../store/WarningStore";
import useFetchLaws from "../api/useFetch/useFetchLaws";
import { observer } from "mobx-react-lite";
import { RouteList } from "../utils/adminType";
import navigator from "../utils/navigator";

const AddEditLawPage = observer(() => {
  const { loading: loadingLaw, getLaw } = useFetchLaws();
  const { loading: addingLaw, addLaw } = useFetchLaws();
  const { loading: editingLaw, editLaw } = useFetchLaws();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && LawFormStore.isCreateModeOn) {
      LawFormStore.setMode("edit");
      LawFormStore.imageEditMode(true);
      getLaw(id).then((res: any) => {
        (res) ? LawFormStore.initiate(res) : LawFormStore.setError();
      });
    };
    
    return () => LawFormStore.resetStorage();
  }, []);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const navigateToLaws = () => {
    navigate(RouteList.laws);
  };

  const onAddEditLaws = async () => {
    const data = LawFormStore.getData();

    if (id) {
      editLaw(id, data).finally(() => navigateToLaws());
    } else {
      addLaw(data).finally(() => navigateToLaws());
    }
  };

  return (
    <Stack data-testid="add-edit-law-page" justifyContent="center" alignItems="center">
      <h1>{LawFormStore.componentTitle}</h1>

      <LawForm
        onAddEdit={onAddEditLaws}
        onBack={onBackClick}
        isLoading={loadingLaw || addingLaw || editingLaw}
      />

    </Stack>
  );
});

export default AddEditLawPage;
