import React, { useEffect } from 'react';
import AdminForm from "../components/Form/AdminForm/AdminForm";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import useFetchAdmin from '../api/useFetch/useFetchAdmin';
import useFetchParties from '../api/useFetch/useFetchParties';
import AdminFormStore from '../store/AdminFormStore';
import PartiesStore from '../store/PartiesStore';
import { observer } from 'mobx-react-lite';
import WarningStore from "../store/WarningStore";
import navigator from '../utils/navigator';

const AddAdminPage = observer(() => {
  const navigate = useNavigate();
  const { loading, getAdminTypes, addAdmin } = useFetchAdmin();
  const { loading: loadingParty, getPartiesList } = useFetchParties();

  useEffect(() => {
    if (AdminFormStore.isNotFilled) {
      getAdminTypes()
        .then((res => {
          const filteredTypes = res.adminTypes.filter((item: any) => item);
          const filteredSubTypes = res.adminSubTypes.filter((item: any) => item);
          AdminFormStore.setAdminTypes(filteredTypes);
          AdminFormStore.setAdminSubTypes(filteredSubTypes);
        }));

      if (!PartiesStore.isAllPartiesFilled) {
        getPartiesList()
          .then(res => PartiesStore.setAllParties(res))
          .finally(() => AdminFormStore.setIsFilled());
      }
    };
  }, []);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const onAddAdmin = async (data: any) => {
    return addAdmin(data).then(() => navigate('/admin'));
  };

  return (
    <Stack justifyContent="center" alignItems="center" data-testid="add-admin-page">
      <h1>Add admin</h1>
      <AdminForm
        onBack={onBackClick}
        onAddEdit={onAddAdmin}
        isLoading={loading || loadingParty}
      />
    </Stack>
  );
});

export default AddAdminPage;