import React, { useEffect } from 'react';
import ElectionModeForm from "../components/Form/ElectionModeForm/ElectionModeForm";
import Stack from '@mui/material/Stack';
import useFetchElectionMode from "../api/useFetch/useFetchElectionMode";
import ElectionStore from "../store/ElectionModeStore";
import { observer } from "mobx-react-lite";
import CandidateStore from '../store/CandidateStore';
import getElectionModes from '../api/actions/getElectionModes';

const ChangeElectionModePage = observer(() => {
  const {
    loading,
    handleElectionMode,
    getMode,
    getCurrentMode
  } = useFetchElectionMode();

  const onCurrentMode = () => {
    getCurrentMode()
      .then((res: any) => {
        const currentMode = (!!res) ? res : 'election';
        ElectionStore.setCurrentMode(currentMode);
        const allias: string =
          currentMode.toLowerCase() !== "final"
            ? "Candidate"
            : "Member of Parliament";
        CandidateStore.setCurrentAllias(allias);
      });
  };

  useEffect(() => {
    if (ElectionStore.isModesEmpty) {
      getElectionModes(getMode);
    }
    if (ElectionStore.isCurrentModeEmpty) {
      onCurrentMode();
    }
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" data-testid="change-mode-page">
      <h1>Change MP election mode</h1>

      <ElectionModeForm
        handleElectionMode={handleElectionMode}
        updateCurrentMode={onCurrentMode}
        isLoading={loading}
      />
    </Stack>
  );
});

export default ChangeElectionModePage;