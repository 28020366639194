import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import LawArticleForm from "../components/Form/LawArticleForm/LawArticleForm";
import { useNavigate } from "react-router-dom";
import LawArticleFormStore from "../store/LawArticleFormStore";
import useFetchLaws from "../api/useFetch/useFetchLaws";
import WarningStore from "../store/WarningStore";
import { observer } from "mobx-react-lite";
import { RouteList } from "../utils/adminType";
import navigator from "../utils/navigator";

const AddEditLawArticlePage = observer(() => {
  const { loading: loadingLaw, getLawArticle } = useFetchLaws();
  const { loading: addingLaw, addLawArticle } = useFetchLaws();
  const { loading: editingLaw, editLawArticle } = useFetchLaws();
  const { id, lawId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && LawArticleFormStore.isCreateModeOn) {
      LawArticleFormStore.setMode("edit");
      LawArticleFormStore.imageEditMode(true);
      getLawArticle(id, lawId)
        .then((res: any) => {
          (res) ? LawArticleFormStore.initiate(res) : LawArticleFormStore.setError();
        });
    };

    //LawArticleFormStore.setId(id, lawId);
    return () => {
      LawArticleFormStore.resetStorage();
      WarningStore.setIsEdited(false);
    };
  }, []);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const navigateToLaw = () => {
    localStorage.removeItem('editCache');
    navigate(`${RouteList.law}/${lawId}`);
  };

  const onAddEditArticle = async () => {
    const data = LawArticleFormStore.getData();
    if (id) {
      editLawArticle(id, data, lawId).finally(() => navigateToLaw());
    } else {
      addLawArticle(data, lawId).finally(() => navigateToLaw());
    }
  };

  return (
    <Stack data-testid="add-edit-law-article-page" justifyContent="center" alignItems="center">
      <h1>{LawArticleFormStore.pageTitle}</h1>

      <LawArticleForm
        onAddEdit={() => onAddEditArticle()}
        onBack={onBackClick}
        isLoading={loadingLaw || addingLaw || editingLaw}
      />

    </Stack>
  );
});

export default AddEditLawArticlePage;
