import React from 'react';
import { InputFileProps } from "../../types/type";
import { Label, Btn } from "./InputStyles";

const InputFile = ({
  accept,
  label,
  onChange,
  name,
  dataTestId,
  isDisabled,
  ...restProps
}: InputFileProps): JSX.Element => {
  return (
    <Label {...restProps} data-testid="input-file-component">
      <Btn style={{
        backgroundColor: isDisabled ? "#ddd" : "",
        color: isDisabled ? "#000" : "",
        cursor: isDisabled ? "auto" : "pointer"
      }}>{label}</Btn>
      <input
        name={name}
        type="file"
        data-testid={dataTestId}
        accept={accept}
        onChange={onChange}
        disabled={isDisabled}
      />
    </Label>
  );
}

export default InputFile;
