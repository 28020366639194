import React, { useState } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import { IExtendedActionProps } from "../../../types/type";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import EditIcon from "@mui/icons-material/Edit";
import { observer } from "mobx-react-lite";
import PodcastsStore from '../../../store/PodcastsStore';
import closeTableWindow from '../../../utils/closeTableWindow';

const PodcastAction = observer(({
  id,
  onDelete,
  onOpen,
  onEdit
}: IExtendedActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleDeleteConfirm = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleDelete = () => {
    PodcastsStore.removeItemLocally(id);
    PodcastsStore.setInQueue(id);

    onDelete(id).finally(() => PodcastsStore.removeFromQueue(id));
  };

  return (
    <>
      <ActionMenuButton dataTestId={`podcasts-action-menu-${id}`} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem onClick={closeTableWindow(id, onOpen, setAnchorEl)}><ReadMoreIcon /> Open</MenuItem>
        <MenuItem onClick={closeTableWindow(id, onEdit, setAnchorEl)}><EditIcon /> Edit</MenuItem>
        <MenuItem data-testid={`delete-law-${id}`} onClick={handleDeleteConfirm}><DeleteIcon color={'error'} /> Delete</MenuItem>
      </ActionMenuButton>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle='Are you sure to delete this podcast?'
          disableStates={[setOpen]}
          callback={async () => handleDelete()}
        />
      )}
    </>
  );
});

export default PodcastAction;