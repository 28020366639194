import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import EpisodeContent from "../components/Form/EpisodeContent/EpisodeContent";
import { useNavigate } from "react-router-dom";
import useFetchPodcasts from "../api/useFetch/useFetchPodcasts";
import { observer } from "mobx-react-lite";
import { Back } from './AddCandidatePage/AddCandidateStyle';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EpisodeFormStore from "../store/EpisodeFormStore";

const EpisodePage = observer(() => {
  const { loading, getPodcast } = useFetchPodcasts();
  const { podcastId, id } = useParams();
  const navigate = useNavigate();

  const getEpisodeHandler = async () => {
    if (id) {
      getPodcast(podcastId).then((res: any) => {
        if (res) {
          EpisodeFormStore.setEpisodeData({
            ...res?.episodes[id],
            podcastIcon: res.image,
          })
        } else {
          EpisodeFormStore.setError();
        }
      });
    }
  };

  useEffect(() => {
    getEpisodeHandler();

    return () => EpisodeFormStore.resetEpisodeData();
  }, []);

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <div data-testid="episode-page">
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>Back</p>
      </Back>
      <Stack justifyContent="center" alignItems="center">
        <h1>{EpisodeFormStore.episodeData?.title ?? ""}</h1>

        <EpisodeContent
          isLoading={loading}
        />

      </Stack>
    </div>
  );
});

export default EpisodePage;
