import { useEffect } from "react";

const useGetItemData = (fetcher: Function, id: string | undefined | null, storage: any, onError?: Function) => {
  useEffect(() => {
    if (id) {
      if (storage.onFirstRender) {
        storage.onFirstRender();
      }

      if (storage.disable) {
        storage.disable();
      }

      fetcher(id)
        .then((res) => res ? storage.setData(res) : storage.setError())
        .catch((err) => {
          storage.setError();

          if (onError) {
            onError(err);
          }
        })
        .finally(() => {
          if (storage.enable) {
            storage.enable();
          }
        });
    };
    return storage.resetData;
  }, []);
};

export default useGetItemData;