import { makeAutoObservable } from "mobx";

class SurveysFormStore {
  title: string = "";
  text: string = "";
  image: null | string = null;
  isVideo: boolean = false;
  fileName: string | null = null;
  answerType: number = 0;
  variants: any[] = [];
  sendPush: boolean = false;
  district: string = "";
  forCandidateOrVoter: number = 0;
  error: any = {};
  _storeIsFilled: boolean = false;
  _currentMode: string = "create";
  expirationDate?: number | null;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  setStorageFilled() {
    this._storeIsFilled = true;
  }

  get isStorageFilled() {
    return this._storeIsFilled;
  }

  setErrorData(data: any) {
    this.error = data;
  }

  get pageHeader() {
    return this.currentModeIsEdit ? "Edit Survey" : "Add New Survey";
  }

  get currentModeIsEdit() {
    return this._currentMode === "edit";
  }

  get getModalTitle() {
    return this._currentMode === "edit"
      ? "Are you sure to apply current edits?"
      : "Are you sure to create survey?";
  }

  setData(field: string, data: any) {
    this[field] = data;
  }

  setCurrentMode(mode: string) {
    this._currentMode = mode;
  }

  resetData() {
    this.title = "";
    this.text = "";
    this.error = {};
    this.image = null;
    this.isVideo = false;
    this.fileName = null;
    this.answerType = 0;
    this.variants = [];
    this.sendPush = false;
    this.district = "All districts";
    this.forCandidateOrVoter = 0;
    this.expirationDate = null;
    this._currentMode = "create";
    this._storeIsFilled = false;
  }

  getData() {
    this.error = {};
    return {
      title: this.title,
      text: this.text,
      image: this.image,
      isVideo: this.isVideo,
      fileName: this.fileName,
      answerType: this.answerType,
      variants: this.variants,
      sendPush: this.sendPush,
      district: this.district,
      expirationDate: this.expirationDate,
      forCandidateOrVoter: this.forCandidateOrVoter,
    };
  }
}

export default new SurveysFormStore();
