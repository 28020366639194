import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import LogsStore from '../../../store/LogsStore';
import useFetchLogs from '../../../api/useFetch/useFetchLogs';
import LogForm from './LogForm';
import Modal from '../../../common/Modal';

const LogContainer = observer(({ id, isOpen, closeLog }: any): JSX.Element => {
  const { loading, getLog } = useFetchLogs();

  useEffect(() => {
    getLog(id).then((res: any) => {
      if (res) {
        LogsStore.setLogData(res)
      } else {
        LogsStore.setError();
      }
    });
    return () => LogsStore.resetLogData();
  }, []);

  return (
    <Modal
      active={isOpen}
      setActive={closeLog}
      title="Log data"
    >
      <LogForm isLoading={loading} />
    </Modal>
  );
});

export default LogContainer;