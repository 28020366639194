import React from 'react';
import { InputPlaceProps } from "../../types/type";
import {
  TextLabel,
  TextLabelName,
  Input,
  TextHelper,
  PlaceList,
  Li,
  PlaceLoader
} from "./InputStyles";

const InputPlace = ({
  placeList,
  loader,
  label,
  helperText,
  onPlaceSelect,
  value,
  ...restProps
}: InputPlaceProps): JSX.Element => {
  const renderList = () => {
    if (placeList) {
      return placeList.map((item: any, i: any) => (
        <Li
          key={i}
          data-testid={`place-${i}`}
          onClick={() => onPlaceSelect(item)}>
          {item}
        </Li>
      ));
    }
  };

  return (
    <TextLabel data-testid="input-place-component">
      <TextLabelName>{label}</TextLabelName>
      <Input value={value} {...restProps} />
      {helperText && <TextHelper>{helperText}</TextHelper>}
      {loader && <PlaceLoader data-testid="input-place-loader" size={15} />}
      <PlaceList>
        {renderList()}
      </PlaceList>
    </TextLabel>
  );
};

export default InputPlace;