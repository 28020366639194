import React, { useState, useEffect } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { IExtendedActionProps } from "../../../types/type";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { observer } from "mobx-react-lite";
import LawStore from '../../../store/LawStore';
import closeTableWindow from '../../../utils/closeTableWindow';

const LawsAction = observer(({
  id,
  onEdit,
  onOpen,
  onDelete
}: IExtendedActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleDeleteConfirm = () => {
    setAnchorEl(null)
    setOpen(true);
  };

  const handleDelete = async () => {
    LawStore.removeItemLocally(id);
    LawStore.setInQueue(id);

    onDelete(id).finally(() => LawStore.removeFromQueue(id));
  };
  
  return (
    <>
      <ActionMenuButton dataTestId={`laws-action-menu-${id}`} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem onClick={closeTableWindow(id, onOpen, setAnchorEl)}><ReadMoreIcon /> Open</MenuItem>
        <MenuItem onClick={closeTableWindow(id, onEdit, setAnchorEl)}><EditIcon /> Edit</MenuItem>
        <MenuItem data-testid={`delete-law-${id}`} onClick={handleDeleteConfirm}>
          <DeleteIcon color={'error'} /> Delete
        </MenuItem>
      </ActionMenuButton>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle='Are you sure to delete this law?'
          disableStates={[setOpen]}
          callback={handleDelete}
        />
      )}
    </>
  );
});

export default LawsAction;