export enum AdminTypes {
  superAdmin = "superadmin",
  complaintsManager = "complaints manager",
  spectator = "spectator",
  partyManager = "party manager",
  podcastManager = "podcast manager",
  newsManager = "news manager",
  lawsManager = "laws manager",
  surveysManager = "surveys manager",
  supportManager = "support manager",
  admin = "admin",
  canAddDistricts = "canAddDistricts",
  canEditOtherCandidates = "canEditOtherCandidates",
  canCreateParties = "canCreateParties",
  canSuspendCandidates = "canSuspendCandidates",
  canAddEditAdmins = "canAddEditAdmins",
}

export enum RouteList {
  home = "/",
  pcmHome = "/pcm",
  complaintsList = "/complaints-list",
  complaintsReports = "/complaints-reports",
  complaint = "/complaint",
  news = "/news",
  addNews = "/add-news",
  editNews = "/edit-news",
  surveys = "/surveys",
  addSurvey = "/add-survey",
  admin = "/admin",
  addAdmin = "/add-admin",
  addCandidate = "/add-candidate",
  addPCMCandidate = "/add-pcm-candidate",
  editCandidate = "/edit-candidate",
  editSurvey = "/edit-survey",
  surveyStatistic = "/survey-statistic",
  parties = "/parties",
  addParty = "/add-party",
  editParty = "/edit-party",
  notificationsList = "/notifications-list",
  addNotification = "/add-notification",
  changeElectionMode = "/change-election-mode",
  changePCMElectionMode = "/change-pcm-election-mode",
  reports = "/reports",
  map = "/map",
  districts = "/districts",
  article = "/article",
  support = "/support",
  supportChat = "/support-chat",
  resetPassword = "/resetPassword",
  passwordSuccess = "/passwordSuccess",
  logs = "/logs",
  log = "/log",
  laws = "/laws",
  editLaws = "/edit-law",
  addLaw = "/add-law",
  law = "/law",
  lawArticle = "/law",
  lawArticleEdit = "/law",
  lawArticleAdd = "/law",
  podcasts = "/podcasts",
  podcast = "/podcast",
  addPodcast = "/add-podcast",
  episode = "/podcast",
  editPodcast = "/edit-podcast",
  editEpisode = "/podcast",
  addEpisode = "/podcast",
  all = "*",
}
