import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import BulkUpload from "../../components/Bulk/BulkUpload";
import BulkEdit from "../../components/Bulk/BulkEdit";
// @ts-expect-error
import Mark from "../../assets/images/need-approval.png";
import AddNewInfo from "../../components/Form/AddNewInfo";
import {useLocation, useNavigate} from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CandidateStore from "../../store/CandidateStore";
import NeedApproval from "../../components/Form/NeedApproval";
import {
  Back,
  PageHeaderContainer,
  TabBtn,
  TabWrap,
} from "./AddCandidateStyle";
import CandidateForm from "../../components/Form/CandidateForm";
import useFetchCandidate from "../../api/useFetch/useFetchCandidate";
import useFetchParties from "../../api/useFetch/useFetchParties";
import useFetchEducation from "../../api/useFetch/useFetchEducation";
import useFetchPlaces from "../../api/useFetch/useFetchPlaces";
import useFetchBulk from "../../api/useFetch/useFetchBulk";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import WarningStore from "../../store/WarningStore";
import ElectionModeStore from "../../store/ElectionModeStore";
import { adminType } from "../../api/network";
import { AdminTypes } from "../../utils/adminType";
import navigator from "../../utils/navigator";
import PCMElectionModeStore from "../../store/PCMElectionModeStore";

const AddCandidatePage = observer(() => {
  const navigate = useNavigate();
  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const { loading: loadingEducation, getEducationList } = useFetchEducation();
  
  const {
    loading: loadingCandidate,
    getAllCandidates,
    createCandidate,
    getCandidate,
    deleteVideo,
    updateCandidate,
    getNewInfo,
    approveCandidate,
    declineCandidate,
    resetDeviceId,
    clearPhoneNumbers,
  } = useFetchCandidate();
  
  const { loading: loadingParty, getPartiesList } = useFetchParties();
  const { getPlaces } = useFetchPlaces();
  const { bulkEdit, uploadBulk } = useFetchBulk();
  const [tab, setTab] = useState(1);
  const { id } = useParams();
  const location = useLocation()

  const getData = async () => {
    CandidateStore.resetFetchedCandidateData();
    return getCandidate(id);
  };

  useEffect(() => {
    if (id && CandidateStore.isCurrentModeCreate) {
      CandidateStore.setCurrentMode("edit");
      getData()
        .then((res) => {
          const newInfoId = res.nextInfo;
          CandidateStore.setFetchedCandidateData(res);
          if (newInfoId) {
            getNewInfo(newInfoId).then((res) => {
              CandidateStore.setData("newInfo", res);
              setTab(4);
            });
          }
        })
        .catch((e) => console.log(e));
    }

    if (location.pathname.includes('add-pcm-candidate')) {
      CandidateStore.setCandidateType("pcm-candidate")
    }
    if (location.pathname.includes("add-candidate")) {
      CandidateStore.setCandidateType("mp-candidate")
    }
    return () => {
      CandidateStore.setCurrentMode("create");
      CandidateStore.resetFetchedCandidateData();
      CandidateStore.resetData();
      CandidateStore.setCandidateType("");
    };
  }, []);

  useEffect(() => {
    if (!CandidateStore.state.educationFilled) {
      getEducationList().then((res) => {
        CandidateStore.setSelectionList(res, "education");
        CandidateStore.setState({ educationFilled: true });
      });
    }
    if (!CandidateStore.state.partiesFilled) {
      getPartiesList().then((res) => {
        CandidateStore.setSelectionList(res, "party");
        CandidateStore.setState({ partiesFilled: true });
      });
    }
    if (!CandidateStore.state.candidatesFilled && !id) {
      getAllCandidates({
        searchKey: "",
        searchValue: "",
        candidateType: CandidateStore.candidateType
      }).then((res) => {
        CandidateStore.setCandidatesList(res.list);
        CandidateStore.setFetchedList(res.list);
        CandidateStore.setCandidatesAmount(res.amount);
        CandidateStore.setState({ candidatesFilled: true });
      });

      return () => {
        CandidateStore.setState({ candidatesFilled: false });
      }
    }
  }, []);

  const readyToRender = (): boolean => {
    return loadingEducation || loadingCandidate || loadingParty;
  };

  const onSubmit = async (userInfo: any) => {
    if (
        CandidateStore.candidateType === 'mp-candidate' &&
        ElectionModeStore.isCurrentModeFinal &&
        adminType().includes(AdminTypes.superAdmin)
    ) {
      userInfo.mp = true;
      CandidateStore.setState({ mp: true });
    }
    userInfo.candidateType = CandidateStore.candidateType
    return createCandidate(userInfo)
      .then(() => {
        navigate(CandidateStore.candidateType === "mp-candidate" ? "/" : "/pcm");

        CandidateStore.resetData();
        WarningStore.resetStorage();
      })
      .catch((e) => {
        console.log(e);
        console.log(e.status);
        if (e.status === 409) {
          return e;
        }
      });
  };

  const deleteVideoHandler = async (video: any) => {
    deleteVideo({ id, video });
  };

  const getAddButtonTitle = () => {
    if(CandidateStore.candidateType === "mp-candidate" ) {
      return ElectionModeStore.isCurrentModeFinal ? "MP" : "MP Candidate";
    } else {
       return PCMElectionModeStore.isCurrentModeFinal? "PCM": "PCM Candidate";
      }
  }

  const fetchPlaces = async (e: any) => {
    return getPlaces(e.target.value).then((res: any) => {
      const arr: string[] = [];

      res.map((i: any) => {
        let name = i?.address?.town ?? i?.address?.city ?? i?.address?.hamlet;

        if (name && i?.address?.country) {
          arr.push(`${name}, ${i?.address?.country}`);
        }
      });

      return arr.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
  };

  const onTabClick = (e: any) => {
    const index = e.currentTarget.getAttribute("data-index");
    setTab(Number(index));
  };

  const updateCandidateData = async () => {
    updateCandidate({
      id,
      userInfo: CandidateStore.getCandidate(),
      fileInfo: CandidateStore.avatarData,
    }).then(() => {
      getData().then((res) => {
        CandidateStore.setFetchedCandidateData(res);
      });
      WarningStore.resetStorage();
    });
  };

  const resetDeviceID = async () => {
    resetDeviceId(id).then(() => navigate(0));
  };

  const newInfoCheck = () => {
    switch (true) {
      case CandidateStore.isCurrentModeCreate:
        return false;
      case CandidateStore.newInfo !== undefined &&
        CandidateStore.newInfo !== null:
        return true;
      default:
        return CandidateStore.newInfo;
    }
  };

  return (
    <Container data-testid="candidate-page" maxWidth="xl">
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>Back</p>
      </Back>
      <PageHeaderContainer>
        <h1>{CandidateStore.candidateType === "mp-candidate" ? CandidateStore.currentHeader : CandidateStore.currentPCMHeader}</h1>
        <p>All information required*</p>
      </PageHeaderContainer>

      <TabWrap>
        <TabBtn
          onClick={(e) => onTabClick(e)}
          variant="contained"
          className={tab === 1 ? "active" : ""}
          data-testid="candidate-first-tab"
          data-index={1}
        >
          {id ? (
            "Profile information"
          ) : (
            `Add ${getAddButtonTitle()}`
          )}
        </TabBtn>
        {!id && (
          <TabBtn
            onClick={(e) => onTabClick(e)}
            variant="contained"
            className={tab === 2 ? "active" : ""}
            data-testid="candidate-second-tab"
            data-index={2}
          >
            {id ? "Public information" : "Bulk Import"}
          </TabBtn>
        )}
        <TabBtn
          onClick={(e) => onTabClick(e)}
          variant="contained"
          className={tab === 3 ? "active" : ""}
          data-testid="candidate-third-tab"
          data-index={3}
        >
          {id ? "Add new info" : "Bulk Edit"}
        </TabBtn>
        {newInfoCheck() && (
          <TabBtn
            onClick={(e) => onTabClick(e)}
            variant="contained"
            className={tab === 4 ? "active" : ""}
            data-index={4}
          >
            New information
            <img src={Mark} alt="Need approval" />
          </TabBtn>
        )}
      </TabWrap>

      {tab === 1 && (
        <CandidateForm
          submitBtn={id ? "Save" : "Add"}
          onSubmit={id ? updateCandidateData : onSubmit}
          add={!id}
          isFetching={readyToRender()}
          getPlaces={fetchPlaces}
          resetDeviceID={resetDeviceID}
          clearPhoneNumbers={clearPhoneNumbers}
        />
      )}
      {tab === 2 && !id && <BulkUpload onUpload={uploadBulk} />}
      {/*tab === 2 && id && (
        <PublicInformation
          id={id}
          onDelete={deleteVideo}
          updateData={getData}
        />
      )*/}
      {tab === 3 && !id && <BulkEdit onUpload={bulkEdit} />}
      {tab === 3 && id && (
        <AddNewInfo
          submitBtn="Save"
          onSubmit={updateCandidateData}
          onDelete={deleteVideoHandler}
          updateData={getData}
          isLoading={loadingCandidate}
        />
      )}
      {tab === 4 && (
        <NeedApproval
          onApprove={approveCandidate}
          onDecline={declineCandidate}
        />
      )}
    </Container>
  );
});

export default AddCandidatePage;
