import React from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import PodcastContent from "../components/Form/PodcastContent/PodcastContent";
import { useNavigate } from "react-router-dom";
import PodcastsStore from "../store/PodcastsStore";
import useFetchPodcasts from "../api/useFetch/useFetchPodcasts";
import { observer } from "mobx-react-lite";
import { Back } from './AddCandidatePage/AddCandidateStyle';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RouteList } from "../utils/adminType";
import useGetItemData from "../hooks/useGetItemData";

const PodcastPage = observer(() => {
  const { loading, getPodcast } = useFetchPodcasts();
  const { id } = useParams();
  const navigate = useNavigate();

  useGetItemData(getPodcast, id, PodcastsStore);

  const onBackClick = () => {
    navigate(RouteList.podcasts);
  };
  
  const onAddEpisode = (id: string) => {
    navigate(`${RouteList.addEpisode}/${id}/episodes/add`);
  };

  return (
    <div data-testid="podcast-page">
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>Back</p>
      </Back>
      <Stack justifyContent="center" alignItems="center">
        <h1>{PodcastsStore.pageTitle} Podcast</h1>

        <PodcastContent
          isLoading={loading}
          onAdd={onAddEpisode}
          parentId={id}
        />

      </Stack>
    </div>
  );
});

export default PodcastPage;
