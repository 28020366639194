import styled from "styled-components";

export const Back = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 43px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  
  button {
    font-size: 16px;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  p {
    margin: 0;
  }
`;

export const Header = styled.div`
  h1 {
    margin-top: 0;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    @media (max-width: 700px) {
      font-size: 18px;
    }
  }
  
  p {
    font-size: 12px;
    line-height: 16px;

    @media (max-width: 700px) {
      font-size: 10px;
    }
  }
`;