import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  Stack,
  CircularProgress,
  Button,
  LinearProgress,
} from "@mui/material";
import * as S from "./BulkStyle";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import readXlsxFile from "read-excel-file";
import { ExportLawsXLSX } from "../../utils/ExportLawsXLSX";
import ModalAlert from "../../common/Modal/ModalAlert";
import { BulkProps } from "../../types/type";
import { observer } from "mobx-react-lite";
import LawStore from "../../store/LawStore";
import lawsCSVChecker from "../../utils/lawsCSVChecker";
import MandatoryFields from "./MandatoryFields";
import ModalConfirm from "../../common/Modal/ModalConfirm";

const BulkEditLaws = observer(({ onUpload }: BulkProps): JSX.Element => {
  const mandatoryFieldsArr = [
    "lawId",
    "lawTitle",
    "factNumber",
    // "issueDate",
    "issuer",
    "lawEnforcement",
    "legislationDate",
    "legislationNumber",
    "legislationType",
    // "note",
    "source",
    "articleId",
    "articleTitle",
    "articleText",
  ];

  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isUploadingData, setIsUploadingData] = useState(false);
  const [isBulkButtonActive, setIsBulkButtonActive] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<any>("");
  const [currentProgress, setCurrentProgress] = useState<any>({
    progress: 0,
    step: 0,
    amount: 0,
  });
  const [open, setOpen] = useState(false);

  const AddCSVButton = () => (
    <S.AddCSVButtonContainer>
      <S.InputLabelBulk htmlFor="file-csv-upload">
        <S.LabelBulkContent>
          {!isFileSelected ? (
            <span style={{ width: "40%", textAlign: "center" }}>
              {LawStore.isExportDataFetched
                ? "Please, wait..."
                : "Select XLSX (bulk insert) less than 10 MB"}
            </span>
          ) : (
            <S.FileInfoContainer>
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>
                {LawStore.bulkExportData.uploadedFile.fileName}
              </span>
              <span style={{ fontSize: 12, marginBottom: 10 }}>
                {`${(
                  LawStore.bulkExportData.uploadedFile.fileSize / 1000000
                ).toFixed(3)} Mb`}
              </span>
              <S.DeleteFileButton
                onClick={() => {
                  LawStore.setBulkExportData("upFileSCV", []);
                  setIsFileSelected(false);
                  LawStore.setBulkExportData("uploadedFile", {});
                  setIsBulkButtonActive(false);
                }}
              >
                Delete
              </S.DeleteFileButton>

              {LawStore.bulk.importResult.length > 0 && (
                <S.ProgressContainer>
                  Each step contains maximum 100 rows
                  {renderImportResult()}
                </S.ProgressContainer>
              )}
            </S.FileInfoContainer>
          )}

          {LawStore.bulkExportData.errorValidationFile && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <S.UploadSuccess>
                {LawStore.bulkExportData.errorValidationFile.successful.length}{" "}
                laws uploaded successfully
              </S.UploadSuccess>
              <S.ErrorsContainer>
                <S.UploadFailed>
                  {LawStore.bulkExportData.errorValidationFile.failed.length}{" "}
                  laws uploaded with errors
                </S.UploadFailed>
                <S.DownloadErrors
                  onClick={() => downloadXLSX(LawStore.bulkExportData.xls)}
                >
                  <GetAppIcon
                    style={{ width: 11, height: 11, marginRight: 8 }}
                  />
                  Download error list
                </S.DownloadErrors>
              </S.ErrorsContainer>
            </div>
          )}
        </S.LabelBulkContent>
      </S.InputLabelBulk>

      <input
        style={{ display: "none" }}
        type="file"
        id="file-csv-upload"
        accept=".xlsx"
        disabled={LawStore.isExportDataFetched}
        onChange={onCSVFileUpload}
      />

      <S.ButtonsWrapperBulk>
        <ExportLawsXLSX
          dataLoaded={LawStore.isExportDataFetched}
          data={LawStore.bulkExportData.laws}
          fileName={"sortedLaws"}
        />

        <Button
          variant="contained"
          disabled={!isBulkButtonActive}
          onClick={onUploadCSVClick}
        >
          Import
        </Button>
      </S.ButtonsWrapperBulk>
    </S.AddCSVButtonContainer>
  );

  const downloadXLSX = (data: any) => {
    const element = document.createElement("a");
    element.id = "download-failed-items";
    const file = new Blob([new Uint8Array(data.data).buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    element.href = URL.createObjectURL(file);
    element.download = "failed_laws.xlsx";
    document.body.appendChild(element);
    element.click();
  };

  const onCSVFileUpload = async (e: any) => {
    const file = e.target.files[0];

    LawStore.setBulkExportData("uploadedFile", {
      fileName: e.target.files[0].name,
      fileSize: e.target.files[0].size,
    });

    if (file.size > 1524000) {
      setModalTitle("File is too large!");
      setIsModalErrorOpen(true);
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async (e) => {
      try {
        let bulk = await readXlsxFile(file);
        const check = lawsCSVChecker(
          bulk,
          mandatoryFieldsArr,
          setModalTitle,
          setIsModalErrorOpen,
          true
        );
        if (check) {
          setIsBulkButtonActive(true);
          LawStore.setBulkExportData("upFileSCV", bulk);
          setIsFileSelected(true);
          setModalTitle(
            "File seems to be ok. You can proceed for further import."
          );
          setIsModalErrorOpen(true);
          LawStore.setBulkExportData("errorValidationFile", null);
          LawStore.setBulkExportData("xls", null);

          const downloadLink = document.querySelector("#download-failed-items");
          if (downloadLink) {
            downloadLink.remove();
          }
        }
      } catch (error) {
        setModalTitle("File is corrupted. Check all the fields.");
        setIsModalErrorOpen(true);
      }
    };
  };

  const renderImportResult = () => {
    return LawStore.bulk.importResult.map((result, index) => {
      return (
        <S.ResultContainer key={index}>
          <S.ProgressTitle>{`${index + 1} step status:`}</S.ProgressTitle>
          <S.UploadSuccess>
            {result.data.successful} laws uploaded successfully
          </S.UploadSuccess>
          <S.ErrorsContainer>
            <S.UploadFailed>
              {result.data.failed} laws uploaded with errors
            </S.UploadFailed>
            <S.DownloadErrors onClick={() => downloadXLSX(result.xls)}>
              <GetAppIcon style={{ width: 11, height: 11, marginRight: 8 }} />
              Download error list
            </S.DownloadErrors>
          </S.ErrorsContainer>
        </S.ResultContainer>
      );
    });
  };

  const onUploadCSVClick = async (e: any) => {
    e.preventDefault();
    setModalTitle(
      "Are you sure to apply edits from your uploaded file to laws? This action is irreversible"
    );
    setOpen(true);
  };

  const onUploadCSV = async () => {
    setOpen(false);
    setIsUploadingData(true);
    setIsBulkButtonActive(false);

    let upload = true;
    let bulk = LawStore.bulkExportData.upFileSCV;
    let header = bulk[0];
    bulk = bulk.splice(1, bulk.length);

    if (bulk.length === 0) {
      setModalTitle("Nothing to import");
      setIsModalErrorOpen(true);
      return;
    }

    const size = 100;
    let p: any = 0;
    let step = 0;

    const createOperationsAmount = (amount) => {
      return new Array(amount).fill(" ");
    };

    const importResult: any = [];
    const amount = Math.ceil(bulk.length / size);

    await createOperationsAmount(amount).reduce(async (promise, id, index) => {
      await promise;

      setCurrentProgress({
        progress: Math.floor((index / amount) * 100),
        step: index,
        amount,
      });

      let chunk: any = [];
      chunk.push(header);
      chunk.push(...bulk.slice(step, step + size));

      if (upload) {
        try {
          await onUpload({ bulk: chunk });
          setModalTitle("File successfully imported.");
          setIsModalErrorOpen(true);
        } catch (error) {
          if (error.status !== 409) {
            setModalTitle(`Error! ${JSON.stringify(error.message ?? error)}`);
            setIsModalErrorOpen(true);
            upload = false;
          } else {
            importResult.push(error.data);
          }
        }

        step += 100;
      } else {
        setModalTitle("Something wrong!");
        setIsModalErrorOpen(true);
        return;
      }
    }, Promise.resolve());

    LawStore.setImportResult(importResult);

    setIsBulkButtonActive(false);
    LawStore.setBulkExportData("upFileSCV", []);
    setIsFileSelected(false);
    setIsUploadingData(false);
    LawStore.setBulkExportData("uploadedFile", {});
  };

  useEffect(() => {
    const searchValue = LawStore.bulkExportData.searchValue;
    if (!searchValue.lawTitle && !searchValue.articleTitle) {
      LawStore.setBulkExportData("laws", LawStore.bulkExportData.fetchedLaws);
    }
  }, [LawStore.bulk.searchValue]);

  const filterSearchList = (
    filteredList: any[],
    props: any,
    searchTerm: any
  ) => {
    const term = searchTerm?.toString().toLowerCase().trim().replace(" ", "");
    const targetList = filteredList.length
      ? filteredList
      : LawStore.bulkExportData.fetchedLaws;
    return targetList.filter((item: any) => {
      const propValue = props.reduce((acc: any, current: any) => {
        return acc + item[current]?.toString();
      }, "");
      return propValue.toLowerCase().includes(term);
    });
  };

  const resetSearchState = () => {
    LawStore.setBulkExportData("laws", LawStore.bulkExportData.fetchedLaws);
    LawStore.setBulkExportData(
      "amount",
      LawStore.bulkExportData.fetchedLaws.length
    );
  };

  const onClearField = (fieldName: any) => {
    LawStore.setSearchValue({ [fieldName]: "" });
    resetSearchState();
  };

  useEffect(() => {
    const searchValue = LawStore.bulkExportData.searchValue;
    const needToSearch = (searchStorage: any) => {
      return Object.values(searchStorage).filter((item) => !!item).length !== 0;
    };

    if (needToSearch(searchValue)) {
      let filteredList: any[] = [];

      if (searchValue.lawTitle) {
        filteredList = filterSearchList(
          filteredList,
          ["lawTitle"],
          searchValue.lawTitle
        );
      }

      if (searchValue.category) {
        filteredList = filterSearchList(
          filteredList,
          ["category"],
          searchValue.category
        );
      }

      if (searchValue.articleTitle) {
        filteredList = filterSearchList(
          filteredList,
          ["articleTitle"],
          searchValue.articleTitle
        );
      }

      if (searchValue.factNumber) {
        filteredList = filterSearchList(
          filteredList,
          ["factNumber"],
          searchValue.factNumber
        );
      }

      if (searchValue.issuer) {
        filteredList = filterSearchList(
          filteredList,
          ["issuer"],
          searchValue.issuer
        );
      }

      if (searchValue.lawEnforcement) {
        filteredList = filterSearchList(
          filteredList,
          ["lawEnforcement"],
          searchValue.lawEnforcement
        );
      }

      if (searchValue.legislationNumber) {
        filteredList = filterSearchList(
          filteredList,
          ["legislationNumber"],
          searchValue.legislationNumber
        );
      }

      if (searchValue.legislationType) {
        filteredList = filterSearchList(
          filteredList,
          ["legislationType"],
          searchValue.legislationType
        );
      }

      if (searchValue.source) {
        filteredList = filterSearchList(
          filteredList,
          ["source"],
          searchValue.source
        );
      }

      LawStore.setBulkExportData("laws", filteredList);
      LawStore.setBulkExportData("amount", filteredList.length);
    } else {
      resetSearchState();
    }
  }, [LawStore.bulkExportData.searchValue]);

  const onChangeSearchHandler = (e: any, fieldName: string) => {
    LawStore.setSearchValue({ [fieldName]: e.target.value });
  };

  return (
    <S.BulkContainer>
      <S.SearchFieldsWrapper>
        <S.Amount>Amount: {LawStore.bulkExportData.amount}</S.Amount>

        <S.InputWrapper
          label="Law title"
          size="small"
          value={LawStore.bulkExportData.searchValue.lawTitle}
          onChange={(e) => onChangeSearchHandler(e, "lawTitle")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue.lawTitle && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("lawTitle")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Law article title"
          size="small"
          value={LawStore.bulkExportData.searchValue.articleTitle}
          onChange={(e) => onChangeSearchHandler(e, "articleTitle")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue.articleTitle && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("articleTitle")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Fact number"
          size="small"
          value={LawStore.bulkExportData.searchValue.factNumber}
          onChange={(e) => onChangeSearchHandler(e, "factNumber")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue.factNumber && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("factNumber")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Issuer"
          size="small"
          value={LawStore.bulkExportData.searchValue.issuer}
          onChange={(e) => onChangeSearchHandler(e, "issuer")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue.issuer && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("issuer")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Law enforcement"
          size="small"
          value={LawStore.bulkExportData.searchValue.lawEnforcement}
          onChange={(e) => onChangeSearchHandler(e, "lawEnforcement")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue
              .lawEnforcement && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("lawEnforcement")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Legislation Number"
          size="small"
          value={LawStore.bulkExportData.searchValue.legislationNumber}
          onChange={(e) => onChangeSearchHandler(e, "legislationNumber")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue
              .legislationNumber && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("legislationNumber")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Category"
          size="small"
          value={LawStore.bulkExportData.searchValue.category}
          onChange={(e) => onChangeSearchHandler(e, "category")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue.category && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("category")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Legislation Type"
          size="small"
          value={LawStore.bulkExportData.searchValue.legislationType}
          onChange={(e) => onChangeSearchHandler(e, "legislationType")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue
              .legislationType && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("legislationType")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Source"
          size="small"
          value={LawStore.bulkExportData.searchValue.source}
          onChange={(e) => onChangeSearchHandler(e, "source")}
          InputProps={{
            endAdornment: LawStore.bulkExportData.searchValue.source && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("source")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />
      </S.SearchFieldsWrapper>
      {!isUploadingData ? (
        <AddCSVButton />
      ) : (
        <Stack
          style={{
            marginTop: "150px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          alignItems="center"
        >
          <CircularProgress color="primary" size={150} />
          <p>{"Please wait, it may took some time to import..."}</p>
          <Stack sx={{ width: "100%" }}>
            <p
              style={{ margin: "0 auto" }}
            >{`${currentProgress.step}/${currentProgress.amount}`}</p>
            <LinearProgress
              color="primary"
              value={currentProgress.progress}
              variant="determinate"
            />
          </Stack>
        </Stack>
      )}
      <S.TemplateContainer>
        <div>
          <S.AnnotationHeader>Mandatory fields in file: </S.AnnotationHeader>
          <MandatoryFields fields={mandatoryFieldsArr} attention={true} />
        </div>
      </S.TemplateContainer>

      {isModalErrorOpen && (
        <ModalAlert
          setActive={setIsModalErrorOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalErrorOpen]}
          callback={() => setModalTitle("")}
        />
      )}

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={modalTitle}
          disabled={isUploadingData}
          disableStates={[setOpen]}
          callback={onUploadCSV}
        />
      )}
    </S.BulkContainer>
  );
});

export default BulkEditLaws;
