import { useHttp } from "../../hooks/useHttp";

const useFetchBulk = () => {
  const { loading, request, error, clearError } = useHttp();

  const bulkEdit = async (data: any) => {
    return await request("post", "api/bulkEditCandidates", data);
  };

  const uploadBulk = async (data: any) => {
    return await request("post", "api/candidateBulkImport", data);
  };

  return {
    loading,
    error,
    clearError,
    bulkEdit,
    uploadBulk
  };
};

export default useFetchBulk;