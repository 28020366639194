import React, { useEffect } from 'react';
import useFetchSupport from "../api/useFetch/useFetchSupport";
import SupportStore from '../store/SupportStore';
import { observer } from "mobx-react-lite";
import { CardBox } from "../common/Layout/LayoutStyle";
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { CircularProgress } from "@mui/material";
import ChatForm from "../components/Form/ChatForm"

const SupportPage = observer((): JSX.Element => {
  const {
    getChat,
    sendMessage
  } = useFetchSupport();

  const { id } = useParams();

  const updateChatHistory = async (id: string | undefined) => {
    return getChat(id).then((res) => SupportStore.setChat(res[0]));
  };

  useEffect(() => {
    SupportStore.disable();
    updateChatHistory(id).finally(() => SupportStore.enable());
    SupportStore.setData("updater", () => updateChatHistory(id));
    SupportStore.launchUpdater();
    return () => SupportStore.resetStorage();
  }, []);

  const handleMessageSending = async (data: any) => {
    if (data.answer.trim().length === 0 || SupportStore.disabled) {
      return;
    }
    SupportStore.disable();
    SupportStore.resetTextField();
    sendMessage(data)
      .then(() => {
        updateChatHistory(id);
      })
      .finally(() => {
        SupportStore.restartUpdater();
        SupportStore.enable();
      });
  };

  return (
    <Stack justifyContent="center" alignItems="center" data-testid="change-mode-page">
      <h1>Support Chat</h1>
      {(!SupportStore.currentChat.requestUniqueId) ? (
        <Stack
          style={{ marginTop: "300px", marginBottom: "50px" }}
          alignItems="center"
        >
          <CircularProgress color="primary" size={100} />
          <p>{"Please wait, loading data..."}</p>
        </Stack>
      ) : (
        <CardBox style={{ width: "100%" }}>
          <ChatForm sendMessage={handleMessageSending} />
        </CardBox>
      )}
    </Stack>
  );
});

export default SupportPage;