import * as React from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { subYears } from "date-fns";
import { Box } from "@mui/material";
import { useState } from "react";
import { InputDateProps } from "../../types/type";
import { DateInput, TextLabelName } from "./InputStyles";

const InputDate = ({
  maxYears,
  minYears,
  onChange,
  name,
  label,
  dataTestId,
  value,
  fullHeight,
  additionalStyles,
  ...props
}: InputDateProps): JSX.Element => {
  const [maxDate] = useState(subYears(new Date(), minYears));
  const [minDate] = useState(subYears(new Date(), maxYears));
  const height = fullHeight ? { height: "100%" } : {};
  return (
    <div style={{ ...height, ...additionalStyles }} data-testid="input-date-component">
      <LocalizationProvider style={height} dateAdapter={AdapterDateFns}>
        {label && <TextLabelName>{label}</TextLabelName>}
        <DatePicker
          {...props}
          mask={"__.__.____"}
          maxDate={maxDate}
          minDate={minDate}
          value={value}
          onChange={(newValue) => onChange(name, newValue)}
          renderInput={({ inputRef, inputProps, InputProps }) => {
            return (<Box style={height} sx={{ display: "flex", alignItems: "center" }}>
              <DateInput
                style={height}
                ref={inputRef}
                name={name}
                data-testid={dataTestId}
                {...inputProps}
              />
              {InputProps?.endAdornment}
            </Box>);
          }}
          inputFormat="dd.MM.yyyy"
        />
      </LocalizationProvider>
    </div>
  );
};

export default InputDate;
