import { RouteList } from "./adminType";

const getResultDescription = (result: string, method: any, getPath?: boolean) => {
  switch (result) {
    case "/change/electionMode":
      return (!getPath) ? (
        'Apply changes on candidate profile'
      ) : (
        { description: 'Change current election mode' }
      );
    case "/change/lawsVisibility":
      return (!getPath) ? (
        'Change laws visibility'
      ) : (
        { description: 'Change laws visibility' }
      );
    case "/notifSent":
      return (!getPath) ? (
        'Send notification'
      ) : (
        { description: 'Send notification' }
      );
    case "/change/deleteElectionMode":
      return (!getPath) ? (
        "Set current election mode to 'election'"
      ) : (
        { description: "Set current election mode to 'election'" }
      );
    case "/create/admin":
      return (!getPath) ? (
        'Сreate new admin profile'
      ) : (
        { description: 'Сreate new admin profile' }
      );
    case "/resendAdminPasswordReset":
      return (!getPath) ? (
        'Reset admin password'
      ) : (
        { description: 'Reset admin password' }
      );
    case "/deleteadmin":
      return (!getPath) ? (
        'Delete admin profile'
      ) : (
        { description: 'Delete admin profile' }
      );
    case "/createparty":
      return (!getPath) ? (
        'Create new party'
      ) : (
        { description: 'Create new party' }
      );
    case "/editparty":
      return (!getPath) ? (
        'Apply edits to party'
      ) : (
        { description: 'Apply edits to party', path: RouteList.editParty, shouldRender: true }
      );
    case "/deleteparty":
      return (!getPath) ? (
        'Delete party'
      ) : (
        { description: 'Delete party' }
      );
    case "/create/candidate":
      return (!getPath) ? (
        'Create candidate profile'
      ) : (
        { description: 'Create candidate profile' }
      );
    case "/candidateBulkImport":
      return (!getPath) ? (
        'Create multiple candidates profile with XLSX file'
      ) : (
        { description: 'Create multiple candidates profile with XLSX file' }
      );
    case "/delete/candidate":
      return (!getPath) ? (
        'Delete candidate'
      ) : (
        { description: 'Delete candidate' }
      );
    case "/editcandidate":
      return (!getPath) ? (
        'Apply changes on candidate profile'
      ) : (
        { description: 'Apply changes on candidate profile', path: RouteList.editCandidate, shouldRender: true }
      );
    case "/bulkEditCandidates":
      return (!getPath) ? (
        'Apply changes on candidates profiles with XLSX file'
      ) : (
        { description: 'Apply changes on candidates profiles with XLSX file' }
      );
    case "/candidate/suspend":
      return (!getPath) ? (
        'Change status of candidate, suspend it'
      ) : (
        { description: 'Change status of candidate, suspend it', path: RouteList.editCandidate }
      );
    case "/candidate/activate":
      return (!getPath) ? (
        'Change status of candidate, activate it'
      ) : (
        { description: 'Change status of candidate, activate it', path: RouteList.editCandidate }
      );
    case "/generateQR":
      return (!getPath) ? (
        'Generate QR code for own profile'
      ) : (
        { description: 'Generate QR code for own profile' }
      );
    case "/enableTFA":
      return (!getPath) ? (
        'Enable TFA for own profile'
      ) : (
        { description: 'Enable TFA for own profile' }
      );
    case "/generateMfaCode":
      return (!getPath) ? (
        'Generate MFA code'
      ) : (
        { description: 'Generate MFA code', shouldRender: true }
      );
    case "/deletevideo":
      return (!getPath) ? (
        'Remove video from candidate profile'
      ) : (
        { description: 'Remove video from candidate profile', path: RouteList.editCandidate }
      );
    case "/clearPhoneNumber":
      return (!getPath) ? (
        'Deleted phone numbers from users profiles'
      ) : (
        { description: 'Deleted phone numbers from users profiles', shouldRender: true }
      );
    case "/declinenewinfo":
      return (!getPath) ? (
        'Decline candidate new info'
      ) : (
        { description: 'Decline candidate new info', path: RouteList.editCandidate }
      );
    case "/approvenewinfo":
      return (!getPath) ? (
        'Approve candidate new info'
      ) : (
        { description: 'Approve candidate new info', path: RouteList.editCandidate }
      );
    case "/addDistrict":
      return "Add new district in districts list";
    case "/resetdeviceid":
      return (!getPath) ? (
        'Reset candidate device ID'
      ) : (
        { description: 'Reset candidate device ID', path: RouteList.editCandidate }
      );
    case "/answerSupport":
      return (!getPath) ? (
        'Send message through support system'
      ) : (
        { description: 'Send message through support system' }
      );
    case "/create/bulkEditLaws":
      return (!getPath) ? (
        'Apply bulk edits'
      ) : (
        { description: 'Apply bulk edits' }
      );
    case "/create/lawBulkImport":
      return (!getPath) ? (
        'Bulk laws creation'
      ) : (
        { description: 'Bulk laws creation', shouldRender: false }
      );
  };

  switch (true) {
    case (method === 'PUT' && result === '/create/news'):
      return (!getPath) ? (
        'Create news article'
      ) : (
        { description: 'Create news article' }
      );
    case (method === 'DELETE' && result.includes('/create/news')):
      return (!getPath) ? (
        'Delete news article'
      ) : (
        { description: 'Delete news article', shouldRender: true }
      );
    case (method === 'POST' && result.includes('/create/news')):
      return (!getPath) ? (
        'Apply edits on news article'
      ) : (
        { description: 'Apply edits on news article', path: RouteList.editNews, shouldRender: true }
      );
    case (method === 'PUT' && result === '/create/surveys'):
      return (!getPath) ? (
        'Create new survey'
      ) : (
        { description: 'Create new survey' }
      );
    case (method === 'DELETE' && result.includes('/create/surveys')):
      return (!getPath) ? (
        'Delete survey'
      ) : (
        { description: 'Delete survey' }
      );
    case (method === 'POST' && result.includes('/create/surveys')):
      return (!getPath) ? (
        'Apply edits to survey'
      ) : (
        { description: 'Apply edits to survey', path: RouteList.editSurvey, shouldRender: true }
      );
    case (method === 'DELETE' && result.includes('/create/complaints')):
      return (!getPath) ? (
        'Delete complaint'
      ) : (
        { description: 'Delete complaint' }
      );
    case (method === 'POST' && result.includes('/create/complaints')):
      return (!getPath) ? (
        'Apply edits on complaint'
      ) : (
        { description: 'Apply edits on complaint', path: RouteList.complaint, shouldRender: true }
      );
    case (method === 'POST' && result.includes('/approve/complaint')):
      return (!getPath) ? (
        'Approve complaint'
      ) : (
        { description: 'Approve complaint', path: RouteList.complaint }
      );
    case (method === 'POST' && result.includes('/decline/complaint')):
      return (!getPath) ? (
        'Decline complaint'
      ) : (
        { description: 'Decline complaint', path: RouteList.complaint }
      );
    case (method === 'PUT' && result.includes('/create/lawArticle')):
      return (!getPath) ? (
        'Create law article'
      ) : (
        { description: 'Create law article' }
      );
    case (method === 'POST' && result.includes('/create/lawArticle')):
      return (!getPath) ? (
        'Edit law article'
      ) : (
        { description: 'Edit law article', path: RouteList.lawArticleEdit, shouldRender: true }
      );
    case (method === 'DELETE' && result.includes('/delete/law')):
      return (!getPath) ? (
        'Deleted law article'
      ) : (
        { description: 'Deleted law article' }
      );
    case (method === 'PUT' && result.includes('/create/law')):
      return (!getPath) ? (
        'Create law'
      ) : (
        { description: 'Create law' }
      );
    case (method === 'DELETE' && result.includes('/create/law')):
      return (!getPath) ? (
        'Deleted law'
      ) : (
        { description: 'Deleted law' }
      );
    case (method === 'POST' && result.includes('/create/law')):
      return (!getPath) ? (
        'Edit law'
      ) : (
        { description: 'Edit law', path: RouteList.editLaws, shouldRender: true }
      );

    case (method === 'PUT' && result.includes('/create/episode')):
      return (!getPath) ? (
        'Create episode'
      ) : (
        { description: 'Create episode' }
      );
    case (method === 'POST' && result.includes('/create/episode')):
      return (!getPath) ? (
        'Edit episode'
      ) : (
        { description: 'Edit episode', path: RouteList.editEpisode, shouldRender: true }
      );
    case (method === 'DELETE' && result.includes('/delete/podcast')):
      return (!getPath) ? (
        'Deleted podcast'
      ) : (
        { description: 'Deleted podcast' }
      );
    case (method === 'PUT' && result.includes('/create/podcast')):
      return (!getPath) ? (
        'Create podcast'
      ) : (
        { description: 'Create podcast' }
      );
    case (method === 'DELETE' && result.includes('/create/podcast')):
      return (!getPath) ? (
        'Deleted podcast'
      ) : (
        { description: 'Deleted podcast' }
      );
    case (method === 'POST' && result.includes('/create/podcast')):
      return (!getPath) ? (
        'Edit podcast'
      ) : (
        { description: 'Edit podcast', path: RouteList.editPodcast, shouldRender: true }
      );
    default:
      return '';
  }
};

export default getResultDescription;