import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";
import { Button } from "@mui/material";

export const Back = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  background: none;
  margin-top: 24px;
  margin-bottom: 43px;
  border: 0;
  padding: 0;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

export const ButtonText = styled.p`
  margin: 0;
  font-size: 16px;
  @media (max-width:700px) {
    font-size: 14px;
  }
`;

export const TextWrapper = styled.p`
  margin: 0px;
  font-size: 16px;
  @media (max-width:700px) {
    font-size: 14px;
  }
`;

export const PageHeaderContainer = styled.div`
  h1 {
    margin-top: 0;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const TabBtn = styled(Button)`
  text-transform: none;
  background: #fff;
  color: #000;
  margin-right: 3px;
  box-shadow: none;
  border-radius: 6px;
  padding: 17px 23px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    font-size: 14px;
  }
  
  img {
    margin-left: 10px;
  }
  
  &.active {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    color: #fff;
  }
  
  &:hover {
    color: #fff;
  }
`;

export const TabWrap = styled.div`
  margin-top: 27px;
  display: flex;
  
  ${TabBtn} {
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

