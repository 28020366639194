import { useHttp } from "../../hooks/useHttp";
import parseSearchBar from "../../utils/parseSearchBar";

const useFetchPlaces = () => {
  const { loading, request, error, clearError } = useHttp();

  const getPlaces = async (path: any) => {
    return await request("get",
      `https://nominatim.openstreetmap.org/search?q=${path
      }&format=json&addressdetails=1&accept-language=${parseSearchBar(location?.search)?.lang ?? "ar-AE"
      }`
    );
  };

  return {
    loading,
    error,
    clearError,
    getPlaces,
  };
}

export default useFetchPlaces;