import React, {
  ChangeEvent,
  useEffect,
  useState
} from "react";
import {
  Grid,
  CircularProgress,
  Stack,
  Theme,
  Button
} from "@mui/material";
import InputText from "../../../common/Input/InputText";
import Textarea from "../../../common/Input/Textarea";
import VideocamIcon from "@mui/icons-material/Videocam";
import { localVideo, youTubeUrl } from "../../../utils/validators";
import ReactPlayer from "react-player";
import { VideoItem } from "../PublicInformation/PublicInformationStyles";
import InputFile from "../../../common/Input/inputFile";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import ModalAlert from "../../../common/Modal/ModalAlert";
import CandidateStore from "../../../store/CandidateStore";
import { AddNewInfoProps } from "../../../types/type";
import { observer } from "mobx-react-lite";
import { Form, GridHr, VideoHead } from "./AddNewInfoStyles";
import WarningStore from "../../../store/WarningStore";
import { getDuration } from "../../../utils/video";
import { ThemeProps } from "styled-components";

const Hr = () => {
  return (
    <GridHr item xs={12}>
      <hr />
    </GridHr>
  );
};

const AddNewInfo = observer(({
  submitBtn,
  onSubmit,
  isLoading,
  updateData,
  onDelete
}: AddNewInfoProps): JSX.Element => {
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fMedia, setFMedia] = useState<any>();
  const [sMedia, setSMedia] = useState<any>();
  const [isLoadingVideo, setIsLoadingVideo] = useState<any>({ media: false, mediaSecond: false });
  const [modalAction, setModalAction] = useState<any>({ action: null });

  useEffect(() => {
    setFMedia(CandidateStore.mediaData.media.url);
    setSMedia(CandidateStore.mediaData.mediaSecond.url);
  }, [CandidateStore.mediaData.media.url, CandidateStore.mediaData.mediaSecond.url]);

  const showErrorMessage = (text: string) => {
    setIsModalErrorOpen(true);
    setModalTitle(text);
  };

  const invalidHandler = () => {
    showErrorMessage("Error! Check the validated fields.");
    setIsSaving(false);
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    validate() ? (
      await onSubmit()
        .then(() => {
          WarningStore.setIsEdited(false);
          setIsSaving(false);
        })
    ) : invalidHandler();
  };

  const validate = () => {
    let isValid = true;
    const validateName = Object.keys(CandidateStore.error.validateFields);

    const concatFields = (fn: Function, val: any, number?: number) => {
      let value;
      if (val === 'media' || val === 'mediaSecond') {
        value = fn(CandidateStore.mediaData[val].url, number);
      } else {
        value = fn(CandidateStore[val], number);
      };
      CandidateStore.updateErrorData("validateFields", { [val]: value });

      value ? isValid = false : null;
    };

    validateName.map(name => {
      if (name === "media" || name === "mediaSecond" && CandidateStore.mediaData[name].url?.length !== 0) {
        if (CandidateStore.mediaData[name].url?.length && (
          youTubeUrl(CandidateStore.mediaData[name]?.url)
        ) && (!CandidateStore.mediaData[name]?.file)) {
          concatFields(localVideo, name);
        };
      };
    });

    return isValid;
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name ? e.target.name : "";
    const value = e.target.value ? e.target.value : "";

    CandidateStore.setData(name, value);
    WarningStore.setIsEdited(true);
  };

  const onChangeInputMedia = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name ? e.target.name : "";
    const value = e.target.value ? e.target.value : "";

    if (value.length && (!youTubeUrl(value) || !localVideo(value))) {
      CandidateStore.updateMedia({
        [name]: { file: "", url: value, name: "", size: null },
      });
    } else {
      return;
    }

    WarningStore.setIsEdited(true);
  };

  const onChangeVideo = (e: any) => {
    const name = e.target.name ? e.target.name : "";
    setIsLoadingVideo({ ...isLoadingVideo, [name]: true });

    const file = e.target.files[0];
    e.target.value = null;

    if ((file.size / 1048576) > 10) {
      showErrorMessage("Video file cant be more than 10MB.");
      setIsLoadingVideo({ ...isLoadingVideo, [name]: false });
      return;
    };

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = async (e) => {
      const duration: any = await getDuration(fileReader);

      if (Math.floor(duration) > 60) {
        showErrorMessage("The duration of the video should be no more than 1 minute.");
        setIsLoadingVideo({ ...isLoadingVideo, [name]: false });
        return;
      };

      CandidateStore.updateMedia({
        [name]: {
          ...CandidateStore.mediaData[name],
          file: fileReader.result,
          url: URL.createObjectURL(file),
          name: file.name,
          size: file.size / 1048576 <= 10,
        },
      });

      WarningStore.setIsEdited(true);
      setIsLoadingVideo({ ...isLoadingVideo, [name]: false });
    };
  };

  const checkUrl = (url: string) => {
    return (youTubeUrl(url) === "Url are incorrect") ? "" : url;
  };

  const onDeleteVideo = async (position: string) => {
    const video = (position === "first") ? "media" : "mediaSecond";
    const currentMedia = CandidateStore.mediaData[video];

    if (currentMedia.size) {
      CandidateStore.updateMedia({
        [video]: { ...CandidateStore.mediaData[video], file: "", url: "", name: "", size: null },
      });
      setIsConfirmModalOpen(false);
      WarningStore.setIsEdited(true);
      return;
    }

    await onDelete(video)
      .then((res: any) => {
        console.log("Video was deleted successfully");
        CandidateStore.updateMedia({
          [video]: { ...CandidateStore.mediaData[video], file: "", url: "", name: "", size: null },
        });
        updateData();
      })
      .catch((e: any) => {
        console.log(e);
      });

    setIsConfirmModalOpen(false);
    WarningStore.setIsEdited(true);
  };

  const handleDeleteVideo = (pos: string) => {
    console.log(pos)
    setModalTitle(`Are you sure to delete ${pos} video?`);
    setModalAction({ action: async () => await onDeleteVideo(pos) });
    setIsConfirmModalOpen(true);
  };

  return (
    (isLoading) ? (
      <Stack style={{ marginTop: "150px" }} alignItems="center">
        <CircularProgress color="primary" size={100} />
        <p>
          {"Please wait, it may took some time to upload profile."}
        </p>
      </Stack>
    ) : (
      <Form noValidate autoComplete="off">
        <Grid sx={{ mt: 3, mb: 5 }} container columnSpacing={8} rowSpacing={4}>

          <Grid item md={6} xs={12}>
            <Grid container columnSpacing={1} rowSpacing={0}>
              <Grid item lg={8} xs={12}>
                <InputText
                  type="url"
                  helperText={CandidateStore.error.validateFields.media}
                  name="media"
                  placeholder="YouTube url"
                  maxLength={1000}
                  value={checkUrl(CandidateStore.mediaData.media.url)}
                  label="First video"
                  onChange={(e: any) => onChangeInputMedia(e)}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <InputFile
                  name="media"
                  label="Upload video"
                  style={{ width: "100%", marginTop: 24 }}
                  type="file"
                  accept="video/mp4, video/x-m4v, video/*"
                  onChange={(e) => onChangeVideo(e)}
                  isDisabled={isLoadingVideo.media || isLoadingVideo.mediaSecond}
                >Upload video</InputFile>
              </Grid>
            </Grid>

            {isLoadingVideo.media && (
              <Stack style={{ marginTop: "125px", marginBottom: "40px" }} alignItems="center">
                <CircularProgress color="primary" size={100} />
                {<p>
                  {"Please wait, loading video..."}
                </p>}
              </Stack>
            )}

            {(CandidateStore.mediaData.media.url && !isLoadingVideo.media) && (
              <Grid>
                <VideoHead>
                  <VideocamIcon style={{ marginRight: 10, fill: `${(props: ThemeProps<Theme>) => props.theme.palette.primary.main}` }} />
                  <span>About first video</span>
                  <span className="delete" onClick={() => handleDeleteVideo("first")}>Delete</span>
                </VideoHead>
                <VideoItem>
                  <ReactPlayer
                    url={fMedia}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                  />
                </VideoItem>
              </Grid>
            )}

          </Grid>

          <Grid item md={6} xs={12}>
            <Grid container columnSpacing={1} rowSpacing={0}>
              <Grid item lg={8} xs={12}>
                <InputText
                  type="url"
                  helperText={CandidateStore.error.validateFields.mediaSecond}
                  name="mediaSecond"
                  placeholder="YouTube url"
                  maxLength={1000}
                  value={checkUrl(CandidateStore.mediaData.mediaSecond.url)}
                  label="Second video"
                  onChange={(e: any) => onChangeInputMedia(e)}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <InputFile
                  name="mediaSecond"
                  label="Upload video"
                  style={{ width: "100%", marginTop: 24 }}
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => onChangeVideo(e)}
                  isDisabled={isLoadingVideo.media || isLoadingVideo.mediaSecond}
                >Upload video</InputFile>
              </Grid>
            </Grid>

            {isLoadingVideo.mediaSecond && (
              <Stack style={{ marginTop: "125px", marginBottom: "40px" }} alignItems="center">
                <CircularProgress color="primary" size={100} />
                {<p>
                  {"Please wait, loading video..."}
                </p>}
              </Stack>
            )}

            {(CandidateStore.mediaData.mediaSecond.url && !isLoadingVideo.mediaSecond) && (
              <Grid>
                <VideoHead>
                  <VideocamIcon style={{ marginRight: 10, fill: `${(props: ThemeProps<Theme>) => props.theme.palette.primary.main}` }} />
                  <span>About second video</span>
                  <span className="delete" onClick={() => handleDeleteVideo("second")}>Delete</span>
                </VideoHead>
                <VideoItem>
                  <ReactPlayer
                    url={sMedia}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                  />
                </VideoItem>
              </Grid>
            )}

          </Grid>

          <Hr />

          <Grid item md={6} xs={12}>
            <Textarea
              name="statement"
              maxLength={1000}
              dataTestId="candidate-statement"
              value={CandidateStore.statement}
              label="Statement"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Textarea
              name="awards"
              maxLength={1000}
              dataTestId="candidate-awards"
              value={CandidateStore.awards}
              label="Previous awards"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            />
          </Grid>

          <Hr />

          <Grid item md={6} xs={12}>
            <Textarea
              name="achievements"
              maxLength={1000}
              dataTestId="candidate-achievements"
              value={CandidateStore.achievements}
              label="Achievements"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Textarea
              name="goals"
              maxLength={1000}
              dataTestId="candidate-goals"
              value={CandidateStore.goals}
              label="Main Goals to achieve when Elected"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            />
          </Grid>

          <Hr />

          <Grid item md={6} xs={12}>
            <Textarea
              name="position"
              maxLength={1000}
              dataTestId="candidate-position"
              value={CandidateStore.position}
              label="Position against main issue"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Textarea
              name="budget"
              maxLength={1000}
              dataTestId="candidate-budget"
              value={CandidateStore.budget}
              label="Company budget and source"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            />
          </Grid>

          <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ mt: 5, mb: 5, width: 345 }}
              variant="contained"
              disabled={!WarningStore.isEdited || isLoadingVideo.mediaSecond || isLoadingVideo.media}
              color="primary"
              onClick={(e: any) => {
                e.preventDefault();
                setModalAction({ action: async () => handleSubmit() });
                setModalTitle("Are you sure to save changes?");
                setIsConfirmModalOpen(true);
              }}>
              {submitBtn}
            </Button>
          </Grid>

        </Grid>

        {isConfirmModalOpen && (
          <ModalConfirm
            setActive={setIsConfirmModalOpen}
            windowTitle={modalTitle}
            disabled={isSaving}
            disableStates={[setIsConfirmModalOpen]}
            callback={modalAction.action}
          />
        )}

        {isModalErrorOpen && (
          <ModalAlert
            setActive={setIsModalErrorOpen}
            windowTitle={modalTitle}
            disableStates={[setIsModalErrorOpen]}
            callback={() => setModalTitle("")}
          />
        )}
      </Form>
    )
  );
});

export default AddNewInfo;
