import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 3%;
  right: 2%;
`;

const SubLoading = () => {
  return (
    <Container>
      <CircularProgress thickness={10} color='info'/>
    </Container>
  );
};

export default SubLoading;