import styled from 'styled-components';

const className = '.uploader';

const ImageUploader = styled.div`
  &${className} {
    display: flex;
    align-items: center;
    justify-content: center;

    input[type="file"] {
      display: none;
    }

    ${className}__button {
      padding: 0;
    }

    ${className}__wrap {
      position: relative;
      display: block;

      &-preview {
        width: 160px;
        height: 160px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(18, 19, 18, 0.1);
        display: block;
      }

      &-edit {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 42px;
        height: 42px;
        background: #fff;
        box-shadow: 0px 2px 12px rgba(46, 105, 74, 0.15);
        z-index: 1;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s ease;
      }

      &:hover {
        .uploader__wrap-edit {
          background: #55AE7E;
          transition: all .2s ease;

          svg path {
            fill: #fff;
            transition: all .2s ease;
          }
        }
      }
    }
  }
`;

export default ImageUploader;
