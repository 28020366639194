export const removeEmpty = (obj: any) => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === null || newObj[key] === undefined || newObj[key] === '') {
      delete newObj[key];
    };

    if (newObj[key] && typeof newObj[key] === 'object' && !Object.values(newObj[key]).length) {
      delete newObj[key];
    };
  });

  return newObj;
};

export const isEmpty = (obj: any) => {
  if (Object.keys(obj).length === 0) {
    return false;
  };

  return true;
};