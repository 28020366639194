import { makeAutoObservable } from "mobx";

class PodcastFormStore {
  title: string = "";
  description: string = "";
  image: string = "";
  fileName: string = "";
  episodes: any = {};

  _currentMode: string = "create";
  _error: boolean = false;
  _imageEdited: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
    this.resetData = this.resetData.bind(this);
  }

  get imageEdited() {
    return this._imageEdited;
  }

  imageEditMode(mode: boolean) {
    this._imageEdited = mode;
  }

  get modalTitle() {
    return this.isCreateModeOn
      ? "Are you sure you want to create new podcast?"
      : "Are you sure you want to edit podcast?";
  }

  get componentTitle() {
    return `${this.isCreateModeOn ? "Add" : "Edit"} podcast`;
  }

  getData() {
    return Object.fromEntries(Object
      .entries(this)
      .filter((item) => !item[0].includes("_"))
    );
  }

  setMode(mode: string) {
    this._currentMode = mode;
  }

  get isCreateModeOn() {
    return this._currentMode === "create";
  }

  get noErrors() {
    return !this._error;
  }

  setError() {
    this._error = true;
  }

  setField(field: string, data: any) {
    this[field] = data;
  }

  get isNotReadyToSend() {
    if (this.image && !this._imageEdited) {
      return !this._imageEdited;
    }
    return !(this.image && this.fileName && this.title && this.description);
  }

  resetData() {
    this._imageEdited = false;
    this.title = "";
    this.image = "";
    this.fileName = "";
    this.description = "";
    this.episodes = {};
    this._currentMode = "create";
    this._error = false;
    this.articles = {};
  };

  onFirstRender() {
    this.setMode("edit");
    this.imageEditMode(true);
  };

  setData(data: any) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    })
  };
};

export default new PodcastFormStore();
