
import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../Input/InputText";
import ModalFooterBtnGroup from "../Modal/ModalFooterBtnGroup";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Button
} from "@mui/material";
import { translateQuota } from "../../utils/i18n";
import { SelectPropsExtended } from "../../types/type";
import { SelectList, H4, Label, InputSelect } from "./SelectStyles";
import { TextLabelName } from "../Input/InputStyles";

const SelectSeatType = ({
  name,
  value,
  active,
  label,
  setActive,
  selectList,
  dataTestId,
  onChange,
  labelBtn,
  helperText,
}: SelectPropsExtended): JSX.Element => {
  const [select, setSelect] = useState(value);

  useEffect(() => {
    setSelect(value);
  }, [value]);

  const handleSelect = (check: any) => {
    const index = select.indexOf(check);
    const publicItem = selectList[0];

    switch (true) {
      case (index > -1 && check !== publicItem):
        {
          const newArr = select.filter((item: any) => item !== check);
          setSelect(newArr)
        }
        break;
      case (index === -1 && check !== publicItem):
        {
          const newArr = select.filter((item: any) => item !== publicItem);
          setSelect([...newArr, check]);
        }
        break;
      case (index === -1 && check === publicItem):
        setSelect([check]);
        break;
      default:
        setSelect([]);
        break;
    };
  };

  const submitSelect = () => {
    onChange(name, select);
    setActive(name);
  };

  const renderSelectList = () => {
    const list = selectList.slice();
    const [publicItem] = list.splice(selectList.indexOf("Public"), 1);

    return (
      <>
        <SelectList>
          <MenuItem
            key={publicItem}
            value={publicItem}
          >
            <Label>
              <Checkbox
                checked={select.indexOf(publicItem) > -1}
                onChange={() => handleSelect(publicItem)}
                value={publicItem} />
              <ListItemText primary={publicItem} />
            </Label>
          </MenuItem>
        </SelectList>
        <H4>Or "Quota":</H4>
        <SelectList>
          {list.map((item, index) => (
            <MenuItem
              key={item}
              value={item}
            >
              <Label>
                <Checkbox
                  data-testid={`seat-type${index}`}
                  checked={select.indexOf(item) > -1}
                  onChange={() => handleSelect(item)}
                  value={item} />
                <ListItemText primary={`${translateQuota(item)} (${item})`} />
              </Label>
            </MenuItem>
          ))}
        </SelectList>
      </>
    );
  };

  const formatStringValue = (value: any) => {
    const formattedValue = value.replace(/[^a-zA-Z]/g, "");
    return formattedValue[0]?.toUpperCase() + formattedValue?.slice(1);
  };

  return (
    <div data-testid="select-seat-type">
      <TextLabelName>{label}</TextLabelName>
      <InputSelect>
        <InputText
          name={name}
          helperText={helperText}
          dataTestId={dataTestId}
          value={Array.isArray(value) ? value.join(", ") : formatStringValue(value)}
          type="text"
          placeholder={label}
          readOnly
          onClick={() => setActive(name)}
        />
        <span className="select-label">{labelBtn ? labelBtn : label}</span>
      </InputSelect>
      {active && (
        <Modal
          active={active}
          setActive={() => setActive(name)}
          title={label}
        >
          {renderSelectList()}

          <ModalFooterBtnGroup>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setActive(name)}
              data-testid="seat-type-selection-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              data-testid="SD-select-seat"
              onClick={submitSelect}
            >Select</Button>
          </ModalFooterBtnGroup>
        </Modal>
      )}
    </div>
  );
};

export default SelectSeatType;
