import styled, { ThemeProps } from "styled-components";
import { Typography } from "@mui/material";

export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 10%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
`;

export const LogoTitle = styled(Typography)`
  color: #8A8A8E;
  font-weight: 600;
  font-size: 20px;
`;

export const LogoTitleSpacing = styled(LogoTitle)`
  margin-bottom: 50px;
  text-align: center;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
`;

export const LogoSpacing = styled(Logo)`
  margin-top: 40px;
`;

export const LogoIcon = styled.img`
  width: 180px;
  height: 180px;
  margin-top: 14px;
  margin-bottom: 14px;
`;

