import styled, { ThemeProps } from "styled-components";
import { IconButton, Theme } from "@mui/material";
import ReactPlayer from "react-player";

export const DropWrap = styled.div`
  padding: 15px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px dashed ${(props: ThemeProps<Theme>) => props.theme.palette?.primary?.main};
  text-align: center;
  transition: all .2s;

  &:hover {
    transition: all .2s;
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background?.paper};
  }
  
  p {
    margin: 0;
    
    &.only {
      margin-top: 5px;
      color: ${(props: ThemeProps<Theme>) => props.theme.palette?.action?.disabled};
      font-size: 10px;
    }
  }
`

export const PreviewImage = styled.div`
  margin-top: 15px;
  position: relative;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background?.paper};
  width: 100px;
  height: 100px;
  border-radius: 5px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
  }
`

export const DeleteFile = styled(IconButton)`
  position: absolute;
  right: -10px;
  top: -7px;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary?.main};
  color: #fff;
  z-index: 10;
  
  &:hover {
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary?.dark};
  }
`

export const PlayerWrap = styled.div`
  margin-top: 20px;
  position: relative;
`

export const PlayerVideo = styled(ReactPlayer)`
  width: 100% !important;
  background: #000;
`