import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";
import { removeEmpty } from "../../utils/removeEmpty";
import { _transformNotification } from "../tranformResponse";

const useFetchNotification = () => {
  const { loading, request, error, clearError } = useHttp();

  const getNotification = async ({ page, rowsPerPage, type }: IGetList) => {
    const res = await request('get', 'api/getAllNotifications', {
      params: removeEmpty({
        page,
        rowsPerPage,
        type
      })
    });
    return {
      amount: res.amount,
      list: res.list.map(_transformNotification)
    };
  };

  const notifSent = async (title: string, body: string, to: string) => {
    request('post', 'api/notifSent', {
      data: {
        status: 'ok',
      },
      notification: { title, body },
      to
    });
  };

  return {
    loading, 
    error, 
    clearError,
    getNotification,
    notifSent
  };
};

export default useFetchNotification;