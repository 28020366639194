import React, { useState } from 'react';
import { LoadingButton } from "@mui/lab";
import { Button } from '@mui/material';

const ButtonLoader = ({ loading, pointerHandler, title }: any): JSX.Element => {
  const [loadingButton, setLoadingButton] = useState(false);

  const onMouseOver = () => {
    setLoadingButton(true);
    pointerHandler();
  };

  return loadingButton ? (
    <LoadingButton
      variant='outlined'
      onMouseEnter={onMouseOver}
      loading={loading}>
      {title}
    </LoadingButton>
  ) : (
    <Button
      variant='outlined'
      onMouseEnter={onMouseOver}
      disabled={loading}>
      {title}
    </Button>
  );
};

export default ButtonLoader;