import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import LawArticleContent from "../components/Form/LawArticleContent/LawArticleContent";
import { useNavigate } from "react-router-dom";
import useFetchLaws from "../api/useFetch/useFetchLaws";
import { observer } from "mobx-react-lite";
import { Back } from './AddCandidatePage/AddCandidateStyle';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LawArticleFormStore from "../store/LawArticleFormStore";

const LawArticlePage = observer(() => {
  const { loading, getLawArticle } = useFetchLaws();
  const { id, lawId } = useParams();
  const navigate = useNavigate();

  const getLawArticleHandler = async (id: any) => {
    getLawArticle(id, lawId).then((res: any) => {
      (res) ? LawArticleFormStore.setLawArticleData({ ...res }) : LawArticleFormStore.setError();
    });
  };

  useEffect(() => {
    getLawArticleHandler(id);

    return () => LawArticleFormStore.resetLawArticleData();
  }, []);

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <div data-testid="law-article-page">
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>Back</p>
      </Back>
      <Stack justifyContent="center" alignItems="center">
        <h1>{LawArticleFormStore?.articleTitle}</h1>

        <LawArticleContent
          isLoading={loading}
        />

      </Stack>
    </div>
  );
});

export default LawArticlePage;
