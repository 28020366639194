import { electionCodeLengthMax, electionCodeLengthMin } from "./constants";
import { validateName } from "./validateNameRegularExp";

export const required = (value: any): undefined | string =>
  String(value) ? undefined : "This field is required";

export const mustBeNumber = (value: any): undefined | string =>
  isNaN(value) ? "Input are not a number!" : undefined;

export const mustBeEqual = (value: any, count: number): undefined | string =>
  String(value).length !== count
    ? `Must be a ${count} symbols length`
    : undefined;

export const mustBeLess = (value: any, count: number): undefined | string =>
  String(value).length >= count
    ? `Must be less then ${count} symbols`
    : undefined;

export const mustBeMore = (value: any, count: number): undefined | string =>
  String(value).length <= count
    ? `Must be more then ${count} symbols`
    : undefined;

export const zalgoFilter = (value: any) => {
  const regex = /^[0-9a-zA-Z -]*$/;
  const result = String(value)
    .split("")
    .filter((s) => regex.test(s))
    ?.join("");
  if (result) {
    return result;
  } else {
    return "";
  };
};

export const dateBirthValidate = (value: any) => {
  const enteredDate = new Date(value);
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18);

  if (oneYearFromNow < enteredDate) {
    return "Candidate can not be younger 18 years old";
  } else {
    return undefined;
  };
};

export const nameValidate = (value: any): undefined | string => {
  return (validateName.test(value)) ? undefined : "Name are incorrect";
};

export const mailValidate = (value: string): undefined | string => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return (regex.test(value)) ? undefined : "Email are incorrect";
};

export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validateElectionCode = (electionCode: string | number): undefined | string => {
  let code = String(electionCode);
  return code.length > electionCodeLengthMax ||
    code.length < electionCodeLengthMin
    ? `Election code / Number (${electionCodeLengthMax} or ${electionCodeLengthMin} digits)`
    : undefined;
};

export const youTubeUrl = (value: string): undefined | string => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  return (regExp.test(value)) ? undefined : "Url are incorrect";
};

export const localVideo = (value: any): undefined | string => {
  const regExp = /^.*(storage.googleapis.com)/;
  return (regExp.test(value)) ? undefined : "Url are incorrect";
};

export const encodeUppercase = (text: string) => {
  return text.replace(/([A-Z])/g, "@$1").toLowerCase();
};
export const decodeUppercase = (text: string) => {
  return text.replace(/@(\w)/g, (match) => match.toUpperCase());
};
