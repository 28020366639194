import React from 'react';
import * as T from "./TablesStyle";
import TableCell from "@mui/material/TableCell";
import { Skeleton } from "@mui/material";

const LoadingRow = ({ colSpan }: any): JSX.Element => {
  return (
    <T.TRow>
      {colSpan.map((t, i) => <TableCell key={i} align="center"><Skeleton height={40} /></TableCell>)}
    </T.TRow>
  );
};

export default LoadingRow;