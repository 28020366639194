import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import Textarea from "../../../common/Input/Textarea";
import ReactPlayer from "react-player";
import { VideoItem } from "../../../common/PublicInformation/";
import { Form } from "./ChatFormStyles";
import SupportStore from "../../../store/SupportStore";
import { IChatForm } from "../../../types/type";
import SendIcon from '@mui/icons-material/Send';
import Chat from "./ChatWindow";
import { Message, MessageTitle, MessageText } from "./ChatFormStyles";

const ChatForm = observer(({ sendMessage }: IChatForm): JSX.Element => {
  const onChangeInput = (e: any) => {
    SupportStore.setData("answer", e.target.value);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    sendMessage(SupportStore.getData());
  };

  useEffect(() => {
    const pressedButtons = new Set();
    const onKeyUpHandler = (e: any) => {
      pressedButtons.delete(e.key);
    };
    const onKeyDownHandler = (e: any) => {
      pressedButtons.add(e.key);
      if (e.key === "Enter" && SupportStore.answer.trim().length > 0) {
        if (pressedButtons.has("Shift")) {
          return;
        }
        sendMessage(SupportStore.getData());
      }
    };
    document.addEventListener("keydown", onKeyDownHandler);
    document.addEventListener("keyup", onKeyUpHandler);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
      document.removeEventListener("keyup", onKeyUpHandler);
    }
  }, []);

  const renderMessage = () => {
    return Object
      .entries(SupportStore.currentChat.messages)
      .map((item, index) => {
        const value: any = item[1];
        return (value.text || value.audioLink || value.videoLink || value.imageLink) ? (
          <Message key={index} style={(value.toUserUid === "admin") ? ({ marginRight: "auto" }) : ({ marginLeft: "auto" })}>
            <MessageTitle>
              {(value.fromUserUid === "admin") ? (
                <p>Admin</p>
              ) : (
                <p>{`${SupportStore.currentChat.firstName} ${SupportStore.currentChat.lastName}`}</p>
              )}
              <p>{new Date(value.createdAt).toLocaleString()}</p>
            </MessageTitle>
            {(value.audioLink) && (
              <VideoItem style={{ marginTop: 20 }}>
                <ReactPlayer
                  url={value.audioLink}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              </VideoItem>
            )}
            {(value.videoLink) && (
              <VideoItem style={{ marginTop: 20 }}>
                <ReactPlayer
                  url={value.videoLink}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              </VideoItem>
            )}
            {value.imageLink && (
              <img src={value.imageLink} style={{ width: "100%", height: "55vh" }} />
            )}
            {value.text && <MessageText>{value.text}</MessageText>}
          </Message>
        ) : null;
      });
  };

  return (
    <Form
      data-testid="surveys-form"
      noValidate
      autoComplete="off"
      onSubmit={submitHandler}>
      <Grid style={{ paddingTop: 0, display: "flex" }} container>
        <Chat>
          {(!SupportStore.currentChat?.messages ||
            Object.values(SupportStore.currentChat?.messages).length === 0) ? (
            <p style={{ margin: "auto" }}>No messages yet</p>
          ) : renderMessage()}
        </Chat>
        <div style={{ marginTop: "auto", width: "100%", display: "flex", alignItems: "flex-end" }}>
          <Textarea
            name="text"
            label="Text"
            dataTestId="support-chat-text"
            value={SupportStore.answer}
            placeholder="Text to answer. Shift + Enter to start on new line. Enter to send message"
            onChange={onChangeInput}
            style={{ minHeight: "50px" }}
          />
          <button
            style={{ border: "none", background: "none", color: "currentColor", cursor: "pointer" }}
            onClick={submitHandler}
            type="submit"
            aria-label="Send message"
            title="Send message"
          >
            <SendIcon />
          </button>
        </div>
      </Grid>
    </Form>
  );
});

export default ChatForm;