export const getDuration = async (f: any) => {
  const media = new Audio(f.result);
  return new Promise(resolve => {
    media.onloadedmetadata = () => {
      resolve(media.duration);
    };
  });
};

export const resizeImage = ({ src, width, height }: any) => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = width;
      elem.height = height;
      const ctx: any = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const data = ctx.canvas.toDataURL();
      res(data);
    };
    
    img.onerror = error => {
      console.log(error);
      rej(error)
    };
  })
};

export const cropImage = ({ image, crop }: any) => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = image.src;

    img.onload = () => {
      const elem = document.createElement('canvas');
      const ctx: any = elem.getContext('2d');

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      elem.width = Math.floor(crop.width * scaleX);
      elem.height = Math.floor(crop.height * scaleY);
      ctx.imageSmoothingQuality = 'high';
      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.translate(-centerX, -centerY);

      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
      );

      const data = ctx.canvas.toDataURL();
      res(data);
    };

    img.onerror = error => {
      console.log(error);
      rej(error)
    };
  });
};