import React, { useEffect, useState } from 'react';
import { useDropzone } from "react-dropzone";
import * as D from './DropzoneStyle'
import { PreviewImage } from './DropzoneStyle'
import { Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { getBase64 } from "react-images-uploading/dist/utils";
import { IDropzone } from "../../types/type";
import { resizeImage } from '../../utils/video';

const Dropzone = ({
  accept,
  margin,
  ratio,
  file,
  changeFile,
  dataTestId,
  shouldResize,
  dontShowPreview
}: IDropzone) => {
  const [path, setPath] = useState<string[]>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: accept,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setPath(acceptedFiles.map(item => URL.createObjectURL(item)))
      acceptedFiles.length && getBase64(acceptedFiles[0]).then(async (res) => {
        if (shouldResize) {
          const resizedImage: any = await resizeImage({ src: res, width: 400, height: 400 });

          changeFile({
            resizedImage: resizedImage,
            fileName: acceptedFiles[0].name,
            image: res,
          });

          return;
        }
        changeFile(res, acceptedFiles[0].name);
      })
    }
  });

  useEffect(() => {
    if (!acceptedFiles.length) {
      !!file ? setPath([file]) : setPath([]);
    };
  }, [file]);

  const removeFile = () => {
    setPath([]);
    changeFile('', '');
    acceptedFiles.splice(0, 1);
  };

  const previewFile = () => {
    return path.map((item, index) => {
      const regImage = /.png|.jpg|.jpeg/i;
      const regVideo = /.mp4/i;

      if ((regImage.test(item)
        || !!acceptedFiles.length && acceptedFiles[0].type === "image/jpeg"
        || !!acceptedFiles.length && acceptedFiles[0].type === "image/jpg"
        || !!acceptedFiles.length && acceptedFiles[0].type === "image/png")
        && !dontShowPreview
      ) {
        return (
          <PreviewImage key={item + index}>
            <D.DeleteFile onClick={removeFile} aria-label="delete">
              <DeleteIcon fontSize={"small"} />
            </D.DeleteFile>
            <img key={item} src={item} alt="" data-testid={dataTestId} />
          </PreviewImage>
        )
      }

      if (regVideo.test(item)
        || !!acceptedFiles.length && acceptedFiles[0].type === "video/mp4"
      ) {
        return (
          <D.PlayerWrap key={item + index}>
            <D.DeleteFile onClick={removeFile} aria-label="delete">
              <DeleteIcon fontSize={"small"} />
            </D.DeleteFile>
            <D.PlayerVideo
              url={item}
              controls={true}
            />
          </D.PlayerWrap>
        )
      }
    })
  }

  return (
    <Box sx={{ marginBottom: margin }}>
      <D.DropWrap {...getRootProps()}>
        <input
          {...getInputProps()}
        />
        <p>Drag and drop some file here, or click to select file</p>
        <p className="only">(Only {accept} will be accepted)</p>
        {!!ratio && (<p className="only">Aspect ratio of the uploaded image should be 4:3 in order to fit in the space</p>)}
      </D.DropWrap>
      {previewFile()}
    </Box>
  );
};

export default Dropzone;