import React, { useEffect, useState } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import { Button, TextField } from "@mui/material";
import * as F from "./PodcastFormStyles";
import Dropzone from "../../../UI/Dropzone/Dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IForm, IDataFormComponent } from "../../../types/type";
import { observer } from "mobx-react-lite";
import PodcastFormStore from "../../../store/PodcastFormStore";
import WarningStore from "../../../store/WarningStore";
import ImageCropper from "../../../common/ImageCropper/ImageCropper";

const schema = yup
  .object({
    title: yup.string().required().max(50),
    description: yup.string().required().max(3000),
  })
  .required();

const PodcastForm = observer(({ onAddEdit, onBack, isLoading }: IForm) => {
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useForm<IDataFormComponent>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const { title, description }: any = PodcastFormStore.getData();
    reset({ title, description });
  }, [PodcastFormStore.title]);

  const changeFile = (value: string, name: string) => {
    WarningStore.setIsEdited(true);
    PodcastFormStore.setField("image", value);
    PodcastFormStore.setField("fileName", name);
    PodcastFormStore.imageEditMode(false);
  };

  const onTextChange = (e: any) => {
    WarningStore.setIsEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    PodcastFormStore.setField(name, value);
    setValue(name, value);
  };

  const onSubmit: SubmitHandler<IDataFormComponent> = (dataForm) =>
    setOpen(true);

  return (
    <F.Wrap>
      {isLoading && !PodcastFormStore.isCreateModeOn ? (
        <F.SkeletonParty variant="rectangular" animation="wave" />
      ) : PodcastFormStore.noErrors ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardBox>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              sx={{ marginBottom: 3 }}
              inputProps={{ maxLength: 50 }}
              helperText={`${PodcastFormStore.title.length} / 50`}
              name="title"
              onChange={onTextChange}
              data-testid="podcast-title"
              value={PodcastFormStore.title}
            />

            <Dropzone
              accept="image/jpg,image/jpeg,image/png"
              margin={3}
              file={PodcastFormStore.image}
              changeFile={changeFile}
              dataTestId="podcast-form-image"
              dontShowPreview={true}
            />

            <ImageCropper
              src={PodcastFormStore.image}
              storage={PodcastFormStore}
            />

            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              multiline
              sx={{ marginBottom: 3 }}
              inputProps={{ maxLength: 3000 }}
              helperText={`${PodcastFormStore.description.length} / 3000`}
              rows={4}
              name="description"
              onChange={onTextChange}
              data-testid="podcast-description"
              value={PodcastFormStore.description}
            />

            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={PodcastFormStore.isNotReadyToSend || isLoading}
              >
                {PodcastFormStore.componentTitle}
              </Button>

              <Button
                type="button"
                onClick={() => onBack()}
                size="large"
                color="error"
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </div>
          </CardBox>
        </form>
      ) : (
        <CardBox style={{ textAlign: "center" }}>
          <ErrorIcon color="error" />
          <p>There is no podcasts with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </CardBox>
      )}

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={PodcastFormStore.modalTitle}
          disabled={isLoading}
          disableStates={[setOpen]}
          callback={async () => {
            WarningStore.resetStorage();
            onAddEdit();
          }}
        />
      )}
    </F.Wrap>
  );
});

export default PodcastForm;
