import React, { useState, useRef } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { observer } from "mobx-react-lite";
import { Stack, Button } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import { cropImage, resizeImage } from "../../utils/video";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteFile } from "../../UI/Dropzone/DropzoneStyle";
import WarningStore from "../../store/WarningStore";

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: 600,
        height: 600,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const ImageCropper = observer(({ src, storage, dataTestId }: any): JSX.Element | null => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect] = useState<number>(16 / 9);

  const imageRef = useRef(null);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  const applyEdits = async () => {
    const croppedImage = await cropImage({
      image: imageRef.current,
      crop: completedCrop,
    });
    const resizedImage = await resizeImage({
      src: croppedImage,
      width: 400,
      height: 400,
    });
    storage.setData("image", resizedImage);
    storage.imageEditMode(true);
  };

  const removeFile = () => {
    storage.setData("image", null);
    storage.setData("fileName", null);
    storage.imageEditMode(false);
    WarningStore.setIsEdited(true);
  };

  return src ? (
    <Stack
      style={{ width: 800, margin: "0 auto" }}
      alignItems={storage.imageEdited ? "center" : ""}
    >
      {storage.imageEdited ? (
        <div style={{ position: "relative" }}>
          <DeleteFile onClick={removeFile} aria-label="delete">
            <DeleteIcon fontSize={"small"} />
          </DeleteFile>
          <img data-testid={dataTestId ? dataTestId : ""} alt="User image" src={src} style={{ width: 400, height: 400 }} />
        </div>
      ) : (
        <>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            locked={true}
          >
            <img
              alt="User image"
              src={src}
              onLoad={onImageLoad}
              ref={imageRef}
            />
          </ReactCrop>
          <Button
            variant="contained"
            onClick={applyEdits}
            style={{ borderRadius: 0 }}
            sx={{ marginBottom: 2 }}
          >
            Apply edits on image
          </Button>
        </>
      )}
    </Stack>
  ) : null;
});

export default ImageCropper;
