import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../../common/Input/InputText";
import ModalFooterBtnGroup from "../../common/Modal/ModalFooterBtnGroup";
import { translateDistrict } from "../../utils/i18n";
import { InputSelect, SelectDistrictList } from "./SelectStyles";
import { SelectPropsExtended } from "../../types/type";
import { TextLabelName } from "../Input/InputStyles";
import { Button } from "@mui/material";

const SelectDistrict = ({
  name,
  value,
  active,
  label,
  setActive,
  dataTestId,
  selectList,
  onChange,
  labelBtn,
  helperText
}: SelectPropsExtended): JSX.Element => {
  const [select, setSelect] = useState(value);

  useEffect(() => {
    setSelect(value);
  }, [value]);

  const handleSelect = (item: any) => {
    setSelect(item);
  };

  const submitSelect = () => {
    onChange(name, select);
    setActive(name);
  };

  const renderSelect = () => {
    return selectList.map((item, key) => (
      <li
        className={item === select ? "active" : ""}
        onClick={() => handleSelect(item)}
        data-testid={`district${key}`}
        key={key}>
        {`${translateDistrict(item)} (${item.replace(
          "_N_",
          " "
        )})`}
      </li>
    ));
  };

  return (
    <div data-testid="select-district-modal-window">
      <TextLabelName>{label}</TextLabelName>
      <InputSelect>
        <InputText
          name={name}
          value={value}
          helperText={helperText}
          dataTestId={dataTestId}
          type="text"
          placeholder={label}
          readOnly
          onClick={() => setActive(name)}
        />
        <span className="select-label">{labelBtn ? labelBtn : label}</span>
      </InputSelect>
      {active && (
        <Modal
          active={active}
          setActive={() => setActive(name)}
          title={label}
        >
          <SelectDistrictList>
            {renderSelect()}
          </SelectDistrictList>
          <ModalFooterBtnGroup>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setActive(name)}
              data-testid="cancel-district-selection"
            >Cancel</Button>
            <Button
              variant="contained"
              data-testid="SD-select-district"
              onClick={submitSelect}
            >Select</Button>
          </ModalFooterBtnGroup>
        </Modal>
      )}
    </div>
  );
};

export default SelectDistrict;
