import React, { useEffect } from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import {
  Container,
  Grid,
  CircularProgress,
  Stack
} from "@mui/material";
import { Header } from "../../../pages/AddSurveyPage/AddSurveyPageStyles";
import SurveyStatsStore from "../../../store/SurveyStatsStore";
import {
  StatisticItem,
  ItemColor,
  ItemCount
} from "./SurveyStatisticStyles";
import { COLORS } from "../../../utils/constants";
import { observer } from "mobx-react-lite";

const Stats = observer((): JSX.Element => {
  let statsData: any[] = [];

  useEffect(() => {
    let answersArr: any[] = [];
    SurveyStatsStore.setIsCalculating(true);

    if (SurveyStatsStore.answers) {
      try {
        Object.values(SurveyStatsStore.answers).map((answer: any) => {
          if (SurveyStatsStore.answerType === 0) {
            if (SurveyStatsStore.variants[answer]) {
              answersArr.push(SurveyStatsStore.variants[answer]);
            }
          } else {
            answer.map((an: any) => {
              if (SurveyStatsStore.variants[an]) {
                answersArr.push(SurveyStatsStore.variants[an]);
              }
            });
          }
        });
      } catch (error) {
        SurveyStatsStore.setIsCalculating(false);
      };

      answersArr = answersArr
        .flat()
        .reduce((cnt: any, cur: any) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});

      Object.entries(answersArr).map((e) =>
        statsData.push({ name: e[0], value: e[1] })
      );

      SurveyStatsStore.setStats(statsData);
    }
    SurveyStatsStore.setIsCalculating(false);
  }, [SurveyStatsStore.answers]);

  const total: Function = () => {
    if (SurveyStatsStore.stats?.length) {
      return SurveyStatsStore.stats.reduce(
        (prevValue, currentValue: any) => currentValue.value + prevValue,
        0
      );
    }
  };

  const variantFilter = () => {
    if (SurveyStatsStore.stats?.length) {
      const arr = SurveyStatsStore.stats
        .slice()
        .sort((prev: any, next: any) => prev.value - next.value)
        .reverse()
        .map((e: any) => e.name);

      return [...new Set([...arr, ...SurveyStatsStore.variants])];
    }
    return [...SurveyStatsStore.variants];
  };

  const renderAnswersList = () => {
    if (variantFilter().length) {
      return variantFilter().map((item, i) => {
        const color = COLORS.filter((color, index) => i === index);

        let count = 0;
        if (SurveyStatsStore.stats?.length) {
          const countFilter: any = SurveyStatsStore.stats.filter((e: any) => e.name === item);
          count = countFilter.length ? countFilter[0].value : 0;
        };

        let percent = 0;
        if (count > 0) {
          percent = (count / total()) * 100;
        };

        return (
          <StatisticItem key={i}>
            <ItemColor style={{ backgroundColor: `${color}` }} />
            <div className="text">{item}</div>
            <ItemCount>
              <span
                data-testid={`survey-statistic-count-${i}`}
                className="count"
              >
                {count}/{total()}
              </span>{" "}
              <span
                data-testid={`survey-statistic-percent-${i}`}
                className="percent"
              >
                {percent.toFixed(2)}%
              </span>
            </ItemCount>
          </StatisticItem>
        );
      });
    }
  };

  return (
    <Container maxWidth="xl">
      {(SurveyStatsStore.disabled || SurveyStatsStore.isCalculating) ? (
        <Stack style={{ marginTop: "300px", marginBottom: "50px" }} alignItems="center">
          <CircularProgress color="primary" size={100} />
          <p>Please wait, loading data...</p>
        </Stack>
      ) : (
        <>
          <Header>
            <h1>{SurveyStatsStore.title}</h1>
            <p>{SurveyStatsStore.text}</p>
          </Header>
          {!SurveyStatsStore.disabled && (
            <Grid sx={{ mt: 3, mb: 5 }} container columnSpacing={12} rowSpacing={4}>
              <Grid item md={5} xs={12}>
                {renderAnswersList()}
              </Grid>
              <Grid data-testid="survey-statistic-charts" item md={6} xs={12}>
                <PieChart width={280} height={280}>
                  <Pie
                    data={SurveyStatsStore.stats}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={140}
                    innerRadius={70}
                    paddingAngle={0}
                    fill="#fff"
                    dataKey="value"
                  >
                    {SurveyStatsStore.stats?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Container>
  );
});

export default Stats;