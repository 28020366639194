import React, { useEffect } from "react";
import Modal from ".";
import Box from "@mui/material/Box";
import { ModalConfirmProps } from "../../types/type";
import { TextField, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import ComplaintsStore from "../../store/ComplaintsStore";

const style = {
  width: "100%",
  bgcolor: "background.default",
  boxShadow: 3,
  borderRadius: "5px",
  textAlign: "center",
};

const acceptButtonStyles = {
  borderRadius: "4px",
  color: "#fff",
  fontSize: "0.9375rem",
  minHeight: "60.25px",
};

const cancelButtonStyles = {
  borderRadius: "4px",
  fontSize: "0.9375rem",
  minHeight: "60.25px",
};

const ModalConfirm = observer(({
  confirmTitle,
  cancelTitle,
  setActive,
  additionalTextField,
  windowTitle,
  windowComponent,
  disabled,
  enableStates,
  disableStates,
  callback,
  shouldntRenderButtons
}: ModalConfirmProps): JSX.Element => {

  useEffect(() => {
    return () => {
      if (additionalTextField) {
        ComplaintsStore.textData.commentary = '';
      }
    }
  }, []);

  return (
    <Modal
      active={setActive}
      setActive={() => {
        setActive(false);
        disableStates.forEach((state) => state(false));
      }}
      component={windowComponent}
      activeAlways={shouldntRenderButtons}
      title={windowTitle}
    >
      {additionalTextField && (
        <div style={{ paddingTop: 5 }}>
          <TextField
            fullWidth
            label="Comment"
            multiline
            sx={{ marginBottom: 3 }}
            helperText={`${ComplaintsStore.textData.commentary.length} / 300`}
            name="text"
            rows={4}
            inputProps={{ maxLength: 300 }}
            onChange={(e) => {
              ComplaintsStore.textData.commentary = e.target.value;
            }}
          />
        </div>
      )}
      <Box sx={style}>
        {!shouldntRenderButtons && (
          <Button
            sx={{ mr: 5, width: 150 }}
            variant="contained"
            type="button"
            disabled={disabled || (additionalTextField && !ComplaintsStore.textData.commentary)}
            data-testid="confirm-modal"
            style={acceptButtonStyles}
            onClick={() => {
              if (enableStates) {
                enableStates.forEach((state) => state(true));
              }
              if (callback) {
                callback(ComplaintsStore.textData.commentary).finally(() => {
                  disableStates?.forEach((state) => state(false));
                  ComplaintsStore.textData.commentary = '';
                });
                return;
              }
              disableStates?.forEach((state) => state(false));
            }}
          >
            {confirmTitle ? confirmTitle : "Confirm"}
          </Button>
        )}
        <Button
          sx={{ width: 150 }}
          variant="contained"
          color="error"
          type="button"
          data-testid="cancel-modal"
          disabled={disabled}
          style={shouldntRenderButtons ? { ...cancelButtonStyles, width: "100%" } : cancelButtonStyles}
          onClick={() =>
            disableStates.length === 0
              ? setActive(false)
              : disableStates.forEach((state) => state(false))
          }
        >
          {cancelTitle ? cancelTitle : "Discard"}
        </Button>
      </Box>
    </Modal>
  );
});

export default ModalConfirm;
