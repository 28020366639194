import styled from "styled-components";
import { TextField } from "@mui/material";

export const Title = styled.h4`
  font-size: 18px;
  margin-top: 0;
  font-weight: 500;
`;

export const Field = styled(TextField)`
  label {
    text-transform: capitalize;
  }
`;