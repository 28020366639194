import React, { useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Avatar } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import * as U from './UploaderStyle';
import { IUploader } from "../../types/type";

const Uploader = ({
  image,
  changeLogo,
  deleteLogo
}: IUploader): JSX.Element => {
  const [images, setImages] = useState<ImageListType>([{ dataURL: image }]);
  const maxNumber = 69;

  useEffect(() => {
    setImages([{ dataURL: image }]);
  }, [image]);

  const onChange = (imageList: ImageListType) => {
    setImages(imageList);

    if (!!imageList[0].dataURL && !!imageList[0].file?.name) {
      changeLogo(imageList[0].dataURL, imageList[0].file.name);
    } else {
      changeLogo('', '');
    };
  };

  const onImageRemove = () => {
    setImages([{ dataURL: '' }]);
    deleteLogo();
  };

  return (
    <ImageUploading
      multiple={false}
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
    >
      {({
        onImageUpload,
      }) => (
        <U.Wrap>
          <Avatar
            src={!!images[0].dataURL ? images[0].dataURL : ''}
            onClick={onImageUpload}
          >
            <AddAPhotoIcon />
          </Avatar>

          {!!images[0].dataURL && (
            <U.Delete
              onClick={() => onImageRemove()}>
              <DeleteIcon />
            </U.Delete>
          )}
        </U.Wrap>
      )}
    </ImageUploading>
  );
};

export default Uploader;