import React, { useState } from "react";
import {
  H2,
  Information,
  VideoHead,
  VideoItem,
  VideoWrap,
  Wrap
} from "../PublicInformation/PublicInformationStyles";
import { Grid, Theme, Button } from "@mui/material";
//import { nextInfo } from "../../../utils/candidateInfo";
import VideocamIcon from "@mui/icons-material/Videocam";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import CandidateStore from "../../../store/CandidateStore";
import { NeedApprovalProps } from "../../../types/type";
import { observer } from "mobx-react-lite";
import { ThemeProps } from "styled-components";

const NeedApproval = observer(({
  onApprove,
  onDecline
}: NeedApprovalProps): JSX.Element => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState<any>({ action: null });

  const navigate = useNavigate();

  const fieldsToRender = [
    { label: "Statement", value: CandidateStore.newInfo.statement },
    { label: "Previous awards", value: CandidateStore.newInfo.awards },
    { label: "Achievements", value: CandidateStore.newInfo.achievements },
    { label: "Company budget and source", value: CandidateStore.newInfo.budget },
    { label: "Main Goals to achieve when Elected", value: CandidateStore.newInfo.goals },
    { label: "Position against main issue", value: CandidateStore.newInfo.position }
  ];

  const informationItems = () => {
    return fieldsToRender.map((item: any, index: number) => (
      <div className="info-item" key={index}>
        <span className="info-item__label">{item.label}:</span>
        <span className="info-item__text">{item.value ? item.value : "---"}</span>
      </div>
    ));
  };

  const navigateToDashboard = () => {
    navigate("/");
  };

  const handleApprove = () => {
    const id = CandidateStore.newInfo.uid || CandidateStore.uid;

    onApprove(id).then(() => navigateToDashboard());
  };

  const handleDecline = () => {
    const id = CandidateStore.newInfo.uid || CandidateStore.uid;

    onDecline(id).then(() => navigateToDashboard());
  };

  const closeModalOnEsc = () => {
    const closeModalOnKeyPressed = (e: any) => {
      if (e.key === "Escape") {
        setIsConfirmOpen(false);
      };
    };

    document.addEventListener("keydown", closeModalOnKeyPressed, { once: true });
  };

  const openModal = (title: string, action: Function) => {
    setIsConfirmOpen(true);
    setModalTitle(title);
    setModalAction({ action: async () => action() });
    closeModalOnEsc();
  };


  const showApproveMessage = () => {
    openModal("Are you sure to confirm this candidate?", handleApprove);
  };

  const showDeclineMessage = () => {
    openModal("Are you sure to decline this candidate?", handleDecline);
  };

  return (
    <Wrap>
      <VideoWrap>
        <H2>Video</H2>
        {(CandidateStore.newInfo?.media || CandidateStore.newInfo?.mediaSecond) ?
          <>
            {CandidateStore.newInfo?.media &&
              <Grid item md={5} xs={12}>
                <VideoHead>
                  <VideocamIcon style={{ marginRight: 10, fill: `${(props: ThemeProps<Theme>) => props.theme.palette.primary.main}` }} />
                  <span>About first video</span>
                </VideoHead>
                <VideoItem>
                  <ReactPlayer
                    url={CandidateStore.newInfo?.media}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                  />
                </VideoItem>
              </Grid>
            }
            {CandidateStore.newInfo?.mediaSecond &&
              <Grid item md={5} xs={12}>
                <VideoHead>
                  <VideocamIcon style={{ marginRight: 10, fill: `${(props: ThemeProps<Theme>) => props.theme.palette.primary.main}` }} />
                  <span>About second video</span>
                </VideoHead>
                <VideoItem>
                  <ReactPlayer
                    url={CandidateStore.newInfo?.mediaSecond}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                  />
                </VideoItem>
              </Grid>
            }
          </>
          : <p>No video!</p>}
      </VideoWrap>
      <Information>
        <H2>Information</H2>
        {informationItems()}
      </Information>

      <Button
        onClick={() => showApproveMessage()}
        style={{ marginRight: 20 }}
        variant="contained">
        Approve
      </Button>

      <Button
        onClick={() => showDeclineMessage()}
        variant="contained"
        color="secondary">
        Decline
      </Button>

      {isConfirmOpen && (
        <ModalConfirm
          setActive={setIsConfirmOpen}
          windowTitle={modalTitle}
          disableStates={[setIsConfirmOpen]}
          callback={modalAction.action}
        />
      )}
    </Wrap>
  );
});

export default NeedApproval;
