import network from "../../api/network";

const fetchReports = (url: string, amount?: boolean) => {
  return async () => {
    if (amount) {
      const response = await network.get(url, {
        params: { amountOnly: true }
      });
      return response.data;
    }

    const response = await network.get(url, {
      params: { type: "votes" }
    });

    return response.data;
  };
};

export default fetchReports;