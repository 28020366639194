import { makeAutoObservable } from "mobx";
import { IDashboardArray } from "../types/type";

class DashboardStore {
  type: string = "active";
  candidateType:string ="mp-candidate";
  list: Array<IDashboardArray | null> = [];
  page: number = 1;
  rowsPerPage: number = 5;
  amount: number = 0;
  nextKey?: string | null;
  prevKey?: string | null;
  disabled: boolean = false;
  searchValue: { [key: string]: string } = {
    name: "",
    electionCode: "",
    mfa: "",
    district: "",
    party: "",
    phone: "",
  };

  _filled: boolean = false;
  _onceRendered: boolean = false;
  _timeMark: number = 0;
  _itemsInAction: Set<string> = new Set();

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  }

  resetStorage() {
    this.disabled = false;
    this.page = 1;
    this.rowsPerPage = 5;
    this._filled = false;
    this._timeMark = 0;
    this._itemsInAction = new Set();
  }

  getOpenButtonTitle(value: any) {
    return value ? "Approve required" : "View full information";
  }

  get isStorageEmpty() {
    return this.list.length === 0;
  }

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  }

  setTimeMark(mark: number) {
    this._timeMark = mark;
  }

  get lastUserRequest() {
    return this._timeMark;
  }

  setCandidateType = (candidateType: string) => {
    this.candidateType = candidateType
  }

  setInQueue(id: string) {
    this._itemsInAction.add(id);
  }

  removeFromQueue(id: string) {
    this._itemsInAction.delete(id);
  }

  get isRequireToUpdate() {
    return (
      this.list.includes(null) &&
      this._itemsInAction.size === 0 &&
      !this.disabled
    );
  }

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [
        this.page,
        this.rowsPerPage,
        this.type,
        this.candidateType,
        this.searchValue,
        this.nextKey,
        this.prevKey,
      ];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
      type: this.type,
      candidateType: this.candidateType,
      searchValue: this.searchValue,
      nextKey: this.nextKey,
      prevKey: this.prevKey,
    };
  }

  get isFilled() {
    return this._filled;
  }

  get isRendered() {
    return this._onceRendered;
  }

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  }
  updatenextKey() {
    if (
      this.list !== null &&
      this.list.length > 0 &&
      this.list[this.list?.length - 1]?.id
    )
      this.nextKey = this.list[this.list.length - 1]?.id;
  }

  setList = (newList: IDashboardArray[]) => {
    this.list = newList;
    this.prevKey = this.list[0]?.id;
    this._filled = true;
  };

  setPage = (page: number) => {
    if (!this.disabled) {
      this.page = page;
      this.updatenextKey();
      this._filled = false;
    }
  };

  removeItemLocally(uid: string) {
    this.list = this.list.filter((item) => item?.id !== uid);
    this.list.push(null);
  }

  setRowsPerPage = (rows: number) => {
    if (!this.disabled) {
      this.rowsPerPage = rows;
      this.page = 1;
      this.nextKey = null;
      this.prevKey = null;
      this._filled = false;
    }
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  setType = (type: string) => {
    this.type = type;
    this._filled = false;
  };

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

  setSearchValue = (obj: { [key: string]: string }) => {
    this.searchValue = obj;
    this._filled = false;
  };
}

export default new DashboardStore();
