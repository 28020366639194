import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { observer } from "mobx-react-lite";
import { RouteList } from "../../../utils/adminType";
import { LawArticleItem, LawArticleItemContainer } from "./LawContentStyle";
import EditIcon from "@mui/icons-material/Edit";
import useFetchLaws from "../../../api/useFetch/useFetchLaws";
import GavelIcon from '@mui/icons-material/Gavel';
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import ModalConfirm from "../../../common/Modal/ModalConfirm";

const LawArticleLink = observer(({ id, data, updateLaw, loading, lawId }: any) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { deleteLawArticle, loading: deletingArticle } = useFetchLaws();

  const openArticle = () => {
    navigate(`${RouteList.lawArticle}/${lawId}/articles/${id}`);
  };

  const editArticle = () => {
    navigate(`${RouteList.lawArticleEdit}/${lawId}/articles/edit/${id}`);
  };

  const deleteArticle = async () => {
    await deleteLawArticle(id, lawId);
    await updateLaw(lawId);
  };

  return (
    <LawArticleItemContainer>
      <LawArticleItem title={data.title} arrow>
        <Button
          data-testid={`law-content-open-article-${id}`}
          onClick={openArticle}
          style={{ textTransform: "revert" }}
        >
          <GavelIcon style={{ marginRight: 10 }} />
          {data.title}
        </Button>
      </LawArticleItem>
      <Tooltip title="Edit" arrow>
        <Button data-testid={`law-content-edit-article-${id}`} onClick={editArticle}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Delete" arrow>
        <Button data-testid={`law-content-delete-article-${id}`} onClick={() => setOpen(true)}>
          <DeleteIcon />
        </Button>
      </Tooltip>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={"Are you sure to delete law article?"}
          disabled={loading || deletingArticle}
          disableStates={[setOpen]}
          callback={deleteArticle}
        />
      )}

    </LawArticleItemContainer>
  );
});

export default LawArticleLink;
