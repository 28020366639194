import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import EpisodeForm from "../components/Form/EpisodeForm/EpisodeForm";
import { useNavigate } from "react-router-dom";
import EpisodeFormStore from "../store/EpisodeFormStore";
import WarningStore from "../store/WarningStore";
import useFetchPodcasts from "../api/useFetch/useFetchPodcasts";
import { observer } from "mobx-react-lite";
import { RouteList } from "../utils/adminType";
import navigator from "../utils/navigator";

const AddEditEpisode = observer(() => {
  const { loading: gettingPodcast, getPodcast } = useFetchPodcasts();
  const { loading, addEpisode } = useFetchPodcasts();
  const { loading: editingEpisode, editEpisode } = useFetchPodcasts();
  const { id, podcastId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    EpisodeFormStore.setData("currentId", id);
    EpisodeFormStore.setData("podcastId", podcastId);

    if (id && EpisodeFormStore.isCreateModeOn) {
      EpisodeFormStore.setMode("edit");

      getPodcast(podcastId)
        .then((res: any) => {
          const episodeData = res?.episodes[id];

          if (res.image) {
            EpisodeFormStore.setData("podcastIcon", res.image);
          }

          if (episodeData) {
            EpisodeFormStore.setOldFileName(episodeData.fileName);
            delete episodeData.fileName;
            EpisodeFormStore.initiate(episodeData);
          } else {
            EpisodeFormStore.setError();
          }
        });
    } else {
      getPodcast(podcastId)
        .then((res) => {
          if (res.image) {
            EpisodeFormStore.setData("podcastIcon", res.image);
          }
        });
    }

    return () => EpisodeFormStore.resetStorage();
  }, []);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const navigateToEpisode = () => {
    navigate(`${RouteList.podcast}/${podcastId}`);
  };

  const onAddEditEpisode = async () => {
    const data = EpisodeFormStore.getData();
    
    if (id) {
      await editEpisode(id, data, podcastId);
    } else {
      await addEpisode(data, podcastId);
    }

    navigateToEpisode();
  };

  return (
    <Stack data-testid="add-edit-episode-page" justifyContent="center" alignItems="center">
      <h1>{EpisodeFormStore.pageTitle}</h1>

      <EpisodeForm
        onAddEdit={() => onAddEditEpisode()}
        onBack={onBackClick}
        isLoading={loading || editingEpisode || gettingPodcast}
      />

    </Stack>
  );
});

export default AddEditEpisode;
