import { makeAutoObservable } from "mobx";

class PodcastsStore {
  list: any = [];
  page: number = 1;
  rowsPerPage: number = 5;
  amount: number = 0;
  disabled: boolean = false;
  podcastData: any = {};
  _error: boolean = false;
  _filled: boolean = false;
  _onceRendered: boolean = false;
  _timeMark: number = 0;
  _itemsInAction: Set<string> = new Set();

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.resetData = this.resetData.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  };

  setTimeMark(mark: number) {
    this._timeMark = mark;
  };

  get lastUserRequest() {
    return this._timeMark;
  };

  setInQueue(id: string) {
    this._itemsInAction.add(id);
  };

  removeFromQueue(id: string) {
    this._itemsInAction.delete(id);
  };

  removeSubItemLocally(id: string) {
    delete this.podcastData.episodes[id];
  };

  get isRequireToUpdate() {
    return this.list.includes(null) && this._itemsInAction.size === 0 && !this.disabled;
  };

  getSettings(asArray: boolean) {
    if (asArray) {
      return [this.page, this.rowsPerPage];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
    };
  };

  removeItemLocally(uid: string) {
    this.list = this.list.filter((item) => item?.id !== uid);
    this.list.push(null);
  };

  setData(podcast: any) {
    this.podcastData = podcast;
  };

  setError() {
    this._error = true;
  };

  resetData() {
    this.podcastData = {};
  };

  get isFilled() {
    return this._filled;
  };

  get isRendered() {
    return this._onceRendered;
  };

  get noErrors() {
    return !this._error;
  };

  get pageTitle() {
    return this.podcastData.title ?? "";
  };

  resetStorage() {
    this.rowsPerPage = 5;
    this.page = 1;
    this.disabled = false;
    this._filled = false;
    this._timeMark = 0;
    this._itemsInAction = new Set();
  };

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  };

  get isStorageEmpty() {
    return this.list.length === 0;
  };

  setList = (newList: any) => {
    this.list = newList;
    this._filled = true;
  };

  setPage = (page: number) => {
    if (!this.disabled) {
      this.page = page;
      this._filled = false;
    }
  };

  setRowsPerPage = (rows: number) => {
    if (!this.disabled) {
      this.rowsPerPage = rows;
      this.page = 1;
      this._filled = false;
    }
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  };

  disable() {
    this.disabled = true;
  };

  enable() {
    this.disabled = false;
  };
};

export default new PodcastsStore();