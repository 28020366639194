import styled from "styled-components";
import { Skeleton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

export const LawArticleItem = styled(Tooltip)`
  width: 100%;
  justify-content: flex-start;
  background-color: rgba(85, 174, 126, 0.2);
  margin-right: 5px;
`;

export const LawArticleItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Wrap = styled.div`
  width: 530px;
  max-width: 100%;
  display: block;
`;

export const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
`;

export const SkeletonParty = styled(Skeleton)`
  border-radius: 5px;
  width: 530px;
  height: 601px;
  max-width: 100%;
`;