import React from "react";
import Modal from ".";
import { Box, Button } from "@mui/material";
import { ModalAlertProps } from "../../types/type";

const style = {
  width: "100%",
  bgcolor: 'background.default',
  boxShadow: 3,
  borderRadius: '5px',
  textAlign: 'center',
};

const cancelButtonStyles = {
  borderRadius: '4px',
  fontSize: "0.9375rem",
  minHeight: "60.25px"
};

const ModalAlert = ({
  closeButtonTitle,
  setActive,
  windowTitle,
  disableStates,
  callback,
  informative
}: ModalAlertProps): JSX.Element => {
  return (
    <Modal
      active={setActive}
      setActive={() => setActive(false)}
      title={windowTitle}>
      <Box sx={style}>
        <Button
          sx={{ width: 150 }}
          variant={informative ? "contained" : "outlined"}
          color={informative ? "primary" : "error"}
          type="button"
          data-testid="modal-alert-close"
          style={cancelButtonStyles}
          onClick={() => {
            disableStates.forEach((state) => state(false));
            callback();
          }}>
          {closeButtonTitle ? closeButtonTitle : "Ok"}
        </Button>
      </Box>
    </Modal>
  );
}

export default ModalAlert;