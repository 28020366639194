import React, { useState } from "react";
import Modal from "../Modal";
import ModalFooterBtnGroup from "../Modal/ModalFooterBtnGroup";
import InputText from "../Input/InputText";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { SelectProps } from "../../types/type";
import { SelectPartyList, InputSelect } from "./SelectStyles";
import { TextLabelName } from "../Input/InputStyles";
import { Button } from "@mui/material";

const SelectParty = ({
  name,
  value,
  active,
  label,
  setActive,
  selectList,
  onChange,
  dataTestId,
}: SelectProps): JSX.Element => {
  const [select, setSelect] = useState({ id: "", name: "", code: "" });

  const handleSelect = (item: any) => {
    setSelect(item);
  };

  const submitSelect = () => {
    onChange(select.name, select.code);
    setActive(name);
  };

  const renderSelect = () => {
    return selectList.map((item, index) => (
      <li
        className={(item.id === select.id) ? "active" : ""}
        onClick={() => handleSelect(item)}
        data-testid={`party-${index}`}
        key={item.id}>
        {(item.logo) ? (<img width={40} height={40} src={item.logo} alt="" />) : (<PeopleOutlineIcon />)}
        {item.name}
      </li>
    ));
  };

  return (
    <div data-testid="select-party-modal-window">
      <TextLabelName>{label}</TextLabelName>
      <InputSelect>
        <InputText
          name={name}
          value={value}
          type="text"
          placeholder={label}
          dataTestId={dataTestId}
          readOnly
          onClick={() => setActive(name)}
        />
        <span className="select-label">{label}</span>
      </InputSelect>
      {active && (
        <Modal
          active={active}
          setActive={() => setActive(name)}
          title={label}
        >
          <SelectPartyList>
            {renderSelect()}
          </SelectPartyList>
          <ModalFooterBtnGroup>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setActive(name)}
              data-testid="cancel-party-selection"
            >Cancel</Button>
            <Button
              variant="contained"
              data-testid="SP-select-party"
              onClick={submitSelect}
            >Select</Button>
          </ModalFooterBtnGroup>
        </Modal>
      )}
    </div>
  );
};

export default SelectParty;
