import React, { useState } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddTaskIcon from "@mui/icons-material/AddTask";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { observer } from "mobx-react-lite";
import DashboardStore from '../../../store/DashboardStore';
import { adminType } from "../../../api/network";
import { AdminTypes } from "../../../utils/adminType";
import { IDashboardActionsProps } from "../../../types/type";

const DashboardActions = observer(({
  id,
  onDelete,
  onSuspend,
  onActivate,
}: IDashboardActionsProps) => {
  const currentRoles = adminType();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [open, setOpen] = useState(false);

  const renderActionButton = (types: string[], button: JSX.Element) => {
    if (types.includes(DashboardStore.type)) {
      return button;
    }
  };

  const handleDeleteConfirm = () => {
    setAnchorEl(null)
    setOpen(true);
  };

  const handleOperation = (operation: Function) => {
    return () => {
      setAnchorEl(null);

      DashboardStore.removeItemLocally(id);
      DashboardStore.setInQueue(id);

      operation(id).finally(() => DashboardStore.removeFromQueue(id));
    }
  };

  return (
    <>
      <ActionMenuButton dataTestId={`table-open-menu-${id}`} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {(currentRoles.includes(AdminTypes.superAdmin) ||
          (currentRoles.includes(AdminTypes.admin) &&
            currentRoles.includes(AdminTypes.canEditOtherCandidates))) && (
            <MenuItem
              data-testid={`delete-profile-button-${id}`}
              onClick={handleDeleteConfirm}>
              <DeleteIcon color='error' /> Remove account
            </MenuItem>
          )}


        {(currentRoles.includes(AdminTypes.superAdmin) ||
          (currentRoles.includes(AdminTypes.admin) &&
            currentRoles.includes(AdminTypes.canSuspendCandidates))) &&
          renderActionButton(
            ["active"],
            <MenuItem
              data-testid={`suspend-profile-button-${id}`}
              onClick={handleOperation(onSuspend)}>
              <ThumbDownIcon color="warning" /> Suspend account
            </MenuItem>
          )}

        {(currentRoles.includes(AdminTypes.superAdmin) ||
          (currentRoles.includes(AdminTypes.admin) &&
            currentRoles.includes(AdminTypes.canEditOtherCandidates))) &&
          renderActionButton(
            ["suspended"],
            <MenuItem
              data-testid={`activate-profile-button-${id}`}
              onClick={handleOperation(onActivate)}>
              <AddTaskIcon color="primary" /> Activate account
            </MenuItem>
          )}
      </ActionMenuButton>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          disabled={isDeleting}
          windowTitle="Are you sure to delete this candidate?"
          enableStates={[setIsDeleting]}
          disableStates={[setOpen, setIsDeleting]}
          callback={handleOperation(onDelete)}
        />
      )}
    </>
  );
});

export default DashboardActions;