import axios from "axios";

const token = sessionStorage.getItem("token") || localStorage.getItem("token");

export const adminType = (): string[] => {
  const token = sessionStorage.getItem("token") || localStorage.getItem("token");

  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload).roles;
  } else return [];
};

export const isAuth = !!token;

let network = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: !!token ? token : "",
  },

  baseURL: process.env.REACT_APP_BASE_URL,
});
export const deepLink = process.env.REACT_APP_DEEP_LINK;

const isStaging =
  process.env.REACT_APP_BASE_URL?.includes("staging") ||
  process.env.REACT_APP_BASE_URL?.includes("localhost");

export const forceLogout = () => {
  sessionStorage.removeItem("token");
  localStorage.removeItem("token");
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("suggest");
  localStorage.removeItem("email");
  localStorage.removeItem("suggest");
  localStorage.removeItem("partyManagerCode");
  window.location.reload();
};

// Add a request interceptor
network.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");
    if (isStaging) console.log("out:", config);
    config.headers["Authorization"] = token;
    return config;
  },
  function (error) {
    // Do something with request error
    if (isStaging) console.log("out error:", error);

    return Promise.reject(error);
  }
);

// Add a response interceptor
network.interceptors.response.use(
  function (response) {
    if (isStaging) console.log("in:", response);
    return response;
  },
  function (error) {
    if (isStaging) console.log(error.response);

    if (error.response.data.code === "auth/wrong-password") {
      return;
    }

    if (error.response?.data === "No complaints are available with current type") {
      return;
    }

    if (
      error.response?.data?.message ===
      "Some of the laws are broken or duplicated!"
    ) {
      throw error;
    }

    if (
      error.response.status === 403 &&
      error.response.data ===
        "Your session is expired, due to login from another place"
    ) {
      localStorage.setItem("showNotification", "true");
      forceLogout();
      return;
    }

    if (
      error.status === 401 ||
      `${error}`.indexOf("Request failed with status code 401") !== -1
    ) {
      forceLogout();
    } else if (error.response.status === 409) {
      return Promise.reject(error.response);
    } else {
      if (error.response?.data?.message === "TOO_SHORT") {
        alert(`Phone's number is too short for selected region`);
      } else if (error.response?.data?.message === "TOO_LONG") {
        alert(`Phone's number is too long for selected region`);
      } else {
        alert(
          `Error! ${
            error?.response?.data?.errorInfo?.message ??
            error?.response?.data?.message ??
            error?.response?.data ??
            error
          }`
        );
      }
    }
    return Promise.reject(error);
  }
);

export default network;
