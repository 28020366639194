import React from 'react';
import Stack from '@mui/material/Stack';
import useFetchSupport from "../api/useFetch/useFetchSupport";
import SupportStore from '../store/SupportStore';
import { observer } from "mobx-react-lite";
import SupportTable from '../components/Tables/SupportTable/SupportTable';
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import Paginator from "../components/Paginator/Paginator";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import getSupportChats from '../api/actions/getSupportChats';
import ShowSubLoading from '../utils/ShowSubLoading';

import useAdjustPage from '../hooks/useAdjustPage';
import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const SupportPage = observer(() => {
  const { getSupportList } = useFetchSupport();

  useClearStorage(SupportStore);
  useGetTableData(SupportStore, () => getSupportChats(getSupportList));
  useAdjustPage(SupportStore);

  return (
    <Stack justifyContent="center" alignItems="center" data-testid="change-mode-page">
      <ShowSubLoading storage={SupportStore} />

      <h1>Support</h1>

      <CardBox style={{ width: "100%" }}>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={SupportStore.rowsPerPage}
            amount={SupportStore.amount}
            setRowsPerPage={SupportStore.setRowsPerPage}
            disabled={SupportStore.disabled}
          />
        </ToolbarTable>

        <SupportTable />

        <Paginator
          amount={SupportStore.amount}
          rowsPerPage={SupportStore.rowsPerPage}
          page={SupportStore.page}
          setPage={SupportStore.setPage}
          disabled={SupportStore.disabled}
        />
      </CardBox>
    </Stack>
  );
});

export default SupportPage;