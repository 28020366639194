import { makeAutoObservable } from "mobx";
import { IComplaint } from "../types/type";

class ComplaintsStore {
  type: string = "new";
  list: Array<IComplaint | null> = [];
  stats: any = {};
  page: number = 1;
  rowsPerPage: number = 5;
  amount: number = 0;
  amountFiltered: number = 0;
  counter: number = 0;
  disabled: boolean = false;
  document: any = { url: "" };
  attachmentsList: any[] = [];
  date: string = "";
  approve: boolean = false;
  newCategory: string = "";
  saveButtonDisabled: boolean = true;
  isModalOpen: boolean = false;
  modalTitle: string = "";
  modalAction: any = { action: null };
  location: any = "";
  textData: any = {
    title: "",
    body: "",
    commentary: "",
  };

  searchValue: { [key: string]: string } = {
    mp: "",
    district: "",
    status: "",
    category: "",
    approved: "",
  };

  _itemsInAction: Set<string> = new Set();
  _currentMode: string = "approve";
  _currentIndex: number = 0;
  _filled: boolean = false;
  _onceRendered: boolean = false;
  _timeMark: number = 0;
  _counterInitiated: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
    this.setIndex = this.setIndex.bind(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  }

  setCounter(counter: number) {
    this.counter = counter;
    this._counterInitiated = true;
  }

  get counterIsNotInitiated() {
    return !this._counterInitiated;
  }

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  }

  setTimeMark(mark: number) {
    this._timeMark = mark;
  }

  setSearchValue = (obj: { [key: string]: string }) => {
    this.searchValue = obj;
    this._filled = false;
  };

  get lastUserRequest() {
    return this._timeMark;
  }

  setInQueue(id: string) {
    this._itemsInAction.add(id);
  }

  removeFromQueue(id: string) {
    this._itemsInAction.delete(id);
  }

  get isRequireToUpdate() {
    return (
      this.list.includes(null) &&
      this._itemsInAction.size === 0 &&
      !this.disabled
    );
  }

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [this.page, this.rowsPerPage, this.type, this.searchValue];
    }

    return {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
      type: this.type,
      searchValue: this.searchValue,
    };
  }

  get isCurrentModeReject() {
    return this._currentMode === "reject";
  }

  get getModalAction() {
    return this.modalAction.action;
  }

  get isStatsEmpty() {
    return this.stats.length === 0;
  }
  get isStorageEmpty() {
    return this.list.length === 0;
  }

  resetComplaintData() {
    this.attachmentsList = [];
    this._currentIndex = 0;
    this.document = { url: "" };
    this.date = "";
    this.newCategory = "";
    this.approve = false;
    this.saveButtonDisabled = true;
    this.isModalOpen = false;
    this.modalTitle = "";
    this.modalAction = { action: null };
    this.location = "";
    this._currentMode = "approve";
    this.textData = {
      title: "",
      body: "",
      commentary: "",
    };
  }

  setComplaintMode(mode: string) {
    this._currentMode = mode;
  }

  setTextData({ title, body }: any) {
    this.textData.title = title;
    this.textData.body = body;
  }

  resetStorage() {
    this.disabled = false;
    this.page = 1;
    this.rowsPerPage = 5;
    this._filled = false;
    this._timeMark = 0;
    this._itemsInAction = new Set();
  }

  get isFilled() {
    return this._filled;
  }

  get isRendered() {
    return this._onceRendered;
  }

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  }

  setData(field: string, data: any) {
    this[field] = data;
  }

  get currentIndex() {
    return this._currentIndex;
  }

  setIndex(index: number) {
    this._currentIndex = index;
  }

  setList = (list: Array<IComplaint>) => {
    this.list = list;
    this._filled = true;
  };

  setStats = (stats: any) => {
    console.log(stats);

    this.stats = stats;
    this._filled = true;
  };

  setPage = (n: number) => {
    if (!this.disabled) {
      this.page = n;
      this._filled = false;
    }
  };

  removeItemLocally(uid: string) {
    this.list = this.list.filter((item) => item?.uid !== uid);
    this.list.push(null);

    if (this.type === "new") {
      this.counter -= 1;
    }
  }

  setRowsPerPage = (n: number) => {
    if (!this.disabled) {
      this.rowsPerPage = n;
      this.page = 1;
      this._filled = false;
    }
  };

  setAmountFiltered = (n: number) => {
    this.amountFiltered = n;
  };

  setAmount = (n: number) => {
    this.amount = n;

    if (this.type === "new") {
      this.counter = n;
    }
  };

  setType = (type: string) => {
    this.type = type;
    this._filled = false;
  };

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }
}

export default new ComplaintsStore();
