import ElectionModeStore from "../../store/ElectionModeStore";

const getElectionModes = async (fetcher: Function) => {
  const electionModes = await fetcher();

  ElectionModeStore.setModes(electionModes);

  return electionModes;
};

export default getElectionModes;