import React from 'react';
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import LogsStore from '../store/LogsStore';
import LogsTable from '../components/Tables/LogsTable/LogsTable';
import useFetchLogs from '../api/useFetch/useFetchLogs';
import getLogsList from '../api/actions/getLogsList';
import FilterTable from '../components/Tables/FilterTable/FilterTable';
import ShowSubLoading from '../utils/ShowSubLoading';

import useClearStorage from '../hooks/useClearStorage';
import useGetTableData from '../hooks/useGetTableData';

const LogsPage = observer(() => {
  const { loading, getLogs } = useFetchLogs();

  useClearStorage(LogsStore);
  useGetTableData(LogsStore, () => getLogsList(getLogs));

  return (
    <>
      <ShowSubLoading storage={LogsStore} />

      <h1>Logs list</h1>

      <FilterTable
        searchValue={LogsStore.searchValue}
        setSearchValue={LogsStore.setSearchValue}
        setPage={LogsStore.setPage}
        disabled={LogsStore.disabled || loading}
      />

      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={LogsStore.rowsPerPage}
            amount={LogsStore.amount}
            setRowsPerPage={LogsStore.setRowsPerPage}
            disabled={LogsStore.disabled}
          />
        </ToolbarTable>

        <LogsTable />

        <Paginator
          amount={LogsStore.amount}
          rowsPerPage={LogsStore.rowsPerPage}
          page={LogsStore.page}
          setPage={LogsStore.setPage}
          disabled={LogsStore.disabled}
        />
      </CardBox>
    </>
  );
});

export default LogsPage;