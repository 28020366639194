import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

export default styled(Checkbox)`
  color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
  margin: ${(props: ThemeProps<Theme>) => props.theme.spacing(1)};
  margin-right: 0px;

  &$checked {
    color: #BCE3CE;
  }
`;
