import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { InputPhoneProps } from "../../types/type";
import { PhoneLabel, LabelName, Helper } from "./InputStyles";


function InputPhone({
  onChange,
  value,
  placeholder,
  helperText,
  name,
  label,
  dataTestId
}: InputPhoneProps): JSX.Element {
  const [phone, setPhone] = useState(value);

  useEffect(() => {
    onChange(name, phone);
  }, [phone]);

  return (
    <PhoneLabel data-testid="input-phone-component">
      <LabelName>{label}</LabelName>
      <PhoneInput
        international={true}
        defaultCountry="IQ"
        value={phone}
        onChange={setPhone}
        limitMaxLength={false}
        maxLength={18}
        placeholder={placeholder}
        data-testid={dataTestId}
      />
      {helperText && <Helper data-testid={`error-${dataTestId}`}>{helperText}</Helper>}
    </PhoneLabel>
  );
}

export default InputPhone;
