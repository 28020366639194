import { useHttp } from "../../hooks/useHttp";

const UseFetchElectionMode = () => {
  const { loading, request, error, clearError } = useHttp();

  const getMode = async () => {
    return await request('get', "api/check/electionModes");
  };

  const getCurrentMode = async () => {
    const res = await request('get', "api/getCurrentElectionMode")
    return res.toUpperCase();
  };

  const handleElectionMode = async (mode: string) => {
    const url = (mode === "election") ? 'api/change/deleteElectionMode' : 'api/change/electionMode';

    return await request('post', url, {
      electionMode: mode,
    });
  };

  return {
    loading, 
    error, 
    clearError,
    handleElectionMode,
    getMode,
    getCurrentMode
  };
};

export default UseFetchElectionMode;