import { makeAutoObservable } from "mobx";

class ReportsStore {
  votes: any[] = [];
  candidates: any[] = [];
  amount: number = 0;
  _loadingCandidates: boolean = false;
  _filledAmount: boolean = false;
  _filled: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  };

  get isFilled() {
    return this._filled;
  };

  get isAmountFilled() {
    return this._filledAmount;
  };

  get isCandsLoading() {
    return this._loadingCandidates;
  };

  disable() {
    this._loadingCandidates = true;
  };

  enable() {
    this._loadingCandidates = false;
  };

  setList(field: string, data: any) {
    this[field] = data;
    this._filled = true;
  };

  setAmount({ amount }: any) {
    this.amount = amount;
    this._filledAmount = true;
  };

  resetStorage() {
    this.votes = [];
    this.candidates = [];
    this._loadingCandidates = false;
    this.amount = 0;
    this._filled = false;
    this._filledAmount = false;
  };
};

export default new ReportsStore();