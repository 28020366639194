import React from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import PodcastForm from "../components/Form/PodcastForm/PodcastForm";
import { useNavigate } from "react-router-dom";
import PodcastFormStore from "../store/PodcastFormStore";
import WarningStore from "../store/WarningStore";
import useFetchPodcasts from "../api/useFetch/useFetchPodcasts";
import { observer } from "mobx-react-lite";
import { RouteList } from "../utils/adminType";
import navigator from "../utils/navigator";
import useGetItemData from "../hooks/useGetItemData";

const AddEditPodcastPage = observer(() => {
  const { loading: addingPodcast, addPodcast } = useFetchPodcasts();
  const { loading: gettingPodcast, getPodcast } = useFetchPodcasts();
  const { loading: editingPodcast, editPodcast } = useFetchPodcasts();
  const { id } = useParams();
  const navigate = useNavigate();

  useGetItemData(getPodcast, id, PodcastFormStore);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const navigateToPodcasts = () => {
    navigate(RouteList.podcasts);
  };

  const onAddEditPodcast = async () => {
    const data = PodcastFormStore.getData();

    if (id) {
      await editPodcast(id, data);
    } else {
      await addPodcast(data);
    }

    navigateToPodcasts();
  };

  return (
    <Stack data-testid="add-edit-podcast-page" justifyContent="center" alignItems="center">
      <h1>{PodcastFormStore.componentTitle}</h1>

      <PodcastForm
        onAddEdit={onAddEditPodcast}
        onBack={onBackClick}
        isLoading={addingPodcast || gettingPodcast || editingPodcast}
      />

    </Stack>
  );
});

export default AddEditPodcastPage;
