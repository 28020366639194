import { makeAutoObservable } from "mobx";

class LawFormStore {
  title: string = "";
  source: string = "";
  note: string = "";
  image: string = "";
  fileName: string = "";
  articles: any = {};
  lawEnforcement: string = "";
  legislationType: string = "";
  issueDate: number | string = "";
  legislationDate: number | string = "";
  factNumber?: number | string = "";
  legislationNumber?: number | string = "";
  issuer: string = "";
  
  _currentMode: string = "create";
  _imageEdited: boolean = false;
  _error: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  get modalTitle() {
    return this.isCreateModeOn
      ? "Are you sure you want to create a new law?"
      : "Are you sure you want to edit law?";
  }

  get imageEdited() {
    return this._imageEdited;
  }

  imageEditMode(mode: boolean) {
    this._imageEdited = mode;
  }

  get componentTitle() {
    return `${this.isCreateModeOn ? "Add" : "Edit"} law`;
  }

  getData() {
    return Object.fromEntries(
      Object.entries(this).filter((item) => !item[0].includes("_"))
    );
  }

  setMode(mode: string) {
    this._currentMode = mode;
  }

  get isCreateModeOn() {
    return this._currentMode === "create";
  }

  get noErrors() {
    return !this._error;
  }

  setError() {
    this._error = true;
  }

  setData(field: string, data: any) {
    this[field] = data;
  }

  get isReadyToCreate() {
    if (this.image && !this._imageEdited) {
      return !this._imageEdited;
    }

    const isReady = !(
      this.note &&
      this.source &&
      this.legislationDate &&
      this.issueDate &&
      this.lawEnforcement &&
      this.legislationNumber &&
      this.factNumber &&
      this.legislationType &&
      this.issuer
    );

    return isReady;
  }

  resetStorage() {
    const excludeList = [
      "source",
      "note",
      "image",
      "fileName",
      "_currentMode",
      "_error",
      "_imageEdited",
      "articles",
      "title",
      "issuer",
      "legislationNumber",
      "lawEnforcement",
      "factNumber",
      "legislationType",
      "issueDate",
      "legislationDate",
    ];
    this.legislationType = "";
    this.source = "";
    this.note = "";
    this.lawEnforcement = "";
    this.title = "";
    this.legislationDate = "";
    this.issueDate = "";
    this.legislationNumber = "";
    this.factNumber = "";
    this.issuer = "";
    this.image = "";
    this.fileName = "";
    this._imageEdited = false;
    this._currentMode = "create";
    this._error = false;
    this.articles = {};
    Object.entries(this).forEach((item) => {
      const key = item[0];
      if (!excludeList.includes(key)) {
        delete this[key];
      }
    });
  }

  initiate(data: any) {
    Object.entries(data).forEach((item) => {
      const key = item[0];
      const value = item[1];
      this[key] = value;
    });
  }
}

export default new LawFormStore();
