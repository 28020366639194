import React from 'react';
import NotificationForm from "../components/Form/NotificationForm/NotificationForm";
import Stack from '@mui/material/Stack';
import useFetchNotification from '../api/useFetch/useFetchNotification';
import { useNavigate } from "react-router-dom";
import { RouteList } from '../utils/adminType';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Back } from './AddCandidatePage/AddCandidateStyle';
import { observer } from "mobx-react-lite";
import WarningStore from "../store/WarningStore";
import navigator from '../utils/navigator';

const AddNotification = observer(() => {
  const { loading, notifSent } = useFetchNotification();
  const navigate = useNavigate();

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const onNotificationSent = async ({ title, body, receiver }: any) => {
    await notifSent(title, body, receiver);
  };

  const redirectToNotificationsList = () => {
    navigate(RouteList.notificationsList);
  };

  return (
    <>
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>Back</p>
      </Back>
      <Stack justifyContent="center" alignItems="center">
        <h1>Add new notification</h1>

        <NotificationForm
          onNotificationSent={onNotificationSent}
          isLoading={loading}
          redirect={redirectToNotificationsList}
        />
      </Stack>
    </>
  );
});

export default AddNotification;