import React, { useState } from 'react';
import ActionMenuButton from "../../../UI/Buttons/ActionMenuButton";
import { MenuItem } from "@mui/material";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import { observer } from "mobx-react-lite";
import { ISurveyActionProps } from "../../../types/type";
import EditIcon from '@mui/icons-material/Edit';
import PieChartIcon from '@mui/icons-material/PieChart';
import DeleteIcon from '@mui/icons-material/Delete';
import SurveysStore from "../../../store/SurveysStore";
import closeTableWindow from '../../../utils/closeTableWindow';

const SurveysActions = observer(({
  id,
  onOpen,
  openStatistic,
  onDelete
}: ISurveyActionProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleDeleteConfirm = async () => {
    SurveysStore.removeItemLocally(id);
    SurveysStore.setInQueue(id);

    onDelete(id).finally(() => SurveysStore.removeFromQueue(id));
  };

  const confirmDeletion = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  return (
    <>
      <ActionMenuButton dataTestId={`table-open-menu-${id}`} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          data-testid={`activate-profile-button-${id}`}
          onClick={closeTableWindow(id, onOpen, setAnchorEl)}
        >
          <EditIcon color="primary" /> Edit
        </MenuItem>

        <MenuItem
          data-testid={`suspend-profile-button-${id}`}
          onClick={closeTableWindow(id, openStatistic, setAnchorEl)}
        >
          <PieChartIcon color="primary" /> View statistics
        </MenuItem>

        <MenuItem
          data-testid={`delete-profile-button-${id}`}
          onClick={confirmDeletion}
        >
          <DeleteIcon color="error" /> Delete
        </MenuItem>
      </ActionMenuButton>

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle="Are you sure to delete this survey?"
          disabled={SurveysStore.disabled}
          disableStates={[setOpen]}
          callback={handleDeleteConfirm}
        />
      )}
    </>
  );
});

export default SurveysActions;