import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {CardBox} from "../../../common/Layout/LayoutStyle";
import * as F from "./FilterTableStyle";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select,} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {IFilterTable} from "../../../types/type";
import DistrictsMenu from "./DistrictsMenu";
import InputDate from "../../../common/Input/InputDate";
import MenuList from "./MenuList";
import i18n, {translateLawData} from "../../../utils/i18n";
import {issuersList, legislationTypesList} from "../../../utils/lawConstants";
import DropDownMenuButton from "./MPsMenu";

const FilterTable = ({
                         additionalLists,
                         searchValue,
                         setSearchValue,
                         setPage,
                         disabled,
                         supportMultiLanguage,
                         additionalButtons,
                     }: IFilterTable) => {
    const [fields, setFields] = useState(searchValue);
    const [reset, setReset] = useState(true);

    useEffect(() => {
        setFields(searchValue);
        return () => {
            resetFilter()
        }
    }, [searchValue]);

    const checkIfStorageIsEmpty = (storage: any) => {
        return Object.values(storage).join("") !== "";
    };

    useEffect(() => {
        if (checkIfStorageIsEmpty(searchValue) || checkIfStorageIsEmpty(fields)) {
            setReset(false);
        } else {
            setReset(true);
        }
    }, [searchValue, fields]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSearchValue(fields);
        setPage(1);
    };

    const resetFilter = () => {
        const resetFields = {...fields};

        Object.keys(resetFields).map((key) => {
            resetFields[key] = "";
            setFields((fields) => ({...fields, [key]: ""}));
        });

        if (checkIfStorageIsEmpty(searchValue)) {
            setSearchValue(resetFields);
            setPage(1);
        }
    };

    const handleChangeInput = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = e.target.name;
        const value = e.target.value;

        setFields((fields) => ({
            ...fields,
            [name]: value,
        }));
    };

    const handleSelect = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => {
        const value = e.target.value;

        setFields((fields) => ({
            ...fields,
            [field]: translateLawData(i18n.language, value),
        }));
    };

    const onDistrictSelect = (data, field) => {
        console.log(fields, additionalLists);

        setFields((fields) => ({
            ...fields,
            [field]: translateLawData(i18n.language, data),
        }));
    };

    const onChangeSelect = (name: any, value: any) => {
        setFields((fields) => ({
            ...fields,
            [name]: String(value.toDateString()),
        }));
    };

    const getFormattedValue = (value) => {
        if (Array.isArray(value)) {
            const example = value[0];
            const formattedValue = example?.split("_N_")[0];

            return formattedValue[0].toUpperCase() + formattedValue.slice(1);
        }

        return value;
    };


    const additionalButtonsRender = () => {
        if (additionalButtons)
            return additionalButtons.map((item, index) => (
                <Wrapper index={index}>{item}</Wrapper>
            ));
    };

    const renderField = () => {
        return Object.keys(fields).map((item, index) => {
            if (item === "mp" && additionalLists[item]) {
                return (
                    <Wrapper index={index}>
                        <FormControl style={{width: "100%"}}>
                            <InputLabel>{"MP"}</InputLabel>
                            <Select
                                value={fields[item] ?? ""}
                                onChange={(e) => {
                                    setFields((fields) => ({
                                        ...fields,
                                        [item]: e.target.value,
                                        district:
                                            additionalLists[item][`${e.target.value}`]["district"],
                                    }));
                                }}
                                MenuProps={{style: {maxHeight: 350}}}
                                label={"MP"}
                            >
                                {Object.entries(additionalLists[item]).map(
                                    (item: any, index) => (
                                        <MenuItem
                                            disabled={
                                                !!fields["district"] &&
                                                item[1][`district`] !== fields["district"]
                                            }
                                            key={index}
                                            value={item[0]}
                                        >
                                            {item[1][`displayName`]}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Wrapper>
                );
            }
            if (item === "category" && additionalLists[item]) {
                let catlist = fields["district"]
                    ? additionalLists[item][fields["district"]]
                        ? [
                            ...additionalLists[item]["Federal"],
                            ...additionalLists[item][fields["district"]],
                        ]
                        : additionalLists[item]["Federal"]
                    : Object.values(additionalLists[item])
                        .map((item: any) => {
                            return Object.values(item).map((catname: any, index) => {
                                return catname;
                            });
                        })
                        .flat(3);

                return (
                    <Wrapper index={index}>
                        <FormControl style={{width: "100%"}}>
                            <InputLabel>{"Category"}</InputLabel>
                            <Select
                                value={fields[item] ?? ""}
                                onChange={(e) => {
                                    let districtOfCategory: any;

                                    Object.entries(additionalLists[item]).map((dis: any) => {
                                        if (dis[1].indexOf(e.target.value) !== -1) {
                                            districtOfCategory = dis[0];
                                        }
                                    });

                                    setFields((fields) => ({
                                        ...fields,
                                        [item]: e.target.value,
                                        district:
                                            districtOfCategory === "Federal"
                                                ? fields["district"]
                                                : districtOfCategory,
                                    }));
                                }}
                                MenuProps={{style: {maxHeight: 350}}}
                                label={"Category"}
                            >
                                {catlist.map((catname: any, index) => (
                                    <MenuItem key={index} value={catname}>
                                        {catname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Wrapper>
                );
            }
            if (item === "status") {
                return (
                    <Wrapper index={index}>
                        <DropDownMenuButton
                            value={fields[item]}
                            list={[
                                "",
                                "SEEN",
                                "IN_PROGRESS",
                                "RESOLVED",
                                "NOT_GOING_TO_BE_DONE",
                            ]}
                            title={"Status"}
                            onChange={(e) => {
                                console.log(e, fields);

                                setFields((fields) => ({
                                    ...fields,
                                    [item]: e,
                                }));
                            }}
                        />
                    </Wrapper>
                );
            }
            if (item === "approved") {
                return (
                    <Wrapper index={index}>
                        <DropDownMenuButton
                            value={fields[item]}
                            list={["All", "New", "Rejected", "Approved"]}
                            title={"Approved"}
                            onChange={(e) => {
                                console.log(e);

                                setFields((fields) => ({
                                    ...fields,
                                    [item]: e,
                                }));
                            }}
                        />
                    </Wrapper>
                );
            }
            if (item === "district") {
                return (
                    <Wrapper index={index}>
                        <DistrictsMenu
                            value={getFormattedValue(fields[item])}
                            onChange={(
                                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => onDistrictSelect(e, "district")}
                        />
                    </Wrapper>
                );
            }

            if (item === "lawEnforcement") {
                return (
                    <Wrapper index={index}>
                        <MenuList
                            value={i18n.t(fields[item])}
                            onChange={(
                                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => handleSelect(e, item)}
                            list={[i18n.t("valid"), i18n.t("not_valid")]}
                            title={
                                supportMultiLanguage
                                    ? i18n.t("enforcement_of_the_law")
                                    : "Law Enforcement"
                            }
                        />
                    </Wrapper>
                );
            }

            if (item === "issuer") {
                return (
                    <Wrapper index={index}>
                        <MenuList
                            value={i18n.t(fields[item])}
                            onChange={(
                                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => handleSelect(e, item)}
                            list={issuersList.map((item) => i18n.t(item))}
                            title={supportMultiLanguage ? i18n.t("issuer") : "Issuer"}
                        />
                    </Wrapper>
                );
            }

            if (item === "legislationType") {
                return (
                    <Wrapper index={index}>
                        <MenuList
                            value={i18n.t(fields[item])}
                            onChange={(
                                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => handleSelect(e, item)}
                            list={legislationTypesList.map((item) => i18n.t(item))}
                            title={
                                supportMultiLanguage
                                    ? i18n.t("legislation_type")
                                    : "Legislation Type"
                            }
                        />
                    </Wrapper>
                );
            }

            if (
                item === "date" ||
                item === "legislationDate" ||
                item === "issueDate"
            ) {
                const getLabel = () => {
                    if (item === "date") {
                        return "";
                    }
                    return item === "legislationDate" ? "legislation_date" : "issue_date";
                };

                return (
                    <Grid
                        style={{position: "relative"}}
                        key={index}
                        item
                        xl={2}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                    >
                        <InputDate
                            name={item}
                            label={supportMultiLanguage ? i18n.t(getLabel()) : getLabel()}
                            value={fields[item] === "" ? null : fields[item]}
                            fullHeight={item === "date"}
                            onChange={onChangeSelect}
                            minYears={0}
                            maxYears={75}
                            additionalStyles={
                                item !== "date" ? {position: "absolute", top: 9} : {}
                            }
                        />
                    </Grid>
                );
            }

            const getLabel = () => {
                let label = item.replace(/([a-z])([A-Z])/g, "$1 $2");

                if (label === "law Title") {
                    label = "legislative_title";
                }

                return label;
            };
            const label = getLabel();

            return (
                <Wrapper index={index}>
                    <F.Field
                        fullWidth
                        name={item}
                        label={
                            supportMultiLanguage
                                ? i18n.t(label.split(" ").join("_").toLowerCase())
                                : label
                        }
                        variant="outlined"
                        type="text"
                        value={fields[item]}
                        onChange={(e) => handleChangeInput(e)}
                    />
                </Wrapper>
            );
        });
    };

    return (
        <CardBox>
            <React.StrictMode>
                <F.Title>Filter</F.Title>

                <form onSubmit={(e) => onSubmit(e)}>
                    <Grid container spacing={2}>
                        {renderField()}
                        {additionalButtonsRender()}

                        <Grid item xs={12}>
                            <LoadingButton
                                loading={disabled}
                                disabled={disabled}
                                type="submit"
                                variant={"contained"}
                                sx={{marginRight: 2}}
                            >
                                Filter
                            </LoadingButton>
                            <Button
                                type="button"
                                onClick={resetFilter}
                                disabled={reset || disabled}
                            >
                                Reset filter
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </React.StrictMode>
        </CardBox>
    );
};

const Wrapper = ({index, children}) => {
    return (
        <Grid key={index} item xl={2} lg={3} md={4} sm={6} xs={12}>
            {children}
        </Grid>
    );
}
export default FilterTable;
