import { useHttp } from "../../hooks/useHttp";

const useFetchEducation = () => {
  const { loading, request, error, clearError } = useHttp();

  const getEducationList = async () => {
    return await request("get", "api/geteducation");
  };

  return {
    loading,
    error,
    clearError,
    getEducationList
  };
}

export default useFetchEducation;