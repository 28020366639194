export const issuersList = [
  // "all",
  "the_federal_government",
  "kurdistan_region",
  "iraq_federal",
];
export const legislationTypesList = [
  "constitution",
  "law",
  "international_agreements",
  "governing_council_decisions",
  "federal_court_decisions",
  "council_decisions",
  "representatives_cabinet_decisions",
  "resolution_of_the_revolutionary_command_council",
  "coalition_authority_orders",
  "systems",
  "instructions",
  "state_council_decisions",
];
