import React from 'react';
import { CardBox, ToolbarTable } from "../common/Layout/LayoutStyle";
import ShowEntries from "../UI/ShowEntries/ShowEntries";
import { observer } from "mobx-react-lite";
import Paginator from "../components/Paginator/Paginator";
import NewsStore from '../store/NewsStore';
import NewsTable from "../components/Tables/NewsTable/NewsTable";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from "@mui/material";
import useFetchNews from '../api/useFetch/useFetchNews';
import { useNavigate } from "react-router-dom";
import getNewsList from '../api/actions/getNewsList';
import ShowSubLoading from '../utils/ShowSubLoading';
import useAdjustPage from '../hooks/useAdjustPage';
import AlignedButtonText from '../common/Text/AlignedButtonText';
import useCompleteTableData from '../hooks/useCompleteTableData';
import { RouteList } from '../utils/adminType';
import useGetTableData from '../hooks/useGetTableData';
import useClearStorage from '../hooks/useClearStorage';

const NewsPage = observer(() => {
  const navigate = useNavigate();
  const { getNews } = useFetchNews();
  const { loading, getNewsShareAmount } = useFetchNews();
  const { deleteNews } = useFetchNews();

  useClearStorage(NewsStore);
  useAdjustPage(NewsStore);
  useGetTableData(NewsStore, () => getNewsList(getNews));
  useCompleteTableData(NewsStore, getNews);

  const getSharesAmountHandler = (id: string) => {
    if (loading) {
      return;
    }

    return getNewsShareAmount(id)
      .then(({ id, sharesAmount }) => {
        NewsStore.setShareAmount(id, sharesAmount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onOpen = (id: string) => {
    navigate(`${RouteList.editNews}/${id}`);
  };

  return (
    <>
      <ShowSubLoading storage={NewsStore} />

      <h1>News list</h1>
      <CardBox>
        <ToolbarTable>
          <ShowEntries
            rowsPerPage={NewsStore.rowsPerPage}
            amount={NewsStore.amount}
            setRowsPerPage={NewsStore.setRowsPerPage}
            disabled={NewsStore.disabled}
          />

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate(RouteList.addNews)}
          >
            <AlignedButtonText>
              Add news
            </AlignedButtonText>
          </Button>
        </ToolbarTable>

        <NewsTable
          isLoading={loading}
          getSharesAmount={getSharesAmountHandler}
          onDelete={deleteNews}
          onOpen={onOpen}
        />

        <Paginator
          amount={NewsStore.amount}
          rowsPerPage={NewsStore.rowsPerPage}
          page={NewsStore.page}
          setPage={NewsStore.setPage}
          disabled={NewsStore.disabled}
        />
      </CardBox>
    </>
  );
});

export default NewsPage;