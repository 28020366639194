import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
  display: flex;

  span {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    min-width: 100px;
    margin-left: 5px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  button {
    &:first-child {
      margin-right: 20px;
    }
  }
`;