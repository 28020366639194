import React, { useEffect, useRef } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import * as F from "./LawArticleContentStyle";
import ErrorIcon from "@mui/icons-material/Error";
import { IWithIsLoading } from "../../../types/type";
import { observer } from "mobx-react-lite";
import LawArticleFormStore from "../../../store/LawArticleFormStore";

const LawArticleContent = observer(({ isLoading }: IWithIsLoading) => {
  const textContainer = useRef<any>(null);

  useEffect(() => {
    if (textContainer.current) {
      textContainer.current.innerHTML = LawArticleFormStore.lawArticleData.text;
    }
  }, [LawArticleFormStore.lawArticleData]);

  const renderContent = () => {
    if (isLoading) {
      return <F.SkeletonParty variant="rectangular" animation="wave" />;
    }

    return LawArticleFormStore.noErrors ? (
      <F.Container
        style={{ direction: "rtl" }}
        data-testid="law-article-content-container"
      >
        <F.SubContainer>
          <p>Created at:</p>
          <p>
            {new Date(
              LawArticleFormStore.lawArticleData.createdAt
            ).toLocaleDateString()}
          </p>
        </F.SubContainer>
        {LawArticleFormStore.lawArticleData.image && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="Law article image"
              width={400}
              height={400}
              src={LawArticleFormStore.lawArticleData.image}
              data-testid="law-article-image"
            />
          </div>
        )}
        <F.ArticleContainer ref={textContainer}></F.ArticleContainer>
        {LawArticleFormStore.lawArticleData.views && (
          <F.SubContainer>
            <p>Total amount of views</p>
            <p>{LawArticleFormStore.lawArticleData.views}</p>
          </F.SubContainer>
        )}
      </F.Container>
    ) : (
      <CardBox style={{ textAlign: "center" }}>
        <ErrorIcon color={"error"} />
        <p>There is no laws with such an ID.</p>
        <p>Check the URL in the browser bar.</p>
      </CardBox>
    );
  };

  return <F.Wrap>{renderContent()}</F.Wrap>;
});

export default LawArticleContent;
