import { makeAutoObservable } from "mobx";
import { INotificationArray } from "../types/type";

class NotificationStore {
  list: INotificationArray[] = [];
  page: number = 1;
  rowsPerPage: number = 5;
  amount: number = 0;
  type: string = "notification";
  disabled: boolean = false;
  _filled: boolean = false;
  _onceRendered: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.setRendered = this.setRendered.bind(this);
    this.resetStorage = this.resetStorage.bind(this);
    this.emergencyRender = this.emergencyRender.bind(this);
  };

  emergencyRender(err) {
    this.setAmount(0);
    this.setList([]);
    this.enable();
  };

  getSettings(asArray?: boolean) {
    if (asArray) {
      return [this.page, this.rowsPerPage, this.type];
    }

    return {
      type: this.type,
      page: this.page,
      rowsPerPage: this.rowsPerPage,
    };
  };

  resetStorage() {
    this.rowsPerPage = 5;
    this.page = 1;
    this.disabled = false;
    this._filled = false;
  };

  get isCurrentModeNotification () {
    return this.type === "notification"
  };

  get isFilled() {
    return this._filled;
  };

  get isRendered() {
    return this._onceRendered;
  };

  get isStorageEmpty() {
    return this.list.length === 0;
  };

  setRendered() {
    if (!this._onceRendered) {
      this._onceRendered = true;
    }
  };

  setList = (newList: INotificationArray[]) => {
    this.list = newList;
    this._filled = true;
  };

  setPage = (page: number) => {
    if (!this.disabled) {
      this.page = page;
      this._filled = false;
    }
  };

  setRowsPerPage = (rows: number) => {
    if (!this.disabled) {
      this.rowsPerPage = rows;
      this.page = 1;
      this._filled = false;
    }
  };

  setAmount = (amount: number) => {
    this.amount = amount;
  };

  disable() {
    this.disabled = true;
  };

  enable() {
    this.disabled = false;
  };

  setType = (type: string) => {
    this.type = type;
    this._filled = false;
  };
};

export default new NotificationStore();