import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 575px;
  max-height: 651px;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  border-radius: 5px;
  z-index: 1;
  padding: 32px;
  transform: scale(0.5);
  transition: all .2s ease;
  
  li {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
      ? '#000'
      : '#fff'
    };

    &:hover {
      background: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
      ? '#F4F4F4'
      : props.theme.palette?.primary.main
      };  
    }
  }
`;

export const ModalWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all .2s ease;
  pointer-events: none;
  color: #000;

  &.active {
    transition: all .2s ease;
    opacity: 1;
    pointer-events: all;

    ${ModalBody} {
      transform: scale(1);
      transition: all .2s ease;
    }
  }
`;

export const Title = styled.h2`
  width: 100%;
  margin-top: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 24px;
  text-align: center;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.mode === 'light'
    ? '#000'
    : '#fff'
  };
`;

export const ModalContent = styled.div`
  position: relative;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  margin-left: -9px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #EDF1F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C1CEDC;
    border-radius: 2px;
    border: 2px solid #C1CEDC;
  }
`;