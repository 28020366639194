import React, { useEffect, useState } from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Divider,
} from "@mui/material";
import * as F from "./LawFormStyle";
import Dropzone from "../../../UI/Dropzone/Dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import ModalConfirm from "../../../common/Modal/ModalConfirm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IForm, IDataFormComponent } from "../../../types/type";
import { observer } from "mobx-react-lite";
import LawFormStore from "../../../store/LawFormStore";
import WarningStore from "../../../store/WarningStore";
import Selector from "./Selector";
import { issuersList, legislationTypesList } from "../../../utils/lawConstants";
import i18n from "../../../utils/i18n";
import InputDate from "../../../common/Input/InputDate";
import ImageCropper from "../../../common/ImageCropper/ImageCropper";

const schema = yup
  .object({ title: yup.string().required().max(300) })
  .required();

const LawForm = observer(({ onAddEdit, onBack, isLoading }: IForm) => {
  const [open, setOpen] = useState(false);
  const [opend, setOpend] = useState(false);

  useEffect(() => {
    switchLanguage();
  }, []);

  const onSelect = (field: string, value: any) => {
    return () => {
      LawFormStore.setData(field, value);
      WarningStore.setIsEdited(true);
    };
  };

  const onDateSelect = (field: string, value: any) => {
    const newValue = Date.parse(value);
    LawFormStore.setData(field, newValue);
    WarningStore.setIsEdited(true);
  };

  const issuers = issuersList.map((el) => (
    <F.Text onClick={onSelect("issuer", el)}>
      {i18n.t(el)}
    </F.Text>
  ));

  const legislationTypes = legislationTypesList.map((el) => (
    <F.Text onClick={onSelect("legislationType", el)}>{i18n.t(el)}</F.Text>
  ));

  const switchLanguage = (lang?: any) => {
    if (lang) i18n.changeLanguage(lang);
    else {
      i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
    }
    setOpend(!opend);
  };

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useForm<IDataFormComponent>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const { title }: any = LawFormStore.getData();
    reset({ title });
  }, [LawFormStore.title]);

  const changeFile = (value: string, name: string) => {
    WarningStore.setIsEdited(true);
    LawFormStore.setData("image", value);
    LawFormStore.setData("fileName", name);
    LawFormStore.imageEditMode(false);
  };

  const onTextChange = (e: any) => {
    WarningStore.setIsEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    LawFormStore.setData(name, value);
    setValue(name, value);
  };

  const onNumberChange: any = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (!String(value)) {
      LawFormStore.setData(name, "");
      e.target.value = "";
      return;
    }

    if (!isFinite(value)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
      return;
    }
    LawFormStore.setData(name, value);
    WarningStore.setIsEdited(true);

    setValue(name, value);
  };

  const onSubmit: SubmitHandler<IDataFormComponent> = (dataForm) =>
    setOpen(true);

  return (
    <F.Wrap>
      <Button
        variant="contained"
        onClick={() => switchLanguage()}
        sx={{ marginBottom: 2 }}
      >
        EN | AR
      </Button>
      {isLoading && !LawFormStore.isCreateModeOn ? (
        <F.SkeletonParty variant="rectangular" animation="wave" />
      ) : LawFormStore.noErrors ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardBox>
            <TextField
              fullWidth
              label={i18n.t("legislative_title")}
              variant="outlined"
              sx={{ marginBottom: 3 }}
              inputProps={{ maxLength: 300 }}
              helperText={`${LawFormStore.title.length} / 300`}
              name="title"
              onChange={onTextChange}
              data-testid="legislative-title"
              value={LawFormStore.title}
            />

            <FormLabel>{i18n.t("issuer")}</FormLabel>
            <Selector value={i18n.t(LawFormStore.issuer)}>{issuers}</Selector>

            <FormLabel>{i18n.t("legislation_type")}</FormLabel>
            <Selector value={i18n.t(LawFormStore.legislationType)}>
              {legislationTypes}
            </Selector>
            <br />
            <Divider />
            <br />

            <br />
            <Divider />
            <br />

            <TextField
              fullWidth
              InputProps={{ inputProps: { pattern: "\d*" } }}
              name="legislationNumber"
              type="text"
              required={true}
              value={LawFormStore.legislationNumber ?? ""}
              label={i18n.t("legislation_number")}
              placeholder={i18n.t("legislation_number")}
              onChange={onNumberChange}
              style={{ marginBottom: 20 }}
            />

            <br />

            <TextField
              fullWidth
              name="factNumber"
              type="text"
              required={true}
              value={LawFormStore.factNumber ?? ""}
              InputProps={{ inputProps: { pattern: "\d*" } }}
              label={i18n.t("issue_number")}
              placeholder={i18n.t("issue_number")}
              onChange={onNumberChange}
              style={{ marginBottom: 20 }}
            />

            <br />
            <Divider />
            <br />

            <div>
              <RadioGroup
                sx={{ mt: 3 }}
                aria-label="choice"
                name="lawEnforcement"
                value={
                  LawFormStore.lawEnforcement === "valid"
                    ? 0
                    : LawFormStore.lawEnforcement === "not_valid"
                      ? 1
                      : ""
                }
                // style={{ alignItems: "center" }}
                onChange={(e) => {
                  WarningStore.setIsEdited(true);
                  switch (parseInt(e.target.value)) {
                    case 0:
                      LawFormStore.setData("lawEnforcement", "valid");
                      break;
                    case 1:
                      LawFormStore.setData("lawEnforcement", "not_valid");
                      break;
                    default:
                      LawFormStore.setData("lawEnforcement", "");
                      break;
                  }
                }}
              >
                <FormLabel>{i18n.t("enforcement_of_the_law")}</FormLabel>
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={i18n.t("valid")}
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={i18n.t("not_valid")}
                />
              </RadioGroup>
            </div>

            <br />
            <Divider />
            <br />

            <InputDate
              name="issueDate"
              label={i18n.t("issue_date")}
              value={LawFormStore.issueDate}
              dataTestId="law-issue_date"
              onChange={onDateSelect}
              minYears={0}
              maxYears={99999}
            />

            <InputDate
              name="legislationDate"
              label={i18n.t("legislation_date")}
              value={LawFormStore.legislationDate}
              dataTestId="law-legislation_date"
              onChange={onDateSelect}
              minYears={0}
              maxYears={99999}
            />

            <br />
            <Divider />
            <br />

            <TextField
              fullWidth
              label={i18n.t("source")}
              variant="outlined"
              sx={{ marginBottom: 3 }}
              inputProps={{ maxLength: 150 }}
              helperText={`${LawFormStore.source.length} / 150`}
              name="source"
              onChange={onTextChange}
              data-testid="source"
              value={LawFormStore.source}
            />
            <br />
            <TextField
              multiline={true}
              maxRows={5}
              minRows={2}
              fullWidth
              label={i18n.t("note")}
              variant="outlined"
              sx={{ marginBottom: 3 }}
              inputProps={{ maxLength: 1000 }}
              helperText={`${LawFormStore.note.length} / 1000`}
              name="note"
              onChange={onTextChange}
              data-testid="note"
              value={LawFormStore.note}
            />

            <br />
            <Divider />
            <br />

            <F.Text>Image (optional)</F.Text>

            <Dropzone
              accept={"image/jpg,image/jpeg,image/png"}
              margin={3}
              file={LawFormStore.image}
              changeFile={changeFile}
              dataTestId="law-form-image"
              dontShowPreview={true}
            />

            <ImageCropper
              src={LawFormStore.image}
              storage={LawFormStore}
            />

            <F.ButtonsContainer>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={!isValid || isLoading || LawFormStore.isReadyToCreate}
              >
                {LawFormStore.componentTitle}
              </Button>

              <Button
                type="button"
                onClick={() => onBack()}
                size="large"
                color="error"
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </F.ButtonsContainer>
          </CardBox>
        </form>
      ) : (
        <CardBox style={{ textAlign: "center" }}>
          <ErrorIcon color="error" />
          <p>There is no laws with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </CardBox>
      )}

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={LawFormStore.modalTitle}
          disabled={isLoading}
          disableStates={[setOpen]}
          callback={async () => {
            WarningStore.resetStorage();
            onAddEdit();
          }}
        />
      )}
    </F.Wrap>
  );
});

export default LawForm;
