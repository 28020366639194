import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const Wrap = styled.div`
  margin-top: 55px;
  padding-bottom: 100px;
`;

export const VideoWrap = styled.div`
  margin-bottom: 48px;
`;

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: 0;
  margin-bottom: 30px;

  @media (max-width:700px) {
    font-size: 16px;
  }
`;

export const VideoItem = styled.div`
  width: 100%;
  height: 300px;
  background: #000;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  border: 1px solid #000;
  position: relative;
`;

export const VideoHead = styled.div`
  background: #ffffff;
  color: #000;
  display: flex;
  padding: 20px 15px;
  border-radius: 12px 12px 0 0;
  align-items: center;
  font-size: 13px;
  box-shadow: 0 3px 8px rgba(18, 19, 18, 0.1);

  @media (max-width:700px) {
    font-size: 11px;
  }

  .delete {
    margin-left: auto;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette.secondary.main};
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
  }
`;

export const TitleModal = styled.h2`
  text-align: center;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette.mode === 'light'
    ? '#000'
    : '#fff'
  }
`;

export const TextWrapper = styled.p`
  font-size: 16px;

  @media (max-width:700px) {
    font-size: 14px;
  }
`;

export const Information = styled.div`
  margin-bottom: 50px;

  .info-item {
    display: grid;
    grid-template-columns: 250px 1fr;
    font-size: 14px;
    padding: 13px 0;
    border-bottom: 1px solid #F8F8F8;
    word-break: break-all;

    @media (max-width:700px) {
      font-size: 12px;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &__label {
      margin-right: 5px;
    }

    &__text {
      color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
      font-weight: 600;
    }
  }
`;

export const Approved = styled.div`
  .approved-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 20px;
    border-radius: 100px;

    svg {
      margin-right: 5px;
    }

    &--green {
      color: ${(props: ThemeProps<Theme>) => props.theme.palette.primary.main};
      background: #DFFFEE;
    }

    &--red {
      color: ${(props: ThemeProps<Theme>) => props.theme.palette.secondary.main};
      background: #ffced0;
    }
  }
`;