import React from "react";
import { observer } from "mobx-react-lite";
import useFetchSurveys from "../api/useFetch/useFetchSurveys";
import Stats from "../components/Form/SurveyStatistics";
import { useParams, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import SurveyStatsStore from "../store/SurveyStatsStore";
import useGetItemData from "../hooks/useGetItemData";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Back } from "./AddCandidatePage/AddCandidateStyle";

const SurveysStatsPage = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getSurvey } = useFetchSurveys();

  useGetItemData(getSurvey, id, SurveyStatsStore);

  const onBackClick = () => navigate(-1);

  return (
    <>
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>
          Back
        </p>
      </Back>
      {(SurveyStatsStore.isFailed) ? (
        <Stack alignItems="center" justifyContent="center">
          <p>There is no survey with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </Stack>
      ) : (<Stats />)}
    </>
  );
});

export default SurveysStatsPage;
