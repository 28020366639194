import { makeAutoObservable } from "mobx";
import { defaultValueNews } from "../utils/constants";

class NewsFormStore {
  title: string = "";
  text: string = "";
  image: string = "";
  thumbnail: string = "";
  fileName: string = "";
  sendPush: boolean = false;
  _currentMode: string = "create";
  _error: boolean = false;
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  get modalTitle() {
    return this.isCreateModeOn
      ? "Are you sure to create new news article?"
      : "Are you sure you want to apply current edits on this news article?";
  }

  get pageTitle() {
    return this.isCreateModeOn
      ? "Add news article"
      : "Edit news article";
  }

  get saveButtonTitle() {
    return this.isCreateModeOn
      ? "Add news"
      : "Edit news";
  }

  get loadingDescription() {
    switch (this._currentMode) {
      case "create":
        return "Please wait, creating news article...";
      case "edit":
        return "Please wait, loading news article...";
      case "changing":
        return "Please wait, saving news article edits...";
    }
  }

  getData() {
    return this.fileName
      ? {
        title: this.title,
        text: this.text,
        image: this.image,
        thumbnail: this.thumbnail,
        fileName: this.fileName,
        sendPush: this.sendPush,
      }
      : {
        title: this.title,
        text: this.text,
        sendPush: this.sendPush,
      };
  }

  setMode(mode: string) {
    this._currentMode = mode;
  }

  get isCreateModeOn() {
    return this._currentMode === "create";
  }

  get noErrors() {
    return !this._error;
  }

  setError() {
    this._error = true;
  }

  setData(field: string, data: any) {
    this[field] = data;
  }

  resetStorage() {
    this.initiate(defaultValueNews);
    this._currentMode = "create";
    this._error = false;
  }

  initiate(data: any) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    });
  };
};

export default new NewsFormStore();
