import { useHttp } from "../../hooks/useHttp";
import { IGetList } from "../../types/type";

const useFetchPodcasts = () => {
  const { loading, request, error, clearError } = useHttp();

  const getPocasts = async ({ page, rowsPerPage }: IGetList) => {
    const res = await request('get', 'api/check/podcasts', {
      params: {
        page,
        rowsPerPage,
      }
    });
    return {
      amount: res.amount,
      list: res.list,
    };
  };

  const getPodcast = async (id: string | undefined) => {
    return await request('get', `api/check/podcasts/${id}`);
  };

  const addPodcast = async (data: object) => {
    return await request('put', 'api/create/podcast', { data });
  };

  const addEpisode = async (data: object, podcastId: string | undefined) => {
    return await request('put', `api/create/episode/${podcastId}`, { data });
  };

  const deletePodcast = async (id: string | undefined) => {
    return await request('delete', `api/delete/podcast/${id}`);
  };

  const deleteEpisode = async (id: string | undefined, podcastId: string | undefined) => {
    return await request('delete', `api/delete/podcast/${podcastId}/${id}`);
  };

  const editPodcast = async (id: string | undefined, data: object) => {
    return await request('post', `api/create/podcast/${id}`, { data });
  };

  const editEpisode = async (id: string | undefined, data: object, podcastId: string | undefined) => {
    return await request('post', `api/create/episode/${id}/${podcastId}`, { data });
  };

  const getEpisode = async (id: string | undefined, podcastId: string | undefined) => {
    return await request('get', "api/check/episodes", {
      params: {
        id,
        podcastId,
      }
    })
  };

  return {
    loading,
    error,
    clearError,
    getPocasts,
    getPodcast,
    addPodcast,
    addEpisode,
    deletePodcast,
    deleteEpisode,
    editPodcast,
    editEpisode,
    getEpisode
  }
}

export default useFetchPodcasts;