//@ts-nocheck
import i18n from "i18next";

const resources: any = {
  en: {
    translation: {
      duhok: "Duhok",
      ninawa: "Ninawa",
      erbil: "Erbil",
      sulaymaniyah: "Sulaymaniyah",
      kirkuk: "Kirkuk",
      salahaddin: "Salahaddin",
      diyala: "Diyala",
      anbar: "Anbar",
      baghdad: "Baghdad",
      babil: "Babil",
      wasit: "Wasit",
      karbala: "Karbala",
      najaf: "Najaf",
      maysan: "Maysan",
      dhiqar: "Dhi Qar",
      almuthanaa: "Almuthanaa",
      albasra: "Albasra",
      diwaniyah: "Diwaniyah",
      christian: "Christian",
      subba: "Subba",
      fili: "Fili",
      shabak: "Shabak",
      azidi: "Azidi",

      ///legislation types
      all: "All",
      federal_supreme_court_decision: "Federal Supreme Court Decision",
      governing_council_decision: "Governing Council Decision",
      cabinet_decision: "Cabinet Decision",
      advertisement: "Advertisement",
      statement: "Statement",
      instructions: "Instructions",
      internal_system: "Internal System",
      command: "Command",
      system: "System",
      resolution: "Resolution",
      decree: "Decree",
      resolution_of_the_revolutionary_command_council:
        "Resolution of the Revolutionary Command Council",
      coalition_authority_order: "Coalition Authority Order",
      royal_will: "Royal Will",
      law: "Law",
      constitution: "Constitution",
      agreement: "Agreement",
      constitution: "Constitution",
      law: "Law",
      international_agreements: "International Agreements",
      governing_council_decisions: "Governing Council Decisions",
      federal_court_decisions: "Federal Court Decisions",
      council_decisions: "Council Decisions",
      representatives_cabinet_decisions: "Representatives Cabinet Decisions",
      resolution_of_the_revolutionary_command_council: "Resolution of the Revolutionary Command Council",
      coalition_authority_orders: "Coalition Authority Orders",
      systems: "Systems",
      instructions: "Instructions",
      state_council_decisions: "State Council Decisions",


      //issuer coutnry types
      the_federal_government: "The Federal Government",
      kurdistan_region: "Kurdistan Region",
      iraq_federal: "Iraq - Federal",

      ///doors
      wired_and_wireless_co: "Wired and Wireless Communications",
      postal_and_telecommun: "Postal and Telecommunications",
      foreigners: "Foreigners",
      personal_and_civil_st: "Personal and Civil Status",
      personal_conditions: "Personal Conditions",
      nationality: "Nationality",
      passport: "Passport",
      religious_denominatio: "Religious Denominations and Institutions",
      care_of_minors: "Care of Minors",
      personal_status: "Personal Status",
      civil_affairs: "Civil Affairs",
      acquisition: "Acquisition",
      possession_and_confis: "Possession and Confiscation",
      confiscation_and_seiz: "Confiscation and Seizure of Money",
      housing: "Housing",
      general_works_and_con: "General Works and Contracting",
      public_works: "Public Works",
      excellence: "Excellence",
      roads_and_bridges: "Roads and Bridges",
      media_and_publication: "Media and Publications",
      flags: "Flags",
      publications_and_publ: "Publications and Publications",
      economics_and_plannin: "Economics and Planning",
      economie: "Economie",
      planning: "Planning",
      insurance: "Insurance",
      insurance_companies: "Insurance Companies",
      urban_planning: "Urban Planning",
      administrative_regula: "Administrative Regulation",
      control_devices: "Control Devices",
      administrative_divisi: "Administrative Division",
      general_administratio: "General Administration",
      public_institutions: "Public Institutions",
      public_bodies: "Public Bodies",
      basic_law_and_public_: "Basic Law and Public Liberties",
      holidays_and_public_h: "Holidays and Public Holidays",
      national_flag_and_emb: "National Flag and Emblem",
      medals: "Medals",
      public_liberties: "Public Liberties",
      // constitution: "constitution",
      public_authorities: "Public Authorities",
      governing_council: "Governing Council",
      state_money: "State Money",
      state_property: "State Property",
      rent: "Rent",
      environment: "Environment",
      commercial: "Commercial",
      // advertisement: "advertisement",
      general_trading: "General Trading",
      trading: "Trading",
      comp: "Comp",
      free_zones__exhibitio: "Free Zones Exhibitions and Conferences",
      chambers_of_commerce_: "Chambers of Commerce and Industry",
      investment: "Investment",
      customs: "Customs",
      culture__education_an: "Culture Education and Higher Education",
      scientific_researches: "Scientific Researches",
      education: "Education",
      translation_and_langu: "Translation and Languages",
      higher_education_and_: "Higher Education and Universities",
      youth_and_sports: "Youth and Sports",
      culture_and_arts: "Culture and Arts",
      associations: "Associations",
      trade_unions__associa: "Trade Unions Associations and Federations",
      political_parties: "Political Parties",
      criminal: "Criminal",
      public_etiquette: "Public Etiquette",
      weapons_and_ammunitio: "Weapons and Ammunition",
      sanctions: "Sanctions",
      principles_of_crimina:
        "Principles of criminal trials for the Internal Security Forces",
      pardon: "Pardon",
      alcoholic_drinks: "Alcoholic Drinks",
      drugs: "Drugs",
      principles_of_militar: "Principles of Military Criminal Trials",
      military_sanctions: "Military Sanctions",
      internal_security_for: "Internal Security Forces Penalties",
      anti_terrorism: "Anti-Terrorism",
      juvenile_care: "Juvenile Care",
      political_prisoners_f: "Political Prisoners Foundation",
      integrity_law: "Integrity Law",
      prostitution: "Prostitution",
      combating_human_traff: "Combating Human Trafficking",
      human_rights: "Human Rights",
      consumer_protection: "Consumer Protection",
      foodstuffs: "Foodstuffs",
      civil_service: "Civil Service",
      salaries_and_wages: "Salaries and Wages",
      general_function: "General Function",
      agriculture_and_lives: "Agriculture and Livestock",
      veterinary_and_livest: "Veterinary and Livestock",
      farming: "Farming",
      tobacco: "Tobacco",
      cotton: "Cotton",
      local_authorities: "Local Authorities",
      tourism_and_antiquiti: "Tourism and Antiquities",
      antiquities_and_herit: "Antiquities and Heritage",
      tourism: "Tourism",
      social_affairs: "Social Affairs",
      foreign_affairs_of_th: "Foreign Affairs of the State",
      foreign_affairs: "Foreign Affairs",
      security_council: "Security Council",
      public_health_medicin: "Public Health Medicine and Medicine",
      health: "Health",
      industry: "Industry",
      industrial_organizati: "Industrial Organization and Development",
      standards_and_specifi: "Standards and Specifications",
      fishing: "Fishing",
      energy_and_natural_re: "Energy and Natural Resources",
      energy: "Energy",
      waters: "Waters",
      oil_and_fuel: "Oil and Fuel",
      mines_and_quarries: "Mines and Quarries",
      minerals: "Minerals",
      justice_and_justice: "Justice and Justice",
      circles_of_reform: "Circles of Reform",
      spend: "Spend",
      notary: "Notary",
      judicial_organization: "Judicial Organization",
      federal_supreme_court: "Federal Supreme Court",
      state_shura_council: "State Shura Council",
      real_estate: "Real Estate",
      property: "Property",
      real_estate_registrat: "Real Estate Registration",
      work_and_social_secur: "Work and Social Security",
      social_security: "Social Security",
      a_job: "a Job",
      international_law: "International Law",
      international_convent: "International Conventions and Treaties",
      gambling_and_betting: "Gambling and Betting",
      gambling_gambling_and: "Gambling Gambling and Betting",
      lotteries_and_subscri: "Lotteries and Subscriptions",
      armed_forces: "Armed Forces",
      national_security: "National Security",
      as_mark: "As Mark",
      public_finance: "Public Finance",
      public_debt: "Public Debt",
      taxes_and_fees: "Taxes and Fees",
      general_accounting: "General Accounting",
      arbitrage: "Arbitrage",
      civil: "Civil",
      civil_pleadings: "Civil Pleadings",
      execution: "Execution",
      proof: "Proof",
      embed: "Embed",
      selling_and_renting_s: "Selling and Renting State Money",
      government_debt_colle: "Government Debt Collection",
      forensic_fees: "Forensic Fees",
      public_prosecution: "Public Prosecution",
      shiite_endowment_offi: "Shiite Endowment Office",
      sunni_endowment_diwan: "Sunni Endowment Diwan",
      the_right_book: "The Right Book",
      provincial_district_a: "Provincial District and Sub-district Councils",
      lawyer: "Lawyer",
      the_traffic: "The Traffic",
      judicial_supervision: "Judicial Supervision",
      foreign_judgments_exe: "Foreign Judgments Execution Law",
      decree_permitting_liq:
        "Decree Permitting Liquidation of the Atomic Endowment",
      executive_procedures_:
        "Executive Procedures for the Formation of Regions",
      traffic_and_vehicles: "Traffic and Vehicles",
      banking: "Banking",
      market: "Market",
      banks: "Banks",
      cash: "Cash",
      stock_markets: "Stock Markets",
      investment_promotion: "Investment Promotion",
      money_laundering: "Money Laundering",
      central_bank: "Central Bank",
      commercial_bank: "Commercial Bank",
      loans: "Loans",
      intellectual_property: "Intellectual Property",
      informative: "Informative",
      free_professions: "Free Professions",
      transfer: "Transfer",
      air_transport: "Air Transport",
      maritime_transport: "Maritime Transport",
      land_trasportation: "Land Trasportation",

      /// field names
      categories_list: "Categories List",
      enforcement_of_the_law: "Enforcement of the Law",
      not_expired: "Not Expired",
      not_valid: "Not Valid",
      valid: "Valid",
      issuer: "Issuer",
      legislation_type: "Legislation Type",
      legislation_number: "Legislation Number",
      legislative_title: "Legislative Title",
      issue_number: "Issue Number",
      legislation_date: "Legislation Date",
      issue_date: "Issue Date",
      source: "Source",
      note: "Note",
      law_creation_date: "Date of Creation of the Law",
      total_amount_of_law_views: "Total Amount of Law Views",
      fact_number: "Fact Number",


      /// button titles
      add_article: "Add Article",
    },
  },
  ar: {
    translation: {
      duhok: "دهوك",
      ninawa: "نينوى",
      erbil: "أربيل",
      sulaymaniyah: "السليمانية",
      kirkuk: "كركوك",
      salahaddin: "صلاح الدين",
      // salahaddin: "صلاحالدين",
      diyala: "ديالى",
      anbar: "الأنبار",
      baghdad: "بغداد",
      babil: "بابل",
      wasit: "واسط",
      karbala: "كربلاء",
      najaf: "النجف",
      maysan: "ميسان",
      dhiqar: "ذي قار",
      almuthanaa: "المثنى",
      albasra: "البصرة",
      diwaniyah: "الديوانية",
      christian: "مسيحي",
      subba: "صابئة",
      fili: "فيلي",
      shabak: "شبك",
      azidi: "أيزيديين",

      ///legislation types
      all: "الكل",
      federal_supreme_court_decision: "قرار المحكمة الاتحادية العليا",
      governing_council_decision: "قرار مجلس الحكم",
      cabinet_decision: "قرار مجلس الوزراء",
      advertisement: "اعلان",
      statement: "بيان",
      instructions: "تعليمات",
      internal_system: "نظام داخلي",
      command: "امر",
      system: "نظام",
      resolution: "قرار",
      decree: "مرسوم",
      resolution_of_the_revolutionary_command_council: "قرار مجلس قيادة الثورة",
      coalition_authority_order: "امر سلطة الائتلاف",
      royal_will: "ارادة ملكية",
      law: "قانون",
      constitution: "دستور",
      agreement: "اتفاقية",
      constitution: "Constitution",
      agreement: "Agreement",
      constitution: "دستور",
      law: "قانون",
      international_agreements: "اتفاقيات دولية",
      governing_council_decisions: "قرارات مجلس الحكم",
      federal_court_decisions: "قرارات المحكمة الاتحادية",
      council_decisions: "قرارات مجلس",
      representatives_cabinet_decisions: "النواب - قرارات مجلس الوزراء",
      resolution_of_the_revolutionary_command_council: "قرارات مجلس قيادة الثورة",
      coalition_authority_orders: "اوامر سلطة الائتلاف",
      systems: "انظمة",
      instructions: "تعليمات",
      state_council_decisions: "قرارات مجلس الدولة",

      //issuer coutrny types
      the_federal_government: "الحكومه الاتحاديه",
      kurdistan_region: "اقليم كوردستان",
      iraq_federal: "العراق - اتحادي",
      ///doors
      wired_and_wireless_co: "اتصالات سلكية ولاسلكية",
      postal_and_telecommun: "بريد واتصالات سلكية ولاسلكية",
      foreigners: "اجانب",
      personal_and_civil_st: "احوال شخصية ومدنية",
      personal_conditions: "احوال شخصية",
      nationality: "الجنسية",
      passport: "جواز سفر",
      religious_denominatio: "طوائف ومؤسسات دينية",
      care_of_minors: "رعاية القاصرين",
      personal_status: "الاحوال الشخصية",
      civil_affairs: "الاحوال المدنية",
      acquisition: "الاستملاك",
      possession_and_confis: "استملاك ومصادرة",
      confiscation_and_seiz: "مصادرة وحجز اموال",
      housing: "اسكان",
      general_works_and_con: "اشغال عامة ومقاولات",
      public_works: "اشغال عامة",
      excellence: "امتياز",
      roads_and_bridges: "طرقات وجسور",
      media_and_publication: "اعلام ومطبوعات",
      flags: "اعلام",
      publications_and_publ: "منشورات ومطبوعات",
      economics_and_plannin: "اقتصاد وتخطيط",
      economie: "اقتصاد",
      planning: "تخطيط",
      insurance: "التأمين",
      insurance_companies: "شركات تامين",
      urban_planning: "تخطيط عمراني",
      administrative_regula: "التنظيم الاداري",
      control_devices: "اجهزة الرقابة",
      administrative_divisi: "تقسيم اداري",
      general_administratio: "ادارة عامة",
      public_institutions: "مؤسسات عامة",
      public_bodies: "هيئات عامة",
      basic_law_and_public_: "القانون الاساسي والحريات العامة",
      holidays_and_public_h: "اعياد وعطل رسمية",
      national_flag_and_emb: "علم وشعار وطني",
      medals: "اوسمة",
      public_liberties: "حريات عامة",
      // constitution: "دستور",
      public_authorities: "سلطات عامة",
      governing_council: "مجلس الحكم",
      state_money: "اموال الدولة",
      state_property: "املاك الدولة",
      rent: "الايجار",
      environment: "بيئة",
      commercial: "تجاري",
      // advertisement: "اعلان",
      general_trading: "تجارة عامة",
      trading: "التجارة",
      comp: "شركات",
      free_zones__exhibitio: "مناطق حرة ومعارض ومؤتمرات",
      chambers_of_commerce_: "غرف التجارة والصناعة",
      investment: "الاستثمار",
      customs: "الكمارك",
      culture__education_an: "ثقافة وتربية وتعليم عالي",
      scientific_researches: "ابحاث علمية",
      education: "تربية وتعليم",
      translation_and_langu: "ترجمة ولغات",
      higher_education_and_: "تعليم عالي وجامعات",
      youth_and_sports: "شباب ورياضة",
      culture_and_arts: "ثقافة وفنون",
      associations: "جمعيات",
      trade_unions__associa: "نقابات وجمعيات واتحادات",
      political_parties: "احزاب سياسية",
      criminal: "جنائي",
      public_etiquette: "اداب عامة",
      weapons_and_ammunitio: "اسلحة وذخائر",
      sanctions: "عقوبات",
      principles_of_crimina: "اصول محاكمات جزائية قوى الامن الداخلي",
      pardon: "عفو",
      alcoholic_drinks: "مشروبات كحولية",
      drugs: "ادوية",
      principles_of_militar: "اصول محاكمات جزائية عسكري",
      military_sanctions: "عقوبات عسكري",
      internal_security_for: "عقوبات قوى الامن الداخلي",
      anti_terrorism: "مكافحة الارهاب",
      juvenile_care: "رعاية الأحداث",
      political_prisoners_f: "مؤسسة السجناء السياسيين",
      integrity_law: "قانون النزاهة",
      prostitution: "البغاء",
      combating_human_traff: "مكافحة الاتجار بالبشر",
      human_rights: "حقوق الانسان",
      consumer_protection: "حماية المستهلك",
      foodstuffs: "مواد غذائية",
      civil_service: "الخدمة المدنية",
      salaries_and_wages: "رواتب واجور",
      general_function: "وظيفة عامة",
      agriculture_and_lives: "زراعة وثروة حيوانية",
      veterinary_and_livest: "بيطرة وثروة حيوانية",
      farming: "زراعة",
      tobacco: "تبغ",
      cotton: "قطن",
      local_authorities: "سلطات محلية",
      tourism_and_antiquiti: "سياحة واثار",
      antiquities_and_herit: "اثار وتراث",
      tourism: "سياحة",
      social_affairs: "شؤون اجتماعية",
      foreign_affairs_of_th: "شؤون الدولة الخارجية",
      foreign_affairs: "شؤون خارجية",
      security_council: "مجلس الامن",
      public_health_medicin: "صحة عامة وادوية وطبابة",
      health: "صحة",
      industry: "صناعة",
      industrial_organizati: "تنظيم وتنمية صناعية",
      standards_and_specifi: "مقاييس ومواصفات",
      fishing: "صيد",
      energy_and_natural_re: "طاقة وموارد الطبيعية",
      energy: "طاقة",
      waters: "مياه",
      oil_and_fuel: "نفط ومحروقات",
      mines_and_quarries: "مناجم ومقالع",
      minerals: "معادن",
      justice_and_justice: "عدل وقضاء",
      circles_of_reform: "دوائر الاصلاح",
      spend: "قضاء",
      notary: "كتابة العدل",
      judicial_organization: "التنظيم القضائي",
      federal_supreme_court: "المحكمة الاتحادية العليا",
      state_shura_council: "مجلس شورى الدولة",
      real_estate: "عقاري",
      property: "ملكية عقارية",
      real_estate_registrat: "التسجيل العقاري",
      work_and_social_secur: "عمل وضمان الاجتماعي",
      social_security: "ضمان اجتماعي",
      a_job: "عمل",
      international_law: "قانون دولي",
      international_convent: "اتفاقيات ومعاهدات دولية",
      gambling_and_betting: "قمار ومراهنات",
      gambling_gambling_and: "قمار ومرابحة ومراهنة",
      lotteries_and_subscri: "يانصيب واكتتابات",
      armed_forces: "قوات مسلحة",
      national_security: "امن وطني",
      as_mark: "كمارك",
      public_finance: "مالية عامة",
      public_debt: "دين عام",
      taxes_and_fees: "ضرائب ورسوم",
      general_accounting: "محاسبات عامة",
      arbitrage: "موازنة",
      civil: "المدني",
      civil_pleadings: "مرافعات مدنية",
      execution: "التنفيذ",
      proof: "الاثبات",
      embed: "التضمين",
      selling_and_renting_s: "بيع وايجار اموال الدولة",
      government_debt_colle: "تحصيل الديون الحكومية",
      forensic_fees: "الرسوم العدلية",
      public_prosecution: "الادعاء العام",
      shiite_endowment_offi: "ديوان الوقف الشيعي",
      sunni_endowment_diwan: "ديوان الوقف السني",
      the_right_book: "الكتاب العدول",
      provincial_district_a: "مجالس المحافظات والاقضية والنواحي",
      lawyer: "المحاماة",
      the_traffic: "المرور",
      judicial_supervision: "الاشراف القضائي",
      foreign_judgments_exe: "قانون تنفيذ الاحكام الاجنبية",
      decree_permitting_liq: "مرسوم جواز تصفية الوقف الذري",
      executive_procedures_: "الاجراءات التنفيذية الخاصة بتكوين الاقاليم",
      traffic_and_vehicles: "مرور ومركبات",
      banking: "مصرفي",
      market: "بورصة",
      banks: "مصارف",
      cash: "نقد",
      stock_markets: "اسواق الاوراق المالية",
      investment_promotion: "تشجيع الاستثمار",
      money_laundering: "غسيل الاموال",
      central_bank: "مصرف مركزي",
      commercial_bank: "مصرف تجاري",
      loans: "قروض",
      intellectual_property: "ملكية فكرية",
      informative: "معلوماتية",
      free_professions: "مهن حرة",
      transfer: "نقل",
      air_transport: "نقل جوي",
      maritime_transport: "نقل بحري",
      land_trasportation: "نقل بري",

      /// field names
      categories_list: "لائحه التصنيفات",
      enforcement_of_the_law: "سريــان التــشريع",
      not_expired: "ساري المفعول",
      not_valid: "غير ساري المفعول",
      valid: "ساري المفعول",
      issuer: "  الجهه المصدرة",
      legislation_type: "نوع التــشريــع",
      legislation_number: "رقـم التـشريـــع",
      legislative_title: "عنوان التـشريع",
      issue_number: "رقم الوقائع",
      legislation_date: "تاريخ التشريع ",
      issue_date: "تاريخ العدد ",
      source: "المصدر",
      note: "ملاحظة",
      law_creation_date: "تاريخ إنشاء القانون",
      total_amount_of_law_views: "إجمالي عدد مشاهدات القانون",
      fact_number: "رقم الحقيقة",


      /// button titles
      add_article: "أضف مقالاً",
    },
  },
};

i18n.init({
  lng: "ar",
  fallbackLng: "en",
  resources: resources,
});

export const translateDistrict = (str: string) => {
  if (str) {
    let dst = str.split("_N_")[0].toLowerCase().replaceAll(" ", "");
    if (str.toLowerCase() === "Federal".toLowerCase()) {
      return " فيدرالي "
    } else if (str.toLowerCase() === "Provincial_Ninawa".toLowerCase()) {
      return " نينوى مكونات ";
    } else if (str.toLowerCase() === "Provincial_Wasit".toLowerCase()) {
      return "واسط مكونات ";
    } else {

      if (!str.split("_N_")[1]) {
        return i18n.t(dst);
      }

      return `${i18n.t(dst)} ${str.split("_N_")[1]}`;
    }
  } else return str;
};

export const translateQuota = (str: string) => {
  let dst = str.toLowerCase().replaceAll(" ", "");
  return `${i18n.t(dst)}`;
};

export const translateLawData = (language: string, term: string) => {
  const entries = Object.entries(resources[language].translation);
  const result = entries.filter((item: any) => item[1] === term);

  if (result.length === 0) {
    return term;
  }

  return result[0][0];
};

export default i18n;
