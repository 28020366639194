export default (p: string) => {
  if (p.length < 8) {
    return {
      result: false,
      error: "Not long enough!"
    };
  }

  var obj: any = {};
  var anUpperCase = /[A-Z]/;
  var aLowerCase = /[a-z]/;
  var aNumber = /[0-9]/;
  var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
  obj.result = true;

  var numUpper = 0;
  var numLower = 0;
  var numNums = 0;
  var numSpecials = 0;
  
  for (var i = 0; i < p.length; i++) {
    if (anUpperCase.test(p[i])) numUpper++;
    else if (aLowerCase.test(p[i])) numLower++;
    else if (aNumber.test(p[i])) numNums++;
    else if (aSpecial.test(p[i])) numSpecials++;
  }

  if (numUpper < 1 || numLower < 1 || numNums < 1 || numSpecials < 1) {
    let numUp = numUpper < 1 ? "1 capital letter," : "";
    let numLow = numLower < 1 ? "1 low letter," : "";
    let numNum = numNums < 1 ? "1 number," : "";
    let numSpec = numSpecials < 1 ? "1 special symbol" : "";
    obj.result = false;

    obj.error = `Add more ${numUp} ${numLow} ${numNum} ${numSpec}`;

    return obj;
  }
  return obj;
};