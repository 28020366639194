import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const WorldIcon = () => {
  return (
    <SvgIcon style={{ height: 22, width: 22 }} viewBox="0 0 22 22" color="none">
      <path d="M9.16667 15.5829H6.71825C6.35683 15.5841 5.99877 15.5134 5.66486 15.3751C5.33095 15.2368 5.02783 15.0336 4.77308 14.7772L0.0476667 10.0518C0.0210833 10.3653 0 10.6797 0 10.9996C0.00402582 13.7563 1.0422 16.4111 2.90926 18.4393C4.77633 20.4674 7.3364 21.7212 10.0833 21.9528V16.4996C10.0833 16.2565 9.98676 16.0233 9.81485 15.8514C9.64294 15.6795 9.40978 15.5829 9.16667 15.5829Z" fill="currentColor" />
      <path d="M18.733 3.18848L17.0922 4.82931C16.6616 5.25747 16.0796 5.49858 15.4724 5.50031H14.2083C14.0868 5.50031 13.9702 5.5486 13.8842 5.63455C13.7983 5.72051 13.75 5.83709 13.75 5.95864V6.87531C13.75 7.4831 13.5086 8.06599 13.0788 8.49576C12.649 8.92553 12.0661 9.16698 11.4583 9.16698C11.3368 9.16698 11.2202 9.21526 11.1342 9.30122C11.0483 9.38717 11 9.50375 11 9.62531V10.0836C11 10.3268 11.0966 10.5599 11.2685 10.7318C11.4404 10.9037 11.6736 11.0003 11.9167 11.0003H14.6667C15.396 11.0003 16.0955 11.29 16.6112 11.8058C17.1269 12.3215 17.4167 13.021 17.4167 13.7503V14.6321C17.4167 14.7535 17.4648 14.8698 17.5505 14.9557L19.9567 17.3629C21.4737 15.2414 22.1838 12.6483 21.9595 10.05C21.7352 7.45161 20.5911 5.01858 18.733 3.18848Z" fill="currentColor" />
      <path d="M15.584 14.6323V13.7505C15.584 13.5074 15.4874 13.2742 15.3155 13.1023C15.1436 12.9304 14.9104 12.8338 14.6673 12.8338H11.9173C11.188 12.8338 10.4885 12.5441 9.97277 12.0283C9.45705 11.5126 9.16732 10.8131 9.16732 10.0838V9.62547C9.16732 9.01768 9.40876 8.43478 9.83853 8.00501C10.2683 7.57524 10.8512 7.3338 11.459 7.3338C11.5805 7.3338 11.6971 7.28551 11.7831 7.19956C11.869 7.1136 11.9173 6.99702 11.9173 6.87547V5.9588C11.9173 5.35101 12.1588 4.76812 12.5885 4.33835C13.0183 3.90858 13.6012 3.66713 14.209 3.66713H15.4731C15.5943 3.66642 15.7104 3.61839 15.7967 3.5333L17.3192 2.01072C15.8938 1.00391 14.2487 0.351525 12.5207 0.107677C10.7926 -0.136171 9.03133 0.0355487 7.38292 0.608591C5.73451 1.18163 4.24649 2.13948 3.04233 3.40266C1.83816 4.66585 0.952557 6.19795 0.458984 7.87188L6.06898 13.4819C6.1543 13.5672 6.25563 13.6349 6.36716 13.681C6.47869 13.7271 6.59822 13.7507 6.7189 13.7505H9.16732C9.89666 13.7505 10.5961 14.0402 11.1119 14.5559C11.6276 15.0716 11.9173 15.7711 11.9173 16.5005V21.9537C14.5085 21.7405 16.9396 20.6135 18.7767 18.7738L16.255 16.2521C15.8268 15.8215 15.5857 15.2395 15.584 14.6323Z" fill="currentColor" />
    </SvgIcon>
  );
};

export default WorldIcon;