//@ts-nocheck
import { Icon } from "leaflet";
import React, { useEffect, useState, useRef } from "react";
import { CircularProgress, Typography, Stack, Button } from "@mui/material";
import {
  MapContainer as Map,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  Polygon,
  useMapEvents,
} from "react-leaflet";
import "./map.css";
import iconmarker from "../../assets/images/marker-icon-2x.png";
import complaintmarker from "../../assets/images/complaint-marker-icon-2x.png";
import { LatLngBounds } from "leaflet";
import ava from "../../assets/images/avatar.png";
import { useNavigate } from "react-router-dom";
import polylabel from '@mapbox/polylabel';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { translateDistrict } from "../../utils/i18n";
import { neighbourhoods } from "../../assets/districts/data";
import { ComplaintWrapper, ComplaintContent } from "./MapContainerStyles";
import PartiesStore from "../../store/PartiesStore";
import MapStore from "../../store/MapStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

const StyledPopup = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    padding: 15px;
  }
  & .leaflet-popup-content {
    width: 242px !important;
    margin: 0px;
  }
`;

const marker = new Icon({
  iconUrl: iconmarker,
  iconSize: [35, 55],
});

const complaintMarker = new Icon({
  iconUrl: complaintmarker,
  iconSize: [35, 55],
});

const MapContainer = observer(({
  getCands
}: any) => {
  const [nbhds, setNbhds] = useState([]);
  const [pins, setpins] = useState([]);
  const [mapBounds, setmapBounds] = useState<any>({});
  const [mapCenter, setmapCenter] = useState<any>({});
  const [isLoading, setisLoading] = useState(true);
  const [candidatesLoading, setCandidatesLoading] = useState(false);
  const [districtName, setdistrictName] = useState<any>();
  const [loadingStatus, setloadingStatus] = useState("");
  const [zoom, setZoom] = useState(0);
  const [candidatesList, setcandidatesList] = useState<any>([]);

  const candidatesPopUp: any = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!MapStore?.coords) {
      const district: any = localStorage.getItem("districtName");
      if (district) {
        setdistrictName(district);
      }
    }
    return () => MapStore.resetComplaintData();
  }, []);

  useEffect(() => {
    if (candidatesPopUp.current && (!MapStore?.coords)) {
      const candidateElem: HTMLElement = candidatesPopUp.current;
      const onScroll = (e: any) => {
        localStorage.setItem("scrollHeight", e.target.scrollTop);
      }
      candidateElem.addEventListener("scroll", onScroll);

      return () => candidateElem.removeEventListener("scroll", onScroll);
    }
  }, [candidatesPopUp.current]);

  const paintDistricts = (neighbrhds: any) => {
    neighbrhds = [...new Set(neighbrhds)];
    let provinces: any = [];
    let districts: any = [];

    neighbrhds.map((item: any) => {
      districts.push(item.name);
      provinces.push(item.name.split("_")[0]);
    });

    let startColor: any = {};
    provinces = [...new Set(provinces)];
    districts = [...new Set(districts)];

    let ds = districts.map((o: any) => o.split("_")[0]);
    provinces.forEach((x: any, i: number) => {
      let piece = 360 / provinces.length;
      let sector = (piece - (piece / 100) * 40) * i;
      startColor[x] = {
        start: sector,
        i: provinces.filter((i: any) => i.split("_")[0] === x).length,
        count: ds.filter((i: any) => i === x).length,
      };
    });

    for (const key in startColor) {
      if (Object.hasOwnProperty.call(startColor, key)) {
        let dis = districts.filter((item: any) => item.split("_")[0] === key);
        for (let i = 0; i < startColor[key].count; i++) {
          for (const dn in neighbrhds) {
            if (Object.hasOwnProperty.call(neighbrhds, dn)) {
              if (neighbrhds[dn].name === dis[i]) neighbrhds[dn].order = i + 1;
            };
          };
        };
      };
    };

    neighbrhds.map((item: any) => {
      let rNbh: any = startColor[item.name.split("_")[0]];

      let h = parseInt(rNbh.start + item.order).toFixed(0);
      let s = (80 / rNbh.count) * item.order + 20;
      let l = (30 / rNbh.count) * item.order + 30;
      let newColor = `hsl(${h},${s || 100}%,${l || 50}%)`;

      let hs = parseInt(String(rNbh.start - item.order)).toFixed(0);
      let ss = (100 / rNbh.count) * item.order;
      let ls = (50 / rNbh.count) * item.order + 20;
      let newStrokeColor = `hsl(${hs},${ss || 100}%,${ls || 50}%)`;

      item.color = newColor;
      item.stroke = newStrokeColor;
    });

    setNbhds(neighbrhds);
  };

  const getBoundingBox = (points: any) => {
    let bounds = new LatLngBounds([]);
    for (let i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    return bounds;
  };

  const onAvatarLoadingError = (err) => {
    err.target.src = ava;
  };

  async function handleModalOpen(id: any) {
    setCandidatesLoading(true);
    getCands({ type: "district", id })
      .then((res: any) => {
        setcandidatesList([...new Set(res)]);
        setdistrictName(id);
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setCandidatesLoading(false);
      });
  }

  const hideModal = () => {
    setdistrictName(null);
    setcandidatesList([]);
  };

  const colorize = (item: any, disNames: any) => {
    let count = disNames.filter((i: any) => i === item.province).length || 1;
    let number = item.districtNumber;
    let str = item.province;
    +"" + number;
    let hashProvince: any = 1;
    for (let i = 0; i < str.length; i++) {
      hashProvince = str.charCodeAt(i) + ((hashProvince << 8) - hashProvince);
    }

    let hsl;
    let ran: any = parseInt(String((hashProvince >> 8) & 0xff));
    hsl =
      "hsl(" +
      ran +
      ", " +
      (100 / count) * number +
      "%, " +
      (40 / number + 40) +
      "%)";
    return hsl;
  };

  const onViewInfoClick = (id: string) => {
    navigate(`/edit-candidate/${id}`);
  };

  const ChangeView = () => {
    const map = useMapEvents({
      load: () => {
        map.fitBounds(mapBounds);
        map.setView({ center: mapCenter, zoom: 6 });
        map.setMaxBounds(mapBounds.pad(0.5));
        map.setMinZoom(14);
        map.setMaxZoom(6);
      },
      zoomend: () => {
        setZoom(map.getZoom());
      },
    });
    return null;
  };

  const fartherstCenter = (points: any) => {
    points = points?.map((i: any) => [i.lat, i.lng]);
    return polylabel([points]);
  };

  useEffect(() => {
    const scrollHeight = localStorage.getItem("scrollHeight");
    if (scrollHeight && candidatesPopUp.current) {
      candidatesPopUp.current.scrollTop = scrollHeight;
    }
  });

  const openModalAction = (name: any) => {
    setdistrictName(name);
    localStorage.setItem("districtName", name);
    localStorage.setItem("scrollHeight", "0");
  };

  const openComplaint = () => {
    navigate(`/complaint/${MapStore.id}`);
  };

  useEffect(() => {
    let list = [];
    let pins: any = [];
    let neighbrhds: any = [];
    let newList: any = [];
    let bb;
    let bbc;
    let inc = 0;

    let newListSaved: any = JSON.parse(String(sessionStorage.getItem("newList")));
    let pinsSaved: any = JSON.parse(String(sessionStorage.getItem("pins")));

    let nbhdsSaved: any = [];

    neighbourhoods.map((item) => {
      let name = item.neighbourhoodId.split("; #")[0];
      let districtNumber = item.neighbourhoodId.split("_N_")[1].split("; #")[0];
      let points: any = [];
      JSON.parse(item.points).forEach((element: any) => {
        points.push({
          lng: element[1],
          lat: element[0],
        });
      });
      nbhdsSaved.push({
        name,
        districtNumber,
        points,
      });
    });

    if (nbhdsSaved && pinsSaved && newListSaved) {
      let bb = getBoundingBox(newListSaved.flat());
      let bbc: any = bb.getCenter();
      setmapBounds(bb);
      setmapCenter(bbc);
      setpins(pinsSaved);
      paintDistricts(nbhdsSaved);

      if (nbhdsSaved.length > 0) setisLoading(false);
    } else {
      let disNames: any = [];
      let districts: any = [];

      nbhdsSaved.forEach((element: any) => {
        let d = element.name.split("; #")[0];
        districts.push(`${d}`);
        let province = element.name.split("_N_")[0];
        disNames.push(`${province}`);
      });

      disNames = [...new Set([...disNames])];
      districts = [...new Set([...districts])];

      districts.forEach((element: any) => {
        let dp: any = [];
        nbhdsSaved.forEach((polygon: any) => {
          let polygonName = polygon.name;
          if (element === polygonName) {
            let pnts: any = [];

            polygon.points.forEach((element: any) => {
              pnts.push(element);
            });

            dp.push(pnts.flat(9));
          }
        });
        pins.push({
          name: element,
          coords: fartherstCenter(dp.flat()),
        });
      });

      nbhdsSaved.forEach((polygon: any) => {
        let polygonName = polygon.name;

        let points: any = [];

        polygon.points.forEach((element: any) => {
          points.push(element);

          list.push(element);
        });

        let disColor = colorize(
          {
            province: polygonName.split("_N_")[0],
            district: polygonName.split("_N_")[1],
            districtNumber: polygonName.split("_N_")[1],
          },
          disNames
        );
        inc++;
        newList.push(points.flat(9));

        neighbrhds.push({
          name: polygonName.split("_N_")[0],
          districtNumber: polygonName.split("_N_")[1],
          points: points,
          stroke: disColor,
        });

        bb = getBoundingBox(newList.flat());
        bbc = bb.getCenter();

        sessionStorage.setItem("pins", JSON.stringify(pins));
        sessionStorage.setItem("newList", JSON.stringify(newList));
        sessionStorage.setItem("districts", JSON.stringify(neighbrhds));

        setmapBounds(bb);
        setmapCenter(bbc);
        setpins(pins);
        setloadingStatus(
          `${parseInt(String((100 / nbhdsSaved.length) * inc)).toFixed(0)}%`
        );
      });

      setisLoading(false);
      navigate(0);
    }
  }, []);

  const calculatePolygonWeight = (currentItem: any) => {
    if (MapStore.coords && MapStore?.district !== "Federal") {
      const formattedDistrict = MapStore?.district.split("Provincial_")[1].toLowerCase();
      const formattedItem = currentItem.split("_n_")[0];
      return (formattedItem === formattedDistrict) ? 4 : 1;
    }

    return (districtName?.toLowerCase() === currentItem) ? 4 : 1;
  };

  return (isLoading) ? (
    <Stack style={{ marginTop: "20%", marginBottom: "20%" }} alignItems="center">
      <CircularProgress color="primary" size={200} />
      <Typography style={{ marginTop: 25 }}>{"Please wait, loading map, it may take a while..."}</Typography>
    </Stack>
  ) : (
    <Map
      center={mapCenter}
      maxBounds={mapBounds?.pad(0.7)}
      boundsOptions={{ maxZoom: 6, minZoom: 14 }}
      bounds={mapBounds}
      maxZoom={14}
      minZoom={6}
      id="map-container"
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <ChangeView />
      <TileLayer
        opacity={0.3}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {nbhds.map((item: any) => {
        return (
          <Polygon
            eventHandlers={{
              click: (e) => openModalAction(item.name),
            }}
            key={Math.floor(100000 + Math.random() * (999999 - 100000))}
            pathOptions={{
              color: item.stroke,
              weight: calculatePolygonWeight(item.name.toLowerCase()),
              fillColor: item.color,
            }}
            positions={item.points}
          />
        );
      })}

      {MapStore?.coords && (
        <Marker
          interactive={true}
          icon={complaintMarker}
          aria-describedby="Complaint marker"
          title={MapStore.title}
          position={{
            lng: MapStore.coords[1],
            lat: MapStore.coords[0],
          }}
        >

          <Popup closeButton>
            <ComplaintWrapper
              data-testid="complaint-popup"
              ref={candidatesPopUp}>
              <p>Complaint title:</p>
              <ComplaintContent>
                <p>{MapStore.title ? (MapStore.title) : "This complaint don`t have any title."}</p>
              </ComplaintContent>
              <p>Complaint text:</p>
              <ComplaintContent>
                <p>{MapStore.text}</p>
              </ComplaintContent>
              <Button
                style={{ margin: 0, marginTop: "auto" }}
                data-testid="open-complaint-button"
                onClick={openComplaint}
              >Open complaint</Button>
            </ComplaintWrapper>
          </Popup>

          <Tooltip interactive permanent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              data-testid="complaint-tooltip"
            >
              <span>{MapStore.title || "Currently opened complaint"}</span>
            </div>
          </Tooltip>
        </Marker>)
      }

      {
        pins.map((item: any, i: number) => {
          const formattedName = item.name.replace('_N_', ' ');
          if (
            zoom > 7 ||
            districtName?.toLowerCase() === item?.name.toLowerCase()
          )
            return (
              <Marker
                interactive={true}
                icon={marker}
                aria-describedby={formattedName}
                key={item.name + i}
                title={formattedName}
                position={{
                  lng: item.coords[1],
                  lat: item.coords[0],
                }}
                eventHandlers={{
                  click: (e) => {
                    openModalAction(item.name);
                    handleModalOpen(item.name).then(() => e.target.openPopup());
                  },
                  add: (e) => {
                    if (item.name === districtName) {
                      handleModalOpen(item.name).then(() => e.target.openPopup());
                    }
                  },
                }}
              >
                {districtName?.toLowerCase() === item?.name.toLowerCase() && (
                  <StyledPopup
                    closeButton
                    eventHandlers={{
                      popupclose: () => {
                        hideModal();
                      },
                    }}
                  >
                    <span>{translateDistrict(item?.name)}</span>

                    <div
                      className={"popup-content"}
                      id="popup-content"
                      ref={candidatesPopUp}
                      data-testid="candidates-popup"
                      style={{
                        height: 260,
                        width: 250,
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingRight: 10,
                        overflowY: "scroll",
                        zIndex: 1919001,
                      }}
                    >
                      {candidatesLoading ? (
                        <p>Loading...</p>
                      ) : candidatesList.length > 0 ? (
                        candidatesList
                          ?.map((cand: any, index: number) => {
                            if (
                              cand?.district.toLowerCase() ===
                              `${item?.name.toLowerCase()}`
                            ) {
                              let logoUrl = null;

                              if (cand.party) {
                                logoUrl = PartiesStore.list.filter(
                                  (i) => i.code === cand.party
                                )[0]?.logo;
                              }

                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 6,
                                    paddingBottom: 6,
                                    width: "100%",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #E3E3E3",
                                  }}
                                  data-testid={`candidate-full-link-${index}`}
                                  key={cand?.name?.replace("_N_", " ")}
                                  onClick={() => onViewInfoClick(cand?.id)}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={cand?.avatar || ava}
                                      alt="avatar"
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 15,
                                      }}
                                      onError={onAvatarLoadingError}
                                    />
                                    <p
                                      key={cand?.name?.replace("_N_", " ")}
                                      onClick={() => onViewInfoClick(cand?.id)}
                                    >
                                      {`${cand?.firstName} ${cand?.lastName}`}
                                    </p>
                                  </div>
                                  {logoUrl ? (
                                    <img
                                      src={logoUrl}
                                      alt="logo"
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginLeft: 15,
                                      }}
                                    />
                                  ) : null}
                                  <div
                                    style={{
                                      width: 35,
                                      height: 35,
                                      borderRadius: 8,
                                      backgroundColor: "#F1F4F8",
                                      display: "flex",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ArrowRightAltIcon />
                                  </div>
                                </div>
                              );
                            }
                          })
                          .slice(0, 50)
                      ) : (
                        <p>No one here</p>
                      )}
                      {!candidatesLoading && candidatesList.length > 0 && (
                        <a
                          key={"all"}
                          style={{
                            textAlign: "center",
                            fontWeight: "800",
                            cursor: "pointer",
                          }}
                          data-testid="view-all-candidates"
                          onClick={() => navigate("/", { state: { searchType: "district", searchRequest: `${item?.name}` } })}
                        >
                          See all
                        </a>
                      )}
                    </div>
                  </StyledPopup>
                )
                }

                <Tooltip interactive permanent>
                  <div
                    data-testid={`${item.name}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {districtName?.toLowerCase() === item?.name.toLowerCase() ? (
                      <h1>{`${translateDistrict(item.name)} (${formattedName[0].toUpperCase() + formattedName.slice(1)})`}</h1>
                    ) : (

                      <span>{`${translateDistrict(item.name)} (${formattedName[0].toUpperCase() + formattedName.slice(1)
                        })`}</span>

                    )}
                  </div>
                </Tooltip>
              </Marker>
            );
        })
      }
    </Map >
  );
});

export default MapContainer;