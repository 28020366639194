import { makeAutoObservable } from "mobx";

class ElectionModeStore {
  _isModesFetched: boolean = false;
  _isCurrentModeFetched: boolean = false;
  modes: string[] = [];
  currentMode: string = "final";

  constructor() {
    makeAutoObservable(this);
  };

  setModes(modes: string[]) {
    this.modes = modes;
    this._isModesFetched = true;
  };

  get isCurrentModeEmpty() {
    return !this._isCurrentModeFetched;
  };

  get isModesEmpty() {
    return !this._isModesFetched;
  };

  get isCurrentModeFinal() {
    return this.currentMode === 'final';
  };

  get dashboardTitle() {
    return this.isCurrentModeFinal ? "Members of Parliament" : "MP Candidates List";
  };

  get dashboardButtonTitle() {
    return this.isCurrentModeFinal ? "Add MP" : "Add candidate";
  }

  setCurrentMode(mode: string) {
    this.currentMode = mode.toLowerCase();
    this._isCurrentModeFetched = true;
  };
};

export default new ElectionModeStore();