import React from "react";

export default (
  bulk: any,
  mandatoryFields: string[],
  setModalTitle: Function,
  setIsModalErrorOpen: Function,
  lawEditMode?: boolean
) => {
  try {
    let missingFields: any = [];
    let mandatoryFieldsCheck = true;
    let unique: any = [...new Set(bulk)];

    if (bulk.length !== unique.length) {
      return false;
    }

    unique.slice(1).forEach((item: any) => {
      if (
        item.includes(null) ||
        item.includes(undefined) ||
        item.includes("")
      ) {
        item.forEach((fieldValue: string, index: number) => {
          if (fieldValue === null || item === undefined || item === "") {
            console.log(unique[0][index]);

            if (lawEditMode) {
              const missingField = unique[0][index];

              if (
                (missingField !== "articleId" &&
                  missingField !== "articleTitle" &&
                  missingField !== "articleText") ||
                missingField === "note" ||
                missingField === "issueDate"
              ) {
                missingFields.push(missingField);
              }
            } else {
              const missingField = unique[0][index];

              if (missingField !== "Note" && missingField !== "Issue date") {
                missingFields.push(missingField);
              }
            }
          }
        });
      }

      missingFields = [...new Set(missingFields)];
    });

    mandatoryFields.forEach((field) => {
      if (bulk[0]?.join().indexOf(field) === -1) {
        mandatoryFieldsCheck = false;
        missingFields.push(field);
      }
    });

    if (missingFields.length !== 0) {
      mandatoryFieldsCheck = false;
    }

    if (!mandatoryFieldsCheck) {
      setModalTitle(
        <>
          <p>Conflict! Check file for missing mandatory fields! </p>
          <ul>
            {missingFields.map((field: any, index: number) => (
              <li key={index}>{field} </li>
            ))}
          </ul>
        </>
      );
      setIsModalErrorOpen(true);
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
    setModalTitle(`Error! ${error}!`);
    setIsModalErrorOpen(true);
    return false;
  }
};
