import React from "react";
import styled, { ThemeProps } from "styled-components";
import {
  Avatar,
  TableHead,
  TableRow,
  Theme,
  Button
} from "@mui/material";
import { INameAvatar } from "../../types/type";

export const THead = styled(TableHead)`
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.paper};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  
  th {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.text.disabled};
    text-transform: uppercase;
    font-size: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0;
  }
`;

export const TRow = styled(TableRow)`
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  
  &:last-child td, &:last-child th {
    border: 0;
  }
`;

export const TContainer = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  max-width: 160px;
  
  .MuiAvatar-root {
    margin-right: 10px;
  }

  @media (min-width: 1640px) {
    span {
      white-space: nowrap;
    }
  }
`;

export const NameAvatar = ({ src, name }: INameAvatar) => {
  return (
    <Name>
      <Avatar src={src} />
      <span>{name}</span>
    </Name>
  )
};

export const ButtonTable = styled(Button)`
  font-size: 14px;
  line-height: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 8px 14px;
  text-transform: none;
  box-shadow: none;
  margin-left: 8px;
  white-space: nowrap;
  background-color: #55AE7E;
  color: #fff;

  @media (max-width: 700px) { 
    font-size: 12px;
  }

  &:hover {
    box-shadow: none;
    background-color: #3b7958;
  }

  &.delete {
    background-color: #c62828;
    &:hover {
      box-shadow: none;
      background-color: #810D0D;
    }
  }
`;


export const TableCellTextContainer = styled.p`
  margin: 0px;
  padding: 0px;

  @media (max-width: 1640px) {
    max-width: 160px;
    word-break: break-all;
  }
`;