import React from 'react';
import PartyForm from "../components/Form/PartyForm/PartyForm";
import Stack from '@mui/material/Stack';
import { useParams, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import PartyFormStore from '../store/PartyFormStore';
import WarningStore from "../store/WarningStore";
import useFetchParties from "../api/useFetch/useFetchParties";
import navigator from '../utils/navigator';
import { RouteList } from '../utils/adminType';
import useGetItemData from '../hooks/useGetItemData';

const AddEditPartyPage = observer(() => {
  const { loading, getParty } = useFetchParties();
  const { loading: loadingEdit, editParty } = useFetchParties();
  const { loading: loadingAdd, addParty } = useFetchParties();
  const { id } = useParams();
  const navigate = useNavigate();

  const onError = (err: any) => {
    if (err.response.data === "You dont have rights for this!") {
      navigate('/');
    }
  };

  useGetItemData(getParty, id, PartyFormStore, onError);

  const onBackClick = () => {
    navigator(WarningStore, navigate);
  };

  const onAddEditParty = async () => {
    const data = PartyFormStore.getData();

    if (id) {
      await editParty(data, id);
    } else {
      await addParty(data);
    };

    navigate(RouteList.parties);
  };

  return (
    <Stack justifyContent="center" alignItems="center" data-testid="add-party-page">
      <h1>{PartyFormStore.componentTitle}</h1>

      <PartyForm
        onAddEdit={onAddEditParty}
        onBack={onBackClick}
        isLoading={loading || loadingEdit || loadingAdd}
      />

    </Stack>
  );
});

export default AddEditPartyPage