import React from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import * as F from "./LawContentStyle";
import ErrorIcon from "@mui/icons-material/Error";
import { IContent } from "../../../types/type";
import { observer } from "mobx-react-lite";
import LawStore from "../../../store/LawStore";
import { LogField } from "../LogForm/LogFormStyles";
import LawArticleLink from "./LawArticleLink";
import { Button, Stack } from "@mui/material";
import i18n from "../../../utils/i18n";

const LawContent = observer(
  ({ isLoading, update, onAdd, parentId }: IContent) => {
    const getArticles = () => {
      if (LawStore.lawData.articles) {
        return Object.entries(LawStore.lawData.articles)
          .reverse()
          .map(([key, value]) => {
            return (
              <LawArticleLink
                key={key}
                id={key}
                lawId={parentId}
                data={value}
                updateLaw={update}
                loading={isLoading}
              />
            );
          });
      }
    };

    const getTranslatedValue = (value: any) => {
      return value ? i18n.t(value) : "";
    };

    const renderContent = () => {
      if (isLoading) {
        return <F.SkeletonParty variant="rectangular" animation="wave" />;
      }

      return LawStore.noErrors ? (
        <CardBox>
          <LogField
            label={i18n.t("legislative_title")}
            name="title"
            value={LawStore.lawData.title}
            disabled={true}
            dataTestId="law-content-title"
          />

          <LogField
            value={getTranslatedValue(LawStore.lawData.category)}
            label={i18n.t("category")}
            disabled={true}
            dataTestId="law-content-category"
          />

          <LogField
            value={new Date(LawStore.lawData.createdAt).toLocaleDateString()}
            label={i18n.t("law_creation_date")}
            disabled={true}
            dataTestId="law-content-date"
          />

          <LogField
            value={LawStore.lawData.views ?? 0}
            label={i18n.t("total_amount_of_law_views")}
            disabled={true}
            dataTestId="law-content-views"
          />

          <LogField
            value={LawStore.lawData.factNumber ?? 0}
            label={i18n.t("fact_number")}
            disabled={true}
            dataTestId="law-content-factNumber"
          />

          <LogField
            value={new Date(LawStore.lawData.issueDate).toLocaleDateString()}
            label={i18n.t("issue_date")}
            disabled={true}
            dataTestId="law-content-issueDate"
          />

          <LogField
            value={getTranslatedValue(LawStore.lawData.issuer)}
            label={i18n.t("issuer")}
            disabled={true}
            dataTestId="law-content-issuer"
          />

          <LogField
            value={getTranslatedValue(LawStore.lawData.lawEnforcement)}
            label={i18n.t("enforcement_of_the_law")}
            disabled={true}
            dataTestId="law-content-lawEnforcement"
          />

          <LogField
            value={new Date(
              LawStore.lawData.legislationDate
            ).toLocaleDateString()}
            label={i18n.t("legislation_date")}
            disabled={true}
            dataTestId="law-content-legislationDate"
          />

          <LogField
            value={LawStore.lawData.legislationNumber ?? 0}
            label={i18n.t("legislation_number")}
            disabled={true}
            dataTestId="law-content-legislationNumber"
          />

          <LogField
            value={getTranslatedValue(LawStore.lawData.legislationType)}
            label={i18n.t("legislation_type")}
            disabled={true}
            dataTestId="law-content-legislationType"
          />

          <LogField
            value={LawStore.lawData.note ?? ""}
            label={i18n.t("note")}
            disabled={true}
            dataTestId="law-content-note"
          />

          <LogField
            value={LawStore.lawData.source ?? ""}
            label={i18n.t("source")}
            disabled={true}
            dataTestId="law-content-source"
          />

          {LawStore.lawData.image && (
            <Stack
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <img
                width={400}
                height={400}
                src={LawStore.lawData.image}
                alt="Law image"
                data-testid="law-content-image"
              />
            </Stack>
          )}

          <F.ButtonContainer>
            <Button
              data-testid="law-content-add-article"
              onClick={() => onAdd(parentId)}
              variant="contained"
              type="button"
            >
              {i18n.t("add_article")}
            </Button>
          </F.ButtonContainer>

          <F.ArticlesContainer>{getArticles()}</F.ArticlesContainer>
        </CardBox>
      ) : (
        <CardBox style={{ textAlign: "center" }}>
          <ErrorIcon color={"error"} />
          <p>There is no laws with such an ID.</p>
          <p>Check the URL in the browser bar.</p>
        </CardBox>
      );
    };

    return <F.Wrap>{renderContent()}</F.Wrap>;
  }
);

export default LawContent;
