const getList = async (fetcher: Function, storage: any, subStorage?: any) => {
  storage.disable();

  if (storage.setTimeMark) {
    storage.setTimeMark(Date.now());
  }

  const setData = (list: any[], amount: number) => {
    storage.setList(list);
    storage.setAmount(amount);
  };

  const { list, amount, partiesData } = await fetcher(storage.getSettings());

  setData(list, amount);

  if (subStorage) {
    subStorage.setAllParties(partiesData);
  }

  storage.enable();
};

export default getList;
