import React, { useState } from "react";
import { UploaderProps } from "../../types/type";
import { IconButton } from "@mui/material";
import uploadLogo from "../../assets/images/uploadIcon.png";
import EditIcon from "../../assets/images/EditIcon";
import ImageUploader from "./UploaderStyles";

const Uploader = ({ 
  pic, 
  onChange, 
  fileType,
  alt 
}: UploaderProps): JSX.Element => {
  const [fileUrl, setFileUrl] = useState("");

  const onFileUpload = async (e: any) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setFileUrl(URL.createObjectURL(file));
    };
    onChange(e);
  };

  const errorHandler = (e: any) => {
    setFileUrl(uploadLogo);
  };

  return (
    <ImageUploader data-testid="image-uploader" className="uploader">
      <label>
        <input
          type="file"
          onChange={onFileUpload}
          data-testid="uploader-select-image"
          accept={fileType || ""}
        />

        <IconButton
          className="uploader__button"
          color="primary"
          aria-label="upload picture"
          component="span"
          size="large">
          <span className="uploader__wrap">
            <img
              data-testid="uploader-image"
              className="uploader__wrap-preview"
              src={!fileUrl ? (pic ? pic : uploadLogo) : fileUrl}
              alt={`${alt ?? 'upload_icon'}`}
              onError={errorHandler}
            />
            <span className="uploader__wrap-edit">
              <EditIcon />
            </span>
          </span>
        </IconButton>
      </label>
    </ImageUploader>
  );
}

export default Uploader;