import React from "react";
import styled, { ThemeProps } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Button, Theme } from "@mui/material";
import { IMenuItem } from "../../types/type";

export const Mode = styled.div`
  padding: 0 30px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 15px;
  
  .mode {
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
    display: inline-block;
    padding: 5px 15px;
    border-radius: 4px;
    text-transform: uppercase;
    background: ${(props: ThemeProps<Theme>) => props.theme.palette?.action.hover};
  }
`;

export const Aside = styled.aside`
  min-width: 260px;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  padding-bottom: 50px;
  z-index: 1400;
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.text.primary};
  padding: 15px 30px;
  font-weight: 600;
  
  img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
`;

export const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 25px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${(props: ThemeProps<Theme>) => props.theme.palette?.divider};
  position: sticky;
  top: 0;
  left: 0;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.default};
  z-index: 1;
`;

export const SubTitle = styled.h4`
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.text.disabled};
  margin: 30px 0 20px 15px;
  font-size: 14px;
`;

export const Menu = styled.div`
  padding: 0 15px
`;

export const Counter = styled.p`
  position: absolute;
  top: -16%;
  right: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  background-color: #d32f2f;
  color: #fff;
`;

export const Item = styled(NavLink)`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 10px;

  &.active {
    button {
      background: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.main};
      color: ${(props: ThemeProps<Theme>) => props.theme.palette?.primary.contrastText};
      box-shadow: 0 0 10px 1px rgba(85, 174, 126, 0.70);
    }
  }

  button {
    justify-content: flex-start;
    padding: 10px 15px;
    text-transform: none;
    color: ${(props: ThemeProps<Theme>) => props.theme.palette?.text.primary};
  }
`;

export const MenuItem = (
  { to, label, handler, dataTestId, end, showCounter, count }: IMenuItem
): JSX.Element => {
  const counterStyles = (count || 0) > 99 ? {
    width: "10%",
    height: "60%",
  } : {
    minWidth: "7.5%",
    minHeight: "7.5%",
  };
  return (
    <Item to={to} end={end}>
      <>
        <Button
          name={to}
          onClick={(e) => handler(e)}
          data-testid={dataTestId}
          fullWidth={true}>
          {label}
        </Button>
        {(showCounter && count !== 0) && (
          <Counter style={counterStyles}>
            {((count || 0) > 99) ? "99+" : count}
          </Counter>
        )}
      </>
    </Item >
  )
};