import { useHttp } from "../../hooks/useHttp";

const useFetchLogin = () => {
  const { loading, request, error, clearError } = useHttp();

  const onLogin = async (login: string, password: string) => {
    return await request("post", "api/auth/login", { login, password });
  };

  return {
    loading, 
    error, 
    clearError,
    onLogin,
  };
};

export default useFetchLogin;