import styled, { ThemeProps } from "styled-components";
import { Theme } from "@mui/material";

export const Wrap = styled.div`
  display: block;
  width: 160px;
  height: 160px;
  margin: 0 auto 30px auto;
  position: relative;
  
  .MuiAvatar-root {
    cursor: pointer;
    width: 160px;
    height: 160px;
    box-shadow: ${(props: ThemeProps<Theme>) => props?.theme.shadows ? props?.theme.shadows[2] : ""};
    
    .MuiSvgIcon-root {
      width: 56px;
      height: 56px;
    }
  }
`;

export const Delete = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  background: ${(props: ThemeProps<Theme>) => props.theme.palette?.background.paper};
  box-shadow: ${(props: ThemeProps<Theme>) => props?.theme.shadows ? props?.theme.shadows[2] : ""};
  border: 0;
  padding: 0;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  color: ${(props: ThemeProps<Theme>) => props.theme.palette?.secondary.main};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .MuiSvgIcon-root {
    width: 20px;
  }
`;