import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack, Button } from "@mui/material";
import LawContent from "../components/Form/LawContent/LawContent";
import { useNavigate } from "react-router-dom";
import LawStore from "../store/LawStore";
import useFetchLaws from "../api/useFetch/useFetchLaws";
import { observer } from "mobx-react-lite";
import { Back } from './AddCandidatePage/AddCandidateStyle';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RouteList } from "../utils/adminType";
import i18n from "../utils/i18n";

const LawPage = observer(() => {
  const [locale, setLocale] = useState(i18n.language);
  const { loading, getLaw } = useFetchLaws();
  const { id } = useParams();
  const navigate = useNavigate();

  const getLawHandler = async (id: any) => {

    getLaw(id).then((res: any) => {
      (res) ? LawStore.setLawData({ ...res }) : LawStore.setError();
    });
  };

  useEffect(() => {
    getLawHandler(id);

    return () => LawStore.resetLawData();
  }, []);

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
    setLocale(i18n.language === "ar" ? "en" : "ar");
  };

  useEffect(() => {
    switchLanguage();
  }, []);

  const onBackClick = () => {
    navigate(RouteList.laws);
  };

  const onAddArticle = (id: string) => {
    navigate(`${RouteList.lawArticleEdit}/${id}/articles/add`);
  };

  return (
    <div data-testid="law-page">
      <Back onClick={onBackClick}>
        <ArrowBackIosIcon style={{ height: 20 }} />
        <p>Back</p>
      </Back>
      <Stack justifyContent="center" alignItems="center">
        <h1>
          {LawStore.pageTitle.length > 100 ? `${LawStore.pageTitle.slice(0, 100)}...` : LawStore.pageTitle} articles
        </h1>

        <div style={{ width: 530 }}>
          <Button
            variant="contained"
            onClick={switchLanguage}
            sx={{ marginBottom: 2 }}
          >
            {`Switch language | ${locale}`}
          </Button>
        </div>

        <LawContent
          isLoading={loading}
          update={getLawHandler}
          onAdd={onAddArticle}
          parentId={id}
        />

      </Stack>
    </div>
  );
});

export default LawPage;
