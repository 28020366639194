import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as T from "../TablesStyle";
import LoadingTable from "../LoadingTable";
import LoadingRow from "../LoadingRow";
import { observer } from "mobx-react-lite";
import LawStore from "../../../store/LawStore";
import setBlur from "../../../utils/setBlur";
import LawsAction from "./LawsAction";
import EmptyRow from "../../../common/EmptyTableRow/EmptyTableRow";
import isStorageLoading from '../../../utils/isStorageLoading';
import { IExtendedTableProps } from "../../../types/type";
import getSlicedString from "../../../utils/getSlicedString";

const LawsTable = observer(({
  onEdit,
  onOpen,
  onDelete
}: IExtendedTableProps): JSX.Element => {
  const rowsEntries = Array(LawStore.rowsPerPage).fill(0);
  const colSpan = Array(4).fill(0);

  const renderRows = () => {
    if (isStorageLoading(LawStore)) {
      return (<LoadingTable loading={LawStore.disabled} rowsEntries={rowsEntries} colSpan={colSpan} />);
    };

    if (LawStore.isStorageEmpty) {
      return <EmptyRow columns={4} />;
    }

    return LawStore.list.map((row, index) => {
      if (row === null) {
        return (<LoadingRow key={index} colSpan={colSpan} />);
      }

      return (
        <T.TRow key={index}>
          <TableCell align="left">
            {new Date(row.createdAt).toLocaleDateString()}
          </TableCell>
          <TableCell align="left">
            {getSlicedString(row.title, 100)}
          </TableCell>
          <TableCell align="left">{row.views ?? 0}</TableCell>
          <TableCell align="center">
            <LawsAction
              id={row.id}
              onEdit={onEdit}
              onOpen={onOpen}
              onDelete={onDelete}
            />
          </TableCell>
        </T.TRow>
      );
    });
  };

  return (
    <T.TContainer>
      <Table>
        <T.THead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Views</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </T.THead>
        <TableBody style={setBlur(LawStore)}>{renderRows()}</TableBody>
      </Table>
    </T.TContainer>
  );
});

export default LawsTable;
