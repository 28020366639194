import PCMElectionModeStore from "../../store/PCMElectionModeStore";

const getElectionModes = async (fetcher: Function) => {
  const electionModes = await fetcher();

  PCMElectionModeStore.setModes(electionModes);

  return electionModes;
};

export default getElectionModes;