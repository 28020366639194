import React from "react";
import { CardBox } from "../../../common/Layout/LayoutStyle";
import * as F from "./PodcastContentStyle";
import ErrorIcon from "@mui/icons-material/Error";
import { IContent } from "../../../types/type";
import { observer } from "mobx-react-lite";
import PodcastsStore from "../../../store/PodcastsStore";
import { LogField } from "../LogForm/LogFormStyles";
import Episode from "./Episode";
import { Button, Stack } from "@mui/material";

const PodcastContent = observer(({
  isLoading,
  onAdd,
  parentId
}: IContent) => {

  const getEpisodes = () => {
    const episodes = PodcastsStore.podcastData.episodes;
    if (episodes) {
      return Object
        .entries(episodes)
        .reverse()
        .map(([key, value]) => {
          return (
            <Episode
              key={key}
              id={key}
              podcastId={parentId}
              data={value}
              loading={isLoading}
            />
          );
        });
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (<F.SkeletonParty variant="rectangular" animation="wave" />);
    };

    return (PodcastsStore.noErrors) ? (
      <CardBox>
        <LogField
          label="Podcast title"
          name="title"
          value={PodcastsStore.podcastData.title}
          disabled={true}
          dataTestId="podcast-content-title"
        />

        <LogField
          value={new Date(PodcastsStore.podcastData.createdAt).toLocaleDateString()}
          label="Podcast creation date"
          disabled={true}
          dataTestId="podcast-content-date"
        />

        <Stack justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
          <img width={400} height={400} src={PodcastsStore.podcastData.image} alt="Podcast image" data-testid="podcast-content-image" />
        </Stack>

        <F.ButtonContainer>
          <Button data-testid="podcast-content-add-article" onClick={() => onAdd(parentId)} variant="contained" type="button">
            Add episode
          </Button>
        </F.ButtonContainer>

        <F.ArticlesContainer>
          {getEpisodes()}
        </F.ArticlesContainer>
      </CardBox>
    ) : (
      <CardBox style={{ textAlign: "center" }}>
        <ErrorIcon color="error" />
        <p>There is no podcasts with such an ID.</p>
        <p>Check the URL in the browser bar.</p>
      </CardBox>
    );
  };

  return (
    <F.Wrap>
      {renderContent()}
    </F.Wrap>
  );
});

export default PodcastContent;
