import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const NewIcon = () => {
  return (
    <SvgIcon style={{ height: 12, width: 12 }} viewBox="0 0 12 12" color="none">
      <path d="M9.91406 0C9.65819 0 9.40228 0.0977187 9.20703 0.292969L8 1.5L10.5 4L11.707 2.79297C12.0975 2.40247 12.0975 1.76941 11.707 1.37891L10.6211 0.292969C10.4258 0.0977187 10.1699 0 9.91406 0ZM7 2.5L1.12988 8.37012C1.12988 8.37012 1.58877 8.329 1.75977 8.5C1.93077 8.671 1.79 9.79 2 10C2.21 10.21 3.32195 10.0622 3.48145 10.2217C3.64095 10.3812 3.62988 10.8701 3.62988 10.8701L9.5 5L7 2.5ZM0.5 10L0.0283203 11.3359C0.00977771 11.3887 0.000204918 11.4441 0 11.5C0 11.6326 0.0526784 11.7598 0.146447 11.8536C0.240215 11.9473 0.367392 12 0.5 12C0.555886 11.9998 0.611342 11.9902 0.664063 11.9717C0.665694 11.971 0.667321 11.9704 0.668945 11.9697L0.681641 11.9658C0.68262 11.9652 0.683596 11.9645 0.68457 11.9639L2 11.5L1.25 10.75L0.5 10Z" fill="#5F5F5F" />
    </SvgIcon>
  )
};

export default NewIcon;