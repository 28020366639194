import React, { useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  LinearProgress,
  List,
  Stack,
  CircularProgress
} from "@mui/material";
import * as S from "./BulkStyle";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import readXlsxFile from "read-excel-file";
import { ExportXLSX } from "../../utils/xlsxExport";
import ModalAlert from "../../common/Modal/ModalAlert";
import { BulkProps } from "../../types/type";
import { observer } from "mobx-react-lite";
import CandidateStore from "../../store/CandidateStore";
import WarningStore from "../../store/WarningStore";
import MandatoryFields from "./MandatoryFields";
import ModalConfirm from "../../common/Modal/ModalConfirm";

const BulkEdit = observer(({
  onUpload
}: BulkProps): JSX.Element => {
  const mandatoryFields = [
    "firstName",
    "lastName",
    "district",
    "electionCode",
    "seatType",
  ];

  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isProgressBar, setIsProgressBar] = useState(false);
  const [isBulkButtonActive, setIsBulkButtonActive] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentProgress, setCurrentProgress] = useState<any>({ progress: 0, step: 0, amount: 0 });
  const [open, setOpen] = useState(false);

  const AddCSVButton = () => (
    <S.CSVButtonContainer>
      <S.InputLabelBulk htmlFor="file-csv-upload">
        <S.LabelBulkContent>
          {!isFileSelected ? (
            <span style={{ width: "40%", textAlign: "center" }}>
              {CandidateStore.fetchedCandidatesList.length === 0
                ? "Please, wait..."
                : "Select XLSX (bulk insert) less than 10 MB"}
            </span>
          ) : (
            <S.FileContainer>
              <span style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>
                {CandidateStore.bulk.uploadedFile.fileName}
              </span>
              <span style={{ fontSize: 12, marginBottom: 10 }}>
                {`${(CandidateStore.bulk.uploadedFile.fileSize / 1000000).toFixed(3)} Mb`}
              </span>
              <S.DeleteUploadedFileButton
                onClick={() => {
                  CandidateStore.setCSVFile([]);
                  setIsFileSelected(false);
                  CandidateStore.setBulkFile({});
                  setIsBulkButtonActive(false);
                  WarningStore.setIsEdited(false);
                }}
              >
                Delete
              </S.DeleteUploadedFileButton>
            </S.FileContainer>
          )}
          {(CandidateStore.bulk.importResult.length > 0) && (
            <S.ProgressContainer>
              Each step contains maximum 100 rows
              {renderImportResult()}
            </S.ProgressContainer>
          )}
        </S.LabelBulkContent>
      </S.InputLabelBulk>

      <input
        style={{ display: "none" }}
        type="file"
        id="file-csv-upload"
        accept=".xlsx"
        disabled={CandidateStore.candidatesAmount === 0}
        onChange={onCSVFileUpload}
      />

      <S.ButtonsWrapperBulk>
        <ExportXLSX
          dataLoaded={CandidateStore.candidatesAmount === 0}
          data={CandidateStore.candidatesList}
          fileName={"sortedCandidates"}
        />

        <Button
          variant='contained'
          disabled={!isBulkButtonActive}
          onClick={onUploadCSVClick}>
          Import
        </Button>
      </S.ButtonsWrapperBulk>
    </S.CSVButtonContainer>
  );

  const downloadXLSX = (data: any) => {
    const element = document.createElement("a");
    const file = new Blob([new Uint8Array(data.data).buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    element.href = URL.createObjectURL(file);
    element.download = "failed_candidates.xlsx";
    document.body.appendChild(element);
    element.click();
  };

  const renderImportResult = () => {
    return CandidateStore.bulk.importResult.map((result, index) => {
      return (
        <S.ResultContainer key={index}>
          <S.ProgressTitle>
            {`${index + 1} step status:`}
          </S.ProgressTitle>
          <S.UploadSuccess>
            {result.data?.successful} candidates uploaded
            successfully
          </S.UploadSuccess>
          <S.ErrorsContainer>
            <S.UploadFailed>
              {result.data?.failed} candidates uploaded with
              errors
            </S.UploadFailed>
            <S.DownloadErrors onClick={() => downloadXLSX(result.xls)}>
              <GetAppIcon
                style={{ width: 11, height: 11, marginRight: 8 }}
              />
              Download error list
            </S.DownloadErrors>
          </S.ErrorsContainer>
        </S.ResultContainer>
      );
    });
  };

  const onCSVFileUpload = async (e: any) => {
    const file = e.target.files[0];
    CandidateStore.setImportResult([]);

    CandidateStore.setBulkFile({
      fileName: e.target.files[0].name,
      fileSize: e.target.files[0].size,
    });

    if (file.size > 1524000) {
      setModalTitle("File is too large!");
      setIsModalErrorOpen(true);
      return;
    };

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async (e) => {
      try {
        let bulk = await readXlsxFile(file);
        setIsBulkButtonActive(true);
        CandidateStore.setCSVFile(bulk);
        setIsFileSelected(true);
        setModalTitle("File seems to be ok. You can proceed for further import.");
        setIsModalErrorOpen(true);
        WarningStore.setIsEdited(true);
      } catch (error) {
        setModalTitle("File is corrupted. Check all the fields.");
        setIsModalErrorOpen(true);
      };
    };
  };

  const onUploadCSVClick = async (e: any) => {
    e.preventDefault();
    setModalTitle("Are you sure to apply edits from your uploaded file to candidates? This action is irreversible");
    setOpen(true);
  };

  const onUploadCSV = async () => {
    setOpen(false);

    const defaultHeaders = [
      "id",
      "avatar",
      "electionCode",
      "district",
      "party",
      "firstName",
      "lastName",
      "nextInfo"
    ];

    const checkFileIsCustom = (headers: string[]) => {
      if (headers.length !== defaultHeaders.length) {
        return true;
      }

      let isFileCustom = false;

      headers.forEach((header) => {
        if (isFileCustom) {
          return;
        }

        if (!defaultHeaders.includes(header)) {
          isFileCustom = true;
        }
      });

      return isFileCustom;
    };

    let upload = true;
    setIsBulkButtonActive(false);
    setIsProgressBar(true);
    let bulk = CandidateStore.bulk.upFileSCV;
    let header = bulk[0];
    bulk = bulk.splice(1, bulk.length);

    if (bulk.length === 0) {
      setModalTitle("Nothing to import");
      setIsModalErrorOpen(true);
      return;
    }

    const isFileCustom = checkFileIsCustom(header);

    const size = 100;
    let step = 0;

    const createOperationsAmount = (amount) => {
      return new Array(amount).fill(" ");
    };

    const importResult: any = [];
    const amount = Math.ceil(bulk.length / size);

    await createOperationsAmount(amount)
      .reduce(async (promise, id, index) => {
        await promise;
        setCurrentProgress({
          progress: Math.floor((index / amount) * 100),
          step: index,
          amount,
        });

        let chunk: any = [];
        chunk.push(header);
        chunk.push(...bulk.slice(step, step + size));

        if (upload) {
          try {
            await onUpload(isFileCustom ? { bulk: chunk, isFileCustom } : { bulk: chunk });
            setModalTitle("File successfully imported.");
            setIsModalErrorOpen(true);
            setIsProgressBar(false);
          } catch (error) {
            if (error.status !== 409) {
              setModalTitle(`Error! ${JSON.stringify(error.message ?? error)}`);
              setIsModalErrorOpen(true);
              upload = false;
            } else {
              importResult.push(error.data);
            }
          }

          step += 100;
        } else {
          setModalTitle("Something wrong!");
          setIsModalErrorOpen(true);
          return;
        }
      }, Promise.resolve());

    CandidateStore.setImportResult(importResult);

    setIsBulkButtonActive(false);
    CandidateStore.setCSVFile([]);
    setIsFileSelected(false);
    CandidateStore.setBulkFile({});
    setIsProgressBar(false);
  };

  useEffect(() => {
    const searchValue = CandidateStore.bulk.searchValue;
    if (
      !searchValue.firstName &&
      !searchValue.lastName &&
      !searchValue.electionCode &&
      !searchValue.mfa &&
      !searchValue.district &&
      !searchValue.party
    ) {
      CandidateStore.setCandidatesList(CandidateStore.fetchedCandidatesList);
    }
  }, [CandidateStore.bulk.searchValue]);

  const filterSearchList = (filteredList: any[], props: any, searchTerm: any) => {
    const term = searchTerm?.toString().toLowerCase().trim().replace(" ", "");
    const targetList = filteredList.length ? filteredList : CandidateStore.fetchedCandidatesList;
    return targetList.filter((item) => {
      const propValue = props.reduce(
        (acc: any, current: any) => acc + item[current]?.toString(),
        ""
      );
      return propValue.toLowerCase().includes(term);
    });
  };

  useEffect(() => {
    const searchValue = CandidateStore.bulk.searchValue;
    if (
      searchValue.firstName ||
      searchValue.lastName ||
      searchValue.electionCode ||
      searchValue.mfa ||
      searchValue.district ||
      searchValue.party
    ) {
      let filteredList: any[] = [];

      if (searchValue.firstName) {
        filteredList = filterSearchList(
          filteredList,
          ["firstName"],
          searchValue.firstName
        );
      }

      if (searchValue.lastName) {
        filteredList = filterSearchList(
          filteredList,
          ["lastName"],
          searchValue.lastName
        );
      }

      if (searchValue.electionCode) {
        filteredList = filterSearchList(
          filteredList,
          ["electionCode"],
          searchValue.electionCode
        );
      }

      if (searchValue.mfa) {
        filteredList = filterSearchList(filteredList, ["mfa"], searchValue.mfa);
      }

      if (searchValue.district) {
        filteredList = filterSearchList(
          filteredList,
          ["district"],
          searchValue.district
        );
      }

      if (searchValue.party) {
        const partyId = CandidateStore.selects.party.list.filter((party: any) => {
          const listParty = party.name.toString().toLowerCase();
          const searchParty = searchValue.party.toString().toLowerCase();
          return listParty.includes(searchParty);
        })[0]?.code;

        filteredList = filterSearchList(filteredList, ["party"], partyId);
      }

      CandidateStore.setCandidatesList(filteredList);
      CandidateStore.setCandidatesAmount(filteredList.length);
    }
  }, [CandidateStore.bulk.searchValue]);

  const onChangeSearchHandler = (e: any, fieldName: string) => {
    CandidateStore.setSearchValue({ [fieldName]: e.target.value });
  };

  const onClearField = (fieldName: any) => {
    CandidateStore.setSearchValue({ [fieldName]: "" });
    CandidateStore.setCandidatesList(CandidateStore.fetchedCandidatesList);
    CandidateStore.setCandidatesAmount(CandidateStore.fetchedCandidatesList.length);
  };

  const affectedFields = ["mp", "electionVotes", "electionCode"];

  return (
    <S.BulkContainer>
      <S.SearchFieldsWrapper>
        <S.Amount>
          Amount: {CandidateStore.candidatesAmount}
        </S.Amount>

        <S.InputWrapper
          label="Full name"
          size="small"
          value={CandidateStore.bulk.searchValue.firstName}
          onChange={(e) => onChangeSearchHandler(e, "firstName")}
          InputProps={{
            endAdornment: CandidateStore.bulk.searchValue.firstName && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("firstName")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="Surname"
          size="small"
          value={CandidateStore.bulk.searchValue.lastName}
          onChange={(e) => onChangeSearchHandler(e, "lastName")}
          InputProps={{
            endAdornment: CandidateStore.bulk.searchValue.lastName && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("lastName")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          InputProps={{
            endAdornment: CandidateStore.bulk.searchValue.electionCode && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("electionCode")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
          label="ELECTION CODE"
          size="small"
          value={CandidateStore.bulk.searchValue.electionCode}
          onChange={(e) => onChangeSearchHandler(e, "electionCode")}
        />

        <S.InputWrapper
          label="MFA CODE"
          size="small"
          value={CandidateStore.bulk.searchValue.mfa}
          onChange={(e) => onChangeSearchHandler(e, "mfa")}
          InputProps={{
            endAdornment: CandidateStore.bulk.searchValue.mfa && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("mfa")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="DISTRICT"
          size="small"
          value={CandidateStore.bulk.searchValue.district}
          onChange={(e) => onChangeSearchHandler(e, "district")}
          InputProps={{
            endAdornment: CandidateStore.bulk.searchValue.district && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("district")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />

        <S.InputWrapper
          label="PARTY"
          size="small"
          value={CandidateStore.bulk.searchValue.party}
          onChange={(e) => onChangeSearchHandler(e, "party")}
          InputProps={{
            endAdornment: CandidateStore.bulk.searchValue.party && (
              <InputAdornment
                position="end"
                onClick={() => onClearField("party")}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />
      </S.SearchFieldsWrapper>

      {isProgressBar ? (
        <Stack style={{ marginTop: "150px", marginLeft: "auto", marginRight: "auto" }} alignItems="center">
          <CircularProgress color="primary" size={150} />
          <p>
            {"Please wait, it may took some time to import..."}
          </p>
          <Stack sx={{ width: '100%' }}>
            <p style={{ margin: "0 auto" }}>{`${currentProgress.step}/${currentProgress.amount}`}</p>
            <LinearProgress color="primary" value={currentProgress.progress} variant="determinate" />
          </Stack>
        </Stack>
      ) : (
        <AddCSVButton />
      )}


      <S.TemplateContainer>
        <div>
          <S.AnnotationHeader>Mandatory fields in file: </S.AnnotationHeader>
          <MandatoryFields fields={mandatoryFields} />
        </div>
        <div>
          <S.AnnotationHeader>Education List </S.AnnotationHeader>
          <List>
            {CandidateStore.selects.education.list?.map((mf: any, i: any) => (
              <S.AnnotationItems key={mf}>
                {`${i} - `}
                {mf}
              </S.AnnotationItems>
            ))}
          </List>
        </div>
        <div>
          <S.AnnotationHeader>Fields that will be changed for non-exported files</S.AnnotationHeader>
          <List>
            {affectedFields.map((data: any, i: any) => (
              <S.AnnotationItems key={i}>{data}</S.AnnotationItems>
            ))}
          </List>
        </div>
      </S.TemplateContainer>

      {isModalErrorOpen && (
        <ModalAlert
          setActive={setIsModalErrorOpen}
          windowTitle={modalTitle}
          disableStates={[setIsModalErrorOpen]}
          callback={() => setModalTitle("")}
        />
      )}

      {open && (
        <ModalConfirm
          setActive={setOpen}
          windowTitle={modalTitle}
          disabled={isProgressBar}
          disableStates={[setOpen]}
          callback={onUploadCSV}
        />
      )}
    </S.BulkContainer >
  );
});

export default BulkEdit;