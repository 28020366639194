import React, { useEffect, useState } from 'react';
import { DistrictMenuProps } from "../../../types/type";
import { neighbourhoods } from "../../../assets/districts/data";
import { provinces } from "../../../assets/provinces/data";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { translateDistrict } from '../../../utils/i18n';

const DistrictsMenu = ({ onChange, value }: DistrictMenuProps): JSX.Element => {
  const [district, setDistricts] = useState<any>([]);
  const [areaData, setAreaData] = useState<any>({});

  useEffect(() => {
    const areas: any[] = [
      "Federal",
      "Provincial_Ninawa",
      "Provincial_Wasit",
    ];

    const areaData = {};

    provinces
      .slice()
      .forEach((province) => {
        const districtsList = neighbourhoods
          .filter((district) => district.province === province.provinceName);
        let districtNames: any[] = [];

        districtsList.forEach((district) => {
          const districtName = district.neighbourhoodId.split("; #")[0];
          districtNames.push(districtName);
        });

        districtNames = [...new Set(districtNames)];

        areas.push(province.provinceName);

        areaData[province.provinceName] = districtNames;

        districtNames.forEach((district) => {
          areas.push(district);
        });
      });

    setDistricts(areas);
    setAreaData(areaData);
  }, []);

  const getFormattedValue = (value) => {
    if (value.includes("_N_")) {
      return `${translateDistrict(value)} (${value.replace(
        "_N_",
        " "
      )})`;
    }

    if (value.includes("_")) {
      return `${translateDistrict(value)}(${value.replace("_", " ")})`;
    }

    return `${translateDistrict(value)}(${value})`;
  };


  const renderDistricts = () => {
    return (
      district.map((item, index) => (
        <MenuItem
          key={index}
          value={item}>
          {getFormattedValue(item)}
        </MenuItem>
      ))
    );
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>District</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          if (areaData[e.target.value]) {
            onChange(areaData[e.target.value]);
            return;
          }
          onChange(e.target.value);
        }}
        MenuProps={{ style: { maxHeight: 350 } }}
        label="District">
        {renderDistricts()}
      </Select>
    </FormControl >
  );
};

export default DistrictsMenu;