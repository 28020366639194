//@ts-nocheck
import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { deepLink, isAuth } from "../api/network";
import logo from "../assets/images/logo.svg";
import parseSearchBar from "../utils/parseSearchBar";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { LogoTitleSpacing, LogoIcon, LogoSpacing, Paper } from "./PasswordResetPageStyles";

const PasswordSuccessPage = () => {
  const navigate = useNavigate();

  if (isAuth) {
    navigate("/");
  };

  const navigateToLogin = () => {
    parseSearchBar(location?.search)?.type === "email"
      ? navigate(deepLink)
      : navigate("/");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <LogoSpacing>
          <LogoIcon src={logo} alt="logo" />
          <LogoTitleSpacing variant="h6">
            E-Parlament
          </LogoTitleSpacing>
        </LogoSpacing>
        <div>
          <LogoTitleSpacing variant="h6">
            {parseSearchBar(location?.search)?.type === "email"
              ? "Email verified!"
              : "Your new password has been set successfully."}
          </LogoTitleSpacing>

          <LoadingButton
            onClick={navigateToLogin}
            size="large"
            variant="contained"
            fullWidth
          >
            Proceed to login
          </LoadingButton>
        </div>
      </Paper>
    </Container>
  );
};

export default PasswordSuccessPage;
