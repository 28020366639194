import React from 'react';
import * as T from "./TablesStyle";
import LoadingRow from "./LoadingRow";
import TableCell from "@mui/material/TableCell";
import { ILoadingTable } from "../../types/type";

const LoadingTable = ({ loading, rowsEntries, colSpan }: ILoadingTable) => {
  return (
    <>
      {loading ? (
        rowsEntries.map((item, index) => (
          <LoadingRow key={index} colSpan={colSpan} />
        ))
      ) : (
        <T.TRow>
          <TableCell colSpan={colSpan.length} align="center">No matching records found</TableCell>
        </T.TRow>
      )}
    </>
  );
};

export default LoadingTable;